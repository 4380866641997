<template>
  <button
    @click="$router.push({ name: 'LearningDashboardIndex' })"
    class="font-body-new text-primary-green flex items-center"
  >
    <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span>
    <span class="font-semibold uppercase text-xs">Back</span>
  </button>
</template>

<script>
export default {
  name: "DashboardBackComponent",
};
</script>

<style></style>
