<template>
  <div
    class="my-8 border-b text-[#A9A9A9] dark:text-[#E5E5E5] dark:border-[#474747] border-[#00000026] dark:bg-transparent"
  >
    <button
      @click="openSlot = 1"
      class="mr-4 -mb-1"
      :class="[
        openSlot == 1
          ? 'border-b-2 border-primary-green font-semibold text-primary-green'
          : '',
      ]"
    >
      Certificate
    </button>
    <button
      @click="openSlot = 2"
      class="mr-4 -mb-1"
      :class="[
        openSlot == 2
          ? 'border-b-2 border-primary-green font-semibold text-primary-green'
          : '',
      ]"
    >
      Bootcamp
    </button>
  </div>
  <div
    class="lg:w-3/4 bg-black rounded mb-4 px-2 md:px-4 py-3 text-white font-body text-xs flex items-center justify-between"
    v-if="settings?.promo_sales == 1"
  >
    <p>End of The Year Discount</p>
    <Vue-countdown
      :time="convertTime('2022-12-31T00:00:00.000+01:00')"
      v-slot="{ totalHours, minutes, seconds }"
      ><p class="font-medium">
        Time Left: {{ totalHours }}h :{{ minutes }}m:{{ seconds }}s
      </p>
    </Vue-countdown>
    <button>
      <span class="iconify" data-icon="material-symbols:close-rounded"></span>
    </button>
  </div>
  <template v-if="paymentList?.certificate && paymentList?.bootcamp">
    <CertificatePayment
      v-if="openSlot == 1"
      :list="paymentList ? paymentList.certificate : []"
    />
    <BootcampPayment
      v-if="openSlot == 2"
      :list="paymentList ? paymentList.bootcamp : []"
    />
  </template>
</template>

<script setup>
import CertificatePayment from "./CertificatePayment.vue";
import BootcampPayment from "./BootcampPayment.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";

import { computed, onMounted, ref } from "vue";
import { inject } from "vue";
import { useRoute } from "vue-router";
import swal from "sweetalert";
import { useStore } from "vuex";
const axios = inject("axios");
const $loading = inject("$loading");
const route = useRoute();
let openSlot = ref(1);

const store = useStore();

const convertTime = (time) => {
  const now = new Date();
  var date = new Date(time);
  date.setDate(date.getDate() + 1);
  const update_at = new Date(date);
  return update_at - now;
};

const settings = computed(() => {
  return store.state.auth.settings;
});

const user = computed(() => {
  return store.state.auth.user;
});

let paymentList = ref(null);

const paymentStatusNotification = () => {
  if (route.query) {
    if (route.query.status == 1) {
      swal({
        title: "Your payment has been received successfully",
      });
    }
    if (route.query.status == 2) {
      swal({
        title: "Payment was not successful",
      });
    }
    if (route.query.status == 3) {
      swal({
        title: "Payment was not cancelled",
      });
    }
  }
};
onMounted(async () => {
  const loader = $loading.show();
  if (route?.params?.openSlot) {
    openSlot.value = route.params.openSlot;
  }
  try {
    const { data } = await axios.get("auth/payments");
    paymentList.value = data.payload;
    loader.hide();
    paymentStatusNotification();
  } catch (error) {
    loader.hide();
    paymentStatusNotification();
  }
});
</script>

<style lang="scss" scoped></style>
