<template>
  <div class="bg-[#333] fixed left-0 right-0 bottom-0 z-50">
    <div
      v-if="deferredPrompt"
      class="text-left py-8 container text-white space-y-4 md:space-y-0 md:space-x-8 flex flex-col md:flex-row items-start md:items-center"
    >
      <p>
        Install the Terra Learning app for free. It won't take up space
        on your device!
      </p>
      <div class="flex flex-row items-center space-x-12 md:space-x-6">
        <button
          class="text-red-400 px-6 py-3 border border-red-400 rounded-md"
          @click="dismiss"
        >
          Dismiss
        </button>
        <button
          class="text-[#e6e6e6] px-6 py-3 border border-primary-green bg-primary-green rounded-md"
          @click="install"
        >
          Install
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      deferredPrompt: null,
      user_data: null,
    };
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  methods: {
    async install() {
      this.deferredPrompt.prompt();
    },
    dismiss() {
      this.deferredPrompt = null;
    },
  },
};
</script>
