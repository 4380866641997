<template>
  <div
    class="grid grid-flow-col place-items-center gap-4 rounded-full px-3 lg:px-2 py-2 border border-primary-green font-body mb-4 cursor-pointer"
  >
    <button
      class="bg-primary-green text-white font-semibold rounded-full text-sm py-1 px-2 hidden lg:block"
    >
      NEW
    </button>
    <h6 class="text-white text-sm">
      <slot name="title"></slot>
    </h6>
    <button class="text-primary-green font-semibold flex items-center">
      <slot name="cta"></slot>
    </button>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
