<template>
  <div class="px-0">
    <LearningNavBar class="z-50" />
    <div class="relative">
      <LearningNavBarMobile class="z-50" />
    </div>
    <main
      class="pt-16 lg:pt-32 min-h-screen"
      :class="$route.name == 'TakeBaseline' ? 'bg-white' : 'bg-[linear-gradient(107.93deg,#ECF2FE_0%,#F1F8F1_104.14%)]'"
    >
      <router-view />
    </main>
  </div>
</template>

<script>
import LearningNavBar from "@/components/lms/LearningNavBar.vue";
import LearningNavBarMobile from "@/components/lms/LearningNavBarMobile.vue";
export default {
  components: {
    LearningNavBar,
    LearningNavBarMobile,
  },
};
</script>

<style></style>
