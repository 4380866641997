<template>
  <div
    v-if="courses?.length > 0"
    class="w-full h-[32rem] lg:h-full lg:py-16 lg:px-16 px-6 py-8 bg-[#FFFFFF] font-body-new rounded-lg shadow-[0px_17.31px_80px_rgba(0,0,0,0.62)]"
  >
    <div
      class="grid gap-y-6 gap-x-24 lg:grid-cols-2 grid-cols-1 overflow-y-scroll h-full"
    >
      <div
        v-for="course in courses"
        :key="course.id"
        class="grid grid-flow-col gap-x-6 md:gap-x-12 justify-between cursor-pointer transition ease duration-100 hover:text-primary-green text-xs text-[#1A1F1A] font-semibold"
        @click="
          user
            ? $router.push({ name: 'SelectCourse' })
            : $router.push({
                name: 'ACoursePage',
                params: { id: course.id, unauthenticated: true },
              })
        "
      >
        <p>{{ course.title }}</p>
        <span
          class="iconify text-sm text-[#000000] inline-block"
          data-icon="akar-icons:arrow-right"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      courses: "auth/courses",
      user: "auth/user",
    }),
  },
  methods: {},
};
</script>
