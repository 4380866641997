<template>
  <main>
    <!-- Whatsapp Chat -->
    <a
        class="fixed bottom-4 right-2 z-50 flex items-center flex-col"
        href="https://wa.me/2347042164117"
        target="_blank"
    >
        <i class="fa-brands fa-whatsapp text-5xl text-primary-green"></i>
        <span class="text-primary-green font-bold">Chat</span></a
    >
  </main>
</template>

<script>
export default {
    name: "WhatsAppComponent",

}
</script>

<style>

</style>
