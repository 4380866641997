<template>
<div class="tooltip font-body">
    <slot name="button"></slot>
  <span class="tooltiptext text-xs">
    <slot name="message"></slot>
  </span>
</div>  
</template>

<script>
export default {

}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 7px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  @apply bg-[#F2F3F2] text-slate-800 shadow-xl border border-[#acacac];
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #F2F3F2 transparent transparent transparent;
}
.tooltip .tooltiptext {
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
}

</style>