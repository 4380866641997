<template>
  <div class="progress-bar">
    <div class="bar" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    duration: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      progress: 100,
      intervalId: null,
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const intervalTime = 1000; // milliseconds
      const numIntervals = this.duration / intervalTime;
      let currentInterval = 0;
      this.intervalId = setInterval(() => {
        currentInterval++;
        this.progress = (currentInterval / numIntervals) * 100;
        if (this.progress <= 0) {
          clearInterval(this.intervalId);
          //   this.$emit("countdown-complete");
        }
      }, intervalTime);
    },
  },
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background-color: #f1f1f1;
}

.bar {
  height: 100%;
  border-radius: 10px;
  background-color: #4caf50;
}
</style>
