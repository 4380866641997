<template>
  <main class="container px-0">
    <div class="lg:w-4/6">
      <div
        class="grid place-content-between grid-flow-col place-items-center px-4 md:px-0"
      >
        <h1
          class="font-header-1 dark:text-white text-[#0C0C0C] font-extrabold text-2xl md:text-3xl"
        >
          Week 1 Quiz
        </h1>
        <div
          v-if="selected.length > 0"
          class="hidden md:grid gap-4 grid-flow-col place-items-center"
        >
          <h5
            class="font-header-1 font-bold text-xl dark:text-white text-[#0C0C0C]"
          >
            Quiz Score:
          </h5>
          <div class="flex items-center justify-between">
            <QuizRating :score="score" />
            <span
              class="font-body font-semibold text-4xl text-[#0C0C0C] dark:text-white"
              >{{ score }}</span
            >
          </div>
        </div>
      </div>
      <div class="">
        <div class="my-8">
          <div
            class="space-y-5 mb-16"
            v-for="(question, index) in selected"
            :key="index"
          >
            <span
              class="dark:text-[#BABABA] dark:bg-[#333333] text-[#838383] bg-[#F4F4F4] font-header-1 font-bold text-sm rounded-full p-2 w-max ml-4 md:ml-0"
            >
              Question {{ index + 1 }} /
              {{ selected.length }}
            </span>
            <p
              class="dark:text-[#E5E5E5] text-[#242424] font-body px-3 md:px-0"
            >
              {{ question.question }}
            </p>
            <div class="flex flex-col font-light space-y-3 font-body">
              <label
                v-for="(option, _index) in question.options"
                :key="_index"
                v-show="option?.trim() != '' && option"
                class="cursor-pointer text-[#242424] dark:text-[#E5E5E5] relative"
                aria-disabled="true"
                :class="[
                  option?.trim() == question?.selected?.trim() &&
                    option?.trim() != question?.answer?.trim() &&
                    `dark:bg-[#991F2A] dark:text-[#E5E5E5] bg-[#FFE8EA] text-[#242424]`,

                  option?.trim() == question?.answer.trim() &&
                    `dark:bg-[#343434] dark:text-[#74C272] bg-[#EAF6EA] text-[#242424]`,
                ]"
              >
                <input
                  type="radio"
                  disabled
                  :name="'question_' + index"
                  class="block absolute left-1 top-1.5 w-5 h-5 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 font-light"
                />
                <div class="w-[87%] pl-6">
                  <span class="w-10/12">{{ option }}</span>
                  <span
                    v-if="option?.trim() == question?.answer.trim()"
                    class="absolute right-2 top-1 font-semibold text-sm text-[#52A350]"
                    >Correct</span
                  >
                  <span
                    v-if="
                      option?.trim() == question?.selected.trim() &&
                      option?.trim() != question?.answer?.trim()
                    "
                    class="absolute right-2 top-1 font-semibold text-sm dark:text-[#FCFCFC] text-[#FF0016]"
                    >Wrong</span
                  >
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="px-4 md:p-0 w-full" v-if="selected.length > 0">
          <button
            class="bg-primary-green w-full md:w-fit text-white py-3 px-12 rounded-md disabled:cursor-not-allowed disabled:bg-slate-300"
            @click="$router.go(-1)"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { decrypt } from "@/helpers/encrypt";
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
import { useRoute } from "vue-router";
import QuizRating from "./QuizRating.vue";
const axios = inject("axios");
const $loading = inject("$loading");
const store = useStore();

const darkTheme = computed(() => {
  return store.state.auth.darkTheme;
});
const route = useRoute();
let id = ref("");
let score = ref(0);
let selected = ref([]);

onMounted(async () => {
  const loader = $loading.show();
  try {
    id.value = decrypt(route.params.id);
    const { data } = await axios.get("auth/quizzes/submitted/" + id.value);
    score.value = data.payload.score;
    selected.value = data.payload.selected;
    loader.hide();
  } catch (error) {
    console.log(error);
    loader.hide();
  }
});
</script>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      colorTheme: "auth/darkTheme",
    }),
    // I used this because the SETUP method was returning the theme as typeof { Object } so I couldn't read it as a Boolean -- @_localdev <--OGA-MI-SIR-->
    correctState() {
      if (this.colorTheme) {
        return {
          wrong: "",
          correct: "",
        };
      } else {
        return {
          wrong: "",
          correct: "",
        };
      }
    },
  },
};
</script>

<style scoped>
label {
  @apply py-1 px-4 md:px-1;
}
</style>
