const installmentHelper = (amount, intervals, item = null) => {
    if (amount && intervals) {
        if (item == 1) return [Math.ceil(amount / 5)]
        if (intervals <= 1) {
            return [amount];
        }
        let unit = Math.floor(amount / intervals);
        let arr = [];
        for(let i=0 ;i<intervals;i++){
            arr.push(amount - i * unit)
        }
        return arr;
    } else return [];
};

export default installmentHelper;
