<template>
  <div class="px-0">
    <div
      class="flex items-center justify-center w-full h-[100vh]"
      v-if="questions"
    >
      <h1>container</h1>
      <div
        v-if="showGameWaiting == true"
        class="flex flex-col lg:w-[30%] w-full h-[60%] px-5 relative"
      >
        <h1>cont 1</h1>
        <p class="font-body text-sm text-center">
          You gained <strong>{{ currentRoundPoints }}</strong> points in this
          round
        </p>
        <div
          class="w-4/5 mx-auto h-4/5 flex items-center justify-center relative"
        >
          <!-- <TrexPage :key="level" /> -->
          <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/games/tom-and-jerry-tom.gif" alt="tom and jerry" />
        </div>
        <div
          class="flex flex-col items-center justify-between mt-4 uppercase font-body font-medium text-sm"
        >
          <p>Next Round Starts In:</p>
          <Vue-countdown
            class="font-semibold"
            @end="
              nextGame ? filterGame('WAIT') : $router.push({ name: 'GameEnd' })
            "
            :time="convertTime(nextGame?.start_time ?? currentGame?.end_time)"
            v-slot="{ minutes, seconds }"
            >{{ minutes }}m {{ seconds }}s</Vue-countdown
          >
        </div>
      </div>
      <div
        v-if="currentGame && currentGame?.options && showGameWaiting == false"
        id="question-grp"
        class="flex flex-col md:w-[60%] w-full h-[60%] px-5 font-body"
        :key="trigger"
      >
        <h2>cont2</h2>
        <div
          class="bg-[#F19534] text-white rounded-2xl w-fit text-center py-2 text-xs px-4 mb-3"
        >
          Round {{ level }}
        </div>
        <p class="text-[#0C180C] font-semibold text-[24px] mt-3 mb-3">
          {{ currentGame?.question }}
        </p>
        <div
          @click="handleClick(currentGame?.id, option)"
          :id="option"
          v-for="(option, index) in JSON.parse(currentGame?.options)"
          :key="index"
          class="bg-[#fff] mb-3 px-5 py-4 cursor-pointer option rounded shadow-[0px_8px_50px_rgba(0,0,0,0.08)] font-semibold"
          :class="
            answer == option ? 'border border-[#93C992] bg-[#F1F8F1]' : ''
          "
        >
          {{ option }}
        </div>
        <div class="flex items-center justify-between mt-4">
          <div class="text-green-500 text-[14px] font-semibold">
            Submits in:
            <Vue-countdown
              @end="filterGame()"
              :time="convertTime(currentGame?.end_time)"
              v-slot="{ seconds }"
              >{{ seconds }}s</Vue-countdown
            >
          </div>
          <button
            @click="handleNext"
            class="text-center bg-green-600 text-white px-10 py-2 cursor-pointer rounded-md"
            :disabled="answer?.length < 1 || !answer"
          >
            {{ level < rounds ? "Next" : "Finish" }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex items-center justify-center w-full h-[100vh] bg-[#fff] font-body"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import LearningNavBar from "@/components/lms/LearningNavBar.vue";
import LearningNavBarMobile from "@/components/lms/LearningNavBarMobile.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Countdown from "./Countdown.vue";
import { ref } from "vue";

export default {
  components: {
    Countdown,
    LearningNavBar,
    LearningNavBarMobile,
    VueCountdown,
  },

  data() {
    return {
      disabled: true,
      countdown: 10,
      levelStart: 0,
      answer: "",
      start: 0,
      point: 10,
      score: 0,
      questions: null,
      end: false,
      timeCont: null,
      winners: null,
      show: null,
      level: 1,
      current_game_id: null,
      rounds: null,
      errorMessage: null,
      currentGame: null,
      nextGame: null,
      showGameWaiting: false,
      currentRoundPoints: 0,
      trigger: Math.random(),
    };
  },
  mounted() {
    this.fetchGame();
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
    }),
  },
  watch: {},

  methods: {
    changeState() {
      console.log("fired");
      this.showGameWaiting = !this.showGameWaiting;
      this.showGameWaiting = this.showGameWaiting;
      this.showGameWaiting = !this.showGameWaiting;
      this.trigger += Math.random();
      // console.log(this.trigger)
    },
    filterGame(val = "DEFAULT") {
      // console.log("fileter games");
      const picked = JSON.parse(localStorage.getItem("answer"));
      if (this.questions?.length > 0) {
        let CURRENT_TIME = new Date();
        let curr_game = this.questions?.find((item) => {
          return (
            CURRENT_TIME >= new Date(item?.start_time) &&
            CURRENT_TIME <= new Date(item?.end_time)
          );
        });

        if (curr_game) {
          let i = this.questions.findIndex((item) => {
            return item?.id == curr_game?.id;
          });
          this.level = i + 1;
          this.nextGame = this.questions?.[this.level] ?? false;
          if (val == "DEFAULT") {
            // check if user has picked answer for question
            if (curr_game.id == picked?.gameId && picked.answer != "") {
              this.showGameWaiting = true;
              this.currentGame = false;
            } else {
              this.showGameWaiting = false;
              return (this.currentGame =
                curr_game ?? this.questions?.[this.level]);
            }
          } else {
            this.showGameWaiting = false;
            return (this.currentGame =
              curr_game ?? this.questions?.[this.level]);
          }
        } else {
          this.showGameWaiting = true;
          return (this.currentGame = false);
        }
      } else return (this.currentGame = false);
    },
    convertTime(time) {
      const now = new Date();
      var date = new Date(time);
      date.setDate(date.getDate());
      const update_at = new Date(date);
      return update_at - now;
    },
    async fetchGame() {
      // console.log("fetch games");
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/games");
        this.questions = data?.payload?.game;
        // console.log(this.questions);
        this.rounds = data?.payload?.rounds;
        this.filterGame();
        loader.hide();
      } catch (error) {
        console.error(error);
        // this.$router.push({name: "GameScore"})
        this.errorMessage = error?.response?.data?.payload ?? "Error!";
        loader.hide();
      }
    },

    removeClass() {
      //select the option group
      const options = document.querySelectorAll(".option");
      //remove active class
      Array.from(options).forEach((option) =>
        option.classList.remove("active")
      );
    },

    handleClick(game_id, option) {
      this.current_game_id = game_id;
      this.answer = option;
    },

    stopPlay() {
      this.handleNext(this.currentGame?.id, "");
    },
    async handleNext() {
      // console.log("submit answer");
      let loader = this.$loading.show();
      localStorage.setItem(
        "answer",
        JSON.stringify({ gameId: this.current_game_id, answer: this.answer })
      );
      try {
        const { data } = await axios.post("auth/games/save", {
          id: this.currentGame?.id,
          answer: this.answer ?? " ",
        });
        this.currentRoundPoints = data.payload;
        this.answer = null;
        this.current_game_id = null;
        this.showGameWaiting = true;
        if (this.level < this.rounds) this.level++;
        loader.hide();
      } catch (error) {
        loader.hide();
        this.answer = null;
        this.current_game_id = null;
        this.showGameWaiting = true;
        if (this.level < this.rounds) this.level++;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

#cont {
  background: linear-gradient(107.93deg, #ecf2fe 0%, #f1f8f1 104.14%);
}

button:disabled {
  background-color: #c3c8c3;
}

#a.active,
#b.active,
#c.active,
#d.active {
  border: 2px solid blue;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

tbody tr {
  margin-bottom: 40px;
  /* background-color: red; */
  /* border: 3px solid #ededed !important; */
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

tr > td {
  border: none;
  margin-bottom: 20px;
  padding: 15px 0px;
  margin-bottom: 40px;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: 150ms ease;
}

.bounce-enter-from,
.bounce-leave-to {
  transform: scale(0);
}
</style>
