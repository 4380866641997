<template>
  <div class="lg:w-4/5 mx-auto mt-8 lg:mt-24">
    <h1 class="font-header-1 font-bold text-body-text text-xl lg:text-4xl">
      Enter new password
    </h1>
    <!-- <p
            class="text-body-text font-normal text-base lg:text-lg font-body mt-4"
        >
            Enter your new password
        </p> -->
    <form @submit.prevent="changePassword()">
      <fieldset class="mt-8 mb-4 relative">
        <div
          class=""
          :class="[
            v$.password.$invalid && password.length > 0 ? 'mb-0' : 'mb-6',
          ]"
        >
          <CustomInputField
            errorMessage="Weak password"
            :validState="!v$.password.$invalid"
            label="Create password"
            inputType="password"
            required
            v-model="password"
          />
        </div>
      </fieldset>
      <CustomInputField
        errorMessage="Password doesn’t match"
        :validState="!v$.rePassword.$invalid"
        label="Confirm password"
        inputType="password"
        required
        class="mt-4 mb-8"
        v-model="rePassword"
      />
      <button
        type="submit"
        :disabled="v$.$invalid || isLoading"
        v-html="buttonText"
      ></button>
    </form>
  </div>
</template>

<script>
import CustomInputField from "./../../components/inc/CustomInputField.vue";
//
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import axios from "axios";
import swal from "sweetalert";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    CustomInputField,
  },
  data() {
    return {
      token: "",
      email: "",
      password: "",
      rePassword: "",
      buttonText: "Change Password",
      isLoading: false,
    };
  },
  validations() {
    return {
      password: { required, minLength: minLength(6) },
      rePassword: { required, sameAs: sameAs(this.password) },
    };
  },
  methods: {
    changePassword() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.buttonText = `<i class="fa-solid fa-spinner fa-spin fa-2x"></i>`;
        this.isLoading = true;
        try {
          const data = {
            password: this.password,
            token: this.token,
            email: this.email,
          };
          axios
            .post("password/change", data)
            .then(() => {
              this.buttonText = "Change Password";
              this.isLoading = false;
              swal({
                title: "Successful",
                text: "Your password has been changed successfully",
              }).then(() => {
                this.$router.push({ name: "LoginPage" });
              });
            })
            .catch((err) => {
              if (err && err.response && err.response.data) {
                swal({
                  title: "Oops!",
                  text: `${err.response.data.payload}`,
                });
              } else {
                swal({
                  title: "Oops!",
                  text: "Please try again shortly",
                });
              }
              this.buttonText = "Change Password";
              this.isLoading = false;
            });
        } catch (error) {
          console.log(error);
          this.buttonText = "Change Password";
          this.isLoading = false;
        }
      }
    },
  },
  mounted() {
    if (!this.$route.query.token || !this.$route.query.email) {
      this.$router.push({ name: "LoginPage" });
    } else {
      this.token = this.$route.query.token;
      this.email = this.$route.query.email;
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  @apply text-base lg:text-lg font-semibold text-white bg-primary-green rounded-md  py-4 px-12 w-full;
}
button:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF];
}
</style>
