<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center p-4 z-50"
    @click="closeModal()"
  >
    <div
      class="flex items-center flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 pt-12 pb-12 space-y-4 md:w-3/5 text-center"
      ref="bootcampModal"
    >
    <form class="w-full" @submit.prevent="submitApplication()">
        <legend class="font-semibold text-xl mb-6">Team Lead Application</legend>
        <div>
            <label for="experience">Do you have any leadership experience</label>
            <textarea required v-model="experience" name="experience" id="experience"></textarea>
        </div>
        <div>
            <label for="reason">Why do you want to be team lead?</label>
            <textarea required v-model="reason" name="reason" id="reason"></textarea>
        </div>
        <button type="submit" class="bg-primary-green text-lg font-semibold text-white rounded px-4 py-2 my-4">Apply</button>
    </form>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
export default {
  data() {
    return {
        experience: "",
        reason: "",
    }
  },
  props:{
    team: {
        type: Number,
    }
  },
  methods: {
    closeModal() {
      const box = this.$refs.bootcampModal;
      if (!box?.contains(event.target)) {
        return this.$emit("closemodal");
      }
      return;
    },
    async submitApplication() {
        let loader = this.$loading.show()
        console.log(this.team)
        const payload = { reason: this.reason, experience: this.experience }
        try {
            const { data } = await axios.post('v2/auth/bootcamp/team-lead-apply', { response: payload, bootcamp_id: this.team })
            swal({
                title: "Success",
                text: `${data?.message ?? "Please try again shortly"}`,
            });
            this.$emit("closemodal");
            loader.hide()
        } catch (error) {
            console.error(error);
            swal({
                title: "Error!",
                text: `${error?.response?.data?.message ?? "Please try again shortly"}`,
            });
            loader.hide()
        }
    }
  },
};
</script>

<style scoped>
textarea {
    @apply w-full rounded;
}
</style>
