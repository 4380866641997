<template>
  <nav
    class="md:hidden w-full md:pb-32 absolute font-body overflow-y-scroll scrollbar-hide min-h-fit z-30 bg-[#FAFAFA] dark:bg-[#121212]"
  >
    <div class="px-4">
      <BackButton class="my-4 ml-0 md:my-12" />
      <div class="mb-4">
      <StreakBar />
    </div>
    </div>
    <nav class="py-4 overflow-y-scroll scrollbar-hide">
      <div
        @click="openLearningPath = !openLearningPath"
        class="px-4 font-body font-semibold text-primary-green cursor-pointer"
      >
        <i
          :class="openLearningPath ? 'fa-xmark' : 'fa-bars'"
          class="fa-solid"
        ></i>
        Lessons Navigation
      </div>
      <div v-if="openLearningPath" class="text-gray-500 h-screen pt-6">
        <div>
          <div class="">
            <template v-for="(week, index) in [1, 2, 3, 4]" :key="index">
              <Disclosure
                as="div"
                v-slot="{ open }"
                class="border-t py-4 dark:border-[#282828] border-[#D2D7D2]"
              >
                <DisclosureButton
                  :class="open ? 'pb-4' : ''"
                  as="div"
                  class="dark:text-[#C1C6C1] text-[#112211] px-4 md:px-12 flex text-sm md:text-base justify-between items-center w-full cursor-pointer"
                >
                  <span class="font-bold">{{ `${weekModule()} ${week}` }}</span>
                  <i
                    :class="open ? 'fa-chevron-up' : 'fa-chevron-down'"
                    class="fa-solid text-xs"
                  ></i>
                </DisclosureButton>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="-translate-y-1"
                  enter-to-class="translate-y-0"
                  leave-active-class="transition duration-100 ease-out"
                  leave-from-class="translate-y-0"
                  leave-to-class="-translate-y-1"
                >
                  <div
                    v-show="
                      (learningType == 'learning' &&
                        lessons?.length > 0 &&
                        eachWeekLessons(week)?.includes(currentLesson?.id)) ||
                      open
                    "
                  >
                    <DisclosurePanel
                      as="ul"
                      static
                      class="dark:text-[#C1C6C1] text-[#3F473F]']"
                    >
                      <!-- ... -->
                      <template
                        v-if="
                          lessons?.length > 0 &&
                          eachWeekLessons(week).length > 0
                        "
                      >
                        <template v-for="(lesson, _index) in eachWeekLessons(week)"
                      :key="'week_' + _index">
                          <li
                      @click="setLesson(lesson, 'learning')"
                      v-if="lesson?.modules?.length > 0"
                      :class="
                        lesson?.id === currentLesson?.id &&
                        learningType == 'learning' &&
                        $route.name === 'LessonPage'
                          ? 'bg-[#51a35021]'
                          : ''
                      "
                      
                      class="py-4 cursor-pointer text-xs text-left md:text-sm px-4 md:px-12 flex justify-start items-start"
                    >
                            <span
                              class="iconify text-lg"
                              data-icon="ei:play"
                            ></span>
                            <span class="mr-2 truncate">{{ lesson.title }}</span>
                            <i
                              v-if="isWatched(lesson?.id)"
                              class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
                            ></i>
                          </li>
                        </template>
                      </template>
                      <li
                        v-if="canSeeResource()"
                        @click="goToResource(week)"
                        :class="
                          $route.name.includes('Resource') &&
                          route.params.week &&
                          decrypt(route.params.week) == week
                            ? 'bg-[#51a35021]'
                            : ''
                        "
                        class="cursor-pointer py-4 text-xs text-left md:text-sm px-4 lg:px-12 flex justify-start items-start"
                      >
                        <span
                          class="iconify text-lg"
                          data-icon="bi:text-left"
                        ></span>
                        <span class="mr-2"
                          ><span class="font-semibold">Slides</span>: Resources
                          to read</span
                        >
                        <!-- Quiz taken checkmark -->
                        <i
                          v-if="takenQuiz && takenQuiz.includes(week)"
                          class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
                        ></i>
                      </li>
                      <li
                        v-if="canSeeQuiz()"
                        @click="goToQuiz(week)"
                        :class="
                          $route.name.includes('Quiz') &&
                          route.params.week &&
                          decrypt(route.params.week) == week
                            ? 'bg-[#51a35021]'
                            : ''
                        "
                        class="cursor-pointer py-4 text-xs text-left md:text-sm px-4 lg:px-12 flex justify-start items-start"
                      >
                        <span
                          class="iconify text-lg"
                          data-icon="healthicons:i-exam-multiple-choice-outline"
                        ></span>
                        <span class="mr-2"
                          >{{ `${weekModule()} ${week} Quiz` }}</span
                        >
                        <!-- Quiz taken checkmark -->
                        <i
                          v-if="takenQuiz && takenQuiz.includes(week)"
                          class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
                        ></i>
                      </li>
                      <div v-if="canSeeTasks()">
                        <li
                          @click="goToTask(week)"
                          :class="
                            $route.name.includes('Quiz') &&
                            route.params.week &&
                            decrypt(route.params.week) == week
                              ? 'bg-[#51a35021]'
                              : ''
                          "
                          class="cursor-pointer py-4 text-xs text-left md:text-sm px-4 lg:px-12 flex justify-start items-start"
                        >
                          <span
                            class="iconify text-lg"
                            data-icon="healthicons:i-exam-multiple-choice-outline"
                          ></span>
                          <span class="mr-2"
                            >{{ `${weekModule()} ${week} Task` }}</span
                          >
                        </li>
                      </div>
                    </DisclosurePanel>
                  </div>
                </transition>
              </Disclosure>
            </template>
            <button 
                @click="$router.push({ name: user?.hasTeam == 1 ? 'MyTeamPage' : 'SelectTeamPage' })"
                v-if="user?.batch?.learning_type == 'instructor' && user?.cohort >= 8 && user?.cohort < 10"
                class="dark:text-[#C1C6C1] text-[#112211] px-4 lg:px-12 flex text-sm md:text-base justify-between items-center w-full border-t py-4 dark:border-[#282828] border-[#D2D7D2]"
                :disabled='siteSettings.week < 5'
            >
                  <span class="font-bold">
                    Level 5 - 6
                  </span>
                  <i class="fa-solid text-xs fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </nav>
</template>

<script>
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      openLearningPath: false,
    };
  },
  components: {
    BackButton,
  },
  computed: {
    ...mapGetters({
      currLesson: "lms/currentLesson",
    }),
  },
  watch: {
    // close mobile lesson nav when user select lesson
    currLesson() {
      this.openLearningPath = false;
    },
    // close mobile lesson nav when $route change
    $route() {
      this.openLearningPath = false;
    },
  },
};
</script>

<script setup>
import { quizTask, weekModule, goToTask, goToQuiz, goToResource, isWatched, canSeeTasks, canSeeQuiz, canSeeResource, setLesson } from "@/helpers/learningController";

import StreakBar from "@/components/lms/StreakBarComponent.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import CourseProgress from "@/components/lms/CourseProgressBar.vue";

import { useStore } from "vuex";
import { onMounted } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
// import swal from "sweetalert";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
import { encrypt, decrypt } from "@/helpers/encrypt";
const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();
/**
 * Array of all all taken quiz
 * @returns [Array]
 */
const takenQuiz = computed(() => {
  return store.state.lms.takenQuiz;
});
const allQuizScore = computed(() => {
  return store.state.lms.allQuizScore;
});

const fetchTakenQuiz = () => {
  store.dispatch("lms/setTakenQuiz");
};

const user = computed(() => {
  return store.state.auth.user;
});

const learningType = computed(() => {
  return store.state.lms.learningType;
});

const lessons = computed(() => {
  return store.state.lms.lessons;
});

const close = () => {
  this.openLearningPath = false;
};

const jobberman_lessons = computed(() => {
  return store.state.jobberman.lessons;
});
const orientation_videos = computed(() => {
  return store.state.lms.orientation_videos;
});

const badges = computed(() => {
  return store.state.lms.badges;
});
const siteSettings = computed(() => {
  return store.state.auth.settings;
});

const currentLesson = computed(() => {
  return store.state.lms.currentLesson;
});
const watchedLessons = computed(() => {
  return store.state.lms.watchedLessons;
});
const userStreak = computed(() => {
  return store.state.lms.userStreak;
});



const eachWeekLessons = computed(() => {
  return (week) => lessons.value.filter((item) => item.week == week);
});

const eachWeekBadges = computed(() => {
  return (week) => badges.value.filter((item) => item.week == week);
});

</script>

<style scoped>
* {
  @apply transition ease-linear;
}
.iconify {
  @apply mr-2;
}
</style>