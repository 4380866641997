<template>
  <main>
    <!-- <h2
      class="font-body-new font-bold text-body-text text-lg leading-6 text-center mb-6"
    >
      <span class="text-[#9B9B9B]">What is your</span> <br />reason for joining
      the internship?
    </h2> -->
    <p class="font-body font-[800] text-[#242424]">
      What is your reason for joining
      the internship?
    </p>
    <form @submit.prevent="submit()" class="w-full">
      <fieldset class="flex flex-col font-light my-4 space-y-3">
        <div class="">
          <label for="learn_new_skill" class="">
            <input
              type="radio"
              v-model="reason_for_joining"
              name="reason_for_joining"
              id="learn_new_skill"
              value="to learn a new skill"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            To learn a new skill
          </label>
        </div>
        <div class="">
          <label for="improve_skill" class="">
            <input
              type="radio"
              v-model="reason_for_joining"
              name="reason_for_joining"
              id="improve_skill"
              value="to improve my skill"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            To improve my skill
          </label>
        </div>
        <div class="">
          <label for="get_job" class="">
            <input
              type="radio"
              v-model="reason_for_joining"
              name="reason_for_joining"
              id="get_job"
              value="to get a job"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            To get a job
          </label>
        </div>
        <!-- <div class="">
          <label for="others" class="">
            <input
              type="radio"
              v-model="reason_for_joining"
              name="reason_for_joining"
              id="others"
              value="others"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Others
          </label>
        </div> -->
      </fieldset>
      <button type="submit" :disabled="!reason_for_joining">Next</button>
    </form>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      reason_for_joining: null,
    };
  },
  computed: {},
  methods: {
    submit() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      user.reason_for_joining = this.reason_for_joining;
      localStorage.setItem("tempUser", JSON.stringify(user));
      this.$emit("next");
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      this.reason_for_joining = JSON.parse(
        localStorage.getItem("tempUser")
      ).reason_for_joining;
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  @apply flex flex-col;
  fieldset {
    .radio-check-group {
      @apply col-span-1 grid grid-cols-1;
      label {
        @apply font-body-new font-semibold text-sm text-[#3D403D] border border-[#D2D2D2] rounded-lg px-6 py-4 w-full cursor-pointer;
      }
    }
  }
  button[type="submit"] {
    @apply py-3 px-8 text-white bg-primary-green font-body-new rounded-md ml-auto mt-6 lg:mt-8;
  }
}
</style>
