<template>
  <div>
    <div class="container md:p-0">
      <BootcampModal
        @closemodal="
          (showBootcampModal = false), $router.push({ name: 'UserTeamPage' })
        "
        :title="currentTeam.title"
        v-if="showBootcampModal"
      />
      <button
        @click="$router.push({ name: 'AllTeamsPage' })"
        class="font-body text-primary-green flex items-center my-4 md:my-12"
      >
        <span
          class="iconify mr-2 text-2xl font-extralight"
          data-icon="entypo:chevron-with-circle-left"
        ></span>
        <span class="font-semibold">Go Back</span>
      </button>
      <div class="md:w-5/6">
        <div
          class="p-4 md:p-6 bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body grid grid-flow-row md:grid-flow-col justify-between items-start gap-12 md:gap-24"
        >
          <div>
            <h3
              class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl"
            >
              {{ currentTeam.title }}
            </h3>
            <p
              class="font-normal text-[#666666] dark:text-[#F4F4F4] text-sm mt-3 mb-12"
            >
              {{ currentTeam.description }}
            </p>
            <button
              :disabled="
                currentTeam?.team?.count < 1 || !currentTeam?.team?.count
              "
              @click="joinTeam(currentTeam?.id)"
              class="font-semibold bg-primary-green text-white rounded-md py-3 px-6 text-sm mt-auto disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
              Join Team
            </button>
          </div>
          <div
            class="rounded bg-[#F7F7F7] dark:bg-[#0000004D] p-3 md:p-4 font-body space-y-3 w-fit"
          >
            <h4 class="font-semibold text-[#222222] dark:text-[#E5E5E5]">
              This team needs:
            </h4>
            <p class="text-[#666666] dark:text-[#E5E5E5] text-xs uppercase">
              <span
                class="font-semibold text-[#222222] dark:text-[#F4F4F4] text-3xl mr-3"
                >{{ currentTeam?.team?.count ?? "0" }}</span
              >
              {{ user?.course?.title ?? "" }} INTERNS
            </p>
          </div>
        </div>
        <div class="my-16 space-y-4 md:space-y-8">
          <h3 class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl">
            Other Teams:
          </h3>
          <div class="relative">
            <div
              v-if="bootcampTeams?.length > 1"
              class="flex flex-col md:flex-row space-y-8 md:space-x-8 md:space-y-0 h-64 md:h-fit overflow-y-scroll flex-nowrap items-start py-6 overflow-x-scroll"
              ref="otherTeamsContainer"
            >
              <template v-for="team in bootcampTeams" :key="team.id">
                <div
                  class="p-4 md:p-6 bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body w-full md:min-w-[35%] md:w-[35%]"
                  v-if="team.id != currentTeam.id"
                >
                  <h3
                    class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-lg"
                  >
                    {{ team.title }}
                  </h3>
                  <p
                    class="font-normal text-[#666666] dark:text-[#F5F5F5] text-xs my-3"
                  >
                    {{ team.description }}
                  </p>
                  <div
                    @click="fetchTeamById(team.id)"
                    class="font-semibold text-primary-green text-xs mt-auto cursor-pointer"
                  >
                    READ MORE
                    <span
                      class="iconify inline-block"
                      data-icon="bi:arrow-up-right"
                    ></span>
                  </div>
                </div>
              </template>
            </div>
            <div v-else class="text-[#222222] dark:text-[#E5E5E5] text-sm">
              There are no other teams for you to select from
            </div>
            <button
              @click="scrollTeams()"
              v-if="bootcampTeams?.length > 3"
              class="hidden md:flex items-center justify-center ml-4 rounded-full bg-primary-green min-w-[2rem] min-h-[2rem] h-8 w-8 text-white mt-4 absolute top-1/2 -translate-y-1/2 -right-12"
            >
              <span class="iconify" data-icon="akar-icons:chevron-right"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BootcampModal from "./BootCampModal.vue";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import swal from "sweetalert";

const $loading = inject("$loading");
const store = useStore();
const route = useRoute();
const router = useRouter();

const user = computed(() => {
  return store.state.auth.user;
});

const otherTeamsContainer = ref(null);

const currentTeam = ref([]);
const bootcampTeams = ref([]);
const showBootcampModal = ref(false);
const fetchTeamById = async (id) => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get(`auth/teams/${id}`);
    currentTeam.value = data.payload;
    window.scrollTo({ top: 0, behavior: "smooth" });
    loader.hide();
  } catch (error) {
    loader.hide();
    console.log(error);
    router.push({ name: "AllTeamsPage" });
  }
};

const fetchAllTeams = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("/auth/teams");
    bootcampTeams.value = data.payload;
    loader.hide();
  } catch (error) {
    loader.hide();
    console.log(error);
  }
};

const joinTeam = async (id) => {
  const loader = $loading.show();
  try {
    const result = await swal({
      title: "Confirm",
      text: `Are you sure you want to join ${currentTeam.value.title}? This decision can not be reversed.`,
      buttons: true,
    });
    if (result) {
      await axios.post(`auth/teams/${id}`);
      loader.hide();
      showBootcampModal.value = true;
      return;
    }
    loader.hide();
  } catch (error) {
    loader.hide();
    console.log(error);
    if (error.response.status == 401) {
      router.push({ name: "LearningDashboardIndex" });
      return;
    }
    swal({
      title: "Oops!!!",
      text: error?.response?.data?.payload ?? "Please try again",
    });
  }
};

const scrollTeams = () => {
  const container = otherTeamsContainer.value;
  container.scrollLeft += 375;
};
onMounted(() => {
  if (route?.params?.id) {
    fetchTeamById(route.params.id);
    fetchAllTeams();
  } else {
    router.push({ name: "AllTeamsPage" });
  }
});
</script>

<style scoped></style>
