import store from "../store";

const checkUserDataCompletion = () => {
    let user = store.getters["auth/user"];
    let userCourse = store.getters["auth/userCourse"];
    if (! user) {
      return false;
    }
    if (! user?.level_of_education) {
      return false;
    }
    if (! user?.employment_status  && ! user?.school ) {
      return false;
    }
    if (! user?.monthly_earning ) {
      return false;
    }
    return true;
};


export default checkUserDataCompletion;
