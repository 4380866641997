<template>
  <nav class="container p-0 pr-12 mt-8 lg:mt-4">
    <div
      v-if="$route.name != 'CourseCertificatePage'"
      class="hidden md:flex items-center justify-between gap-8 text-xs font-body container p-0"
    >
      <div class="flex items-center">
        <!-- <button
          v-if="learningType == 'orientation'"
          class="truncate max-w-full py-1 text-[#0C0C0C] dark:text-[#52A350]"
        >
          Orientation
        </button> -->
        <!-- <button
          v-else-if="learningType == 'jobberman'"
          class="truncate max-w-full py-1 text-[#0C0C0C] dark:text-[#52A350]"
        >
          Jobberman Soft Skills Training
        </button> -->
        <!-- <button
          v-else
          class="truncate max-w-full py-1 text-[#0C0C0C] dark:text-[#52A350]"
        >
          Lessons
        </button>
        <span class="mx-4 mt-1 text-base dark:text-[#E5E5E5] text-[#000000]"
          >/</span
        >
        <button
          class="truncate max-w-full py-1 px-2 text-[#0C0C0C] dark:text-[#52A350]"
        >
          {{
            $route.name == "LessonPage" && currentLesson
              ? currentLesson.title
              : "Quiz"
          }}
        </button> -->
      </div>
      <div class="flex items-center justify-between ml-auto" v-if="false">
        <button
          @click="prevLesson(currentLesson.id, currentLesson.week)"
          class="py-1 min-w-fit px-2 rounded-full border bg-[#F8FCF8] dark:bg-[#2B342B] dark:border-[#2B342B] font-semibold text-primary-green"
        >
          <span
            class="iconify inline"
            data-icon="akar-icons:chevron-left"
          ></span>
          Previous
        </button>
        <!-- akar-icons:chevron-down -->
        <button
          @click="nextLesson(currentLesson.id, currentLesson.week)"
          class="ml-2 py-1 min-w-fit px-2 rounded-full border bg-[#F8FCF8] dark:bg-[#2B342B] dark:border-[#2B342B] font-semibold text-primary-green"
        >
          Next
          <span
            class="iconify inline"
            data-icon="akar-icons:chevron-right"
          ></span>
        </button>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
// import swal from "sweetalert";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
import { encrypt, decrypt } from "@/helpers/encrypt";
// const axios = inject("axios");
const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();

const breadCrumbElements = computed(() => {
  return route.matched;
});
const lessons = computed(() => {
  return store.state.lms.lessons;
});
const siteSettings = computed(() => {
  return store.state.auth.settings;
});

const learningType = computed(() => {
  return store.state.lms.learningType;
});

const currentLesson = computed(() => {
  return store.state.lms.currentLesson;
});
const watchedLessons = computed(() => {
  return store.state.lms.watchedLessons;
});

const eachWeekLessons = computed(() => {
  return (week) => lessons.value.filter((item) => item.week == week);
});

const setCurrentLesson = (lesson) =>
  store.dispatch("lms/setCurrentLesson", lesson);

const prevLesson = (id, week) => {
  if (route.name != "LessonPage") {
    if (route.name == "QuizInstructionPage") {
      let nextWeekLessons = eachWeekLessons.value(week - 1);
      router.push({
        name: "LessonPage",
        params: {
          id:
            nextWeekLessons.length > 0
              ? nextWeekLessons[nextWeekLessons.length - 1].id
              : 0,
        },
      });

      return;
    }
    router.push({ name: "LessonPage", params: { id: id } });
    return;
  }

  const currentWeekLessons = eachWeekLessons.value(week);
  let currentLessonIndex = currentWeekLessons.findIndex(
    (item) => item.id == id
  );
  if (currentLessonIndex == 0 && siteSettings.value.current_week > 0) {
    goToQuiz(week - 1);
    return;
  }
  if (currentLessonIndex > 0) {
    setCurrentLesson(currentWeekLessons[currentLessonIndex - 1]);
  }
};
const nextLesson = (id, week) => {
  const currentWeekLessons = eachWeekLessons.value(week);
  let currentLessonIndex = currentWeekLessons.findIndex(
    (item) => item.id == id
  );
  if (currentLessonIndex >= 0) {
    if (route.name == "QuizInstructionPage") {
      if (siteSettings.value.current_week < week + 1) {
        swal({
          title: "Opps !!!",
          text: "You're yet to get to week  " + (week + 1),
        });
        return;
      }
      let nextWeekLessons = eachWeekLessons.value(week + 1);
      router.push({
        name: "LessonPage",
        params: {
          id: nextWeekLessons.length > 0 ? nextWeekLessons[0].id : 0,
        },
      });

      return;
    }

    if (currentLessonIndex + 1 == currentWeekLessons.length) {
      goToQuiz(week);
      return;
    }

    setCurrentLesson(currentWeekLessons[currentLessonIndex + 1]);
  }
};

const setLesson = (lesson) => {
  if (siteSettings.value.current_week < lesson.week) {
    swal({
      title: "Opps !!!",
      text: "You're yet to get to week " + lesson.week,
    });
    return;
  }
  if (route.name != "LessonPage") {
    router.push({ name: "LessonPage", params: { id: lesson.id } });
    return;
  }
  setCurrentLesson(lesson);
};

const goToQuiz = (week) => {
  if (siteSettings.value.current_week < week) {
    swal({
      title: "Oops !!!",
      text: "You're yet to get to week " + week + " quiz now",
    });
    return;
  }
  const unwatchedLessons = eachWeekLessons.value(week).filter((item) => {
    return !watchedLessons.value.includes(item.id);
  });
  if (unwatchedLessons.length > 0) {
    swal({
      title: "Oops!",
      text: "You are yet to complete your lessons for this week",
    });
    return;
  }
  router.push({
    name: "QuizInstructionPage",
    params: { week: encrypt(week) },
  });
};
</script>

<style></style>
