<template>
    <div class="container font-body">
        <BackButton class="my-4 md:my-8" />
        <div class="grid gap-6 grid-cols-1 md:grid-cols-2">
          <div class="">
            <h1 class="font-bold font-header-1 text-5xl md:text-6xl">Learn and Build</h1>
            <p class="text-sm font-medium my-2 md:w-5/6 lg:w-3/4 leading-loose">
              At Terra HQ, we are committed to not only providing you with top-notch tech skills but also ensuring that you are fully prepared for your career journey. That's why we've partnered with Peiges, a leading portfolio creation platform, to help our interns showcase their skills and projects effectively.
              <br />
              Start building your professional portfolio now
            </p>
          </div>
          <div class="flex flex-col items-end gap-6 pt-2 pb-8">
            <img class="max-h-16 text-center self-start order-last md:order-none" src="@/assets/img/logo/terraxpeiges.png" alt="Terra X Peiges" />
            <form v-if="! user?.peige" @submit.prevent="signupPeiges()" class="my-6 flex flex-col w-full">
              <fieldset class="flex flex-col gap-y-4 w-full">
                <label for="peiges_username" class=" text-gray-800 border border-gray-400 rounded-md shadow-md text-xs md:text-sm font-medium overflow-clip grid  grid-cols-7 items-center bg-white px-4 py-2 gap-x-4 w-full">
                  <span class="col-span-2 border-r border-gray-300">Username</span>
                  <input @keydown="myCustomHandler()" @blur="checkUsername()" v-model="username" class="font-light border-0 border-gray-300 ring-0 bg-none py-0 focus-within:ring-0 focus-within:border-gray-400 col-span-4 text-xs" type="text" name="peiges_username" id="peiges_username" />
                  <span class="justify-self-end text-xl" :class="isErrorMessage == true ? 'text-red-600' : 'text-primary-green' ">
                    <i v-if="isLoadingUsername == true" class="fa-solid fa-ellipsis text-gray-600 fa-beat"></i>
                    <i v-else-if="message?.length > 0 && isErrorMessage == false" class="fa-regular fa-circle-check"></i>
                    <i v-else-if="message?.length > 0 && isErrorMessage == true" class="fa-regular fa-circle-xmark"></i>
                    <i v-else class="fa-regular fa-lightbulb text-gray-600"></i>
                  </span>
                </label>
                <span class="text-sm font-medium capitalize -mt-2" :class="isErrorMessage == true ? 'text-red-600' : 'text-primary-green' ">{{ message }}</span>
              </fieldset>
              <button 
                :disabled="message?.length < 1 || isErrorMessage == true" 
                type="submit" 
                class="hover:bg-primary-green/70 transition ease-linear duration-300 bg-primary-green disabled:bg-gray-500 my-6 px-4 py-3 rounded-md shadow text-white font-semibold text-sm w-fit self-end">
                Create Portfolio
              </button>
            </form>
            <div v-else class="self-center md:self-end -order-1">
              <p class="text-sm font-medium text-center hidden">
                Checkout your Peiges Portfolio at <a class="underline text-primary-blue" target="_blank" :href="user?.peige?.url">{{ user?.peige?.url }}</a>
              </p>
              <div class="">
                <h3 class="font-bold text-2xl mb-2 font-header-1">Guide</h3>
                <ol class="text-sm list-decimal leading-loose font-medium">
                  <li>Sign in to Peiges using the link <a href="https://app.peiges.co" class="underline underline-offset-2 text-primary-blue px-1">https://app.peiges.co</a> and input your newly created username and password <i>(check your mailbox for your temporary password)</i> in the corresponding input field.
                  </li>
                  <li>Customize your portfolio page.</li>
                  <li>Share your portfolio URL <a class="underline text-primary-blue" target="_blank" :href="user?.peige?.url">{{ user?.peige?.url }}</a> with your friends and colleagues</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import axios from "axios";
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isErrorMessage: false,
      message: '',
      username: '',
      isLoadingUsername: false,
      timeOutId: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  components: {
    BackButton,
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt"
    }),
    myCustomHandler(){
      if(this.timeOutId) {
        clearTimeout(this.timeOutId);
      }
      this.timeOutId = window.setTimeout(this.checkUsername, 800)
    },
    async checkUsername() {
      if (this.username?.length < 2) {
        this.isErrorMessage = true;
        this.message = 'The username must be at least 2 characters long';        
        return;
      }
      this.isLoadingUsername = true
      this.message = null
      // Check if username is available
      const payload = { username: this.username }
      const { data } = await axios.post('v2/auth/portfolio/peiges/username', payload)
      this.message = data.message
      if (data?.data?.statusCode == 200) this.isErrorMessage = false;
      else this.isErrorMessage = true
      this.isLoadingUsername = false
    },
    async signupPeiges() {
      if (this.username?.length < 2) {
        this.isErrorMessage = true;
        this.message = 'The username must be at least 2 characters long';        
        return;
      }
      try {
        let loader = this.$loading.show()
        // Check if username is available
        const payload = { username: this.username }
        const { data } = await axios.post('v2/auth/portfolio/peiges/signup', payload)
        this.message = data.message
        if (data?.data?.statusCode == 200) this.isErrorMessage = false;
        else this.isErrorMessage = true
        setTimeout(() => {
          this.attempt({});
        }, 1000);
        loader.hide()
      } catch (error) {
        this.isErrorMessage = true
        this.message = error?.data?.message;
        this.attempt({});
        loader.hide()
      }
    }
  }
}
</script>