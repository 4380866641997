<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center z-50"
    id="withdrawReferral" @click="closeModal"
  >
    <div
      class="flex flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 py-12 space-y-6 mx-auto w-11/12 md:w-3/6 lg:w-5/12 text-left"
      ref="WithdrawalModal"
    >
      <h2 class="font-header-1 text-primary-green font-bold text-3xl">Enter your bank details</h2>
      <form @submit.prevent="withdraw()" class="w-full">
        <fieldset class="grid gap-4 my-4 w-full">
          <select
            class="w-full focus:outline-none focus:border-primary-green focus:rounded-md p-2 focus:border-2 font-medium text-xs border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
            name="bankCode"
            v-model="bankCode"
            @change="account = null"
          >
            <option selected value='false' disabled>Select Bank</option>
            <option v-for="(bank, index) in banks" :key="index" :value="bank.code">{{bank.name}}</option>
          </select>
          <div v-if="bankCode" class="relative h-fit">
            <div 
                class="bg-primary-green text-xs px-3 py-1 rounded-full whitespace-nowrap text-white absolute right-2 top-1/2 -translate-y-1/2 w-fit cursor-pointer"
                v-if="accountNumber?.length == 10"
                @click="verifyAccountNumber()"
            >
                Verify
            </div>
            <input
                type="tel"
                name="accountNumber"
                id="accountNumber"
                v-model="accountNumber"
                @change="account = null"
                @keyup="account = null"
                v-if="bankCode"
                maxlength="10"
                placeholder="Enter your account number"
                class="text-center rounded-lg border border-[#C4C4C4] focus:border-primary-green focus:ring-primary-green font-body text-body-text text-sm w-full font-semibold h-full"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
          </div>
          <div v-if="account" class="text-center text-sm font-medium">
            <span class="text-primary-green" v-if="account.requestSuccessful == true">
                {{ account.responseBody.accountName }}
            </span>
            <span class="text-red-500" v-else>
                {{ account.responseMessage }}
            </span>
          </div>
          <input
            type="number"
            name="accountNumber"
            id="accountNumber"
            v-model="amount"
            v-if="account?.requestSuccessful == true"
            min="5000"
            :max="balance"
            steps="100"
            placeholder="Enter your amount to withdraw"
            class="text-center rounded-lg border border-[#C4C4C4] focus:border-primary-green focus:ring-primary-green font-body text-body-text text-sm w-full font-semibold h-full"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            />
        </fieldset>
        <button 
            v-if="account?.requestSuccessful == true && amount >= 5000" 
            class="rounded-md bg-primary-green text-white font-medium text-sm px-3 py-2 mx-auto w-fit"
        >
            Request Withdrawal
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from 'sweetalert';
export default {
  props: {
    balance: {
        type: Number,
        default: 0,
    },
  },
  data() {
    return {
      banks: [],
      bankCode: null,
      accountNumber: null,
      buttonText: "Update Now",
      account : null, 
      amount: this.balance,
    };
  },
  methods: {
    closeModal() {
      const box = this.$refs.WithdrawalModal;
      if (!box?.contains(event.target)) {
        return this.$emit("closemodal");
      }
      return;
    },
    async fetchBankList() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("v2/auth/banking/banks");
        this.banks = data.data;
        loader.hide()
      } catch (error) {
        loader.hide()
        console.log(error);
      }
    },
    async verifyAccountNumber() {
      let loader = this.$loading.show();
      const payload = {
        account_number: this.accountNumber,
        bank_code: this.bankCode
      }
      try {
        const { data } = await axios.post("v2/auth/banking/verify-account", payload);
        this.account = data.data;
        loader.hide()
      } catch (error) {
        loader.hide()
        console.log(error);
      }
    },
    async withdraw() {
      let loader = this.$loading.show();
      const payload = {
        account_number: this.accountNumber,
        bank_code: this.bankCode,
        amount: this.amount,
        account_name: this.account?.responseBody?.accountName,
        bank_name: this.banks.find((item) => {
                return item.code == this.bankCode
            }).name
      }
      try {
        const { data } = await axios.post("v2/auth/referrals/withdraw", payload);
        loader.hide()
        swal({
            title: "Success!",
            text: `${
                data?.message ?? "Please try again shortly"
            }`,
        });
        return this.$emit("closemodal");
      } catch (error) {
        swal({
            title: "Oops!",
            text: `${
                error?.response?.data?.message ?? "Please try again shortly"
            }`,
        });
        loader.hide()
        console.log(error);
        return this.$emit("closemodal");
      }
    },
  },
  mounted(){
    this.fetchBankList();
  }
};
</script>

<style lang="scss" scoped>

</style>
