<template>
  <div class="px-0">
    <div
      class="flex dark:text-white items-center justify-center w-full h-[100vh]"
    >
      <div class="flex flex-col md:w-[30%] w-full h-[60%]">
        <div
          class="flex items-center justify-center text-white text-center py-2 text-[12px] mb-1"
        >
          <img
            src="@/assets/img/img/confetti.png"
            alt="confetti"
            class="w-[100px]"
          />
        </div>
        <h3 class="text-center py-2 text-[22px] font-extrabold -mt-4 mb-2">
          {{ winner?.user?.first_name }} {{ winner?.user?.last_name }}
        </h3>
        <p class="font-semibold text-[16px] text-center -mt-3 mb-3">
          is the winner for this week with
        </p>
        <p class="text-center text-[#52A350] font-extrabold text-[33px]">
          {{ winner?.sum }} points
        </p>
        <div class="flex flex-col items-center justify-between uppercase mt-7">
          <button
            @click="handleClick"
            class="bg-[#52A350] px-8 py-2 text-white"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearningNavBar from "@/components/lms/LearningNavBar.vue";
import LearningNavBarMobile from "@/components/lms/LearningNavBarMobile.vue";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  components: {
    LearningNavBar,
    LearningNavBarMobile,
  },

  data() {
    return {
      winner: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
    }),
  },

  mounted() {
    this.fetchLeaderBoard();
    this.sound = new Audio('https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/games/winner.mp3');
    this.sound.volume = 0.2;
    this.sound.play();
  },

  beforeRouteLeave() {
    this.sound?.pause();
  },

  methods: {
    handleClick() {
      return this.$router.push({ name: "GameScore" });
    },
    async fetchLeaderBoard() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/games/points");
        this.winner = data.payload[0];
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

#cont {
  background: linear-gradient(107.93deg, #ecf2fe 0%, #f1f8f1 104.14%);
}

button:disabled {
  background-color: #c3c8c3;
}

#a.active,
#b.active,
#c.active,
#d.active {
  border: 2px solid blue;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

tbody tr {
  margin-bottom: 40px;
  /* background-color: red; */
  /* border: 3px solid #ededed !important; */
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

tr > td {
  border: none;
  margin-bottom: 20px;
  padding: 15px 0px;
  margin-bottom: 40px;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: 150ms ease;
}

.bounce-enter-from,
.bounce-leave-to {
  transform: scale(0);
}
</style>
