<template>
  <div class="container md:p-0">
    <div class="max-w-full">
      <BackButton class="my-4 md:my-12" />
      <div class="mb-8">
        <p
          class="dark:text-[#E5E5E5] text-[#242424] font-body text-sm md:text-base"
        >
          Here you will find answers to frequently asked questions about the
          internship and your track
        </p>
      </div>
      <div class="lg:w-4/6">
        <NewPost @refreshPosts="fetchAllPosts" />
        <div class="flex items-center justify-between w-full my-4">
          <div class="border-t-[0.5px] border-[#00000026] grow"></div>
          <span
            class="w-fit font-body text-[#A7A5A5] text-xs ml-4 cursor-pointer"
            @click="sortPosts"
            >Sort by: <strong> {{ sorting }} </strong>
            <span
              class="iconify inline font-bold"
              data-icon="bi:caret-down-fill"
            ></span>
          </span>
        </div>
        <template v-if="posts.length > 0">
          <template v-for="(post, index) in posts" :key="post.id">
            <Posts
              :post="post"
              @closepost="closePost(post.id)"
              v-if="index < postsToShow"
            />
          </template>
          <button
            class="w-full py-2 rounded font-semibold dark:text-[#747272] dark:bg-[#191919] dark:border-[#0000001A] text-[#747272] bg-[#FAFAFA] border-[#0000001A]"
            @click="postsToShow += 5"
            v-if="posts.length > postsToShow"
          >
            See more posts
          </button>
        </template>
        <div v-else class="dark:text-[#E5E5E5] text-black font-semibold">
          No Post available to see
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import NewPost from "./NewPostComponent.vue";
import Posts from "./PostsComponent.vue";
import axios from "axios";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  components: {
    BackButton,
    NewPost,
    Posts,
  },
  data() {
    return {
      posts: [],
      sorting: "Recent",
      postsToShow: 5,
    };
  },
  methods: {
    sortPosts() {
      this.posts.reverse();
      if (this.sorting == "Recent") {
        return (this.sorting = "Oldest");
      } else {
        return (this.sorting = "Recent");
      }
    },
    closePost(id) {
      const filteredPosts = this.posts.filter((item) => {
        return item.id != id;
      });
      return (this.posts = filteredPosts);
    },
    async fetchAllPosts() {
      try {
        let loader = this.$loading.show();
        axios
          .get("auth/forum")
          .then((res) => {
            this.posts = res.data.payload;
            loader.hide();
            if (this.$route.params && this.$route.params.post) {
              const filteredPosts = this.posts.filter((item) => {
                return item.id == this.$route.params.post;
              });
              return (this.posts = filteredPosts);
            }
            return;
          })
          .catch(() => {
            loader.hide();
          });
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    },
  },
  mounted() {
    this.fetchAllPosts();
  },
};
</script>

<style scoped></style>
