<template>
  <main>
    <div class="container">
      <BackButton class="my-4 ml-3 md:my-12" />
      <div
        class="w-full md:w-4/5 lg:w-4/6 bg-[#FCFDFC] dark:bg-[#333] shadow-md rounded-lg p-4 md:p-12"
      >
        <h1 class="text-2xl font-bold font-body dark:text-[#e3e3e3] flex">
          Leaderboard
        </h1>
        <nav
          class="border-2 border-primary-green rounded-md p-2 grid gap-4 justify-between items-stretch my-6 grid-cols-2"
        >
          <button
            @click="sort = 'track'"
            class="rounded-md text-sm font-semibold py-2 transition ease-linear"
            :class="
              sort == 'track'
                ? 'bg-primary-green text-white shadow-sm shadow-primary-green'
                : ' text-primary-green'
            "
          >
            {{ user?.batch?.course?.title }}
          </button>
          <button
            @click="sort = 'overall'"
            class="rounded-md text-sm font-semibold py-2 transition ease-linear"
            :class="
              sort == 'overall'
                ? 'bg-primary-green text-white shadow-sm shadow-primary-green'
                : ' text-primary-green'
            "
          >
            {{ 'Overall' }}
          </button>
        </nav>
        <section class="py-2 md:py-4 dark:text-[#e3e3e3]" v-if="leaders?.length > 0">
          <table class="table-auto w-full border-separate border-spacing-y-3">
            <template v-for="(leader, index) in leaders" :key="index">
                <tr 
                  v-if="index < 20 || leader?.user_id == user?.id"
                  class="cursor-pointer border  hover:shadow-lg transition ease-linear"
                  :class="leader?.user_id == user?.id ? 'shadow-md bg-gray-100' : ''">
                  <td class="pl-3">
                    {{ index + 1 }}.
                  </td>
                  <td>
                    {{`${leader?.user?.first_name} ${leader?.user?.last_name}`}}
                  </td>
                  <td class="max-w-[84px] lg:max-w-full">
                    <div class="truncate mr-4">
                      {{ leader.course?.title }}
                    </div>
                  </td>
                  <td class="pr-3">
                    {{ fix(leader?.score)}}
                  </td>
                </tr>
            </template>
          </table>
        </section>
        <section class="p-2 md:p-4 dark:text-[#e3e3e3]" v-else>
            <h1>Submit your quizzes to get featured on the Leaderboard</h1>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      sort: "track",
      week: 1,
      leaderboard: null,
    };
  },
  computed:{
    ...mapGetters({
      user: "auth/user",
    }),
    leaders(){
      if (!this.leaderboard) {
        return [];
      }
      if (this.sort == "track") {
        return this.leaderboard?.track ?? []
      }
      if (this.sort == "overall") {
        return this.leaderboard?.overall ?? []
      }
      return [];
    }
  },
  components: {
    BackButton,
  },
  methods:{
    async fetchLeaderBoard(){
      let loader = this.$loading.show();
      try {
        const {data} = await axios.get("v2/auth/leaderboard")
        this.leaderboard = data.data;        
        console.log(this.leaderboard)
        loader.hide()
      } catch (error) {
        loader.hide()
        console.error(error);
      }
    },
    fix(x){
      let val = parseFloat(x).toFixed(2);
      return val ?? 0;
    },
  },
  
  mounted(){
    this.fetchLeaderBoard()
  }
};
</script>

<style scoped>
table tr td {
  @apply py-2 font-body text-sm font-semibold mb-4 border-y align-top;
}

table tr td:last-child {
  @apply py-2 font-body text-sm font-semibold mb-4 border-r rounded-r;
}
table tr td:first-child{
  @apply py-2 font-body text-sm font-semibold mb-4 border-l rounded-l;
}
table tr {
  @apply border;
}
</style>