<template>
  <main>
    <p class="font-body font-[800] text-[#242424]">
      What is your monthly salary?
    </p>
    <form @submit.prevent="submit()" class="w-full">
      <!-- <fieldset class="grid grid-flow-row gap-4 w-full"> -->
      <fieldset class="flex flex-col font-light my-4 space-y-3">
        <div class="">
          <label for="0-200" class="">
            <input
              type="radio"
              v-model="monthly_earning"
              name="monthly_earning"
              id="0-200"
              :value="user.country_id == 156 ? '0-50000' : '0-200'"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            <span v-if="user.country_id == 156">
              &#8358;0 - &#8358;50,000</span
            >
            <span v-else> &dollar;0 - &dollar;200</span>
          </label>
        </div>
        <div class="">
          <label for="201-1000" class="">
            <input
              type="radio"
              v-model="monthly_earning"
              name="monthly_earning"
              id="201-1000"
              :value="user.country_id == 156 ? '50000-200000' : '201-1000'"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            <span v-if="user.country_id == 156">
              &#8358;50,000 - &#8358;200,000</span
            >
            <span v-else> &dollar;201 - &dollar;1000</span>
          </label>
        </div>
        <div class="">
          <label for="above1000" class="">
            <input
              type="radio"
              v-model="monthly_earning"
              name="monthly_earning"
              id="above1000"
              :value="user.country_id == 156 ? 'above 200000' : 'above 1000'"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            <span v-if="user.country_id == 156"> Above &#8358;200,000</span>
            <span v-else> Above &dollar;1000</span>
          </label>
        </div>
      </fieldset>
      <button type="submit" :disabled="!monthly_earning">Next</button>
    </form>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      monthly_earning: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    submit() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      user.monthly_earning = this.monthly_earning;
      localStorage.setItem("tempUser", JSON.stringify(user));
      this.$emit("next");
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      this.monthly_earning = JSON.parse(
        localStorage.getItem("tempUser")
      ).monthly_earning;
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  @apply flex flex-col ;
  fieldset {
    .radio-check-group {
      @apply col-span-1 grid grid-cols-1;
      label {
        @apply font-body-new font-semibold text-sm text-[#3D403D] border border-[#D2D2D2] rounded-lg px-6 py-4 w-full cursor-pointer;
      }
    }
  }
  button[type="submit"] {
    @apply py-3 px-8 text-white bg-primary-green font-body-new rounded-md ml-auto mt-6 lg:mt-8;
  }
}
</style>
