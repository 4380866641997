<template>
  <div class="px-0">
    <LearningNavBar class="z-50" />
    <div class="relative">
      <LearningNavBarMobile class="z-50" />
    </div>
    <div
      class="bg-[#FFFFFF] w-full pt-16 lg:pt-24 pb-8 min-h-screen"
    >
      <router-view class="md:px-16" />
    </div>
  </div>
</template>


<script>
import LearningNavBar from "./navs/NavBar.vue";
import LearningNavBarMobile from "./navs/NavBarMobile.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LearningNavBar,
    LearningNavBarMobile,
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
    }),
  },
  watch: {

  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    
  },
  async mounted() {
    const user = this.user;
    if (user?.hasPaidBootcamp == true && !user?.bootcamp?.type) {
      return this.$router.push({name: "SelectBootcampType"})
    }
    if (user?.hasPaidBootcamp == true && user?.bootcamp?.team) {
      return this.$router.push({name: "BootcampWelcome"})
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: 150ms ease;
}

.bounce-enter-from,
.bounce-leave-to {
  transform: scale(0);
}
</style>
