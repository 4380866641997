<template>
  <main>
    <p class="font-body font-[800] text-[#242424]">
      How much time can you dedicate per week?
    </p>
    <form @submit.prevent="submit()" class="w-full">
      <fieldset class="flex flex-col font-light my-7 space-y-3">
        <div class="">
          <label for="4-10" class="">
            <input
              type="radio"
              v-model="availability"
              name="availability"
              id="4-10"
              value="4-10 hours"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 "
            />
            4 - 10 hours weekly
          </label>
        </div>
        <div class="">
          <label for="11-20" class="">
            <input
              type="radio"
              v-model="availability"
              name="availability"
              id="11-20"
              value="11-20 hours"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 "
            />
            11 - 20 hours weekly
          </label>
        </div>
        <div class="">
          <label for="21+" class="">
            <input
              type="radio"
              v-model="availability"
              name="availability"
              id="21+"
              value="21+ hours"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600 "
            />
            21+ hours weekly
          </label>
        </div>
      </fieldset>
      <button type="submit" :disabled="!availability">Finish</button>
    </form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert";
export default {
  data() {
    return {
      availability: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
      fetchUserData: "auth/fetchUserData",
    }),
    async submit() {
      let loader = this.$loading.show();
      try {
        let user = JSON.parse(localStorage.getItem("tempUser"));
        user.availability = this.availability;
        localStorage.setItem("tempUser", JSON.stringify(user));
        await axios.put("v2/auth/update/4", user);
        localStorage.removeItem("tempUser");
        this.attempt({});
        await this.fetchUserData();
        loader.hide();
        this.$emit("next");
      } catch (error) {
        loader.hide();
        swal({
          title: "Oops!!!",
          text:
            error?.response?.data?.payload ??
            "Please reload this page and try again",
        });
      }
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      this.availability = JSON.parse(
        localStorage.getItem("tempUser")
      ).availability;
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  @apply flex flex-col;
  fieldset {
    .radio-check-group {
      @apply col-span-1 grid grid-cols-1;
      label {
        @apply font-body-new font-semibold text-sm text-[#3D403D] border border-[#D2D2D2] rounded-lg px-6 py-4 w-full cursor-pointer;
      }
    }
  }
  button[type="submit"] {
    @apply py-3 px-8 text-white bg-primary-green font-body-new rounded-md ml-auto mt-6 lg:mt-8;
  }
}
</style>
