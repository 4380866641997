<template>
  
  <div class="animate-pulse mt-28  bg-gray-200 rounded-lg p-1 w-3/4 mx-auto px-8 py-2 h-[350px] flex items-start flex-col justify-start" v-if="loading">
      <div class="h-4 bg-gray-300 rounded w-full my-4"></div>
      <div class="h-4 bg-gray-300 rounded w-full my-4"></div>
      <div class="h-4 bg-gray-300 rounded w-full my-4"></div>
      <div class="h-4 bg-gray-300 rounded w-full my-4"></div>
      <div class="h-4 bg-gray-300 rounded w-full my-4"></div>
      <div class="h-4 bg-gray-300 rounded w-full"></div>
  </div>
  <div v-else-if="questions.length < 1 && !loading">
      <div class="flex place-content-center">
        <div class="block mt-28 dark:hidden">
          <img
            class="h-32 mx-auto"
            src="@/assets/img/emptyl.png"
            alt="Game icon"
          />
          <p class="text-xs mt-3 text-[#0C180C] text-center">
            No Quiz available
          </p>
        </div>
        <div class="hidden mt-28 items-center dark:block">
          <img
            class="h-32 mx-auto"
            src="@/assets/img/empty.png"
            alt="Game icon"
          />
          <p class="text-[#E9E9E9] mt-3 text-xs text-center">
            No Quiz available
          </p>
        </div>
      </div>
   </div>
  <div v-else class="min-h-screen dark:bg-[#000000] bg-[#FFFFFF]">
    <nav class="border-b h-16 min-h-[4rem]"></nav>
    <main class="container lg:px-0 py-4 md:py-8">
      <button
        @click="$router.push({ name: 'QuizInstructionPage' })"
        class="py-1 min-w-fit px-3 rounded-full border text-sm bg-[#F8FCF8] font-semibold text-primary-green flex items-center"
      >
        <span class="iconify inline" data-icon="akar-icons:chevron-left"></span>
        Go Back
      </button>

      <div class="lg:w-3/6 mx-auto mt-4 md:mt-8" v-if="questions.length > 0">
        <h1
          class="font-header-1 font-extrabold text-2xl md:text-3xl dark:text-white text-[#0C0C0C]"
        >
          Week
          {{ questions.length > 0 ? questions[0].week : "Undefined" }}
          Quiz
        </h1>
        <div class="">
          <div class="my-8">
            <div
              class="space-y-5 mb-16"
              v-for="(question, index) in questions"
              :key="index"
            >
              <span
                class="font-header-1 font-bold text-sm rounded-full p-2 w-max dark:text-[#BABABA] dark:bg-[#333333] text-[#838383] bg-[#F4F4F4]"
              >
                Question {{ index + 1 }} /
                {{ questions.length }}
              </span>
              <p class="font-body dark:text-[#E5E5E5] text-[#242424]">
                {{ question.question }}
              </p>
              <div class="flex flex-col font-light space-y-3">
                <label
                  v-for="(option, _index) in question.options"
                  :key="_index"
                  class="cursor-pointer dark:text-[#E5E5E5] text-[#242424]"
                  @click="selectOption(question.id, option)"
                  v-show="option.trim() != '' && option"
                >
                  <input
                    type="radio"
                    :name="'question_' + index"
                    :checked="option == question.option"
                    class="w-5 h-5 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
                  />
                  {{ option }}
                </label>
              </div>
            </div>
          </div>
          <button
            class="bg-primary-green w-full text-white py-3 rounded-sm disabled:cursor-not-allowed disabled:bg-slate-300"
            @click="submitQuiz"
            :disabled="selected < questions.length"
          >
            Submit
          </button>
        </div>
      </div>

      <div
        class="bg-gray-200 rounded-lg p-1 w-full md:w-2/4 px-8 py-2 h-[200px] flex items-start flex-col justify-start mt-10"
        v-else
      >
        <h2 class="h-4 rounded w-full my-4 text-[20px] font-semibold">
          There is no quiz at the moment
        </h2>
        <div class="h-4 bg-gray-300 rounded w-full my-4 hidden"></div>
        <div class="h-4 bg-gray-300 rounded w-full my-4 hidden"></div>
        <div class="h-4 bg-gray-300 rounded w-full hidden"></div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
import swal from "sweetalert";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { decrypt } from "@/helpers/encrypt";
const axios = inject("axios");
const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();

let questions = ref([]);
let loading = ref(true);
let week = ref("");
let doneWithQuiz = ref(false);

const user = computed(() => {
  return store.state.auth.user;
});

const selected = computed(() => {
  return questions.value.filter((item) => item.option).length;
});

/**
 * Get the unanswered questions
 */
const unansweredQuestion = computed(() => {
  const unanswered = questions.value.filter((item) => !item.option);
  return questions.value.indexOf(unanswered[0]) + 1;
});

/**
 * Get selected Option with it's ID respectively and save the progress in localStorage
 * @param {Number} id
 * @param {string} answer
 */
const selectOption = (id, option) => {
  let choosen = questions.value.find((item) => item.id == id);
  if (choosen) {
    choosen.option = option;
  }
  localStorage.setItem("currentQuiz", JSON.stringify(questions.value));
};

/**
 * submit user's quiz
 */
const submitQuiz = async () => {
  // if (!navigator.onLine) {
  //   swal({
  //     title: "Network Error",
  //     text: "Please check your network and try again",
  //   });
  //   return;
  // }
  if (selected.value < questions.value.length) {
    swal({
      title: "You still have unanswered question(s)",
      text: `Please answer question ${unansweredQuestion.value}`,
    });
    return;
  }
  let loader = $loading.show();

  try {
    const { data } = await axios.post("auth/quizzes/submit", {
      selected: questions.value,
      week: questions.value[0].week,
    });

    loader.hide();
    store.dispatch("auth/attempt").then(
      // once quiz has been successfully submitted call store action to fectch all taken quiz score
      store.dispatch("lms/setTakenQuiz"),
      localStorage.removeItem("currentQuiz"),
      swal({
        title: "Saved",
        text: data.payload,
      }).then(() => {
        doneWithQuiz.value = true;
        store.dispatch("lms/fetchUserStreak")
        router.push({ name: "QuizInstructionPage" });
      })
    );
  } catch (error) {
    swal({
      title: "Opps",
      text:
        error?.response?.data?.payload ??
        "Unable to submit your quiz at the moment. Please try again shortly",
    });
    loader.hide();
  }
};

/**
 * Prompt user before leaving the quiz page
 */
onBeforeRouteLeave(async (to, from) => {
  if (doneWithQuiz.value) return true;
  if (questions.value.length < 1) return true;
  const shouldDelete = await swal({
    title: "Are you sure?",
    text: "Do you really want to leave? You haven't finished your quiz!",
    buttons: true,
  });
  if (!shouldDelete) return false;
});

/**
 * get question on page mounted
 */
onMounted(async () => {
  const loader = $loading.show();
  week.value = route.params.week ? decrypt(route.params.week) : null;
  if (localStorage.currentQuiz) {
    const localQuestions = JSON.parse(localStorage.currentQuiz);
    // check that the quiz in local storage is for the same week as the quiz to be taken
    if (
      localQuestions[0] &&
      localQuestions[0].week == week.value &&
      localQuestions[0]?.course_id == user?.value?.course?.id
    ) {
      questions.value = localQuestions;

      loader.hide();
      loading.value = false;
      return;
    }
  }
  try {
    /**
     * a retake param is passed as Boolean
     * if true hit retake endpoint
     */
    if (route.params.retake) {
      const { data } = await axios.get("/auth/quizzes/retake/" + week.value);
      questions.value = data.payload;
      if (questions.value.length > 0) {
        localStorage.setItem("currentQuiz", JSON.stringify(questions.value));
      }
    } else {
      /**
       * if no retake param is passed -- hit the direct quiz endpoint
       */
      const { data } = await axios.post("/auth/quizzes", {
        week: week.value,
      });
      questions.value = data.payload;
      if (questions.value.length > 0) {
        localStorage.setItem("currentQuiz", JSON.stringify(questions.value));
      }
    }
    loading.value = false;
    loader.hide();
  } catch (error) {
    loading.value = false;
    loader.hide();
    swal({
      title: "Oops!",
      text: error?.response?.data?.payload ?? "Please try again shortly.",
    }).then(() => {
      router.go(-1);
    });
  }
});
</script>

<style scoped></style>
