<template>
  <div class="lg:w-4/5 mx-auto mt-8 lg:mt-16">
    <h1 class="font-header-1 font-bold text-body-text text-3xl md:text-4xl">
      Sign in
    </h1>
    <p
      class="text-body-text font-normal text-base lg:text-sm font-body-new my-4"
    >
      Enter your details to continue your learning path
    </p>
    <form @submit.prevent="loginUser()">
      <CustomInputField
        errorMessage="Email is required"
        label="Email address"
        inputType="email"
        :validState="!v$.email.$invalid"
        required
        class="my-8"
        v-model="email"
      />
      <CustomInputField
        errorMessage="Password is required"
        :validState="!v$.password.$invalid"
        label="Password"
        inputType="password"
        required
        class="my-8"
        v-model="password"
      />
      <button
        class="button"
        type="submit"
        :disabled="v$.$invalid"
        v-html="buttonText"
      ></button>
    </form>
    <p
      class="font-body-new text-body-text text-xs md:text-sm font-normal text-center mt-6 mb-3"
    >
      Forgot Password?<router-link :to="{ name: 'RequestPasswordReset' }">
        <span class="text-primary-green font-medium cursor-pointer">
          Recover here.</span
        >
      </router-link>
    </p>
    <div class="text-center border-t border-t-[#ACB1AC] pt-3">
      <p class="text-xs md:text-sm mb-3">Don’t have an account?</p>
      <SecondaryButton
        @click="$router.push({ name: 'RegisterEmail' })"
        class="w-full"
        >Sign up for FREE</SecondaryButton
      >
      <p
        class="font-body-new text-body-text text-xs md:text-sm font-normal text-center my-6"
      >
        Login to cohort 5.0 dashboard
        <a
          href="https://internship5.terrahq.co/login"
          target="_blank"
          class="text-primary-green font-medium cursor-pointer"
          >here</a
        >
      </p>
    </div>
  </div>
</template>

<script>
import CustomInputField from "./../../components/inc/CustomInputField.vue";
//
import useVuelidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import swal from "sweetalert";
import SecondaryButton from "../../components/inc/SecondaryButton.vue";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    CustomInputField,
    SecondaryButton,
  },
  data() {
    return {
      email: "",
      password: "",
      buttonText: "Start Learning",
      isLoading: true,
      load: false,
    };
  },
  validations() {
    return {
      email: { required, email },
      password: { required, minLength: minLength(6) },
    };
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",

    }),
    async loginUser() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let loader = this.$loading.show();
        this.buttonText = `<i class="fa-solid fa-spinner fa-spin fa-2x"></i>`;
        try {
          const data = {
            email: this.email,
            password: this.password,
          };
          let response = await axios.post("v2/login", data)
          if (response.status == 203) {
            swal({
              title: "Welcome Back!",
              text: "We found an existing account with this email - kindly update your profile",
              button: "Ok, Continue",
            }).then((e) => {
              this.$router.push({
                name: "EnterDetails",
                params: response.data.data,
              });
            });
            loader.hide()
            return;
          }
          let token = response.data.data.token;
          let user_payload = response.data.data.user;
          await this.attempt({token, user_payload});
          loader.hide();
          if (this.$route?.query?.redirect) {
            return this.$router.push({
              path: this.$route?.query?.redirect,
            });
          } else {
            this.$router.push({
              name: "SelectBatchPage",
            });
          }
        } catch (error) {
          console.log(error)
          this.buttonText = "Proceed";
          loader.hide();
          if (error?.response?.data?.message) {
            swal({
              title: "Oops!",
              text: `${error?.response?.data?.message}` ?? "Please check your network and try again.",
            });          
          }
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.button {
  @apply text-base lg:text-lg font-semibold text-white bg-primary-green rounded-md  py-4 px-12 w-full;
}
.button:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF];
}
</style>
