<template>
  <div class="lg:w-4/5 mx-auto mt-8 lg:mt-24">
    <h1 class="font-header-1 font-bold text-body-text text-5xl lg:text-4xl">
      Set up your account
    </h1>
    <p class="text-body-text font-normal text-base lg:text-lg font-body my-4">
      Enter your details to set up your personal learning experience
    </p>
    <form @submit.prevent="submitDetails()">
      <fieldset class="grid grid-flow-row gap-8 md:grid-cols-2 md:gap-4 my-8">
        <CustomInputField
          errorMessage="First name is required"
          label="First Name"
          inputType="text"
          :validState="!v$.user.first_name.$invalid"
          required
          v-model="user.first_name"
          @change="SpecialCharactersFirst"
        />
        <CustomInputField
          errorMessage="Last name is required"
          label="Last Name"
          :validState="!v$.user.last_name.$invalid"
          inputType="text"
          required
          v-model="user.last_name"
          @change="SpecialCharacterslast"
        />
      </fieldset>
      <fieldset class="grid gap-4 my-8 w-full">
        <select
          class="w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
          name="gender"
          v-model="user.gender"
        >
          <option selected disabled value="">Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </fieldset>
      <fieldset class="grid grid-cols-6 gap-4 my-8 w-full" v-if="true">
        <select
          class="col-span-2 w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
          name="country"
          v-model="user.country_id"
          @change="checkPhoneValid"
        >
          <option selected disabled value="">Select Country</option>
          <template v-for="country in countries" :key="country.id">
            <option :value="country.id">
              {{ country.nicename }}
            </option>
          </template>
        </select>
        <CustomInputField
          :errorMessage="phoneValidationMsg"
          :validState="phoneValid"
          label="Phone number"
          inputType="tel"
          required
          class="col-span-4"
          v-model="user.phone_number"
          @validatephone="checkPhoneValid"
        />
      </fieldset>
      <fieldset class="grid gap-4 my-8 w-full">
        <select
          class="w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
          name="hear_about_us"
          v-model="user.hear_about_us"
        >
          <option selected disabled value="">How did you hear about us?</option>
          <option value="facebook">Facebook</option>
          <option value="instagram">Instagram</option>
          <option value="linkedin">LinkedIn</option>
          <option value="twitter">Twitter</option>
          <option value="referral">Referral</option>
          <option value="other">Other</option>
        </select>
      </fieldset>
      <CustomInputField
        label="Referral Code (Optional)"
        inputType="text"
        class="mt-4 mb-8"
        v-model="user.referred_by"
      />
      <CustomInputField
        errorMessage="Enter at least 6 characters"
        :validState="!v$.user.password.$invalid"
        :label="password_label"
        inputType="password"
        class="mt-4 mb-8"
        required
        v-model="user.password"
      />
      <CustomInputField
        errorMessage="Password doesn’t match"
        :validState="!v$.user.rePassword.$invalid"
        :label="re_password_label"
        inputType="password"
        required
        class="mt-4 mb-8"
        v-model="user.rePassword"
      />
      <button
        type="submit"
        :disabled="v$.$invalid || isLoading  || !phoneValid || !user.country_id"
        v-html="buttonText"
      ></button>
    </form>
  </div>
</template>

<script>
import CustomInputField from "@/components/inc/CustomInputField.vue";
//
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import phoneValidator from "@/helpers/phoneValidator";
import swal from "sweetalert";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    CustomInputField,
  },
  watch: {
    "user.phone_number"() {
      this.checkPhoneValid();
    },
  },
  data() {
    return {
      user: {
        email: "",
        first_name: "",
        last_name: "",
        phone_number: "",
        gender: "",
        password: "",
        rePassword: "",
        country_id: "",
        referred_by: "",
        hear_about_us: "",
      },
      buttonText: "Proceed",
      isLoading: false,
      countries: [],
      phoneValid: false,
      phoneValidationMsg: "",
      password_label: "Create password",
      re_password_label: "Confirm password",
    };
  },
  computed: {
    SpecialCharactersFirst() {
      const newValue = this.user.first_name;
      if (this.checkForSpecialCharacters(newValue)) {
        this.user.first_name = this.removeSpecialCharacters(newValue);
      }
    },
    SpecialCharacterslast() {
      const newValue = this.user.last_name;
      if (this.checkForSpecialCharacters(newValue)) {
        this.user.last_name = this.removeSpecialCharacters(newValue);
      }
    },
    ...mapGetters({
      referral_code: "auth/getReferral",
    }),
  },
  validations() {
    return {
      user: {
        first_name: { required },
        last_name: { required },
        phone_number: { required },
        gender: { required },
        password: { required, minLength: minLength(6) },
        rePassword: { required, sameAs: sameAs(this.user.password) },
      },
    };
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    countryChanged(country) {
      // console.log(country);
      const selectedCountry = this.countries.find((item) => {
        return item.iso == country.iso2;
      });
      return (this.user.country_id = selectedCountry.id);
    },
    getCountries() {
      axios.get("countries").then((res) => {
        this.countries = res.data.payload;
        localStorage.setItem("countries", JSON.stringify(this.countries));
      });
    },
    checkPhoneValid() {
      let selectedCountry = this.countries.find(
        (item) => item.id == this.user.country_id
      );
      if (!this.user.country_id) {
        this.phoneValidationMsg = "Please select your country";
        return;
      }
      if (this.user.phone_number.length < 8) {
        this.phoneValid = false;
        return;
      } 
      let res = phoneValidator(this.user.phone_number, selectedCountry.iso);
      if (res.status) {
        this.user.phone_number = res.phoneNumber;
        this.phoneValidationMsg = "";
        this.phoneValid = true;
      } else {
        this.phoneValid = false;
        this.phoneValidationMsg = "Enter a valid phone number";
      }
    },
    async submitDetails() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let loader = this.$loading.show();
        this.buttonText = `<i class="fa-solid fa-spinner fa-spin fa-2x"></i>`;
        try {
          const data = this.user;
          let response = await axios.put("v2/register/2", data)
          const localEmail = localStorage.getItem("pendingEmail");
          if (localEmail) {
            localStorage.removeItem("pendingEmail");
          }
          if (localStorage.referral_code) {
            localStorage.removeItem("referral_code");
          }
          this.attempt({token: response.data.data}).then(() => {
            if (
              this.$route?.params?.unAuthApplication &&
              localStorage.getItem("unAuthApplication")
            ) {
              const payload = localStorage.getItem("unAuthApplication");
              axios.put("v2/auth/update/3", JSON.parse(payload)).then(() => {
                localStorage.removeItem("unAuthApplication");
                loader.hide();
                this.buttonText = "Proceed";
                this.$router.push({
                  name: "SelectBatchPage",
                });
              });
            } else {
              loader.hide();
              this.buttonText = "Proceed";
              this.$router.push({
                name: "SelectBatchPage",
              });
            }
          });
        } catch (error) {
          console.log(error);
          swal({
            title: "Oops!",
            text: `${
              error?.response?.data?.payload ?? "Please try again shortly"
            }`,
          });
          loader.hide();
          this.buttonText = "Proceed";
        }
      }
    },
    checkForSpecialCharacters(charactersString) {
      const specialCharactersRegex = /[^\w\s]/;
      return specialCharactersRegex.test(charactersString);
    },
    removeSpecialCharacters(charactersString) {
      return charactersString.replace(/[^\w\s]/gi, "");
    },
  },
  mounted() {
    const localEmail = localStorage.getItem("pendingEmail");
    if (!this.$route.params.email && !localEmail) {
      this.$router.push({ name: "RegisterEmail" });
    } else {
      this.user.email = this.$route.params.email
        ? this.$route.params.email
        : localEmail;
      if (this.$route.params.first_name) {
        this.user.first_name = this.$route.params.first_name;
      }
      if (this.$route?.params?.last_name || this.$route?.params?.first_name) {
        this.password_label = "Update Password";
        this.re_password_label = "Confirm Password";
      }
      if (this.$route.params.last_name) {
        this.user.last_name = this.$route.params.last_name;
      }
      if (this.$route.params.phone_number) {
        this.user.phone_number = this.$route.params.phone_number;
      }
      const countries = localStorage.getItem("countries");
      if (Array.isArray(countries) && countries?.length > 1 ) {
        this.countries = JSON.parse(countries);
      } else {
        this.getCountries();
      }
    }
    if (localStorage.referral_code) {
      const referral_code = localStorage.getItem("referral_code");
      this.user.referred_by = referral_code;
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  @apply text-base lg:text-lg font-semibold text-white bg-primary-green rounded-md  py-4 px-12 w-full;
}
button:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF];
}

.vue-tel-input {
  @apply w-full focus-within:shadow-none focus:outline-none focus:border-primary-green focus:rounded-md px-4 py-2 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585] ring-0 focus:ring-0;
  .vti__input {
    @apply border-none outline-none ring-0 focus:ring-[transparent] focus:shadow-none focus:outline-none focus:border-none;
    --tw-ring-shadow: 0 0 #000 !important;
  }
  input[type="tel"]:focus {
    @apply ring-0 ring-offset-0 ring-opacity-100;
    --tw-ring-shadow: 0 0 #000 !important;
  }
}
</style>
