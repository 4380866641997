export const integerFormatter = (number) => {
  if (number) {
    const numFormat = Intl.NumberFormat("en-US");
    const newInteger = numFormat.format(number);
    return newInteger;
  } else {
    return "0";
  }
};

export const formatDate = (date) => {
  if (date) {
    const newDate = new Date(date).toDateString();
    return newDate;
  } else {
    return " ";
  }
};

export const countDown = (date) => {
  if (date) {
    const deadlineData = new Date(date);
    const CURRENT_TIME = new Date();
    const diff = deadlineData.getTime() - CURRENT_TIME.getTime();
    const dayDiff = diff / (1000 * 60 * 60 * 24);
    if (Math.ceil(dayDiff) <= 0) {
      return 0;
    } else {
      return Math.ceil(dayDiff);
    }
  } else {
    return 0;
  }
};
