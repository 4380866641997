const theme = (mode) => {
  // Check what is the active theme and change theme.
  if (mode) {
    localStorage.setItem("theme", "light");
    document.querySelector("html").classList.remove("dark");
  }else{
    localStorage.setItem("theme", "light");
    document.querySelector("html").classList.remove("dark");
  }
  return;
};

export default theme;
