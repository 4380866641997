<template>
  <main class="bg-[linear-gradient(107.93deg,#ECF2FE_0%,#F1F8F1_104.14%)]  font-body flex text-[#404040]  place-content-center  min-h-screen">
      <div class="w-[60vw] h-fit bg-white md:1/3  rounded-md p-5 md:p-10   text-center flex flex-col mt-20 mb-16">
      
          <h1 class="text-center text-xl  md:text-2xl font-semibold">
              We’re waiting to receive your transfer. <br/> This can take a while
          </h1>
          
          <div
              class="my-6 flex w-fit mx-auto items-center rounded-full px-3 py-1.5 border border-[#9ACD98] bg-[#F5FAF5] text-primary-green uppercase font-body font-medium text-sm"
              >
              <span class="iconify text-lg mr-2" data-icon="mdi:alarm-clock"></span>
              <Vue-countdown
                  class="font-semibold text-black ml-1"
                  :time="convertTime(pending_payment?.updated_at)"
                  v-slot="{ minutes, seconds }"
                  >{{ minutes }}:{{ seconds }}
              </Vue-countdown>
          </div>
          <div v-if="showDetails" >
              <div class="bg-[#F6F7F6] text-xs p-5 mt-5 w-[80%] text-left mx-auto" >
                  <p>Account Name: <span class="font-semibold ">{{ pending_payment?.account_name }}</span></p>
                  <p>Account Number :  <span class="font-semibold leading-relaxed mb-3">{{ pending_payment?.account_number }}</span></p>
                  <p>Bank Name : <span class="font-semibold leading-relaxed mb-3">{{ pending_payment?.bank_name }}</span></p>
                  <p>Narration : <span class="font-semibold leading-relaxed">{{ pending_payment.reference_id }}</span></p>
              </div>
              <p @click="showDetails = false"  class="text-sm cursor-pointer mt-3 mb-5">Hide account details</p>
          </div>
          <p v-else @click="showDetails = true" class="text-sm cursor-pointer mt-7 mb-8">Show account details</p>
          <p class="text-sm font-[400] mt-auto w-[90%] mx-auto"><span class="font-bold">NOTE: </span>For support or complaints regarding payment, kindly reach out to us via <a href="mailto:accounts@sidehustle.ng" class="italic text-primary-green underline">accounts@sidehustle.ng</a></p>
      </div>
  </main>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import { mapActions, mapGetters } from 'vuex';
import ProgressBar from '@/views/lms/game/ProgressBar.vue';
import axios from "axios";

export default{
  data(){
      return{
          showDetails: false,
          pending_payment: null,
      }
  },
  components: {
      VueCountdown,
      ProgressBar
  },
  computed:{
      ...mapGetters({
          user:  "auth/user",
          settings: "auth/settings", 
      })
  },
  watch:{
      user(){
          if (this.user?.can_access == 1) {
              return this.$router.push({name: "LearningDashboardIndex"});
          }
      }
  },
  methods:{
      ...mapActions({
          attempt: "auth/attempt"
      }),
      convertTime(time) {
        const now = new Date().getTime();
        var date = new Date(time);
        date = new Date(date.getTime() + 60*60*1000)
        return date - now;
      },
      async fetchLatestPendingPayment(){
        let loader = this.$loading.show()
        try {
            const { data } = await axios.get('v2/auth/payment/get/pending')
            this.pending_payment = data.data;
            loader.hide()
        } catch (error) {
            console.log(error);
            loader.hide()
            return this.$router.push({name: "LearningDashboardIndex"});
        }
      }
  },
  mounted(){
    this.fetchLatestPendingPayment();
    setInterval(() => {
        this.attempt();            
        this.fetchLatestPendingPayment();            
    }, 300000); // run this check every 5 minutes
  }
}
</script>
