<template>
  <main>
    <p class="font-body font-[800] text-[#242424]">
      How would you describe your knowledge of this course?
    </p>
    <form @submit.prevent="submit()" class="w-full">
      <fieldset class="flex flex-col font-light my-4 space-y-3">
        <div class="">
          <label for="beginner" class="">
            <input
              type="radio"
              v-model="knowledge_level"
              name="knowledge_level"
              id="beginner"
              value="beginner"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Beginner
          </label>
        </div>
        <div class="">
          <label for="intermediate" class="">
            <input
              type="radio"
              v-model="knowledge_level"
              name="knowledge_level"
              id="intermediate"
              value="intermediate"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Intermediate
          </label>
        </div>
        <div class="">
          <label for="Advanced" class="">
            <input
              type="radio"
              v-model="knowledge_level"
              name="knowledge_level"
              id="Advanced"
              value="advanced"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Advanced
          </label>
        </div>
      </fieldset>
      <button type="submit" :disabled="!knowledge_level">Next</button>
    </form>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      knowledge_level: null,
    };
  },
  computed: {},
  methods: {
    submit() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      user.knowledge_level = this.knowledge_level;
      localStorage.setItem("tempUser", JSON.stringify(user));
      this.$emit("next");
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      this.knowledge_level = JSON.parse(
        localStorage.getItem("tempUser")
      ).knowledge_level;
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  @apply flex flex-col;
  fieldset {
    .radio-check-group {
      @apply col-span-1 grid grid-cols-1;
      label {
        @apply font-body-new font-semibold text-sm text-[#3D403D] border border-[#D2D2D2] rounded-lg px-6 py-4 w-full cursor-pointer;
      }
    }
  }
  button[type="submit"] {
    @apply py-3 px-8 text-white bg-primary-green font-body-new rounded-md ml-auto mt-6 lg:mt-8;
  }
}
</style>
