<template>
    <main class="bg-white -mt-12 md:mt-0  font-body flex items-center text-[#404040]  min-h-screen w-full scrollbar-hide">
        <div class="md:w-[60vw] h-fit bg-white md:1/3  rounded-md p-5 md:p-10 flex flex-col mb-16">
        
            <h1 class="text-center md:text-left text-xl  md:text-2xl font-bold uppercase">
                Bootcamp Start Date
            </h1>
            <p class="text-xs text-center md:text-left font-semibold text-[#404040] mt-4 md:mt-2">Congratulations on the success of your application. The bootcamp will begin 
                on the {{ startDate }}.</p>

            <Counter class="mt-10 md:mx-0 mx-auto" :date="startDate" /> 
            <a 
                :href="settings?.bootcamp_workspace_link"
                v-if="settings?.bootcamp_workspace_link"
                target="_blank"
                class="bg-[#EFEFEF] rounded py-3 text-left mt-8 px-4 md:px-6 cursor-pointer"
            >
                <p
                    class="font-body-new font-medium text-[12px] md:text-sm text-[#5B645B]"
                >
                    To join the Slack workspace
                    <strong class="text-primary-green underline">Click Here</strong>
                </p>
            </a>
        </div>
        <div class="hidden loader lg:block scrollbar-hide">
            <img class="w-96" src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/WaitImg.png" alt="Wait">
        </div>
    </main>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import Counter from "@/components/inc/Counter.vue";
  
  export default{
    data(){
        return{

        }
    },
    components: {
        Counter
    },
    computed:{
        ...mapGetters({
            user:  "auth/user",
            settings: "auth/settings", 
        }),
        startDate() {
            const batch_start = this.user.bootcamp.batch.start_on
            const date = new Date(batch_start);
            return date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });;
        }
    },
    methods:{
        ...mapActions({
            attempt: "auth/attempt"
        }),
    },
    mounted(){
        const user = this.user;
        if (!user?.bootcamp?.type) {
            return this.$router.push({name: "SelectBootcampType"})
        }
        if (new Date(this.user?.bootcamp?.batch?.start_on)  <= new Date() || this.settings?.can_access == 1) {
            return this.$router.push({ name: "BootcampWelcome" });
        }
    }
  }
  </script>

<style>
.loader {
    animation: rotation 8s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
</style>
  
