<template>
  <Transition name="bounce">
    <FeedBackModal
      @close-modal="showFeedbackModal = false"
      v-if="showFeedbackModal"
    ></FeedBackModal>
  </Transition>
  <nav
    class="hidden md:block md:w-[25%] h-full md:pb-32 md:fixed -ml-9 font-body overflow-y-scroll bg-[#FAFAFA] dark:bg-[#121212]"
  >
    <!-- User Streak Bar -- showing on lessons and quiz page -->
    <div class="w-10/12 ml-auto">
      <StreakBar />
    </div>
    <!-- User Streak Bar -- showing on lessons and quiz page -->
    <div class="pt-4 mt-3" v-if="true">
      <template v-for="(week, index) in [ 1, 2, 3, 4]" :key="index">
        <Disclosure
          as="div"
          v-slot="{ open }"
          class=" border-b py-4 dark:border-[#282828] border-[#D2D7D2]"
        >
          <DisclosureButton
            as="button"
            :class="open ? 'pb-2' : ''"
            class="dark:text-[#C1C6C1] text-[#112211] px-4 lg:px-12 flex text-sm md:text-base justify-between items-center w-full"
          >
            <span class="font-bold">
              {{ `${weekModule()} ${week}` }}</span
            >
            <i
              :class="open ? 'fa-chevron-up' : 'fa-chevron-down'"
              class="fa-solid text-xs"
            ></i>
          </DisclosureButton>
          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="-translate-y-1"
            enter-to-class="translate-y-0"
            leave-active-class="transition duration-100 ease-out"
            leave-from-class="translate-y-0"
            leave-to-class="-translate-y-1"
          >
            <div
              v-show="
                ( learningType == 'learning' &&
                  lessons?.length > 0 &&
                  eachWeekLessons(week)?.includes(currentLesson?.id)) ||
                open
              "
            >
              <DisclosurePanel
                as="ul"
                static
                class="dark:text-[#C1C6C1] text-[#3F473F]"
              >
                <template
                  v-if="lessons?.length > 0 && eachWeekLessons(week)?.length > 0"
                >
                <template v-for="(lesson, _index) in eachWeekLessons(week)"
                    :key="'week_' + _index">
                  <li
                    @click="setLesson(lesson, 'learning')"
                    v-if="lesson?.modules?.length > 0"
                    :class="
                      lesson?.id === currentLesson?.id &&
                      learningType == 'learning' &&
                      $route.name === 'LessonPage'
                        ? 'bg-[#51a35021]'
                        : ''
                    "
                    class="cursor-pointer py-4 text-xs text-left md:text-sm px-4 lg:px-12 flex justify-start items-start"
                  >
                    <span class="iconify text-2xl" data-icon="ei:play"></span>
                    <span class="mr-2">{{ lesson?.title }}</span>
                    <i
                      v-if="isWatched(lesson?.id)"
                      class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
                    ></i>
                  </li>
                </template>
                </template>
                <div v-if="canSeeResource()">
                  <li
                    @click="goToResource(week)"
                    :class="
                      $route.name.includes('Resource') &&
                      route.params.week &&
                      decrypt(route.params.week) == week
                        ? 'bg-[#51a35021]'
                        : ''
                    "
                    class="cursor-pointer py-4 text-xs text-left md:text-sm px-4 lg:px-12 flex justify-start items-start"
                  >
                    <span
                      class="iconify text-lg"
                      data-icon="bi:text-left"
                    ></span>
                    <span class="mr-2"
                      ><span class="font-semibold">Slides</span>: Resources to
                      read</span
                    >
                    
                  </li>
                </div>
                <div v-if="canSeeQuiz()">
                  <li
                    @click="goToQuiz(week)"
                    :class="
                      $route.name.includes('Quiz') &&
                      route.params.week &&
                      decrypt(route.params.week) == week
                        ? 'bg-[#51a35021]'
                        : ''
                    "
                    class="cursor-pointer py-4 text-xs text-left md:text-sm px-4 lg:px-12 flex justify-start items-start"
                  >
                    <span
                      class="iconify text-lg"
                      data-icon="healthicons:i-exam-multiple-choice-outline"
                    ></span>
                    <span class="mr-2"
                      > {{ `${weekModule()} ${week} Quiz` }}</span
                    >
                    <!-- Quiz taken checkmark -->
                    <i
                      v-if="takenQuiz && (takenQuiz?.includes(week) || takenQuiz?.includes(week.toString()))"
                      class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
                    ></i>
                  </li>
                </div>
                <div v-if="canSeeTasks()">
                  <li
                    @click="goToTask(week)"
                    :class="
                      $route.name.includes('Task') &&
                      route.params.week &&
                      decrypt(route.params.week) == week
                        ? 'bg-[#51a35021]'
                        : ''
                    "
                    class="cursor-pointer py-4 text-xs text-left md:text-sm px-4 lg:px-12 flex justify-start items-start"
                  >
                    <span
                      class="iconify text-lg"
                      data-icon="healthicons:i-exam-multiple-choice-outline"
                    ></span>
                    <span class="mr-2"
                      >{{ `${weekModule()} ${week} Task` }}</span
                    >
                    <i
                      v-if="false"
                      class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
                    ></i>
                  </li>
                </div>
              </DisclosurePanel>
            </div>
          </transition>
        </Disclosure>
      </template>
      <button 
          @click="$router.push({ name: user?.hasTeam == 1 ? 'MyTeamPage' : 'SelectTeamPage' })"
          v-if="user?.batch?.learning_type == 'instructor' && user?.cohort >= 8 && user?.cohort < 10"
          class="dark:text-[#C1C6C1] text-[#112211] px-4 lg:px-12 flex text-sm md:text-base justify-between items-center w-full py-2"
          :disabled='siteSettings.week < 5'
      >
            <span class="font-bold">
              Level 5 - 6
            </span>
            <i class="fa-solid text-xs fa-chevron-right"></i>
      </button>
    </div>
  </nav>
</template>

<script setup>
// modular helpers
import { quizTask, weekModule, goToTask, goToQuiz, goToResource, isWatched, canSeeTasks, canSeeQuiz, canSeeResource, setLesson } from "@/helpers/learningController";
import { encrypt, decrypt } from "@/helpers/encrypt";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

// components
import StreakBar from "@/components/lms/StreakBarComponent.vue";
import CourseProgress from "@/components/lms/CourseProgressBar.vue";
import FeedBackModal from "@/views/lms/feedback/FeedBackModal.vue"

import { useStore } from "vuex";
import { onMounted, ref, watch } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();

let open = ref(true);
let progress = ref(0);
let showFeedbackModal= ref(false);
/**
 * Array of all all taken quiz
 * @returns [Array]
 */
const takenQuiz = computed(() => {
  return store.state.lms.takenQuiz;
});
const allQuizScore = computed(() => {
  return store.state.lms.allQuizScore;
});

const user = computed(() => {
  return store.state.auth.user;
});

const learningType = computed(() => {
  return store.state.lms.learningType;
});

const lessons = computed(() => {
  return store.state.lms.lessons;
});

const jobberman_lessons = computed(() => {
  return store.state.jobberman.lessons;
});
const orientation_videos = computed(() => {
  return store.state.lms.orientation_videos;
});

const badges = computed(() => {
  return store.state.lms.badges;
});
const siteSettings = computed(() => {
  return store.state.auth.settings;
});

const currentLesson = computed(() => {
  return store.state.lms.currentLesson;
});


const watchedLessons = computed(() => {
  return store.state.lms.watchedLessons;
});
const userStreak = computed(() => {
  return store.state.lms.userStreak;
});

const jobbermanTests = computed(() => {
  return store.state.jobberman.checkTests;
});

const eachWeekLessons = computed(() => {
  return (week) => lessons?.value.filter((item) => item.week == week);
});

const eachWeekBadges = computed(() => {
  return (week) => badges.value.filter((item) => item.week === week);
});

const fetchTakenQuiz = () => {
  store.dispatch("lms/setTakenQuiz");
};

const userRank = computed(() => {
  // 0 star- Rookie -0-10
  // 1 star- Captain -10-30
  // 2 stars- Colonel -30-50
  // 3 stars- General -50-70
  // 4 stars- Field Marshal -70-100

  if (!streakProgress.value) {
    return {
      star: 0,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
      title: "Rookie",
    };
  }
  const sek = userStreak?.value?.streak;
  if ( streakProgress.value >=0 && streakProgress.value <= 10
  ) {
    return {
      star: 0,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
      title: "Rookie",
    };
  }
  if (streakProgress.value>=11 && streakProgress.value<= 30
  ) {
    return {
      star: 1,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Captain.png",
      title: "Captain",
    };
  }
  if ( streakProgress.value >= 30 && streakProgress.value <= 50
  ) {
    return {
      star: 2,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Colonel.png",
      title: "Colonel",
    };
  }
  if (streakProgress.value >= 50 && streakProgress.value<= 70
  ) {
    return {
      star: 3,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/General.png",
      title: "General",
    };
  }
  if (streakProgress.value >= 70
  ) {
    return {
      star: 4,
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Marshall.png",
      title: "Field Marshal",
    };
  }
  return {
    star: 0,
    image:
      "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
    title: "Rookie",
  };
});

const saveWatchedLessons = () => store.dispatch("lms/saveWatchedLessons");


const check = () => {
  if (localStorage.getItem("o_id")) {
    return JSON.parse(localStorage.getItem("o_id")) == 1;
  }
  return false;
};
</script>

<style scoped>
* {
  @apply transition ease-linear;
}

.bounce-enter-active {
  animation: bounce-in 0.6s;
}
.bounce-leave-active {
  animation: bounce-in 0.4s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  70% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
.iconify {
  @apply mr-2;
}
</style>