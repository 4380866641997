<template>
  <!-- Start Section -- Greeting & Rank Card  -->
  <section class="flex flex-row w-full">
    <div
      class="dark:bg-[#333333] bg-[#1B201B] border welcome_card grow overflow-clip rounded-2xl my-4 md:my-8 border-[#ADAEAD] dark:border-[#333333] flex items-center justify-between shadow-[#52A3504D]">
      <div class="px-4 md:px-8 py-4 w-full">
        <div class="flex items-center">
          <img :src="rank?.image" alt="" class="mr-4 md:mr-8 h-12 md:h-20" />
          <h4 class="font-bold font-header-1 text-xl md:text-4xl text-white">
            Welcome, {{ rank?.title }}
            {{ user?.first_name ?? "first_name" }}
            <p class="font-normal uppercase text-xs font-body mt-1">
              INTERN ID: {{ user?.user_id }}
            </p>
          </h4>
        </div>
        <div class="my-2 flex items-center justify-between p-0 w-full"></div>
        <p class="text-white font-body text-xs">
          You’re currently in module
          {{ currentModule }} of {{ 4 }}
        </p>
        <div class="flex flex-row gap-x-2 items-center justify-between mt-4">
          <div class="bg-white grow rounded-full h-3 overflow-clip p-0 border-none">
            <div class="bg-primary-green/90 h-full border-none rounded-r-full"
              :style="{ width: `${Math.floor(userStreak.streak ?? 0)}%` }"></div>
          </div>
          <p class="text-white font-semibold text-sm">
            {{ `${Math.floor(userStreak.streak ?? 0)}%` }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Section 1 -- Greeting & Rank Card  -->
</template>

<script setup>
import ProgressBar from "@/components/lms/CourseProgressBar.vue";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
const store = useStore();
const user = computed(() => {
  return store.state.auth.user;
});
const siteSettings = computed(() => {
  return store.state.auth.settings;
});

const userStreak = computed(() => {
  return store.state.lms.userStreak;
});
const allQuizScore = computed(() => {
  return store.state.lms.allQuizScore;
});

const rank = computed(() => {
  if (userStreak.value.streak < 10) {
    return {
      title: "Rookie",
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
    };
  }
  if (userStreak.value.streak >= 10 && userStreak.value.streak < 30) {
    return {
      title: "Captain",
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Captain.png",
    };
  }
  if (userStreak.value.streak >= 30 && userStreak.value.streak < 50) {
    return {
      title: "Colonel",
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Colonel.png",
    };
  }

  if (userStreak.value.streak >= 50 && userStreak.value.streak < 70) {
    return {
      title: "General",
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/General.png",
    };
  }
  if (userStreak.value.streak >= 70) {
    return {
      title: "Field Marshal",
      image:
        "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Marshal.png",
    };
  }
  return {
    title: "Rookie",
    image:
      "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
  };
});

const currentModule = computed(()=> {
  if (allQuizScore.value?.length >= 4) return 4
  if (allQuizScore.value?.length > 0) return allQuizScore.value?.length + 1
  return 1;
});
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

.welcome_card {
  background-image: url("https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/zig-zag2.svg");
  background-size: cover;
}
</style>
