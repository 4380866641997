<template>
  <div
    class="fixed bg-[#636363]/[0.27] overflow-y-scroll backdrop-blur-[10px] custom_fade top-0 right-0 left-0 bottom-0 w-screen h-screen flex items-center justify-center z-[10000]"
  >
    <div
      class="container p-0 flex items-center justify-center custom_bounce h-full"
    >
      <div
        class="max-w-[90vw] max-h-[95vh] font-body md:w-fit text-[#1A1F1A] lg:w-fit lg:px-10 pt-4 pb-10 px-7 bg-[#FCFDFC] rounded-2xl flex flex-col justify-between items-center text-center space-y-6 relative overflow-y-scroll customized-scrollbar"
      >
        <div
          @click="$emit('closeEnrollPrompt')"
          class="flex cursor-pointer items-center py-2 pr-5 text-primary-green mr-auto"
        >
          <span
            class="iconify text-primary-green bg-[#E4F2E4] rounded-full mr-2"
            data-icon="ion:close"
          ></span>
          <p class="font-body text-sm">CLOSE</p>
        </div>
        <h1 class="text-[#3B433B] text-xl font-bold md:text-3xl mt-9">
          Registration for the 2023 cohort <br />
          of the internship is now open!!!
        </h1>
        <img
          class="h-[40vh] md:h-[55vh]"
          src="@/assets/img/img/reap.jpg"
          alt="reapply"
        />
        <PrimaryButton
          @click="
            $router.push({ name: 'SelectCourse' }),
              $emit('closeEnrollPrompt')
          "
          >Enroll for new course</PrimaryButton
        >
        <button
          @click="$emit('closeEnrollPrompt')"
          class="hover:border-hover-green hover:text-hover-green transition ease-linear rounded-md px-3 py-1.5"
        >
          Continue current course
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SecondaryButton from "../../components/inc/SecondaryButton.vue";
import PrimaryButton from "../inc/PrimaryButton.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  components: {
    SecondaryButton,
    PrimaryButton,
  },
  methods: {
    closeModalFunction() {
      this.$emit("closeModal");
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
