<template>
  <div class="container p-0 md:px-12 md:py-16">
    <BackButton class="my-4 ml-3 md:my-12" />
    <div class="md:-mt-12 w-full">
      <form
        class="grid grid-flow-row w-full md:w-4/5 gap-8 md:gap-12 px-4"
        @submit.prevent="enroll()"
      >
        <img
          src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/jobberman-flier.png"
          alt="Jobberman Enrollment"
        />
        <div class="grid gap-8 grid-cols-1 md:grid-cols-2">
          <!-- First Name -->
          <div class="relative w-full">
            <input
              class="peer"
              type="text"
              name="first_name"
              v-model="payload.firstname"
              required
              readonly
              placeholder=""
            />
            <label class=" " for="first_name">First Name</label>
          </div>
          <!-- First Name -->
          <!-- last Name -->
          <div class="relative w-full">
            <input
              class="peer"
              type="text"
              name="last_name"
              v-model="payload.lastname"
              required
              readonly
              placeholder=""
            />
            <label class=" " for="last_name">Last Name</label>
          </div>
          <!-- last Name -->
          <!-- Eamil -->
          <div class="relative w-full">
            <input
              class="peer"
              type="email"
              name="email"
              v-model="payload.email"
              required
              readonly
              placeholder=""
            />
            <label class=" " for="email">Email Address</label>
          </div>
          <!-- Email -->
          <!-- Phone Number -->
          <div class="relative w-full">
            <input
              class="peer"
              type="tel"
              name="phone_number"
              v-model="payload.phone_number"
              required
              readonly
              placeholder=""
            />
            <label class=" " for="phone_number">Phone Number</label>
          </div>
          <!-- Phone Number -->
          <!-- Gender -->
          <select
            class="h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded bg-white text-[#5F5F5F] border-[#5F5F5F] dark:bg-[#121212] dark:text-[#BFBFBF] dark:border-[#393939] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
            name="gender"
            v-model="payload.gender"
            required
          >
            <option selected disabled value="">Gender</option>
            <option
              v-for="(gender, index) in genders"
              :key="index"
              :value="index"
            >
              {{ gender }}
            </option>
          </select>
          <!-- Gender -->
          <!-- Age Range -->
          <select
            class="h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded bg-white text-[#5F5F5F] border-[#5F5F5F] dark:bg-[#121212] dark:text-[#BFBFBF] dark:border-[#393939] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
            name="age"
            v-model="payload.age"
            required
          >
            <option selected disabled value="">Age range</option>
            <option v-for="(age, index) in ages" :key="index" :value="index">
              {{ age }}
            </option>
          </select>
          <!-- Age Range -->
          <!-- What is your highest educational qualification? -->
          <select
            class="h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded bg-white text-[#5F5F5F] border-[#5F5F5F] dark:bg-[#121212] dark:text-[#BFBFBF] dark:border-[#393939] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
            name="highest_educational_qualification"
            required
            v-model="payload.highest_educational_qualification"
          >
            <option selected disabled value="">
              What is your highest educational qualification?
            </option>
            <option
              v-for="(qualification, index) in qualifications"
              :key="index"
              :value="index"
            >
              {{ qualification }}
            </option>
          </select>
          <!-- What is your highest educational qualification? -->
          <!-- Location -->
          <select
            required
            class="h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded bg-white text-[#5F5F5F] border-[#5F5F5F] dark:bg-[#121212] dark:text-[#BFBFBF] dark:border-[#393939] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
            name="location_based_id"
            v-model="payload.location_based_id"
          >
            <option selected disabled value="">Location</option>
            <option
              v-for="(location, index) in locations"
              :key="index"
              :value="index"
            >
              {{ location }}
            </option>
          </select>
          <!-- Location -->
          <!-- Termss && Conditions -->
          <div
            class="md:col-span-2 md:flex agreement md:justify-center md:items-start"
          >
            <div class="flex items-center">
              <input
                required
                v-model="payload.terms_accepted"
                class="ring-0"
                type="checkbox"
                name="agreement"
                id="agreement"
              />
              <label
                for="agreement"
                class="relative text-sm px-3 text-[#5F5F5F] dark:text-[#BFBFBF] dark:border-[#393939]"
                >I agree to the Terms and Conditions</label
              >
            </div>
          </div>
          <!-- Termss && Conditions -->
        </div>

        <button
          type="submit"
          class="w-full md:w-fit py-4 px-2 md:px-8 rounded font-body bg-primary-green text-white place-self-center"
        >
          Join the soft skill class
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/lms/DashboardBackComponent.vue";

export default {
  components() {
    BackButton
  }
}
</script>


<script setup>
import { ref } from "@vue/reactivity";
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { inject } from "vue";
import swal from "sweetalert";
import axios from "axios";

const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();

const user = computed(() => {
  return store.state.auth.user;
});

const checkTests = computed(() => {
  return store.state.jobberman.checkTests;
});

const setLesson = (lesson) => {
  store.dispatch("lms/setCurrentLesson", lesson);
};

const jobberman_lessons = computed(() => {
  return store.state.jobberman.lessons;
});

const ages = ref([]);
const genders = ref([]);
const locations = ref([]);
const qualifications = ref([]);

const payload = ref({
  firstname: "",
  lastname: "",
  email: "",
  phone_number: "",
  gender: "",
  age: "",
  highest_educational_qualification: "",
  location_based_id: "",
  terms_accepted: false,
});

const enroll = async () => {
  const loader = $loading.show();
  try {
    await axios.post("auth/jobberman/register", payload.value);
    await store.dispatch("auth/attempt");
    loader.hide();
    router.push({ name: "LearningJobbermanBaseline" });
  } catch (error) {
    loader.hide();
    if (error.response.status == 400) {
      swal({
        title: "Info",
        text: "You have been previously enrolled for the Soft Skills training. Would you like to re-enroll",
        buttons: {
          cancel: "No",
          confirmButtonText: "Yes, re-enroll",
        },
      }).then((result) => {
        if (result) {
          overwriteEnrollment();
        }
      });
      return;
    }
    swal({
      title: "Oops!",
      text:
        error?.response?.data?.payload ??
        "Unable to enroll you at the moment. Please try again shortly",
    });
    console.log(error);
  }
};

const overwriteEnrollment = async () => {
  const loader = $loading.show();
  try {
    await axios.post("auth/jobberman/overwrite", payload.value);
    store.dispatch("auth/attempt").then(() => {
      loader.hide();
      router.push({ name: "LearningJobbermanBaseline" });
    });
  } catch (error) {
    loader.hide();
    swal({
      title: "Oops!",
      text:
        error?.response?.data?.payload ??
        "Unable to enroll you at the moment. Please try again shortly",
    });
    console.log(error);
  }
};

const getOptions = async () => {
  const loader = $loading.show();
  try {
    axios
      .get("auth/jobberman/options")
      .then((res) => {
        const payload = res.data.payload;
        ages.value = payload.ages;
        genders.value = payload.genders;
        locations.value = payload.locations;
        qualifications.value = payload.qualifications;
        loader.hide();
      })
      .catch((err) => {
        console.log(err);
        loader.hide();
      });
  } catch (error) {
    loader.hide();
    console.log(error);
  }
};

onMounted(() => {
  if (user.value.canTakeJobberman == 3) {
    router.push({ name: "LearningDashboardIndex" });
    return;
  }

  if (
    user.value.canTakeJobberman == 2 &&
    checkTests?.value?.baseline == false
  ) {
    router.push({ name: "LearningJobbermanBaseline" });
    return;
  }

  if (user.value.canTakeJobberman == 2) {
    store.dispatch("lms/setLearningType", "jobberman");
    checkTests?.value.baseline
      ? setLesson(jobberman_lessons?.value?.[0])
      : setLesson(
          jobberman_lessons?.value?.[jobberman_lessons?.value.length - 1]
        );
    router.push({ name: "LessonPage" });
    return;
  }
  getOptions();
  payload.value.firstname = user.value.first_name || "";
  payload.value.lastname = user.value.last_name || "";
  payload.value.phone_number = user.value.phone_number || "";
  payload.value.email = user.value.email || "";
});
</script>

<style scoped>
label {
  @apply absolute left-4 text-[10px] top-1 peer-focus:block peer-focus:bg-[transparent] peer-focus:font-medium peer-focus:top-1 peer-focus:text-primary-green bg-white dark:bg-transparent dark:text-[#bfbfbf] block text-[#414141] font-normal;
}
input {
  @apply w-full focus:outline-none focus:border-primary-green focus:rounded-md px-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] dark:bg-[#121212] dark:text-[#BFBFBF] dark:border-[#393939] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585] focus:ring-0 pt-6 pb-2;
}
button:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF] text-[#707070];
}
.agreement label {
  transform: unset;
  position: unset;
}
.agreement input {
  width: fit-content;
  padding: 7px;
  position: unset;
}
</style>
