import axios from "axios";
import router from "./../router";

export default {
  namespaced: true,
  state: {
    teams: null,
  },

  getters: {
    teams(state) {
        return state.teams;
    }, 
  },

  mutations: {
    SET_TEAMS(state, teams) {
        state.teams = teams;
    },    
  },

  actions: {
    async fetchTeams({ commit }) {
        try {
            const { data } = await axios.get("v2/auth/teams");
            commit("SET_TEAMS", data.data);
        } catch (error) {
            if (error?.response?.status == 401) {
              router.push({name: "LearningDashboardINdex"});
            }
            console.error(error);
        }
    }, 
    clearTeams({ commit }) {
      commit("SET_TEAMS", null);
    }   
  },
};
