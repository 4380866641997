<template>
  <div class="w-full">
    <div class="relative w-full font-body-new">
      <input
        class="peer focus:ring-0"
        :class="[
          showInputError ? 'error_input_field' : '',
          modelValue.length > 0
            ? 'pt-6 pb-2'
            : ' pt-4 pb-4 focus:pt-6 focus:pb-2',
        ]"
        :type="formType"
        :name="label"
        :value="modelValue"
        :required="required"
        :placeholder="label"
        :autocomplete="autocomplete"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('validatephone'), validateInput()"
        @keyup="$emit('validatephone'), validateInput()"
      />
      <label
        class="peer-focus:block peer-focus:bg-[transparent] peer-focus:top-1 peer-focus:text-primary-green peer-focus:font-light"
        :class="[
          modelValue.length > 0
            ? 'bg-white block top-4 text-[#414141] font-normal'
            : 'hidden',
        ]"
        for="testing"
        >{{ label }}</label
      >
      <small
        v-show="inputType == 'password'"
        @click="
          formType == 'password' ? (formType = 'text') : (formType = 'password')
        "
        class="-translate-y-1/2 absolute top-1/2 right-4 non-italic text-xs font-bold text-[#52A350] font-body-new cursor-pointer"
        >{{ formType == "password" ? "SHOW" : "HIDE" }}</small
      >
      <i
        v-show="showInputError && inputType != 'password'"
        class="fa-solid fa-exclamation -translate-y-1/2 absolute top-1/2 right-4 text-sm font-extralight text-white bg-[#F81414] h-4 w-4 flex items-center justify-center rounded-full"
      ></i>
    </div>
    <div class="flex justify-between min-w-full mt-2 font-body-new">
      <span
        v-show="showInputError"
        class="text-[#F81414] text-sm font-body-new"
        >{{ errorMessage }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formType: this.inputType,
      showInputError: false,
    };
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    validState: {
      type: Boolean,
      default: true,
    },
    inputType: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    autocomplete: {
      type: [String, Boolean],
      default: "off"
    }
  },
  watch: {
    validState() {
      if (!this.validState) {
        return (this.showInputError = true);
      } else {
        return (this.showInputError = false);
      }
    },
  },
  methods: {
    validateInput() {
      if (!this.validState) {
        return (this.showInputError = true);
      } else {
        return (this.showInputError = false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  @apply absolute left-4 text-[10px] top-1;
}
input {
  @apply w-full focus:outline-none focus:border-primary-green focus:rounded-md px-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585];
}
.error_input_field {
  @apply border-[#F81414] focus:border-[#F81414];
}
</style>
