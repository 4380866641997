<template>
  <div>
    <div class="container pb-24">
      <BackButton class="my-4 md:my-12" />
      <div v-if="showCertificateUpgradeCard" class="p-4 border border-blue-400/90 rounded-md mt-2 mb-4 shadow-sm bg-blue-400/60 text-slate-700 font-body text-sm">
        Upgrade your certificate to premium! By upgrading, you get a verification link and a downloadable PDF format. Click the button below to upgrade your certificate.
      </div>
      <div class="">
        <p class="font-body text-sm dark:text-[#E5E5E5] text-[#343434]">
          Your name,
          <span class="uppercase"
            >{{ user.first_name }} {{ user.last_name }}</span
          >, will appear on your certificates. Click
          <span
            @click="$router.push({ name: 'ProfilePage' })"
            class="cursor-pointer text-primary-green"
            >here</span
          >
          to change your name on the profile page.
        </p>
      </div>

      <div class="lg:w-5/6 my-16 font-body">
        <div
          v-if="certificates && certificates?.length == 0"
          class="cursor-pointer bg-[#FCFCFC] dark:bg-[#262626] dark:text-[#E5E5E5] border border-dashed border-[#CCCCCC] font-body text-[#363636] flex items-center justify-center py-16 px-6 text-center rounded-xl"
        >
          You have not earned any certificates yet
        </div>
        <template v-else>
          <div
            v-for="(certificate, index) in certificates"
            :key="index"
            class="cursor-pointer bg-[#FCFCFC] dark:bg-[#262626] dark:text-[#E5E5E5] border border-[#CCCCCC] font-body text-[#363636] flex items-center p-4 text-center rounded-xl mb-6"
          >
            <embed
              :src="certificate?.url"
              :alt="certificate?.title"
              class="w-1/5 rounded border"
            />
            <div class="ml-6 text-left font-body space-y-1 overflow-x-clip">
              <h2 class="font-semibold">{{ certificate?.title ?? " " }}</h2>
              <h2 class="font-light text-xs">
                Issued on: {{ new Date(certificate?.issued_on).toDateString() }}
              </h2>
              <a
                v-if="certificate.link"
                :href="certificate.link"
                target="_blank"
                class="text-sm text-primary-green block truncate text-ellipsis"
                >{{ certificate.link }}</a
              >
              <div class="flex pt-4 md:flex-row items-stretch gap-x-6 flex-col gap-y-2">
                <button
                  @click="
                    downloadFile(
                      certificate.type,
                      certificate.url,
                      certificate.package,
                      certificate?.title,
                      certificate?.count
                    )
                  "
                  class="bg-primary-green disabled:bg-[#CFCFCF] text-white p-3 rounded-md disabled:cursor-not-allowed flex justify-center items-center gap-x-2 text-center text-sm"
                >
                  <span class="iconify " data-icon="solar:download-outline"></span>
                  Download
                </button>
                <button
                  @click="upgradeCertificate(certificate.batch_id)"
                  v-if="certificate?.package == 1 && certificate?.type == 'internship'"
                  class="border border-primary-green disabled:bg-[#CFCFCF] text-primary-green p-3 rounded-md disabled:cursor-not-allowed flex justify-center items-center gap-x-2 text-center text-sm"
                >
                  <span class="iconify " data-icon="carbon:upgrade"></span>
                  Upgrade
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { encrypt } from "@/helpers/encrypt";
</script>
<script>
import { mapGetters , mapActions } from "vuex";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { jsPDF } from "jspdf";

import axios from "axios";
import swal from "sweetalert";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    showCertificateUpgradeCard() {
      if (this.certificates?.length < 1) {
        return false;        
      }
      const findBasicCerts = this.certificates?.find((item)=> item.package == 1 && item.type == 'internship')
      if (findBasicCerts) {
        return true;
      }
      return false;
    }
  },
  components: {
    BackButton,
  },
  data() {
    return {
      certificates: null,
    };
  },
  mounted() {
    this.fetchAllCertificates();
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    async upgradeCertificate(batch_id){
      const loader = this.$loading.show();
      try {
        // switch users active batch on the users table
        await axios.post("auth/batch/switch", {
          batch_id
        });
        await this.attempt({}); // re-fetch user auth data 
        loader.hide(); // hide loader 
        return this.$router.push({name: 'ApplicationPayment', params: { slug: 'certificate-upgrade' } }) 
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    async fetchAllCertificates() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/certificate/all");
        this.certificates = data.payload;
        loader.hide();
      } catch (error) {
        this.certificates = "";
        console.error(error);
        loader.hide();
      }
    },
    downloadFile(type, url, package_id, title, count_download = 0) {
      if (type == "internship") {
        if (count_download > 1) {
          swal({
            title: "Oops",
            text: "This certificate package is only available for a one-time download",
          });
          return;
        }
        this.downloadCert(url, package_id);
        return;
      }
      if (type == "letter") {
        let loader = this.$loading.show();
        let a = document.createElement("a");
        a.href = url;
        a.download = `${title}.pdf`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        loader.hide();
        return;
      }
      let loader = this.$loading.show();
      let a = document.createElement("a");
      a.href = url;
      a.download = `${title}`;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
      loader.hide();
      return;
    },

    downloadCert(url, type) {
      const file_name =
        this.user.first_name +
        "_" +
        this.user.last_name +
        "_Side_Hustle_Certificate";
      let img = url;
      let loader = this.$loading.show();
      // PDF download
      const pdfArray = [2, 3, 5, 6, 14, 15];
      if (pdfArray.includes(type*1)) {
        let newImg = document.createElement('img');
        newImg.id = '_uialeiiiajs89_as'; //random ID
        newImg.src = img;
        document.body.appendChild(newImg);
        newImg = document.querySelector("#_uialeiiiajs89_as");
        // jsPDF init
        const pdf = new jsPDF({
          orientation: "landscape",
          unit: "in",
          format: [(newImg.naturalHeight/96), (newImg.naturalWidth/96)], // size of image
        });
        pdf.addImage(newImg, 0, 0);
        const newBlob = new Blob([pdf.output("blob")], {
          type: "application/octet-stream",
        });
        let url = URL.createObjectURL(newBlob);
        var a = document.createElement("a");
        a.href = url;
        a.download = file_name + ".pdf";
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          document.body.removeChild(newImg);
        }, 0);
        loader.hide();
        return;
      }

      // png download
      const basicArray = [1, 4, 13];
      if (basicArray.includes(type*1)) {
        let a = document.createElement("a");
        a.href = img;
        a.download = file_name;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(img);
        }, 0);
        loader.hide();
        return;
      }
      loader.hide();
      return;
    },
  },
};
</script>

<style scoped></style>
