<template>
  <main>
    <section class="">
      <div class="lg:w-3/6 mx-auto p-4">
        <h2
          class="text-2xl font-semibold font-header-1 mb-5 dark:text-white text-[#0C0C0C]"
        >
          Jobberman
          {{ !checkTests.baseline ? "Baseline" : "Endline" }}
          Assessment
        </h2>
        <div class="space-y-10 mb-10">
          <template v-if="questions.length > 0">
            <div
              class="space-y-3"
              v-for="(question, index) in questions"
              :key="index"
            >
              <span
                class="font-header-1 font-bold text-sm dark:text-[#BABABA] dark:bg-[#333333] text-[#838383] bg-[#F4F4F4] rounded-full p-2 w-max"
              >
                Question {{ index + 1 }}/{{ questions.length }}
              </span>
              <p class="font-body dark:text-[#E5E5E5] text-[#242424]">
                {{ question.question }}
              </p>
              <div class="flex flex-col font-light space-y-3">
                <label
                  :for="`option_${index + 1}_${sub_index + 1}`"
                  class="cursor-pointer dark:text-[#E5E5E5] text-[#242424]"
                  v-for="(item, sub_index) in question.options"
                  :key="sub_index"
                >
                  <input
                    type="radio"
                    :name="`question_${index + 1}`"
                    :id="`option_${index + 1}_${sub_index + 1}`"
                    @click="selectOption(question.id, item)"
                    class="w-5 h-5 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
                  />
                  {{ item }}
                </label>
              </div>
            </div>
          </template>
        </div>
        <button
          class="bg-primary-green w-full text-white py-3 rounded-sm disabled:cursor-not-allowed disabled:bg-slate-300"
          :disabled="selected.length < questions.length"
          @click="submitTest"
          v-if="questions.length > 0"
        >
          Submit
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import swal from "sweetalert";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      questions: [],
      selected: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      checkTests: "jobberman/checkTests",
      lessons: "jobberman/lessons",
      watchedLessons: "jobberman/watchedLessons",
    }),
    completed() {
      const totalLessons = this.lessons.length;
      const totalWatched = this.watchedLessons.length;
      if (totalWatched < totalLessons) {
        return false;
      }
      if (totalWatched >= totalLessons) {
        return true;
      }
    },
    completeCourseSelection() {
      if (!this.user.course) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
      checkTestsMethod: "jobberman/checkTests",
      setLesson: "lms/setCurrentLesson",
      setLearningType: "lms/setLearningType",
    }),
    /**
     * Get jobberman question
     */
    async getJobbermanQuestions() {
      if (localStorage.jbquest) {
        this.questions = JSON.parse(localStorage.jbquest);
        return;
      }

      let loader = this.$loading.show();
      try {
        const { data } = await this.axios.get("auth/jobberman/tests");
        if (data.payload.length > 0) {
          localStorage.setItem("jbquest", JSON.stringify(data.payload));
        }
        this.questions = data.payload;
        loader.hide();
      } catch (error) {
        loader.hide();
        if (error.response.status == 502) {
          swal({
            title: "Try Again",
            text:
              error?.response?.data?.payload ??
              "Unable to fetch your test questions at the moment. Please try again shortly.",
          });
          return;
        }
      }
    },

    /**
     * Get selected Option with it's ID respectively
     * @param {Number} id
     * @param {string} answer
     */
    selectOption(id, answer) {
      let choosen = this.selected.find((item) => item.id == id);
      if (choosen) {
        choosen.answer = answer;
      } else {
        this.selected.push({ id, answer });
      }
    },

    /**
     * submit baseline test
     */
    async submitTest() {
      if (this.selected.length < this.questions.length) {
        swal({
          title: "You still have unanswered questions left",
          text: "Please attend all questions",
        });
        return;
      }
      let loader = this.$loading.show();
      let payload = { selected: this.selected };
      try {
        const { data } = await this.axios.post(
          "auth/jobberman/tests/grade",
          payload
        );
        loader.hide();
        this.setLearningType("jobberman");
        !this.checkTests.baseline
          ? this.setLesson(this.lessons?.[0])
          : this.setLesson(this.lessons?.[this.lessons?.length - 1]);
        this.checkTestsMethod().then(
          swal({
            title: !this.checkTests.baseline ? "Saved" : "Congrats",
            text: !this.checkTests.baseline
              ? "Your test has been submitted successfully"
              : "You have completed your Jobberman Soft Skill Training.",
          }).then(() => {
            if (this.checkTests.baseline) {
              this.attempt();
            }
            this.$router.push({ name: "LessonPage" });
            return;
          })
        );
      } catch (error) {
        swal({
          title: "Oops!",
          text:
            error?.response?.data?.payload ??
            "Unable to submit your test at the moment. Please try again shortly.",
        }).then(() => {
          if (error.response.status == 403) {
            this.$router.push({
              name: !this.checkTests.baseline
                ? "LearningJobbermanLessons"
                : "WelcomeDashboard",
            });
          }
        });
        loader.hide();
      }
    },
  },
  mounted() {
    if (
      (this.checkTests.baseline == true && this.checkTests.endline == true) ||
      this.user.canTakeJobberman != 2
    ) {
      this.$router.push({ name: "WelcomeDashboard" });
      return;
    }
    if (this.checkTests.baseline == true && !this.completed) {
      this.$router.push({ name: "LearningJobbermanLessons" });
      return;
    }
    this.getJobbermanQuestions();
  },
};
</script>

<style>
label {
  @apply cursor-pointer;
}
</style>
