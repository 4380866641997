<template>
  <section class="container grid lg:grid-cols-3 gap-8 py-16 lg:py-24">
    <div class="lg:col-span-1 text-center lg:text-left lg:mt-12">
      <h2
        class="font-header-1 font-extrabold text-3xl md:text-5xl text-[#2F2F2F]"
      >
        What you stand to <span class="text-primary-green">gain</span>
      </h2>
      <p class="my-8 text-sm font-normal text-[#474747] font-body">
        Since 2020, Terra Learning has trained over 350 thousand African youths in
        various fields across Engineering, Marketing, Product and Design.
      </p>
      <router-link :to="{ name: 'RegisterEmail' }" class="hidden md:block">
        <PrimaryButton class="w-full md:w-fit">
          Sign up for FREEE
        </PrimaryButton>
      </router-link>
    </div>
    <div class="lg:col-span-2 grid md:grid-cols-2 gap-x-8">
      <div class="benefits_card">
        <div
          class="bg-[#F4F5F7] rounded-2xl flex items-center justify-center p-4"
        >
          <span
            class="iconify"
            data-icon="ant-design:safety-certificate-outlined"
          ></span>
        </div>
        <h4
          class="mt-6 mb-1 font-header-1 text-body-text text-xl font-bold md:text-lg"
        >
          Earn a certificate
        </h4>
        <p class="text-[#474747] text-sm font-body font-normal">
          All Terra Learning finalists will be awarded a paid
          certificate of completion at the end of the Internship.
        </p>
      </div>
      <div class="benefits_card">
        <div
          class="bg-[#F4F5F7] rounded-2xl flex items-center justify-center p-4"
        >
          <span
            class="iconify"
            data-icon="fluent:brain-circuit-20-regular"
          ></span>
        </div>
        <h4
          class="mt-6 mb-1 font-header-1 text-body-text text-xl font-bold md:text-lg"
        >
          Get hands-on experience
        </h4>
        <p class="text-[#474747] text-sm font-body font-normal">
          During the Terra Learning Internship, you will be assigned projects and
          team assignments that will give you the needed experience, and help
          you build your portfolio, teamwork, and communication skills.
        </p>
      </div>
      <div class="benefits_card">
        <div
          class="bg-[#F4F5F7] rounded-2xl flex items-center justify-center p-4"
        >
          <span class="iconify" data-icon="codicon:book"></span>
        </div>
        <h4
          class="mt-6 mb-1 font-header-1 text-body-text text-xl font-bold md:text-lg"
        >
          Learn from the very best
        </h4>
        <p class="text-[#474747] text-sm font-body font-normal">
          Learning is a wonderful experience, and learning from the best makes
          everything ten times easier. The Terra Learning mentors and
          lead instructors are all here to make your Internship experience
          unforgettable.
        </p>
      </div>
      <div class="benefits_card">
        <div
          class="bg-[#F4F5F7] rounded-2xl flex items-center justify-center p-4"
        >
          <span
            class="iconify"
            data-icon="fluent:fast-acceleration-20-regular"
          ></span>
        </div>
        <h4 class="mt-6 mb-1 font-header-1 text-body-text text-xl font-bold">
          Be a part of a fast-growing startup
        </h4>
        <p class="text-[#474747] text-sm font-body font-normal">
          During the bootcamp, all Terra Learning finalists will have
          the opportunity to build a fast-paced start-up.
        </p>
      </div>
    </div>
    <router-link :to="{ name: 'RegisterEmail' }" class="md:hidden block w-full">
      <PrimaryButton class="w-full"> Sign up for FREE </PrimaryButton>
    </router-link>
  </section>
</template>

<script>
import PrimaryButton from "./../inc/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton,
  },
};
</script>

<style lang="scss" scoped>
.benefits_card {
  background-image: url(./../../assets/img/bg/card-bg-lines.svg);
  @apply p-8 rounded-3xl flex flex-col justify-start items-center text-center lg:text-left lg:items-start bg-right-top bg-no-repeat bg-contain;
}
</style>
