<template>
  <div
    @click="handleCloseModal"
    class="bg-[#000000B5] w-full fixed text-white top-0 bottom-0 right-0 left-0 flex flex-col justify-center items-center"
  >
    <div class="h-[70vh] z-[1555] relative">
      <button
        @click="$emit('closemodal')"
        class="font-header-1 text-white flex items-center absolute -top-8 mb-12 right-0"
      >
        <span class="iconify mr-1" data-icon="eva:close-circle-fill"></span>
        Close
      </button>
      <video
        src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/side_hustle_testimonials%20%281080p%29.mp4"
        autoplay
        controls
        muted
        type="video/mp4"
        class="min-h-full h-full max-h-full rounded-md w-[500px]"
        ref="modal_box"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleCloseModal() {
      const box = this.$refs.modal_box;
      if (box && !box.contains(event.target)) {
        this.$emit("closemodal");
      }
    },
  },
};
</script>

<style></style>
