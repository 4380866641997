<template>
  <div
    class="fixed bg-[#63636345] custom_fade backdrop-blur-[10px] top-0 right-0 left-0 bottom-0 w-screen h-screen flex items-center justify-center z-[9999] p-4 overflow-y-scroll"
  >
    <template v-if="onboardingStep >= 1 && onboardingStep < 8">
      <div
        class="container relative gap-24 justify-center rounded-3xl overflow-y-scroll scrollbar-hide bg-white shadow-[0px_4px_50px_rgba(60,60,78,0.15)] p-6 sm:p-4 md:p-6 md:w-8/12 xl:w-1/2 min-h-[80%] h-fit md:h-4/5"
      >
        <div class="mx-auto mt-4 md:w-10/12">
          <h3 class="font-semibold text-xs">Question {{ onboardingStep }} of 7</h3>
          <div class="flex justify-center space-x-2 items-center mt-3 mb-5">
            <div
              v-for="index in (1,2,3,4,5,6,7)" :key="index"
              @click="onboardingStep = index"
              :class="onboardingStep >= index ? 'bg-primary-green' : 'bg-[#C7C5C5]'"
              class="h-1 rounded grow transition-colors duration-500 ease-linear cursor-pointer"
            ></div>
          </div>

          <button
            @click="onboardingStep--"
            class="font-body-new text-primary-green mb-5 flex items-center"
            :class="onboardingStep > 1 ? 'visible' : 'invisible'"
          >
            <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span>
            <span class="font-semibold text-xs">{{onboardingStep > 1 ? 'Previous' : 'BACK' }}</span>
          </button>

          
          <div class="relative">
            <!-- <Transition name=""> -->
              <qualification-slot
                @next="selectedQualification()"
                v-if="onboardingStep == 1"
                class="absolute left-0 right-0 w-full"
              ></qualification-slot>
              <school-slot
                @next="onboardingStep = 4"
                v-else-if="onboardingStep == 2"
                class="absolute left-0 right-0 w-full"
              ></school-slot>
              <employment-slot
                @next="onboardingStep = 4"
                v-else-if="onboardingStep == 3"
                class="absolute left-0 right-0 w-full"
              ></employment-slot>
              <earning-slot
                @next="onboardingStep = 5"
                v-else-if="onboardingStep == 4"
                class="absolute left-0 right-0 w-full"
              ></earning-slot>
              <reason-slot
                @next="onboardingStep = 6"
                v-else-if="onboardingStep == 5"
                class="absolute left-0 right-0 w-full"
              ></reason-slot>
              <knowledge-slot
                @next="onboardingStep = 7"
                v-else-if="onboardingStep == 6"
                class="absolute left-0 right-0 w-full"
              ></knowledge-slot>
              <time-slot
                @next="onboardingStep = 8"
                v-else-if="onboardingStep == 7"
                class="absolute left-0 right-0 w-full"
              ></time-slot>
            <!-- </Transition> -->
          </div>
        </div>
      </div>
    </template>
    <template v-if="onboardingStep == 0 || onboardingStep == 8">
      <div
        class="container p-0 rounded-3xl overflow-clip bg-white shadow-[0px_4px_50px_rgba(60,60,78,0.15)] md:w-8/12 xl:w-1/2 custom-bounce min-h-[80%] h-fit md:h-4/5 "
      >
        <header
          class="pt-8 text-center font-header-1 font-bold text-body-text text-3xl"
        >
          <h1 v-if="onboardingStep == 0">Welcome On-board</h1>
          <img
            v-if="onboardingStep == 8"
            src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/success.gif"
            class="w-52 mx-auto"
            alt=""
          />
        </header>
        <section
          class="text-center space-y-4 flex flex-col items-center font-body-new pt-4 pb-12 px-4"
          v-if="onboardingStep == 0"
        >
          <img src="@/assets/img/icons/emiji-thumbs-up.png" alt="" />
          <p class="font-medium text-body-text md:w-4/5 lg:w-3/6 mx-auto">
            Let us know a little about you and why you want to learn this course
          </p>
          <button
            @click="onboardingStep = 1"
            class="px-8 py-3 bg-primary-green rounded-md font-bold text-white"
          >
            Proceed
          </button>
        </section>
        <section
          class="text-center space-y-4 flex flex-col items-center font-body-new pt-4 pb-12 px-4"
          v-if="onboardingStep == 8"
        >
          <p class="font-bold text-body-text">Thank You!</p>
          <p class="font-medium text-body-text md:w-4/5 lg:w-3/6 mx-auto">
            Alright, you can now proceed to the dashboard
          </p>
          <button
            @click="closeModalFunction()"
            class="px-8 py-3 bg-primary-green rounded-md font-bold text-white"
          >
            Proceed
          </button>
        </section>
      </div>
    </template>
  </div>
</template>

<script>
import QualificationSlot from "./QualificationLevel.vue";
import SchoolSlot from "./EnterSchool.vue";
import EmploymentSlot from "./EmploymentStatus.vue";
import EarningSlot from "./MonthlyEarning.vue";
import ReasonSlot from "./WhyJoin.vue";
import KnowledgeSlot from "./CourseKnowledge.vue";
import TimeSlot from "./DedicateTime.vue";
import ApplicationSuccessModal from "@/views/apply/ApplicationSuccessModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      onboardingStep: 0,
      availableStep: 0,
      // onboardingStep: 1,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      userCourse: "auth/userCourse"
    }),
  },
  watch: {
    onboardingStep() {
      this.updateAvailableStep();
    },
  },
  components: {
    QualificationSlot,
    SchoolSlot,
    EmploymentSlot,
    EarningSlot,
    ReasonSlot,
    KnowledgeSlot,
    TimeSlot,
    ApplicationSuccessModal,
  },
  methods: {
    updateAvailableStep() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      if (user?.availability) {
        return (this.availableStep = 7);
      }
      if (user?.knowledge_level) {
        return (this.availableStep = 6);
      }
      if (user?.reason_for_joining) {
        return (this.availableStep = 5);
      }
      if (user?.monthly_earning) {
        return (this.availableStep = 4);
      }
      if (user?.level_of_education == "graduate") {
        return (this.availableStep = 3);
      }
      if (user?.level_of_education == "undergraduate") {
        return (this.availableStep = 2);
      }
      if (user?.level_of_education) {
        return (this.availableStep = 1);
      }
      return (this.availableStep = 1);
    },
    closeModalFunction() {
      this.$emit("closeModal");
      if (this.user?.batch?.learning_type == 'instructor') {
        this.$router.push({ name: "StartWaitPage" });
      } else {
        this.$router.push({ name: "LearningDashboardIndex" });
      }
      return;
    },
    prevTab() {
      if (this.onboardingStep <= 1) {
        return;
      }
      let user = JSON.parse(localStorage.getItem("tempUser"));
      if (this.onboardingStep == 4) {
        if (user.level_of_education == "undergraduate") {
          return (this.onboardingStep = 2);
        }
        if (user.level_of_education == "graduate") {
          return (this.onboardingStep = 3);
        }
      }
      if (this.onboardingStep == 3) {
        if (user.level_of_education == "graduate") {
          return (this.onboardingStep = 1);
        }
      }
      return (this.onboardingStep = this.onboardingStep - 1);
    },
    nextTab() {
      if (this.onboardingStep >= 7) {
        return;
      }
      let user = JSON.parse(localStorage.getItem("tempUser"));
      if (this.onboardingStep == 1) {
        if (user.level_of_education == "undergraduate") {
          return (this.onboardingStep = 2);
        }
        if (user.level_of_education == "graduate") {
          return (this.onboardingStep = 3);
        }
      }
      if (this.onboardingStep == 2) {
        return (this.onboardingStep = 4);
      }
      return (this.onboardingStep = this.onboardingStep + 1);
    },
    selectedQualification() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      if (user.level_of_education == "undergraduate") {
        return (this.onboardingStep = 2);
      }
      if (user.level_of_education == "graduate") {
        return (this.onboardingStep = 3);
      }
      return (this.onboardingStep = 1);
    },
    handleBack(){
        if(this.onboardingStep > 1){
            this.onboardingStep --;
        }else{
            this.$router.back();
        }
    },
    setStep() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      if (!user.level_of_education) {
        this.availableStep = 1;
        return (this.onboardingStep = 1);
      }
      if (!user.monthly_earning) {
        this.availableStep = 4;
        return (this.onboardingStep = 4);
      }
      if (!user.reason_for_joining) {
        this.availableStep = 5;
        return (this.onboardingStep = 5);
      }
      if (!user.knowledge_level) {
        this.availableStep = 6;
        return (this.onboardingStep = 6);
      }
      if (!user.availability) {
        this.availableStep = 7;
        return (this.onboardingStep = 7);
      }
      this.availableStep = 7;
      return (this.onboardingStep = 7);
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      if (user.email == this.user.email) {
        return this.setStep();
      } else {
        localStorage.removeItem("tempUser");
      }
    }
    if (!localStorage.getItem("tempUser")) {
      const user = {
        email: this.user.email,
      };
      return localStorage.setItem("tempUser", JSON.stringify(user));
    }
  },
};
</script>

<style scoped>
.goodies_modal {
  background-image: url('https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/goodies-bg.png');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0%;
  background-size: contain;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
}

.slide-up-leave-to {
  opacity: 0;
}
</style>
