<template>
  <div class="lg:w-4/5 mx-auto mt-8 lg:mt-20">
    <h1 class="font-header-1 font-bold text-body-text text-3xl md:text-4xl">
      Reset password
    </h1>
    <p class="text-body-text font-normal text-base md:text-lg font-body my-4">
      Enter your email to request for a password reset
    </p>
    <form @submit.prevent="requestReset()">
      <CustomInputField
        errorMessage="Email is required"
        :validState="!v$.email.$invalid"
        v-model="email"
        label="Your email address"
        inputType="email"
        required
        class="my-8"
      />
      <button
        type="submit"
        :disabled="v$.$invalid || isLoading"
        v-html="buttonText"
      ></button>
    </form>
  </div>
</template>

<script>
import CustomInputField from "./../../components/inc/CustomInputField.vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import swal from "sweetalert";
import axios from "axios";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      email: "",
      isLoading: false,
      buttonText: "Reset Password",
    };
  },
  validations() {
    return {
      email: { required, email },
    };
  },
  components: {
    CustomInputField,
  },
  methods: {
    requestReset() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.buttonText = `<i class="fa-solid fa-spinner fa-spin fa-2x"></i>`;
        this.isLoading = true;
        const data = {
          email: this.email,
        };
        axios
          .post("password/reset", data)
          .then(() => {
            this.buttonText = "Reset Password";
            this.isLoading = false;
            swal({
              title: "Successful",
              text: "A password reset link has been sent to your e-mail",
            });
          })
          .catch((err) => {
            if (err?.response?.data) {
              swal({
                title: "Oops!",
                text: err.response.data.payload,
              }).then((click) => {
                if (err.response.status == 422) {
                  this.$router.push({
                    name: "RegisterEmail",
                  });
                  return;
                }
              });
            } else {
              swal({
                title: "Oops!",
                text: "Please try again later",
              });
            }
            this.buttonText = "Reset Password";
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button[type="submit"] {
  @apply text-base lg:text-lg font-semibold text-white bg-primary-green rounded-md  py-4 px-12 w-full;
}
button[type="submit"]:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF];
}
</style>
