<template>
  <div class="container p-0">
    <div class="p-0 my-8 container" v-if="dComment">
      <!-- Main Comment -->
      <div class="p-0 my-4 flex items-start justify-start container">
        <img
          v-if="dComment.user.avatar"
          :src="dComment.user.avatar"
          :alt="dComment.user.first_name"
          class="rounded-full h-8 w-8 md:h-10 md:w-10 mr-2"
        />
        <img
          v-else
          src="@/assets/img/img/missing_avatar.png"
          :alt="dComment.user.first_name"
          class="rounded-full h-8 w-8 md:h-10 md:w-10 mr-2"
        />
        <div
          class="dark:bg-[#1A1A1A] dark:border-[#0000001A] bg-[#F7F7F7] border-[#F7F7F7] border rounded p-2 w-[calc(100%-3rem)]"
        >
          <div class="mb-2">
            <p class="text-xs dark:text-[#F3F3F3] text-[#242424]">
              {{ dComment.user.first_name }}
              {{ dComment.user.last_name }}
            </p>
            <p class="text-[10px] text-[#A7A5A5] dark:text-[#E1E1E1]">
              {{ user?.batch?.course?.title }}
            </p>
            <p class="text-[7px] dark:text-[#F4F4F4] text-[#A7A5A5]">
              {{ timeFormat(dComment.created_at) }}
            </p>
          </div>
          <p
            class="text-[12px] dark:text-[#FFFFFF] text-[#242424] break-all container p-0"
          >
            {{ dComment.comment }}
          </p>
        </div>
      </div>
      <form
        @submit.prevent="replyComment()"
        class="w-[calc(100%-3rem)] ml-auto flex items-end justify-between rounded-md p-2 border my-4 dark:border-[#656565] dark:bg-[#262626] dark:text-[#DBDBDB] border-[#777977] text-[#777977] bg-white']"
      >
        <span
          class="bg-transparent text-sm min-h-[1rem] max-w-[95%] grow border-none focus:ring-0 focus:outline-none focus:border-none break-all"
          role="textbox"
          @input="onInput"
          contenteditable
          ref="replyInput"
        >
        </span>
        <button type="submit">
          <span class="iconify" data-icon="carbon:send-filled"></span>
        </button>
      </form>
      <!-- Main Comment -->
      <!-- Sub Comments -->
      <div
        v-if="dComment.reply && dComment.reply.length > 0"
        class="ml-auto w-[calc(100%-3rem)] container p-0 mr-0"
      >
        <template v-for="(reply, index) in dComment.reply" :key="reply.id">
          <div
            class="p-0 my-4 flex items-start justify-start container"
            v-if="index < replyToShow"
          >
            <img
              v-if="reply.user.avatar"
              :src="reply.user.avatar"
              :alt="reply.user.first_name"
              class="rounded-full h-8 w-8 md:h-10 md:w-10 mr-2"
            />
            <img
              v-else
              src="@/assets/img/img/missing_avatar.png"
              :alt="reply.user.first_name"
              class="rounded-full h-8 w-8 md:h-10 md:w-10 mr-2"
            />
            <div
              class="border rounded p-2 grow dark:bg-[#1A1A1A] dark:border-[#0000001A] bg-[#F7F7F7] border-[#F7F7F7] w-[calc(100%-3rem)]"
            >
              <div class="mb-2">
                <p class="text-xs text-[#242424] dark:text-[#F3F3F3]">
                  {{ reply.user.first_name }}
                  {{ reply.user.last_name }}
                </p>
                <p class="text-[10px] dark:text-[#E1E1E1] text-[#A7A5A5]">
                  {{ user.batch?.course?.title }}
                </p>
                <p class="text-[7px] dark:text-[#F4F4F4] text-[#A7A5A5]">
                  {{ timeFormat(reply.created_at) }}
                </p>
              </div>
              <p
                class="text-[12px] dark:text-[#FFFFFF] text-[#606060] break-all container p-0"
              >
                {{ reply.reply }}
              </p>
            </div>
          </div>
        </template>
        <!-- See more replies -->
        <button
          v-if="dComment.reply && dComment.reply.length > replyToShow"
          class="w-full border-t rounded-b text-xs py-2 text-[#747272] bg-[#FAFAFA] border-[#0000001A] dark:text-[#747272] dark:bg-[#191919] dark:border-[#0000001A]"
          @click="replyToShow += 2"
        >
          See more replies
        </button>
        <!-- See more replies -->
      </div>

      <!-- Sub Comments -->
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert";

export default {
  data() {
    return {
      reply: "",
      dComment: null,
      replyToShow: 2,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: ["comment"],
  methods: {
    onInput(e) {
      this.reply = e.target.innerText;
    },
    timeFormat(date) {
      return moment(date).format("M/D/YYYY h:mma");
    },
    async replyComment() {
      if (this.reply == "") {
        swal({
          title: "Oops!",
          text: "Reply cannot be empty",
        });
        return;
      }

      try {
        let loader = this.$loading.show();
        const data = {
          reply: this.reply,
          comment_id: this.comment.id,
        };
        axios
          .post("auth/forum/comment/reply", data)
          .then((res) => {
            loader.hide();
            this.dComment = res.data.payload;
            this.reply = "";
            this.$refs.replyInput.innerText = "";
          })
          .catch(() => {
            loader.hide();
          });
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    },
  },
  mounted() {
    this.dComment = this.comment;
  },
};
</script>

<style></style>
