<template>
  <button>
    <slot> Button </slot>
  </button>
</template>

<script>
export default {};
</script>

<style scoped>
button {
  @apply font-body font-medium lg:font-normal bg-white border border-[#9ACD98] rounded-lg px-5 py-3 text-primary-green hover:bg-primary-green hover:font-bold hover:text-white;
}
</style>
