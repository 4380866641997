<template>
  <main>
    <p class="font-body font-[800] text-[#242424]">
      What is your employment status?
    </p>
    <form @submit.prevent="submit()" class="w-full">
      <fieldset class="flex flex-col font-light my-4 space-y-3">
        <div class="">
          <label for="unemployed" class="">
            <input
              type="radio"
              v-model="employment_status"
              name="employment_status"
              id="unemployed"
              value="unemployed"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Unemployed
          </label>
        </div>
        <div class="">
          <label for="selfemployed" class="">
            <input
              type="radio"
              v-model="employment_status"
              name="employment_status"
              id="selfemployed"
              value="self employed"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Self employed
          </label>
        </div>
        <div class="">
          <label for="employed" class="">
            <input
              type="radio"
              v-model="employment_status"
              name="employment_status"
              id="employed"
              value="employed"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Employed
          </label>
        </div>
      </fieldset>
      <button type="submit" :disabled="!employment_status">Next</button>
    </form>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      employment_status: null,
    };
  },
  computed: {},
  methods: {
    submit() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      user.employment_status = this.employment_status;
      localStorage.setItem("tempUser", JSON.stringify(user));
      this.$emit("next");
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      this.employment_status = JSON.parse(
        localStorage.getItem("tempUser")
      ).employment_status;
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  @apply flex flex-col ;
  fieldset {
    .radio-check-group {
      @apply col-span-1 grid grid-cols-1;
      label {
        @apply font-body-new font-semibold text-sm text-[#3D403D] border border-[#D2D2D2] rounded-lg px-6 py-4 w-full cursor-pointer;
      }
    }
  }
  button[type="submit"] {
    @apply py-3 px-8 text-white bg-primary-green font-body-new rounded-md ml-auto mt-6 lg:mt-8;
  }
}
</style>
