<template>

  <main v-if="winnerList.length < 1">
    <div class="container">
      <div class="flex font-semibold text-sm text-primary-green mt-12 cursor-pointer items-center">
        <span class="iconify text-primary-green text-lg" data-icon="eva:arrow-ios-back-fill"></span>
        <p @click="$router.push({name: 'LearningDashboardIndex'})" class=" cursor-pointer">BACK</p>
      </div>
      
      <div class="flex place-content-center">
        
        <div class="block mt-28  dark:hidden">
          <img class="h-32 mx-auto" src="@/assets/img/light-game.png" alt="Game icon">
          <p class="text-xs mt-3 text-[#0C180C]">Games not available at the moment</p>
        </div>
        <div class="hidden mt-28 items-center dark:block">
          <img class="h-32 mx-auto" src="@/assets/img/dark-game.png" alt="Game icon">
          <p class="text-[#E9E9E9] mt-3 text-xs">Games not available at the moment</p>
        </div>
      </div>
      
    </div>
  </main>
  <div v-if="winnerList.length > 1" class="px-0">
    <div class="flex dark:text-white items-center justify-center w-full h-[100vh] max-h-full px-3">
      <div class="flex flex-col font-body lg:w-3/6 w-full h-[60%]">
        <table class="table-auto">
          <thead>
            <tr class="font-medium text-sm text-left">
              <th class="px-6">Rank</th>
              <th class="px-6">Intern Name</th>
              <th class="px-6">Point</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(winner, index) in winnerList" :key="winner.id">
              <tr
                v-if="index < 20 || winner?.user_id == user?.id"
                class="text-left px-4 text-sm rounded"
                :class="
                  winner?.user_id == user?.id
                    ? 'bg-[#F1F8F1] border border-[#93C992] font-semibold'
                    : ''
                "
              >
                <td>{{ index + 1 }}.</td>
                <td>
                  {{
                    `${winner?.user?.first_name} ${winner?.user?.last_name[0]}.`
                  }}
                </td>
                <td>{{ winner?.sum }}</td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="flex flex-col items-center justify-between mt-4 uppercase">
          <button
            @click="handleClick"
            class="bg-[#52A350] px-8 py-2 text-white rounded-md"
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearningNavBar from "@/components/lms/LearningNavBar.vue";
import LearningNavBarMobile from "@/components/lms/LearningNavBarMobile.vue";
import { useRouter } from "vue-router";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";
// import BackButton from "@/components/lms/DashboardBackComponent.vue";
const router = useRouter();
export default {
  components: {
    LearningNavBar,
    LearningNavBarMobile
    // BackButton,
  },

  data() {
    return {
      winnerList: [],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
    }),
  },

  methods: {
    handleClick() {
      return this.$router.push({ name: "LearningDashboardIndex" });
    },
    async fetchLeaderBoard() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/games/points");
        this.winnerList = data.payload;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
  },
  mounted() {
    this.fetchLeaderBoard();
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

#cont {
  background: linear-gradient(107.93deg, #ecf2fe 0%, #f1f8f1 104.14%);
}

button:disabled {
  background-color: #c3c8c3;
}

#a.active,
#b.active,
#c.active,
#d.active {
  border: 2px solid blue;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

tbody tr {
  margin-bottom: 40px;
  /* background-color: red; */
  /* border: 3px solid #ededed !important; */
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

tr > td {
  border: none;
  margin-bottom: 20px;
  padding: 15px 24px;
  margin-bottom: 40px;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: 150ms ease;
}

.bounce-enter-from,
.bounce-leave-to {
  transform: scale(0);
}
</style>
