<template>
  <WhatsAppComponent />
  <div class="min-h-screen h-full">
    <main class="container font-body pt-3 md:pt-24 lg:pt-12 text-[#3F3E3E]">
      <div
        @click="handleRedirect()"
        class="w-full mb-8 text-sm flex items-center justify-start"
      >
        <span class="iconify inline" data-icon="eva:arrow-ios-back-fill"></span>
        <p
          class="text-left font-bold text-[#52A350] uppercase cursor-pointer"
        >
          back
        </p>
      </div>
      <div class="w-full flex flex-col md:gap-x-24 md:flex-row">
        <div class="md:max-w-[50%]">
          <h2 class="text-3xl text-[#0C0C0C] mb-5 font-[500] text-left">
           {{ "Make Payment" }}
          </h2>
          <div class="flex items-center" v-if="false">
            <p
              v-if="paymentData?.initial_price"
              class="text-[#0C0C0C] mb-10 text-2xl font-semibold relative w-fit px-2 mr-4"
            >
              {{
                ` ${
                  paymentData?.currency == "ngn" ? "&#8358;" : "&dollar;"
                } ${integerFormatter(paymentData?.initial_price)}` ?? " "
              }}
              <span v-if="paymentData?.id == 1" class="text-base tracking-wider">(Mandatory Certificate)</span>
              <span v-if="paymentData?.id == 2" class="text-base tracking-wider">(6 weeks)</span>
            </p>
          </div>
          <template v-if="paymentData?.description">
            <p class="text-sm mb-5">
              You’d get
            </p>
            <p class="flex items-center text-sm mb-5" v-for="(item, index) in JSON.parse(paymentData?.description)" :key="index">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              {{item}}
            </p>
          </template>
        </div>
        <div
          class="lg:w-2/5 md:w-1/2 shadow-[0px_16px_50px_rgba(37,77,36,0.05)] mb-12 pb-16 lg:rounded-lg border pt-12 font-normal bg-[#FFFFFF]/[0.92] px-8 lg:m-0 font-body-new"
        >
        <p class="text-sm text-primary-green font-medium mb-4 -mt-4" v-if="paymentData && paymentData.id <= 2">
          You are about to make payment for a {{ user?.batch?.course?.title }} course
        </p>
          <form class="" @submit.prevent="false">
            <template v-if="paymentData?.installments > 1 && !$route.query.type">
              <h2 class="text-[#404040] font-medium mb-5 text-lg text-center">
                Select Payment Plan
              </h2>
              <fieldset class="grid mt-3 grid-cols-2 mb-7 gap-6 w-full">
                <div class="radio-check-group">
                  <label for="full_payment" :class="payment_plan == 'full_payment' ? 'border-primary-green' : 'bg-[#F7F7F7] border-[#D2D2D2]'">
                    <input
                      type="radio"
                      name="payment_plan"
                      id="full_payment"
                      value="full_payment"
                      v-model="payment_plan"
                      class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                    />
                    Full Payment
                  </label>
                </div>
                <div class="radio-check-group" v-if="(paymentData?.installments - paymentData?.installments_made) > 1" >
                  <label for="installment" :class="payment_plan == 'installment' ? 'border-primary-green' : 'bg-[#F7F7F7] border-[#D2D2D2]'">
                    <input
                      type="radio"
                      name="payment_plan"
                      id="installment"
                      value="installment"
                      v-model="payment_plan"
                      class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                    />
                    Installments
                  </label>
                </div>
              </fieldset>
            </template>
            <template v-if="paymentData?.installments > 1 && payment_plan == 'installment' && !$route.query.type">
              <fieldset class="grid mt-3 grid-cols-2 mb-7 gap-6 w-full border-y py-6">
                <template v-for="_amount in installments" :key="_amount">
                  <div class="radio-check-group" v-if="_amount < paymentData?.amount">
                    <label :class="amount == _amount ? 'border-primary-green' : 'bg-[#F7F7F7] border-[#D2D2D2]'">
                      <input
                        type="radio"
                        :name="`installment_${_amount}`"
                        :id="`installment_${_amount}`"
                        :value="_amount"
                        v-model="amount"
                        class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3 "
                      />
                      {{ _amount }}
                    </label>
                  </div>
                </template>
              </fieldset>
            </template>
            <template v-if="payment_plan != '' && amount">
              <h2 class="text-[#404040] font-medium mb-5 text-left">
                Select Payment Method
              </h2>
              <fieldset class="grid mt-3 grid-cols-2 mb-7 gap-6 w-full">
                <div class="radio-check-group" v-if="(settings?.flutter_card_payment_active == 1 || settings?.monnify_card_active == 1) && payment_details.flutterwave">
                  <label for="Flutterwave" :class="paymentMethod == 'Flutterwave' ? 'border-primary-green' : 'bg-[#F7F7F7] border-[#D2D2D2]'">
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="Flutterwave"
                      value="Flutterwave"
                      v-model="paymentMethod"
                      class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                    />
                    Card
                  </label>
                </div>
                <div class="radio-check-group" v-if="settings?.selar_payment_active && payment_details?.selar">
                  <label for="bank" :class="paymentMethod == 'selar' ? 'border-primary-green' : 'bg-[#F7F7F7] border-[#D2D2D2]'">
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="selar"
                      value="selar"
                      v-model="paymentMethod"
                      class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                    />
                    Selar
                  </label>
                </div>
                <div class="radio-check-group" v-if="settings?.paystack_active && payment_details?.bank_transfer">
                  <label for="bank" :class="paymentMethod == 'bank' ? 'border-primary-green' : 'bg-[#F7F7F7] border-[#D2D2D2]'">
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="bank"
                      value="bank"
                      v-model="paymentMethod"
                      class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                    />
                    Bank Transfer
                  </label>
                </div>
                
              </fieldset>
            </template>
            <div v-if="payment_details">
              <div class="flex space-x-4 items-stretch my-4">
                <input 
                  type="text" 
                  name="coupon" 
                  id="coupon" 
                  v-model="coupon" 
                  placeholder="Scholarship code (Optional)" 
                  class="border border-primary-green rounded-md grow text-sm text-primary-green placeholder:text-primary-green placeholder:font-semibold font-semibold focus:border-primary-green" 
                />
                <button @click="verifyCouponCode()" class="rounded-md px-4 bg-[#ECF2FE] border border-primary-green text-sm text-primary-green font-semibold w-fit">
                  Apply
                </button>
              </div>
              <div v-if="couponDetails?.description" class="text-xs font-semibold pb-4 text-primary-green -mt-3">
                {{ couponDetails?.description }}
              </div>
              <div v-if="couponDetails?.error" class="text-xs font-semibold pb-4 text-red-500 -mt-3">
                {{ couponDetails.error }}
              </div>
            </div>
            <!--  -->
            <PaymentBreakdown
              :card="payment_details?.flutterwave"
              :selar="payment_details?.selar"
              :bank="payment_details?.bank_transfer"
              :type="paymentMethod"
              :currency="paymentData?.currency"
            />
            <FlutterwaveCheckout
              :data="payment_details?.flutterwave"
              :currency="paymentData?.currency"
              v-if="paymentMethod == 'Flutterwave' && payment_details?.flutterwave"
            />
            <FlutterwaveCheckout
              :data="payment_details?.selar"
              :currency="paymentData?.currency"
              v-if="paymentMethod == 'selar' && payment_details?.selar"
            />
            <BankCheckout
              :data="payment_details?.bank_transfer"
              v-if="paymentMethod == 'bank' && payment_details?.bank_transfer "
            />
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { integerFormatter } from "@/helpers/customFormatter.js";

</script>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import FlutterwaveCheckout from "@/components/payment/FlutterwaveCheckout.vue";
import PaymentBreakdown from "@/components/payment/PaymentBreakdownComponent.vue";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import WhatsAppComponent from "@/components/inc/WhatsAppComponent.vue";
import BankCheckout from "@/components/payment/VirtualCheckout.vue";
import MonnifyCheckout from "@/components/payment/MonnifyCheckout.vue";
import installmentHelper from "@/helpers/installmentHelper.js";
import swal from 'sweetalert';

import { createToast } from 'mosha-vue-toastify';

export default {
  data() {
    return {
      paymentMethod: null,
      paymentData: null,
      amount: null,
      payment_plan: null,
      payment_details: null,
      coupon: "",
      couponDetails: null,
      loading_page: false
    };
  },
  watch: {
    coupon(){
      this.coupon = this.coupon.trim().toUpperCase();
    },
    amount() {
      this.fetchPaymentDetails();
    },
    payment_plan(){
      if (this.payment_plan == 'full_payment') {
        return this.amount = this.paymentData?.amount;
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      baseline: "auth/baseline",
      settings: "auth/settings",
    }),
    installments() {
      return installmentHelper(this.paymentData?.initial_price, this.paymentData?.installments, this.paymentData?.id)
    }
  },
  components: { FlutterwaveCheckout, BankCheckout, BackButton, MonnifyCheckout, WhatsAppComponent, PaymentBreakdown },
  methods: {
    applyCouponDiscount(){
      let discount = this.couponDetails.discount;
      let type = this.couponDetails.type
      let amount = this.amount;
      if (type == "percent") {
        amount = ( discount * amount ) / 100;
        this.amount = this.amount - amount;
      } else if (type = "flat") {
        amount = amount - discount;
        this.amount = amount;
      }
      return 
    },
    async verifyCouponCode(){
      if (this.coupon.trim().length < 3) {
        return createToast("Enter a valid code", { type: "info" });
      }
      const payload = {
          coupon: this.coupon,
          payment_plan: this.payment_plan,
          price_id: this.paymentData?.id,
          course_id: this.user.batch?.course?.id ?? false
      };
      let loader = this.$loading.show();
      try {
        const { data } = await axios.post("/v2/auth/coupons/verify", payload)
        createToast(`${ data.data.description ?? "Coupon code verified" }`, { type: "success" });
        this.couponDetails = data.data;
        loader.hide()
        return this.fetchPaymentDetails(this.couponDetails.id);
      } catch (error) {
        loader.hide()
        const payload = {
          error: error?.response?.data?.message ?? "Unable to apply coupon at the moment. Please try again shortly"
        }
        this.couponDetails = payload;
        createToast(`${ error?.response?.data?.message ?? "Unable to apply coupon at the moment. Please try again shortly" }`, { type: "danger" });
        this.fetchPaymentDetails();
      }
    },
    handleRedirect() {
      return this.$router.back();
    },
    handleDepositPrice(){
      if (this.$route.query.type) {
        const slug = this.$route.params.slug
        const payment_type = this.$route.query.type
        let full_price = this.paymentData.initial_price
        if (payment_type == "full") {
          this.amount = full_price;
          this.payment_plan = "full_payment";
        } else if (payment_type == "part") {
          this.payment_plan = "installment"
          if (slug == "self_paced") {
            this.amount = (20 * Number(full_price)) / 100;
          } else if (slug == "hands-on") {
            const installments = this.paymentData.installments;
            this.amount = Math.ceil( Number(full_price) / installments );
          }
        }
      } else {
        this.payment_plan = "full_payment";
      }
      return this.fetchPaymentDetails();
    },
    /**
     * Fetch the amount for the payment package
     */
    async fetchItemDetails() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get(`v2/auth/payment/${this.$route?.params?.slug}`,);
        this.paymentData = data.data;
        this.paymentMethod = "Flutterwave";
        this.amount = data?.data?.amount;
        if (this.amount <= 0 ) {
          loader.hide();
          swal({
            title: "Hooray!",
            text: "You have already completed your payment for this learning path.",
          });  
          return this.$router.push({name : "SelectBatchPage"})
        }
        this.handleDepositPrice();
        if (this.paymentData?.coupon_code && this.payment_plan == "full_payment")
        {
          this.coupon = this.paymentData.coupon_code
          this.verifyCouponCode();
        }
        loader.hide();
      } catch (error) {
        console.error(error);
        loader.hide();
        this.handleRedirect()
      }
    },
    /**
     * Fetch the Payment link and bank account details
     */
    async fetchPaymentDetails(coupon = null) {
      let loader = this.$loading.show();
      const payload = {
        price_id: this.paymentData?.id,
        amount: this.amount,
        coupon_id: coupon ?? null
      };
      try {
        const response = await axios.post(`v2/auth/payment`, payload);
        if (response.status == 203) {
          // if user has a pending bank transfer payment, redirect to to payment confirmation page
          loader.hide();
          return this.$router.push({name : "PaymentConfirmationCountDown"})
        }
        this.payment_details = response?.data?.data;
        loader.hide();
      } catch (error) {
        loader.hide();
      }
    },
  },
  mounted() {
    this.fetchItemDetails();
  },
}
</script>

<style lang="scss" scoped>
form {
  fieldset {
    .radio-check-group {
      label {
        @apply font-body font-medium text-[11px] lg:text-xs text-[#3D403D] border rounded-[4px] flex items-center px-4 py-5 lg:py-3 w-full text-left cursor-pointer h-full;
      }
    }
  }
}
</style>
