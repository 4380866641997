<template>
  <div class="p-0">
    <form
      class="grid grid-flow-row gap-8 md:gap-12"
      @submit.prevent="updateProfile"
    >
      <div class="grid gap-8 md:grid-flow-col">
        <div class="relative w-full">
          <input
            class="peer"
            type="text"
            name="first_name"
            v-model="payload.first_name"
            required
            placeholder=""
          />
          <label class="" for="first_name">First Name</label>
        </div>
        <div class="relative w-full">
          <input
            class="peer"
            type="text"
            name="last_name"
            v-model="payload.last_name"
            required
            placeholder=""
          />
          <label class="" for="last_name">Last Name</label>
        </div>
      </div>
      <!-- Phone Number -->
      <div class="relative w-full">
        <input
          class="peer"
          type="tel"
          name="phone_number"
          v-model="payload.phone_number"
          required
          placeholder=""
          @keyup="checkPhone(payload.phone_number)"
        />
        <label class="" for="phone_number">Phone Number</label>
      </div>
      <!-- Phone Number -->
      <h3 class="font-body text-lg dark:text-[#BFBFBF] text-[#3F3F3F]">
        Change Password
      </h3>
      <!-- New Password -->
      <div class="relative w-full">
        <input
          class="peer"
          :type="[showOldPassword ? 'text' : 'password']"
          name="old_password"
          v-model="payload.old_password"
          placeholder=""
        />
        <label class="" for="old_password">Enter Old Password</label>
        <small
          @click="showOldPassword = !showOldPassword"
          class="-translate-y-1/2 absolute top-1/2 right-4 text-xs font-medium cursor-pointer"
          >{{ !showOldPassword ? "SHOW" : "HIDE" }}</small
        >
      </div>
      <!-- New Password -->
      <!-- Renter New Password -->
      <div class="relative w-full">
        <input
          autocomplete="new-password"
          class="peer"
          :type="[showNewPassword ? 'text' : 'password']"
          name="password"
          ref="newPassword"
          v-model="payload.password"
          placeholder=""
        />
        <label class="" for="password">Enter New Password</label>
        <small
          @click="showNewPassword = !showNewPassword"
          class="-translate-y-1/2 absolute top-1/2 right-4 text-xs font-medium cursor-pointer"
          >{{ !showNewPassword ? "SHOW" : "HIDE" }}</small
        >
      </div>
      <!-- Renter New Password -->
      <button
        type="submit"
        class="w-full py-4 px-4 rounded font-body bg-primary-green text-white"
      >
        Save Changes
      </button>
    </form>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, ref, reactive } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
import swal from "sweetalert";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import phoneValidator from "@/helpers/phoneValidator";
const axios = inject("axios");
const $loading = inject("$loading");
const store = useStore();

const showNewPassword = ref(false);
const showOldPassword = ref(false);

const payload = reactive({
  first_name: "",
  last_name: "",
  phone_number: "",
  password: "",
  old_password: "",
});

const rules = {
  firstName: { required },
  lastName: { required },
  phone_number: { required },
  password: { required },
  old_password: { required },
};
const v$ = useVuelidate(rules, payload);

const user = computed(() => {
  return store.state.auth.user;
});
const darkTheme = computed(() => {
  return store.state.auth.darkTheme;
});

/**
 * Validate phone
 */
const checkPhone = (phone_number) =>
  phoneValidator(phone_number, user.value.country.iso3);

/**
 * Update user profile
 */
const updateProfile = async () => {
  if (v$.$error) {
    return alert("no");
  }
  const loader = $loading.show();
  try {
    // console.log(payload.old_password.length);
    if (payload.old_password.length > 0) {
      if (payload.old_password.length < 6) {
        swal({
          title: "Oops!",
          text: "Old password cannot be less than 6 characters",
        });
        loader.hide();
        return;
      }
      if (payload.password.length == 0) {
        swal({
          title: "Oops!",
          text: "Enter your new password",
        });
        loader.hide();
        return;
      }
      if (payload.password.length < 6) {
        swal({
          title: "Oops!",
          text: "New password cannot be less than 6 characters",
        });
        loader.hide();
        return;
      }
    }
    const uploadReq = axios.put("auth/update/profile", payload);
    Promise.all([uploadReq])
      .then((result) => {
        loader.hide();
        store.dispatch("auth/attempt");
        swal({
          text: result[0].data.payload,
        });
        payload.password = "";
        payload.old_password = "";
      })
      .catch((error) => {
        loader.hide();
        swal({
          title: "Oops!",
          text:
            error?.response?.data?.payload ??
            "Unable to update your profile at the moment. Please try again shortly.",
        });
      });
  } catch (error) {
    loader.hide();
    swal({
      title: "Oops!",
      text:
        error?.response?.data?.payload ??
        "Unable to update your profile at the moment. Please try again shortly.",
    });
  }
};

onMounted(() => {
  payload.first_name = user.value.first_name || "";
  payload.last_name = user.value.last_name || "";
  payload.phone_number = user.value.phone_number || "";
});
</script>

<style scoped>
label {
  @apply absolute left-4 text-[10px] top-1 peer-focus:block peer-focus:bg-[transparent] peer-focus:font-medium peer-focus:top-1 peer-focus:text-primary-green bg-white block dark:text-[#BFBFBF] dark:bg-transparent text-[#414141] font-normal;
}
input {
  @apply w-full focus:outline-none focus:border-primary-green focus:rounded-md px-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] dark:bg-[#121212] dark:text-[#BFBFBF] dark:border-[#393939] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585] focus:ring-0 pt-6 pb-2;
}
button:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF] text-[#707070];
}
</style>
