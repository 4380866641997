<template>
  <div class="p-0 md:-ml-16">
    <SideNav />
    <div class="relative">
      <SideNavMobile />
    </div>
    <div>
      <div
        class="md:grid pt-0 md:grid-cols-[25%_auto] px-0 md:px-4 w-full h-screen container"
      >
        <div class="md:col-start-2 py-12 md:py-4">
          <div class="container p-0 md:pl-16 md:w-4/5">
            <h2
              class="text-[#0C0C0C] dark:text-[#E5E5E5] font-header-1 font-extrabold text-2xl md:text-3xl px-4 md:px-0 mb-8"
            >
              {{ currentLesson && currentLesson.title }}
            </h2>
            <VideoPlayer
                :videoSrc="currentLesson?.url"
                @ended="
                saveLesson(currentLesson.id), nextLesson(currentLesson.id)
              "
                @play="saveLesson(currentLesson.id)"
                v-if="siteSettings.video_mode == 'DO'"
                />
            <div class="flex items-start justify-between font-body text-sm">
              <button
                @click="previousLesson"
                v-if="showPrevButton"
                class="py-4 px-4 md:px-8 bg-[#e5e5e5] text-[#777977] mr-auto dark:bg-transparent dark:border-2 dark:border-t-0 dark:border-[#1F3E1E] dark:text-[#e1e1e1] font-semibold"
              >
                Previous Lesson
              </button>
              <button
                @click="nextLesson"
                v-if="showNextButton"
                class="py-4 px-4 md:px-8 bg-[#e5e5e5] text-[#777977] ml-auto dark:bg-transparent dark:border-2 dark:border-t-0 dark:border-[#1F3E1E] dark:text-[#e1e1e1] font-semibold"
              >
                Next Lesson
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import VideoPlayer from "@/components/lms/VideoPlayer.vue";
import SideNav from "./LessonSideNav.vue";
import SideNavMobile from "./LessonSideNavMobile.vue";
import { useStore } from "vuex";
import { onMounted, watch, watchEffect } from "vue";
import { computed, ref } from "@vue/reactivity";
import { inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
import axios from "axios";
const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();
let player = ref(null);

const user = computed(() => {
  return store.state.auth.user;
});

const siteSettings = computed(() => {
  return store.state.auth.settings;
});
const lessons = computed(() => {
  return store.state.jobberman.lessons;
});

const checkTests = computed(() => {
  return store.state.jobberman.checkTests;
});

const currentLesson = computed(() => {
  return store.state.jobberman.currentLesson;
});


const setCurrentLesson = (id) => store.dispatch("jobberman/setLesson", id);

const previousLesson = () => {
  const currId = currentLesson.value.id;
  const index = lessons.value.findIndex((object) => {
    return object.id == currId;
  });
  if (index > 0) {
    const lesson = lessons.value[index - 1];
    setCurrentLesson(lesson.id);
  } else {
    swal({
      title: "Oops!",
      text: "This is the first lesson",
    });
    return;
  }
};

const nextLesson = () => {
  const currId = currentLesson.value.id;
  const index = lessons.value.findIndex((object) => {
    return object.id == currId;
  });
  if (index < lessons.value.length - 1) {
    const lesson = lessons.value[index + 1];
    setCurrentLesson(lesson.id);
  } else {
    swal({
      title: "Oops!",
      text: "This is the last lesson",
    });
    return;
  }
};

const fetchJobbermanLessons = async () => {
  const loader = $loading.show();
  store
    .dispatch("jobberman/fetchJobbermanLessons")
    .then(() => {
      store.dispatch("jobberman/checkTests");
      store.dispatch("jobberman/savedLessons");
      loader.hide();
    })
    .catch((err) => {
      loader.hide();
      console.log(err);
    });
};
const saveLesson = async (id) => {
  // console.log(id);
  axios
    .post("auth/jobberman/lessons/progress", { lesson_id: id })
    .then((res) => {
      // console.log(res);
      store.dispatch("jobberman/savedLessons", res.data.payload);
    })
    .catch((err) => {
      loader.hide();
      console.log(err);
    });
};

/**
 * Show prev botton
 */
const showPrevButton = computed(() => {
  let currentIndex = lessons.value.findIndex(
    (item) => item.id == currentLesson.value.id
  );
  return currentIndex == 0 ? false : true;
});

/**
 * Show next botton
 */
const showNextButton = computed(() => {
  let currentIndex = lessons.value.findIndex(
    (item) => item.id == currentLesson.value.id
  );
  return currentIndex == lessons.value.length - 1 ? false : true;
});

onMounted(() => {
  if (user.value.canTakeJobberman == 3) {
    router.push({ name: "WelcomeDashboard" });
    return;
  }
  if (user.value.canTakeJobberman == 1) {
    router.push({ name: "LearningJobbermanEnroll" });
    return;
  }
  fetchJobbermanLessons();
});
</script>

<style scoped>
* {
  @apply transition ease-linear;
}
.iconify {
  @apply mr-2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
nav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
