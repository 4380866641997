<template>
  <main class="container">
    <div class="lg:mx-16 relative">
      <section class="lg:col-span-2 px-4 md:px-12 py-10 lg:py-20 text-center">
        <h2
          class="font-header-1 font-extrabold text-3xl md:text-5xl mb-10 text-body-text lg:mb-10"
        >
          Ready to join the Internship?
        </h2>

        <router-link :to="{ name: 'RegisterEmail' }">
          <PrimaryButton class="w-full md:w-fit">
            Sign up for FREE

          </PrimaryButton>
        </router-link>
      </section>
    </div>
  </main>
</template>

<script>
import PrimaryButton from "./../inc/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton,
  },
};
</script>

<style lang="scss" scoped></style>
