import { createStore } from "vuex";

import auth from "./auth";
import jobberman from "./jobberman";
import lms from "./lms";
import teams from "./teams";
import bootcamp from "./bootcamp";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    jobberman,
    lms,
    teams,
    bootcamp
  },
});
