<template>
    <div class="font-medium text-sm flex items-center space-x-4 w-fit ml-auto font-body mt-6" v-if="liveClass && user?.batch?.learning_type == 'instructor'">
        <template  v-if="new Date() > new Date(liveClass.start_time) && new Date(liveClass.end_time) > new Date()" >
            <p>Live Class Started</p> 
            <button 
                class="whitespace-nowrap text-xs rounded-full px-4 py-2 bg-primary-green text-white shadow-lg hover:scale-105 transition ease-linear" 
                @click="joinClass(liveClass.link)">
                <i class="fa-solid fa-video fa-beat mr-2"></i> Join
            </button>
        </template>
        <template  v-else>
            <p>Upcoming Live Class</p> 
            <button class="whitespace-nowrap text-xs rounded-full px-4 py-2 bg-primary-green text-white shadow-lg">
                <i class="fa-solid fa-calendar-check fa-shake"></i>
                {{ new Date(liveClass.start_time).toLocaleString() }}
            </button>
        </template>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            liveClass: null
        }
    },
    computed: {
        ...mapGetters({
            user: "auth/user",
        }),
    },
    methods:{
        async fetchClass(){
            const { data } = await axios.get('v2/auth/class')
            this.liveClass = data.data
        },
        joinClass(url) {
          return window.open(url, "_blank");
        }
    },
    mounted(){
        this.fetchClass();
    }

};
</script>