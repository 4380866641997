<template>
    <div class="bg-[#F8FCF8] rounded-md p-2 my-4 border border-[#B5DAB4]" v-if="payment">
        <table class="table-auto w-full border-separate border-spacing-y-2 text-xs font-semibold">
            <tr>
                <td>Subtotal</td>
                <td class="text-right">
                    <span class="font-sans">
                        {{ currency == "ngn" ? "&#8358;" : "&dollar;" }}
                    </span>
                    {{ integerFormatter(payment.initial_amount) }}
                </td>
            </tr>
            <tr>
                <td>Service Charge</td>
                <td class="text-right">
                    <span class="font-sans">
                        {{ currency == "ngn" ? "&#8358;" : "&dollar;" }}
                    </span>
                    {{ integerFormatter(payment.transaction_charge_amount) ?? 0 }} 
                </td>
            </tr>
            <tr>
                <td>Discount</td>
                <td class="text-right">
                    <span class="font-sans">
                        {{ currency == "ngn" ? "&#8358;" : "&dollar;" }}
                    </span>
                    {{ integerFormatter(payment.discount_amount) ?? 0 }}
                </td>
            </tr>
            <tr>
                <td class="border-t pt-2 border-[#BBDDBB]">Total</td>
                <td class="text-right pt-2 border-t border-[#BBDDBB]">
                    <span class="font-sans">
                        {{ currency == "ngn" ? "&#8358;" : "&dollar;" }}
                    </span>
                    {{ integerFormatter(payment.total_amount) ?? 0 }}
                </td>
            </tr>
        </table>
    </div>
</template>
<script setup>
import { integerFormatter } from "@/helpers/customFormatter.js";
</script>
<script>
export default {
    props:[
        "card",
        "bank",
        "selar",
        "type",
        "currency"
    ],
    data(){
        return {
            //
        }
    },
    computed:{
        payment(){
            if (this.type == "Flutterwave") {
                return this.card;
            }
            if (this.type == "bank") {
                return this.bank;
            }
            if (this.type == "selar") {
                return this.selar;
            }
            return null;
        }
    }
}
</script>

<style>

</style>