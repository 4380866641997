<template>
  <div class="min-h-screen">
    <div
      class="min-h-screen onboard-right-panel bg-gradient-to-r from-[#ECF2FE] to-[#F1F8F1] w-full"
    >
      <div class="container px-0 pb-24 py-16 relative">
        <router-link to="/"
          ><img
            src="@/assets/img/logo/side-hustle-logo.svg"
            alt="Side Hustle Logo"
            class="fixed top-2 md:top-4 left-0 md:left-12 scale-75 md:scale-100"
        /></router-link>
        <div class="mx-auto w-11/12 md:w-4/6 lg:w-2/5 relative h-fit">
          <div
            class="bg-[#52A350] rounded-full absolute top-1 -left-4 w-[157px] h-[157px]"
          ></div>
          <div
            class="bg-[#0B52D1] bottom-4 -right-2 rounded-full absolute w-[157px] h-[157px]"
          ></div>

          <div
            class="px-5 backdrop-blur-[25px] shadow-[0px_16px_50px_rgba(37,77,36,0.08)] pb-16 rounded-[25px] border bg-[#FFFFFF]/[0.92] md:px-8 font-body-new"
          >
            <router-link v-if="backRoute" :to="{ name: backRoute }"
              ><p
                class="w-fit ml-auto cursor-pointer font-bold text-right mb-12 text-sm md:text-sm text-[#52A350] uppercase mt-8 pr-2"
              >
                back
              </p></router-link
            >
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MutedNavBar from "./../../components/inc/MutedNavBar.vue";
export default {
  components: {
    MutedNavBar,
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (this.$route.path == "/register") {
          this.backRoute = "Home";
          this.RegisterText1 = "Already have an account?";
          this.RegisterText2 = "Log In";
          this.RegisterLink = "LoginPage";
        }
        if (this.$route.path == "/onboarding/details") {
          this.backRoute = "RegisterEmail";
          this.RegisterText1 = null;
          this.RegisterText2 = null;
          this.RegisterLink = null;
        }
        if (this.$route.path == "/password/reset") {
          this.backRoute = "LoginPage";
          this.RegisterText1 = null;
          this.RegisterText2 = null;
          this.RegisterLink = null;
        }
        if (this.$route.path.includes("/password/new")) {
          this.backRoute = "LoginPage";
          this.RegisterText1 = null;
          this.RegisterText2 = null;
          this.RegisterLink = null;
        } else if (this.$route.path == "/login") {
          this.backRoute = "Home";
          this.RegisterText1 = "Don’t have an account? ";
          this.RegisterText2 = "Register";
          this.RegisterLink = "RegisterEmail";
        }
      },
    },
  },
  mounted() {
    if (this.$route?.query?.ref) {
      localStorage.setItem("referral_code", this.$route?.query?.ref);
    }
  },
  created() {
    if (this.authUser) {
      this.$router.push({ name: "SelectBatchPage" });
    }
  },
};
</script>

<style lang="scss" scoped>
.onboard-left-panel {
  background-image: url('https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/zig-zag.svg'),
    url('https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/coral-bg.svg'),
    linear-gradient(180deg, #1a1f1a 0%, #232a23 100%);
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  @apply lg:pl-16 2xl:pl-24 lg:pr-8 2xl:pr-10 lg:pt-7 2xl:pt-12 text-white hidden lg:block lg:col-span-5;
  ul {
    @apply list-disc list-inside;
    li {
      @apply my-5;
      div {
        @apply w-11/12 -mt-1 align-top inline-flex flex-col;
        h3 {
          @apply font-header-2 font-bold lg:text-xl 2xl:text-3xl lg:leading-10;
        }
        p {
          @apply font-body lg:text-sm 2xl:text-lg font-normal;
        }
      }
    }
  }
}
</style>
