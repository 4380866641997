<template>
    <img v-if="src == '1.png'" src="@/assets/img/1.png" alt="avatar"/>
    <img v-if="src == '2.png'" src="@/assets/img/2.png" alt="avatar"/>
    <img v-if="src == '3.png'" src="@/assets/img/3.png" alt="avatar"/>
    <img v-if="src == '4.png'" src="@/assets/img/4.png" alt="avatar"/>
    <img v-if="src == '5.png'" src="@/assets/img/5.png" alt="avatar"/>
    <img v-if="src == '6.png'" src="@/assets/img/6.png" alt="avatar"/>
    <img v-if="src == '7.png'" src="@/assets/img/7.png" alt="avatar"/>
    <img v-if="src == '8.png'" src="@/assets/img/8.png" alt="avatar"/>
    <img v-if="src == '9.png'" src="@/assets/img/9.png" alt="avatar"/>
    <img v-if="src == '10.png'" src="@/assets/img/10.png" alt="avatar"/>
    <img v-if="src == '11.png'" src="@/assets/img/11.png" alt="avatar"/>
    <img v-if="src == '12.png'" src="@/assets/img/12.png" alt="avatar"/>
    <img v-if="src == '13.png'" src="@/assets/img/13.png" alt="avatar"/>
    <img v-if="src == '14.png'" src="@/assets/img/14.png" alt="avatar"/>
    <img v-if="src == '15.png'" src="@/assets/img/15.png" alt="avatar"/>
    <img v-if="src == '16.png'" src="@/assets/img/16.png" alt="avatar"/>
    <img v-if="src == '17.png'" src="@/assets/img/17.png" alt="avatar"/>
    <img v-if="src == '18.png'" src="@/assets/img/18.png" alt="avatar"/>
    <img v-if="src == '19.png'" src="@/assets/img/19.png" alt="avatar"/>
    <img v-if="src == '20.png'" src="@/assets/img/20.png" alt="avatar"/>
</template>

<script>
 export default {
    name: Image,
    props : {
        src: String
    }
 }
</script>