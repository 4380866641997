<template>
  <WithdrawalModal
    v-if="showWithdrawalModal && referralData?.earnings?.balance >= 5000"
    :balance="referralData?.earnings?.balance"
    @closemodal="(showWithdrawalModal = false), fetchReferralData()"
  />
  <main class="pt-4 md:pt-12 px-4 lg:px-8 lg:w-4/5 pb-24">
    <BackButton class="mb-12 md:mb-12" />
    <p
      class="text-[#343434] dark:text-[#E5E5E5] mt-4 font-body text-sm lg:w-3/5"
    >
      Earn up to NGN 50,000 monthly by referring your friends to any of our learning program. Get your referral link and start earning!
    </p>
    <!-- Stats Cards -->
    <div
      class="grid grid-rows-4 md:grid-rows-1 md:grid-cols-4 gap-4 md:gap-8 font-body text-center my-4 lg:my-12"
    >
      <div
        class="cursor-pointer flex flex-col items-center justify-center bg-[#FAFAFA] dark:bg-[#262626] dark:text-[#E5E5E5] relative rounded-md border border-[#D2D2D2] dark:border-none py-4 px-2"
      >
        <div class="absolute top-2 right-4">
          <Tooltip>
            <template #button>
              <i class="fa-solid fa-circle-info"></i>
            </template>
            <template #message>
              Number of people who have registered an account using your referral code
            </template>
          </Tooltip>
        </div>
        <span class="text-black text-lg dark:text-[#E5E5E5] md:text-3xl mt-6">{{
          referralData.total_referrals ?? 0
        }}</span>
        <span class="text-[#505050] dark:text-[#E5E5E5]">Total Referrals</span>
      </div>
      <div
        class="cursor-pointer flex flex-col items-center justify-center bg-[#FAFAFA] dark:bg-[#262626] dark:text-[#E5E5E5] relative rounded-md border border-[#D2D2D2] dark:border-none py-4 px-2"
      >
        <div class="absolute top-2 right-4">
          <Tooltip>
            <template #button>
              <i class="fa-solid fa-circle-info"></i>
            </template>
            <template #message>
              Number of people who have completed a payment using your referral code
            </template>
          </Tooltip>
        </div>
        <span class="text-black text-lg dark:text-[#E5E5E5] md:text-3xl mt-6">{{
          referralData.confirmed_referrals ?? 0
        }}</span>
        <span class="text-[#505050] dark:text-[#E5E5E5]">Completed Referrals</span>
      </div>
      <div
        class="cursor-pointer flex flex-col items-center justify-center bg-[#FAFAFA] dark:bg-[#262626] dark:text-[#E5E5E5] relative rounded-md border border-[#D2D2D2] dark:border-none py-4 px-2"
      >
        <div class="absolute top-2 right-4">
          <Tooltip>
            <template #button>
              <i class="fa-solid fa-circle-info"></i>
            </template>
            <template #message>
              You only get paid when your referrals complete their payments
            </template>
          </Tooltip>
        </div>
        <span class="text-black dark:text-[#E5E5E5] text-lg md:text-3xl mt-6"
          >NGN {{ referralData?.earnings?.total_earnings ?? 0 }}</span
        >
        <span class="text-[#505050] dark:text-[#E5E5E5]">Total Earnings</span>
      </div>
      <div
        class="cursor-pointer flex flex-col items-center justify-center bg-[#FAFAFA] dark:bg-[#262626] dark:text-[#E5E5E5] relative rounded-md border border-[#D2D2D2] dark:border-none py-4 px-2"
      >
        <button
          @click="showWithdrawalModal = true"
          :disabled="referralData?.earnings?.balance < 5000 || !referralData?.earnings?.balance"
          class="whitespace-nowrap rounded-full font-medium text-xs px-3 py-1 bg-white border border-[#D2D2D2] disabled:cursor-not-allowed disabled:bg-[#dedede] absolute top-2 left-4 shadow-md hover:scale-105 ease-linear duration-300"
        >
          <i class="fa-solid fa-money-bill-transfer"></i>
          Withdraw
        </button>
        <div class="absolute top-2 right-4">
          <Tooltip>
            <template #button>
              <i class="fa-solid fa-circle-info"></i>
            </template>
            <template #message>
              Minimum withdrawal amount is NGN 5000
            </template>
          </Tooltip>
        </div>
        <span class="text-black dark:text-[#E5E5E5] text-lg md:text-3xl mt-6"
          >NGN {{ referralData?.earnings?.balance ?? 0 }}</span
        >
        <span class="text-[#505050] dark:text-[#E5E5E5]"
          >Available Balance</span
        >
      </div>
    </div>
    <!-- Stats Cards -->
    <h4 class="font-body font-semibold text-[#343434] dark:text-[#E5E5E5]">
      Your Referral Code
    </h4>
    <div
      class="rounded-md border border-[#52A350] font-body flex items-stretch overflow-clip mt-4 mb-12"
    >
      <input
        ref="referralCode"
        type="text"
        readonly
        :value="`${referralLink}`"
        class="bg-[#FCFCFC] grow outline-none border-0 text-[#505050] font-body"
      />
      <button class="bg-[#52A350] text-white px-2 md:px-8" @click="copier">
        Copy <i class="fa-solid fa-copy"></i>
      </button>
    </div>
    <div>
      <fwb-tabs v-model="activeTab" variant="underline" class="py-4">
        <fwb-tab :name="index" :title="`${index} Referral Payments`" v-for="index in ['Completed', 'Pending', 'All']" :key="index">
          <TerraTable :data="referral_data" :columns="columns" />          
        </fwb-tab>
        <fwb-tab name="withdrawal" title="Withdraw History">
          <TerraTable 
            :data="referralData?.earnings?.withdrawals" 
            :columns="[
                  { key: 'account_name', title: 'Account Name' },
                  { key: 'account_number' , title: 'Account Number'},
                  { key: 'bank_name' , title: 'Bank Name'},
                  { key: 'amount', title: 'Amount' },
                ]" 
          />
        </fwb-tab>
      </fwb-tabs>
    </div>
  </main>
</template>


<script>
import swal from "sweetalert";
import axios from "axios";
import { mapGetters } from "vuex";
import TerraTable from "@/components/inc/DataTable.vue";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import Tooltip from "@/components/lms/TooltipComponent.vue";
import WithdrawalModal from "./WithdrawalModal.vue";
import {
  FwbTab,
  FwbTabs,
} from "flowbite-vue";

export default {
  data() {
    return {
      referralData: {},
      showWithdrawalModal: false,
      activeTab: "Completed",
      referral_data: [],
      columns:[
        {
            title: 'Name',
            key: 'full_name'
        },
        {
            title: 'Status',
            key: 'completed'
        },
      ],
    }
  },
  components: {
    BackButton,
    Tooltip,
    WithdrawalModal,
    FwbTab,
    FwbTabs,
    TerraTable,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    referralLink() {
      return `${window.location.origin}/register?ref=${this.user?.referral_code}`;
    },
  },
  watch:{
    activeTab(){
      if (this.activeTab !== 'withdrawal') {
        return this.fetchReferralData();
      }
      return;
    }
  },
  methods: {
    /**
     * Copy Referral Link to Clipboard
     */
    copier() {
      let text = this.$refs.referralCode.value;
      const el = document.createElement("textarea");
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (document.execCommand("copy")) {
        swal({
          title: "Success",
          text: "Your referral code has been copied",
        });
      }
    },
    /**
     * Fetch Referrals
     */
    async fetchReferral() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("v2/auth/referrals");
        this.referralData = data.data;
        loader.hide();
        this.fetchReferralData()
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    async fetchReferralData () {
      this.referral_data = [];
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get(`v2/auth/referrals/data?query=${this.activeTab}`);
        this.referral_data = data.data;
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    }
  },
  mounted() {
    this.fetchReferral();
  },
};
</script>

<style scoped>

</style>
