<script>
import { provide } from "vue";
import { messaging as firebaseMessaging } from "./helpers/firebase.js";
// import { isSupported, onMessage } from "firebase/messaging";
// import { getMessaging,  } from "firebase/messaging";
import AskPushNotification from "./components/lms/AskPushNotification.vue";
import PWAInstall from "./components/inc/PWAInstallPrompt.vue";
import BlackFriday from "@/components/inc/BlackFridayComponent.vue";
import { mapActions, mapGetters } from "vuex";
import WhatsAppComponent from "@/components/inc/WhatsAppComponent.vue";

export default {
  data() {
    return {
      showCourseModal: false,
      allowed: null,
    };
  },
  watch: {
    $route(to) {
      document.title = to.meta.title;
    },
  },
  computed: {
    ...mapGetters({
      settings: "auth/settings",
      user: "auth/user",
    }),
  },
  mounted() {
    const note = JSON.parse(localStorage.getItem('notification'));
    const today = new Date()
    if (note > today) {
      this.allowed = true;
    } else {
      this.allowed = false;
      localStorage.setItem('notification', JSON.stringify(today.setDate(today.getDate() + 1)))
    }
  },
  methods: {
    showModal() {
      this.showCourseModal = !this.showCourseModal;
    },
    closeModal() {
      this.showCourseModal = false;
    },
    ...mapActions({
      fetchCourses: "auth/fetchCourses",
    }),
  },
  setup() {
    provide("messaging", firebaseMessaging);
  },
  mounted() {
    this.fetchCourses();
  },
  components: { AskPushNotification, PWAInstall, BlackFriday, WhatsAppComponent },
};
</script>

<template>
  <WhatsAppComponent />
  <AskPushNotification />
  <RouterView />
</template>
