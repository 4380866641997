<template>
  <div class="lg:w-4/5 mx-auto mt-8 lg:mt-24">
    <div
      class="bg-secondary-green text-center text-sm font-body-new absolute left-0 right-0 top-14 lg:top-20"
      v-if="settings.can_register == 0"
    >
      <div class="py-1 md:py-2 space-x-2">
        <span class="text-xs md:text-sm"
          >Registration for this cohort of the  Terra Learning Is Closed</span
        >
        <a
          class="font-medium cursor-pointer text-primary-green underline"
          href="https://docs.google.com/forms/d/e/1FAIpQLSc1nVzLH86ZWZz1O0rBnzc1POXA6Bu6ozpPtIuvLxdJS437Ug/viewform"
          target="_blank"
        >
          Join Waitlist
          <span
            class="iconify inline font-bold"
            data-icon="akar-icons:arrow-up-right"
          ></span>
        </a>
      </div>
    </div>
    <h1 class="font-header-1 font-bold text-body-text text-3xl md:text-4xl">
      Apply to join the internship
    </h1>
    <p class="text-body-text font-normal text-sm lg:text-sm font-body my-3">
      Enter your email to begin your application
    </p>
    <form @submit.prevent="submitEmail()">
      <fieldset :disabled="settings.can_register == 0">
        <CustomInputField
          errorMessage="Email is required"
          :validState="!v$.user.email.$invalid"
          v-model="user.email"
          label="Email address"
          inputType="email"
          required
          class="my-8"
        />

        <CustomInputField
          errorMessage="Email doesn't match"
          :validState="!v$.user.confirmEmail.$invalid"
          v-model="user.confirmEmail"
          label="Re-enter email address"
          inputType="email"
          required
          class="my-8"
        />

        <button
          class="button"
          type="submit"
          :disabled="v$.$invalid || isLoading"
          v-html="buttonText"
        ></button>
      </fieldset>
    </form>
    <p
      class="font-body text-body-text text-xs lg:sm font-normal text-center my-6"
    >
      By applying for the internship, I agree to
      <a
        href="/terms-and-conditions"
        class="text-primary-green font-medium cursor-pointer"
        >Side Hustle’s Terms and Conditions</a
      >
      and
      <a
        href="/privacy-policies"
        class="text-primary-green font-medium cursor-pointer"
        >Privacy Policy</a
      >
    </p>
    <div class="text-center border-t border-t-[#ACB1AC] pt-5">
      <p class="text-xs md:text-sm mb-5">Already have an account?</p>
      <SecondaryButton
        @click="$router.push({ name: 'LoginPage' })"
        class="w-full"
        >Login</SecondaryButton
      >
    </div>
  </div>
</template>

<script>
import CustomInputField from "./../../components/inc/CustomInputField.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import swal from "sweetalert";
import axios from "axios";
import { inject } from "@vue/runtime-core";
import { mapGetters } from "vuex";
import SecondaryButton from "../../components/inc/SecondaryButton.vue";

export default {
  computed: {
    ...mapGetters({
      settings: "auth/settings",
    }),
  },
  data() {
    return {
      user: {
        email: "",
        confirmEmail: "",
      },
      isLoading: false,
      buttonText: "Apply now",
      authType: "",
    };
  },
  validations() {
    return {
      user: {
        email: { required, email },
        confirmEmail: { required, sameAs: sameAs(this.user.email) },
      },
    };
  },
  components: {
    CustomInputField,
    SecondaryButton,
  },
  methods: {
    async submitEmail() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let loader = this.$loading.show();
        this.buttonText = `<i class="fa-solid fa-spinner fa-spin fa-2x"></i>`;
        const data = this.user;
        try {
          const response = await axios.post("v2/register", data)
          loader.hide();
          // set email in localStorage to be used in the Enter Details page
          localStorage.setItem("pendingEmail", this.user.email);
          if (response.status == 203) {
            // If email already has incomplete reg, inform user and redirect to complete reg
            swal({
              title: "Welcome Back!",
              text: `${response?.data?.message}`,
              buttons: ["No", "Yes, Continue"],
            }).then((e) => {
              if (e) {
                this.$router.push({
                  name: "EnterDetails",
                  params: {
                    email: this.user.email,
                    unAuthApplication:
                      this.$route?.params?.unAuthApplication ?? false,
                  },
                });
              } else {
                this.buttonText = "Apply now";
              }
            });
            return;
          }
          this.$router.push({
            name: "EnterDetails",
            params: {
              email: this.user.email,
              unAuthApplication:
                this.$route?.params?.unAuthApplication ?? false,
            },
          });
        } catch (error) {
          loader.hide();
          swal({
            title: "Oops!",
            text: `${error?.response?.data?.message}` ?? "Please try again later." ,
          });
          this.buttonText = "Apply now";          
        }
      }
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
};
</script>

<style lang="scss" scoped>
.button[type="submit"] {
  @apply text-base lg:text-lg font-semibold text-white bg-primary-green rounded-md  py-4 px-12 w-full;
}
.button[type="submit"]:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF];
}
.external_auth_button {
  .iconify {
    @apply mr-4;
  }
  @apply text-left text-base lg:text-lg font-semibold text-[#8A918A] bg-white w-full rounded-md p-4 border border-[#D2D7D2] my-4 flex items-center;
}
</style>
