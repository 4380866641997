<template>
  <div>
    <div class="container md:p-0">
      <BackButton class="my-4 md:my-12" />
      <div
        class="lg:w-5/6 my-8 rounded-2xl md:border border-[#0000001A] md:px-8 py-8 font-body"
      >
        <h2
          class="font-body font-semibold text-lg md:text-xl border-b pb-4 dark:text-white dark:border-[#C8C8C8] text-[#242424] border-[#00000026]"
        >
          Keep tab on your badges
        </h2>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 py-8">
          <div
            class="flex flex-col items-center text-center cursor-pointer"
            v-if="awarded_badges.length == 0"
          >
            <img src="@/assets/img/badges/locked.png" alt="Locked Badge" />
            <p class="text-sm dark:text-white text-[#4F4F4F]">
              You haven’t earned any badge
            </p>
          </div>
          <template
            v-else
            v-for="badge in awarded_badges"
            :key="badge.badge_id"
          >
            <div
              class="flex flex-col items-center text-center cursor-pointer"
              @click="shareBadge(badge.id)"
            >
              <img class="h-32" :src="badge.badge.url" :alt="badge.title" />
              <p class="text-sm font-semibold dark:text-white text-[#4F4F4F]">
                {{ badge.badge.title }}
              </p>
              <p class="dark:text-white text-[#4F4F4F]">
                Click here to share badge
              </p>
            </div>
          </template>
        </div>
        <div>
          <h3
            class="border-b pb-2 text-sm text-[#242424] border-[#00000026] dark:text-white dark:border-[#C8C8C8]"
          >
            LOCKED
          </h3>
          <div
            v-if="badges.length > awarded_badges.length"
            class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-16 py-8"
          >
            <template v-for="badge in badges" :key="badge.id">
              <div
                v-if="(!unlockedBadges.includes(badge.id) && !unlockedBadges.includes(badge.id.toString())) && badge.isAvailable == 1 "
                class="flex flex-col items-center text-center"
              >
                <img
                  class="h-32 grayscale"
                  :src="badge.url"
                  :alt="badge.title"
                />
                <p class="text-sm font-semibold dark:text-white text-[#4F4F4F]">
                  {{ badge.title }}
                </p>
                <p class="text-xs dark:text-white text-[#4F4F4F]">
                  {{ badge.description }}
                </p>
              </div>
            </template>
          </div>
          <div
            v-if="badges.length > 0 && unlockedBadges.length == badges.length"
            class="dark:text-white text-[#4F4F4F] font-body text-xl my-8"
          >
            Congrats!!! You have unlocked all badges.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { share as shareService } from "@/helpers/navigatorShare.js";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    unlockedBadges() {
      return this.awarded_badges.map((badge) => badge.badge_id);
    },
  },
  components: {
    BackButton,
  },
  data() {
    return {
      badges: [],
      awarded_badges: [],
      badgeShare: null,
    };
  },
  methods: {
    async fetchBadges() {
      let loader = this.$loading.show();
      try {
        axios
          .get("auth/badges")
          .then((res) => {
            this.badges = res.data.payload.badges ?? [];
            this.awarded_badges = res.data.payload.awarded_badges ?? [];
            loader.hide();
          })
          .catch(() => {
            this.badges = [];
            this.awarded_badges = [];
            loader.hide();
          });
      } catch (error) {
        this.badges = [];
        this.awarded_badges = [];
        loader.hide();
      }
    },
    /**
     * Share badge
     * @param int
     */
    async shareBadge(id) {
      let loader = this.$loading.show();
      try {
        const payload = {
          id: id,
        };
        const { data } = await axios.post("auth/badges/share", payload);
        this.badgeShare = data.payload;
        if (!data.payload.file) {
          return;
        }
        const rand1 = Math.random().toString(16).substr(2, 11);
        const rand2 = Math.random().toString(16).substr(2, 6);
        const badgeID = `${payload.id}_${rand2}-${rand1}`;
        shareService(
            data.payload.file, // file
            "Side_Hustle_Badge.jpg", // file_name
            `Hello, I just earned a ${this.badgeShare.title} badge at the Terra Learning Internship.`, //text
            `https://lms.terrahq.co/badge/${badgeID}` // url
        );
        loader.hide();
      } catch (err) {
        console.error(`${err.name}: ${err.message}`);
        loader.hide();
      }
    },
  },
  mounted() {
    this.fetchBadges();
  },
};
</script>

<style scoped>
img {
  @apply mb-4;
}
</style>