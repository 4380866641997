// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCdF0238lAS9sMMHFzbWkeLgyqeIDHuUkE",
  authDomain: "terra-learning-a1695.firebaseapp.com",
  projectId: "terra-learning-a1695",
  storageBucket: "terra-learning-a1695.appspot.com",
  messagingSenderId: "560793213661",
  appId: "1:560793213661:web:d4ecc249877267409a1934",
  measurementId: "G-EDJRHHMXR2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
