<template>
  <WhatsAppComponent />
  <CurriculumModal
    v-if="course?.curriculum && showCurriculum == true"
    @closemodal="showCurriculum = false"
    :curriculum="course?.curriculum?.flat()"
  />
  <main class="pt-5 md:pt-12 lg:pt-0">
    <header
      class="text-left pb-12 lg:pb-16 container md:bg-[url(/src/assets/img/logo/terra-logo.png)] bg-no-repeat bg-right bg-[length:250px_250px]"
    >
      <button
        class="text-primary-green text-sm flex items-center uppercase font-semibold mb-8"
        @click="$router.push({ name: 'SelectCourse' })"
      >
        <span class="iconify inline" data-icon="eva:arrow-ios-back-fill"></span>
        BACK
      </button>
      <div class="flex items-start relative">
        <div class="md:w-3/6 space-y-6 flex flex-col items-start">
          <div class="space-y-2">
            <h1 class="font-header-1 font-bold text-body-text text-4xl">
              {{ course?.title ?? " " }}
            </h1>
            <p class="text-sm">{{ course?.description ?? " " }}</p>
          </div>
          <div class="md:hidden">
            <img
              src="@/assets/img/icons/laptop-required.svg"
              alt="Laptop is required for this course"
              class="h-24 my-4"
              :class="
                course?.requires_laptop == 1 ? 'visible' : 'invisible'
              "
            />
          </div>
          <div class="relative w-full">
            <p 
              class="font-body-new text-[#0B52D1] mb-2 text-xs font-medium cursor-pointer"
              @click="showCurriculum = true" >
              <span
                class="iconify inline"
                data-icon="material-symbols:help-outline"
              ></span>
              What you'll learn
            </p>
          </div>
          <div class="flex items-center text-[#333333]">
            <div class="mr-3">
              Tuition:
              <div class="font-semibold inline-block tracking-wide relative">
                ₦{{ integerFormatter(course?.price) ?? 0 }}
                <span
                  class="top-2.5 left-0 inline-block bg-primary-green absolute rotate-[356deg] w-full h-[2px]"
                ></span>
              </div>
            </div>
            <img class="h-12" src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/free.svg" alt="free" />
          </div>
          <button
            @click="selectCourse()"
            class="px-6 py-3 bg-primary-green text-white font-semibold rounded-sm text-sm w-full md:w-fit shadow-[0px_8px_20px_rgba(56,127,54,0.15)]"
          >
            Apply Now
            <span
              class="iconify inline"
              data-icon="eva:arrow-ios-forward-fill"
            ></span>
          </button>
        </div>
        <div
          class="hidden md:flex items-center justify-center grow md:absolute md:right-[75px] md:top-1/2 md:-translate-y-1/2"
        >
          <img
            src="@/assets/img/icons/laptop-required.svg"
            alt="Laptop is required for this course"
            class="h-[30%]"
            :class="
              course?.requires_laptop == 1 ? 'visible' : 'invisible'
            "
          />
        </div>
      </div>
    </header>
    <section class="bg-white w-full py-8 lg:py-16">
      <div class="container space-y-24">
        <div
          class="md:grid gap-8 lg:gap-16 md:grid-cols-2 flex flex-col md:space-y-0 w-full font-body-new"
        >
          <div
            class="bg-[#222522] shadow-[0px_16px_25px_rgba(34,37,34,0.38)] text-white rounded-2xl p-12 space-y-16"
          >
            <div class="">
              <h2 class="font-extrabold text-2xl">
                How the learning path works
              </h2>
            </div>
            <div class="grid grid-flow-col">
              <div
                class="h-full flex flex-col justify-between items-center py-3 space-y-2 w-fit mr-4"
              >
                <p class="md:h-4 md:w-4 h-4 w-4 mx-0 bg-white rounded-full"></p>
                <p class="w-1 bg-white grow"></p>
                <p class="md:h-4 md:w-4 h-4 w-4 mx-0 bg-white rounded-full"></p>
                <p class="w-1 bg-[#FFFFFF4A] grow"></p>
                <p
                  class="md:h-4 md:w-4 h-4 w-4 mx-0 bg-[#FFFFFF4A] rounded-full"
                ></p>
                <p class="w-1 bg-[#FFFFFF4A] grow"></p>
                <p
                  class="md:h-4 md:w-4 h-4 w-4 mx-0 bg-[#FFFFFF4A] rounded-full"
                ></p>
              </div>
              <div class="text-[8px] space-y-8 font-body-new">
                <div>
                  STEP ONE
                  <p class="text-sm leading-6 font-semibold">Register</p>
                </div>
                <div>
                  STEP TWO
                  <p class="text-sm leading-6 font-semibold">Select a Course</p>
                </div>
                <div>
                  STEP THREE
                  <p class="text-sm leading-6 font-semibold">
                    Make payment
                  </p>
                </div>
                <div>
                  STEP FOUR
                  <p class="text-sm leading-36 font-semibold">
                    Complete all lessons and assessments to download certificate
                  </p>
                </div>
              </div>
            </div>

            <div class="space-y-12 pb-6">
              <p class="italic text-[#F1B51C] text-xs">
                ***Note: You will only qualify for a certificate after making
                payment for the certificate and completing all 4 weeks of the
                internship
              </p>
              <button
                @click="selectCourse()"
                class="px-6 py-3 bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear text-white font-semibold rounded-sm text-sm shadow-[0px_8px_20px_rgba(56,127,54,0.15)]"
              >
                Apply Now
                <span
                  class="iconify inline"
                  data-icon="eva:arrow-ios-forward-fill"
                ></span>
              </button>
            </div>
          </div>
          <div
            class="bg-[#0B52D1] shadow-[0px_16px_25px_rgba(11,82,209,0.29)] text-white rounded-2xl p-12 order-first"
          >
            <h2 class="font-extrabold text-2xl">Why apply for this course</h2>
            <p class="font-medium leading-9 text-sm">
              Here are some interesting facts about this course
            </p>
            <ul class="grid grid-cols-1 md:grid-cols-2 gap-16 my-8 text-xs">
              <div>
                <img src="@/assets/img/icons/aim.png" alt="" />
                Learn the skills you need to gain employment
              </div>
              <div>
                <img src="@/assets/img/icons/mentor.png" alt="" />
                Flexible learning schedule
              </div>
              <div>
                <img src="@/assets/img/icons/hands.png" alt="" />
                Get hands-on experience
              </div>
              <div>
                <img src="@/assets/img/icons/portfolio.png" alt="" />
                Career advancement
              </div>
            </ul>
            <div class="space-y-4 my-16" v-if="course?.opportunities">
              <h2 class="font-extrabold text-lg md:text-2xl">
                Career Opportunities
              </h2>
              <p class="text-xs leading-6">
                {{ course?.opportunities ?? " " }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>


<script setup>
import { integerFormatter } from "@/helpers/customFormatter.js";
</script>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import CurriculumModal from "./CurriculumModal.vue";
import WhatsAppComponent from "@/components/inc/WhatsAppComponent.vue";
export default {
  data() {
    return {
      course_id: "",
      course: [],
      batches: [],
      selected_batch: "",
      learning_type: "self_paced",
      showCurriculum: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
      fetchedCourses: "auth/courses",
    }),
  },
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    CurriculumModal,
    WhatsAppComponent
  },

  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    JSONParser(string){
      if (typeof string == 'object') return string;
      else return JSON.parse(string);
    },
    async fetchCoursesByID(id) {
      localStorage.setItem("select-course", id);
      if (this.fetchedCourses?.length > 0) {
        this.course = this.fetchedCourses.find((item) => {
          return item?.id == id
        })
      } else {
        let loader = this.$loading.show()
        const { data } = await axios.get(`v2/courses/${id}`);
        this.course = data.data
        loader.hide()
        return
      }
    },
    async fetchBatches() {
      try {
        const { data } = await axios.get("/batches");
        this.batches = data.payload.filter((batch) => {
          return new Date(batch.start_on) > new Date();
        });
        if (this.batches?.length == 0 && data?.payload?.length > 0) {
          this.batches = [data.payload[data.payload.length - 1]];
        }
        this.selected_batch = this.batches[0];
      } catch (error) {
        console.error(error);
      }
    },
    async selectCourse() {
      let loader = this.$loading.show()
      let payload = {course_id: this.course?.id, learning_type : this.learning_type == 'hands-on' ? 'instructor' : this.learning_type }
      try {
        await axios.put("v2/auth/update/3", payload)
        await this.attempt({});
        loader.hide()
        this.$router.push({ name: 'DepositPage', params: { slug: this.learning_type },})
      } catch (error) {
        console.error(error)
        loader.hide()
      }

    }
    
  },
  mounted() {
    if (!this.$route?.params?.id) {
      const courseID = localStorage.getItem("select-course");
      if (courseID) {
        return this.fetchCoursesByID(courseID);
      }
      return this.$router.push({ name: "SelectCourse" });
    }
    this.fetchCoursesByID(this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped></style>
