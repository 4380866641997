<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center p-4 z-50"
    @click="closeModal()"
  >
    <div
      class="flex flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 py-12 space-y-6 md:w-3/5 text-left"
      ref="applicationSuccessModal"
    >
      <h2 class="font-header-1 text-primary-green font-bold text-4xl">Hey!</h2>
      <p class="font-body text-sm font-semibold text-[#616161] dark:text-white">
        You haven’t updated your Raven account number!
      </p>
      <p
        class="font-body text-sm font-normal text-[#616161] dark:text-white w-full md:w-3/5"
      >
        Don’t miss out on amazing rewards you can earn when you update your
        Raven account number!
      </p>
      <div class="flex items-center justify-end w-full text-sm pt-8">
        <button
          @click="this.$emit('closemodal')"
          class="text-[#747874] whitespace-nowrap dark:text-[#B3B3B3] font-semibold font-body w-fit py-3 pr-12"
        >
          I’d do it later
        </button>
        <button
          @click="
            this.$emit('closemodal'), $router.push({ name: 'LearningRaven' })
          "
          class="bg-primary-green whitespace-nowrap rounded-md text-white font-semibold font-body w-fit p-3"
        >
          Update Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["batch"],
  methods: {
    closeModal() {
      const box = this.$refs.applicationSuccessModal;
      if (!box?.contains(event.target)) {
        return this.$emit("closemodal");
      }
      return;
    },
  },
};
</script>

<style></style>
