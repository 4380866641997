<template>
  <div class="container">
    <main class="md:pb-24">
      <button @click="$router.push({name: 'LessonPage'})" class="font-body-new text-primary-green flex items-center my-4 md:my-12">
        <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span>
        <span class="font-semibold uppercase text-xs">Back</span>
    </button>
      <div @click="handleClick">
      <div
        v-if="user.learning_type != 'instructor'"
        class="border border-[#F7C93B] bg-[#FFFDF5] rounded py-3 text-left my-8 px-4 md:px-6 cursor-pointer md:mt-5 mt-20"
      > 
        <p
          class="font-body-new font-semibold text-[10px] md:text-[12px] text-[#211D12] mb-1"
        >
        Upgrade your learning path to get feedback from mentors when you submit your tasks . 
Click here to upgrade your learning path
        </p>
       
      </div>
  </div>
      <div>
        <h1 class="text-[#0C0C0C]'] dark:text-[#E5E5E5] font-header-1 font-extrabold text-2xl md:text-3xl px-4 md:px-0 mb-5">Week {{ baselineTask?.week }} Task</h1>
        
        <div class="animate-pulse  bg-gray-200 rounded-lg p-1 w-full px-8 py-2 h-[200px] flex items-start flex-col justify-start" v-if="loading">
                  <div class="h-4 bg-gray-300 rounded w-1/2 my-4"></div>
                  <div class="h-4 bg-gray-300 rounded w-full my-4"></div>
                  <div class="h-4 bg-gray-300 rounded w-full my-4"></div>
                  <div class="h-4 bg-gray-300 rounded w-full"></div>
              </div>
        <div v-else-if="!baselineTask && !loading">
          <div class="flex w-full h-screen items-center justify-center flex-col">
      <!-- <img src="../../../../assets/img/empty.png" class="w-32" alt="empty" /> -->
      <h1
        class="text-[#0C0C0C]'] dark:text-[#E5E5E5] font-header-1 px-4 md:px-0 mb-5"
      >
        No Task available
      </h1>
    </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-5 gap-12 " v-else>
          <div  :class="baselineTask?.other_resources ? 'md:col-span-3' : 'md:col-span-5'" class="md:col-span-3 font-body h-fit space-y-6 ">
            <div class="font-body h-fit bg-white shadow-[0px_3.64384px_22.774px_rgba(0,0,0,0.08)] rounded-lg p-4 md:p-8 space-y-6 border dark:border-none dark:bg-[#333333] text-sm text-[#6F706F] font-medium">
              <div :class="`space-y-6 rounded-lg p-4 md:p-8 dark:bg-[#333333]  ${baselineTask?.submission?.graded == 1 ? 'bg-white': 'bg-[#F6F7F6]'}`" v-if="baselineTask?.task">
                <div  v-if="baselineTask?.submission?.graded == 1" class="w-full flex items-center px-2 py-1 font-bold text-[10px] ">
                    <h2 class="font-semibold mr-5 text-[#0C0C0C]'] dark:text-[#E5E5E5]  text-xl">
                      Task Score: 
                    </h2>
                    <h3 class="font=semibold mr-3 text-[#0C0C0C]'] dark:text-[#E5E5E5] text-3xl">
                      {{ baselineTask?.submission?.score }}
                    </h3>
                    
                    <button class="border border-green-500 text-green-600 ml-auto px-10 py-4 text-[#0C0C0C]'] dark:text-[#E5E5E5] rounded-lg">Review Task</button>
                </div>
                <div class="flex items-center h-5 space-y-4 space-x-4 my-4" v-else>
                  <h2 class="text-body-text font-bold text-[#0C0C0C]'] dark:text-[#E5E5E5] ">Task</h2>
                  <div class="gap-5 -translate-y-2">
                    <button  v-if="baselineTask?.submitted" class="mr-4 text-[#0D68CE] bg-[#E7F2FD] rounded-full px-2 md:px-4 py-1 font-bold text-[10px] w-fit">
                    Task Submitted
                  </button>
              
                  </div>
                </div>
                <ul class="text-sm text-[#4B524B] list-inside list-disc text-[#0C0C0C]'] dark:text-[#E5E5E5]" v-for="(task, index) in JSON.parse(baselineTask?.task)" :key="index">
                  <!-- <li> <strong>Step {{index+1}}:</strong> <span v-html="task"></span></li> -->
                  <p>
                    {{  baselineTask?.submission?.graded == 1 ? '':baselineTask.instruction }}
                  </p>
                  <h4 class="mt-4 font-semibold text-[15px]" v-if="JSON.parse(baselineTask.task)[0] != null && baselineTask?.task.length > 0 && !baselineTask?.submitted">
                    Instructions:
                  </h4>
                  <ol class="ml-1 mt-2" type="1" v-if="JSON.parse(baselineTask.task)[0] != null && baselineTask?.task.length > 0 && !baselineTask?.submitted">
                    
                    <li v-for="(tasks, indexz) in JSON.parse(baselineTask?.task).join().split('.')" class="mb-2" :key="tasks.id">
                      {{ tasks }}
                      {{ 
                        indexz + 1 == JSON.parse(
                          baselineTask?.task
                          ).join()
                          .split('.').
                          length ?'' : indexz + 1 }}. {{ tasks  }}
                    </li>
                  </ol>
                </ul>
                <p class="text-sm italic font-semibold text-[#0C0C0C]'] dark:text-[#E5E5E5]" v-if="baselineTask?.note">Note: {{baselineTask?.instruction}}</p>
              </div>
              <div class="py-4 border-y border-[#D4D7D4] text-[#0C0C0C]'] dark:text-[#E5E5E5]" v-if="baselineTask?.has_video">
                <h2 class="text-body-text font-bold">{{baselineTask?.video_title}}</h2>
                <video v-if="baselineTask?.source =='DO'"  controls :src="baselineTask?.video_url" class="w-full my-4 max-w-2xl mx-auto"></video>
                <div  v-if="baselineTask?.source =='youtube'" class="relative max-w-[500px]  h-96 overflow-hidden">
                    <iframe class="" width="100%" height="100%"
                        oncontextmenu="return false"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                        :src="`${baselineTask.youtube_url}?autoplay=1&;modestbranding=1&;rel=0`"  >
                    </iframe>
                </div>
              </div>
              <!-- <form @submit.prevent="submitBaselineTask()" v-if="!baselineTask?.submitted" class="flex flex-col space-y-4">
                <h2 class="font-header-1 font-bold text-xl">Attach submission here</h2>
                <input
                    type="file"
                    ref="fileInput"
                    class="hidden"
                    @change="updateFile"
                    accept=".jpg, .jpeg, .png"
                />
                <div @click="selectFile" class="customFileInput" > {{ file_name }}</div>
                <button type="submit" :disabled="file_name == 'Select file'" class="bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-4 py-2 text-white w-fit">Submit task</button>
              </form> -->
              <div class="pl-8" v-if="!baselineTask?.submitted">
              <div class="flex flex-col space-y-4">
                <h2 class="font-header-1 font-bold text-xl text-[#0C0C0C]'] dark:text-[#E5E5E5]">Submit the link to Task</h2>
                <input
                  type="text"
                  id="link"
                  ref="linkInput"
                  @input="validateLink"
                  placeholder="Paste link to task here"
                  :class="`w-3/4 px-3 py-2 mb-2 inline-block ${error ? 'active': 'green'}`"
                />
                <div v-if="error" class="text-red-600 text-sm">{{ "This is not a valid link" }}</div>
               
              </div>
              <button type="submit"  @click="handleTask"  class="mt-3 bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-4 py-2 text-white w-fit">Submit task</button>
    
            </div>
            </div>
          </div>
          <div class="md:col-span-2 w-full lg:w-4/5 lg:ml-auto font-body" v-if="baselineTask?.other_resources">
              <h2 class="font-body text-[#3B433B] font-bold text-2xl mb-4">Download Data Set</h2>
              <div class="w-full space-y-6">
                <template v-for="(resource, index) in JSON.parse(baselineTask?.other_resources)" :key="index">
                  <a :href="resource.url" download  class="flex w-full bg-[#FFFFFF] transition ease-linear duration-150 hover:shadow-[0px_6.64384px_25.774px_rgba(0,0,0,0.20)] shadow-[0px_3.64384px_22.774px_rgba(0,0,0,0.08)] rounded-md py-6 px-4">
                    <span class="iconify inline-block font-bold text-xl text-primary-green" data-icon="material-symbols:arrow-outward-rounded"></span>
                    <div class="ml-2 break-all">
                      <p class="font-bold text-[#384138]">{{resource?.title ?? ""}}</p>
                      <a class="text-primary-green text-xs" target="_blank" :href="resource?.url"> {{resource?.url ?? ""}}</a>
                    </div>
                  </a>
                </template>
              </div>
              <!-- <div v-if="baselineTask?.instruction" class="bg-[#F1F7FE] mt-7 py-6 px-4 flex w-full border border-[#60AAFD] rounded-md">
                <span class="iconify inline-block font-bold text-4xl text-[#60AAFD]" data-icon="mdi:idea"></span>
                <div class=" ml-2">
                  <h2 class="font-bold mb-2 text-[#384138]">What should I do with other resources?</h2>
                  <p class="text-xs text-[#4B524B]">{{baselineTask?.instruction ?? ""}}</p>
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </main>
</div>
</template>


<script setup>
import VueCountdown from '@chenfengyuan/vue-countdown';
import axios from "axios";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { useStore } from "vuex";
import { watch, getCurrentInstance } from "vue";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
import { decrypt } from "@/helpers/encrypt";
import {saveAs} from 'file-saver';
const route = useRoute();

const linkInput = ref(null);

const convertTime = (time) => {
  const now = new Date();
  var date = new Date(time);
  date.setDate(date.getDate() - 5);
  const update_at = new Date(date);
  return update_at - now;
}


const $loading = inject('$loading')

const store = useStore();
const router = useRouter();

const user = computed(()=>{
  return store.state.auth.user;
});

const handleClick = () => {
  router.push({name: "CommitmentPayment"})
}

let week = ref("");
let current = ref("");
let loading = ref(true);

const fileInput = ref(null);
const file_name = ref("Select file (Maximum file size is 2MB)");
let error = ref(false)
let errorMessage = ref("")


watch(fileInput,() => {
 validateLink()
})

watch(current, () => {
  console.log('changed')
  const instance = getCurrentInstance()
instance.forceUpdate() 
})


const selectFile = async () => {
  fileInput.value.click();
};
const updateFile = async () => {
  const file = fileInput.value.files[0];
  file_name.value = file.name
};

const validateLink = (e) => {
  console.log(linkInput.value.value)
// Regular expression for a valid URL
var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                         '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                         '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                         '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                         '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                         '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

                         console.log(!pattern.test(linkInput.value.value))
// Check if the input matches the pattern
if (!pattern.test(linkInput.value.value)) {
      error.value = true;
      errorMessage.value = "Please enter a valid link";
    } else {
      error.value = false;
      errorMessage.value = "";
    }
//   return pattern.test(e.target.value);
}

const baselineTask = ref(null);
const baselineSubmission = ref("");
const prevRoute = ref(null);

const handleTask = async () => {
let loader = $loading.show()
// payload.append("link", linkInput.value);
// payload.append("id", baselineTask?.value?.id);  
if (!error.value) {
  // The input is a valid URL
  try {
  await axios.post("auth/tasks/submit2", {link:linkInput.value.value,id:baselineTask.value.id});
  await store.dispatch("auth/attempt");
  loader.hide();
  fetchBaselineTask();
} catch (error) {
  loader.hide();
  swal({
    title: "Oops",
    text:
      error?.response?.data?.payload ??
      "Unable to save your submission at the moment. Please try again shortly",
  });
  console.error(error);
}
} else {
  // The input is not a valid URL
  loader.hide();
  swal({
    title: "Error",
    text:
      "Link is not valid",
  });
}


};

const fetchBaselineTask = async () => {
let loader = $loading.show()
try {
  const {data} = await axios.get('auth/tasks/fetch/'+week.value)
  baselineTask.value = data.payload
  // console.log(baselineTask.value)
  loading = false;
  loader.hide()
} catch (error) {
  loader.hide()
  console.error(error);
}
}
const submitBaselineTask = async () => {
const file = fileInput.value.files[0];
const maxAllowedSize = 2 * 1024 * 1024;
if (file.size > maxAllowedSize) {
      swal({
          title: "Oops!",
          text: "Maximum allowed file size is 2MB",
      });
      return;
  }
if (file == undefined) return;
const payload = new FormData();
payload.append("file", file);
payload.append("id", baselineTask?.value?.id);
let loader = $loading.show()
try {
  await axios.post('auth/tasks/submit', payload)
  await store.dispatch("auth/attempt")
  fetchBaselineTask();
  loader.hide()
} catch (error) {
  loader.hide()
  swal({
    title: "Oops",
    text: error?.response?.data?.payload ?? "Unable to save your submission at the moment. Please try again shortly",
  });
  console.error(error);
}
}

// const downloadDoc = async (linkSrc) => {
//       try {
//         const doc = await fetch(linkSrc);
//         const docBlob = await doc.blob();
//         const docURL = URL.createObjectURL(docBlob);
//         const a = document.createElement("a");
//         a.href = docURL;
//         a.download = linkSrc.split('/').pop();
//         a.click();
//       } catch (e) {
//         console.log(e);
//       }
// };

// const downloadDoc = async (linkSrc) => {
//     await fetch(linkSrc)
//     .then(response => response.blob())
//     .then(blob => {saveAs(response.data, 'downloaded-file.pdf')});
  
//     // .then(blob => {
//     //   const url = URL.createObjectURL(blob);
//     //   const a = document.createElement('a');
//     //   a.href = url;
//     //   a.download = linkUrl.split('/').pop();
//     //   a.click();
//     // });
// }

// const downloadDoc = (url) => {
//   fetch(url)
//     .then(response => response.blob())
//         .then(response => {
//             saveAs(response.data, 'downloaded-file.pdf');
//         })
// }

// const XLSX = require('xlsx');


// const options = {
//   method: 'GET',
//   headers: {
//     'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
//   }
// };

// fetch(url, options)
//   .then(res => res.arrayBuffer())
//   .then(data => {
//     const workbook = XLSX.read(data, { type: 'array' });
//     // do something with the workbook
//   })
//   .catch(err => console.error(err));

// const downloadDoc = (linkSrc) => {
//   fetch(linkSrc)
//     .then(response => response.blob())
//     .then(blob => {
//       const url = URL.createObjectURL(blob);
//       const a = document.createElement('a');
//       a.href = url;
//       a.download = linkSrc.split('/').pop();
//       a.click();
//     });
// }


onMounted(()=>{
  window.addEventListener('hashchange', () => {
      current.value = window.location.href
return
  })
week.value = route.params.week ? decrypt(route.params.week) : null;
fetchBaselineTask();
})

</script>


<style scoped>
.active {
  border: 2px solid red !important;
}

.green {
  border: 2px solid green !important
}
</style>
