<template>
  <nav
    class="hidden md:block md:w-[25%] h-full md:pb-32 md:fixed -ml-1 font-body overflow-y-scroll bg-[#FAFAFA] dark:bg-[#121212]"
  >
    <div class="pt-4">
      <!-- Baseline -->
      <button
        :disabled="checkTests.baseline"
        class="cursor-pointer py-3 text-xs w-full text-left md:text-sm px-4 lg:px-8 flex justify-start items-start dark:text-[#C1C6C1] text-[#3F473F]"
      >
        <span
          class="iconify text-2xl min-w-fit mr-3"
          data-icon="healthicons:i-exam-multiple-choice-outline"
        ></span>
        <span class="mr-2">Baseline Assessment</span>
        <i
          v-if="checkTests.baseline"
          class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
        ></i>
      </button>
      <!-- Baseline -->
      <!-- Lessons -->
      <template v-for="lesson in lessons" :key="lesson.id">
        <li
          @click="selectLesson(lesson.id)"
          :class="
            currentLesson.id == lesson.id
              ? 'bg-[#51a35021] text-primary-green font-semibold'
              : 'dark:text-[#C1C6C1] text-[#3F473F]'
          "
          class="cursor-pointer py-3 text-xs text-left md:text-sm px-4 lg:px-8 flex justify-start items-start"
        >
          <span
            class="iconify text-2xl min-w-fit mr-3"
            data-icon="ei:play"
          ></span>
          <span class="mr-2">{{ lesson.title }}</span>
          <i
            v-if="watchedLessons.includes(lesson.id)"
            class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
          ></i>
        </li>
      </template>
      <!-- Lessons -->
      <!-- Endline -->
      <button
        @click="takeEndline"
        :disabled="checkTests.endline"
        class="cursor-pointer py-3 text-xs text-left md:text-sm px-4 lg:px-8 flex justify-start w-full items-start dark:text-[#C1C6C1] text-[#3F473F]"
      >
        <span
          class="iconify text-2xl min-w-fit mr-3"
          data-icon="healthicons:i-exam-multiple-choice-outline"
        ></span>
        <span class="mr-2">Endline Assessment</span>
        <i
          v-if="checkTests.endline"
          class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
        ></i>
      </button>
      <!-- Endline -->
      <!-- Certificate -->
      <div
        class="border-[#D2D7D2] dark:text-[#C1C6C1] text-[#3F473F] px-4 lg:px-8 border-t mt-2 py-4 flex text-sm md:text-base justify-between items-center w-full cursor-pointer"
        @click="checkCertificate"
      >
        <span>Download Certificate </span>
        <span class="iconify text-sm ml-2" data-icon="bxs:lock-alt"></span>
        <i class="fa-solid fa-chevron-right text-xs ml-auto"></i>
      </div>
      <!-- Certificate -->
    </div>
  </nav>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted } from "vue";
import { computed, ref } from "@vue/reactivity";
import { inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();

const completedLessons = computed(() => {
  const totalLessons = lessons.value.length;
  const totalWatched = watchedLessons.value.length;
  if (totalWatched < totalLessons) {
    return false;
  }
  if (totalWatched >= totalLessons) {
    return true;
  }
});

const siteSettings = computed(() => {
  return store.state.auth.settings;
});

const user = computed(() => {
  return store.state.auth.user;
});
const lessons = computed(() => {
  return store.state.jobberman.lessons;
});
const checkTests = computed(() => {
  return store.state.jobberman.checkTests;
});

const currentLesson = computed(() => {
  return store.state.jobberman.currentLesson;
});
const watchedLessons = computed(() => {
  return store.state.jobberman.watchedLessons;
});

const selectLesson = (id) => store.dispatch("jobberman/setLesson", id);

const takeEndline = () => {
  if (completedLessons.value == false) {
    swal({
      title: "Oops!",
      text: "You have to watch all videos before proceeding to take your endline test",
    });
    return;
  }
  router.push({ name: "LearningJobbermanBaseline" });
};

/**
 * check certificate
 */
const checkCertificate = () => {
  if (user.value.batch.id >= siteSettings.value.active_batch) {
    swal({
      title: "Not Yet Available",
      text: "You will be able to download your certificate at the end of the 4th week of the internship",
    });
    return;
  }
  router.push({ name: "AllCertificatesPage" });
};
const downloadCert = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("auth/jobberman/certificate");
    const file = data.payload.file;
    const file_name =
      user.value.first_name +
      "_" +
      user.value.last_name +
      "_Soft_Skills_Certificate";
    let a = document.createElement("a");
    a.href = file;
    a.download = file_name;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(img.src);
    }, 0);
    loader.hide();
    swal({
      title: "Congrats!",
      text: "Your Soft Skills Certificate has been downloaded to your device",
    });
  } catch (error) {
    loader.hide();
    swal({
      title: "Oops!",
      text:
        error?.response?.data?.payload ??
        "Unable to fetch your certificate. Please try again later",
    });
  }
};
</script>

<style></style>
