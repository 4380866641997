<template>
  <div>
    <div class="container md:p-0">
      <button
        @click="$router.push({ name: 'LearningDashboardIndex' })"
        class="font-body text-primary-green flex items-center my-4 md:my-12"
      >
        <span
          class="iconify mr-2 text-2xl font-extralight"
          data-icon="entypo:chevron-with-circle-left"
        ></span>
        <span class="font-semibold">Go to Dashboard</span>
      </button>
      <div class="md:w-5/6">
        <div
          class="p-4 md:p-6 bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body grid grid-flow-row md:grid-flow-col justify-between items-start gap-12 md:gap-24"
        >
          <div>
            <h3
              class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl"
            >
              {{ userTeam?.bootcamp?.title ?? " " }}
            </h3>
            <p
              class="font-normal text-[#666666] dark:text-[#F4F4F4] text-sm mt-3 mb-12"
            >
              {{ userTeam?.bootcamp?.description ?? " " }}
            </p>
            <a
              :href="settings?.bootcamp_workspace_link"
              v-if="false"
              target="_blank"
              rel="noopener noreferrer"
              class="font-semibold bg-primary-green text-white rounded-md py-3 px-6 text-sm mt-auto"
            >
              Join Bootcamp Workspace
            </a>
          </div>
          <div
            class="rounded bg-[#F7F7F7] dark:bg-[#0000004D] p-3 md:p-4 font-body space-y-3 w-fit"
          >
            <h4 class="font-semibold text-[#222222] dark:text-[#E5E5E5]">
              This team has:
            </h4>
            <p class="text-[#666666] dark:text-[#E5E5E5] text-xs uppercase">
              <span
                class="font-semibold text-[#222222] dark:text-[#F4F4F4] text-3xl mr-3"
                >{{ userTeam?.count ?? "0" }}</span
              >
              MEMBERS
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const $loading = inject("$loading");
const store = useStore();
const router = useRouter();

const user = computed(() => {
  return store.state.auth.user;
});
const settings = computed(() => {
  return store.state.auth.settings;
});

const userTeam = ref([]);

const fetchUserTeam = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("auth/teams/status/check");
    userTeam.value = data?.payload?.user_team;
    loader.hide();
  } catch (error) {
    loader.hide();
    if (error.response.status == 401) {
      router.push({ name: "LearningDashboardIndex" });
      return;
    }
    console.error(error);
  }
};

onMounted(() => {
  fetchUserTeam();
});
</script>

<style scoped></style>
