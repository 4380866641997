<template>
  <div class="min-h-screen h-full">
    <main class="container font-body pt-3 md:pt-24 lg:pt-12 text-[#3F3E3E]">
      <div
        @click="
          user?.can_access == 1 ? handleRedirect() : $router.push({ name: 'SelectPath' })
        "
      >
        <p
          class="text-left ml-6 font-bold mb-8 md:mb-12 text-sm text-[#52A350] uppercase mt-4 cursor-pointer"
        >
          back
        </p>
      </div>
      <div class="flex flex-col md:gap-x-24 md:flex-row">
        <div class="pl-5">
          <h2 class="text-3xl text-[#0C0C0C] mb-5 font-[500] text-left">
           Commitment Fee
          </h2>
          <div class="flex items-center">
            <p
              v-if="paymentData?.initial_price != paymentData?.amount"
              class="text-[#0C0C0C] mb-10 text-2xl font-semibold relative w-fit px-2 mr-4"
            >
              {{
                ` ${
                  paymentData?.currency == "dollar" ? "&dollar;" : "&#8358;"
                } ${parseNumber(paymentData?.initial_price)}` ?? "₦1,000"
              }}
            </p>
          </div>
          <p class="flex items-center text-sm mb-5">
            <span
              class="iconify text-primary-green mr-3"
              data-icon="akar-icons:check"
            ></span>
            Collaboration with team-mates
          </p>
          <p class="flex items-center text-sm mb-5">
            <span
              class="iconify text-primary-green mr-3"
              data-icon="akar-icons:check"
            ></span>
            Weekly tasks
          </p>
        </div>
        <div
          class="lg:w-2/5 md:w-1/2 shadow-[0px_16px_50px_rgba(37,77,36,0.05)] pb-16 lg:rounded-lg border pt-12 font-normal bg-[#FFFFFF]/[0.92] px-8 lg:m-0 font-body-new"
        >
          <form class="" @submit.prevent="submit()">
            <h2 class="text-[#404040] font-medium mb-5 text-lg text-center">
              Select Payment Method
            </h2>
            <fieldset class="grid mt-3 grid-cols-2 mb-7 gap-6 w-full">
              <div class="radio-check-group" v-if="paymentData?.flutterwave">
                <label for="Flutterwave" class="border-[#D2D2D2] bg-[#F7F7F7]">
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="Flutterwave"
                    value="Flutterwave"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Card
                </label>
              </div>
              <div class="radio-check-group" v-if="paymentData?.bank_transfer">
                <label for="bank" class="order-[#D2D2D2] bg-[#F7F7F7]">
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="bank"
                    value="bank"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Bank Transfer
                </label>
              </div>
              <div class="radio-check-group" v-if="paymentData?.monnify">
                <label for="monnify" class="order-[#D2D2D2] bg-[#F7F7F7]">
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="monnify"
                    value="monnify"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Bank Transfer
                </label>
              </div>
              <div class="radio-check-group" v-if="paymentData?.payPal">
                <label for="payPal" class="order-[#D2D2D2] bg-[#F7F7F7]">
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="payPal"
                    value="payPal"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  PayPal
                </label>
              </div>
            </fieldset>
            <FlutterwaveCheckout
              :data="paymentData?.flutterwave"
              :currency="paymentData?.currency"
              v-if="paymentMethod == 'Flutterwave'"
            />
            <FlutterwaveCheckout
              :data="paymentData?.payPal"
              :currency="paymentData?.currency"
              v-if="paymentMethod == 'payPal'"
            />
            <BankCheckout
              :data="paymentData?.bank_transfer"
              v-if="paymentMethod == 'bank' "
            />
            <MonnifyCheckout
              :data="paymentData?.monnify"
              v-if="paymentMethod == 'monnify'"
            />
          </form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import { integerFormatter } from "@/helpers/customFormatter.js";
import FlutterwaveCheckout from "../payment/checkout-components/FlutterwaveCheckout.vue";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import BankCheckout from "../payment/checkout-components/VirtualCheckout.vue";
import MonnifyCheckout from "../payment/checkout-components/MonnifyCheckout.vue";
import { mapGetters } from "vuex";
// import MintCheckout from "../payment/checkout-components/MintCheckout.vue";
export default {
  data() {
    return {
      paymentMethod: "Flutterwave",
      paymentData: null,
      come: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      baseline: "auth/baseline",
    }),
  },
  components: { FlutterwaveCheckout, BankCheckout, BackButton, MonnifyCheckout },
  methods: {
    parseNumber(num) {
      return integerFormatter(num);
    },
    submit() {
      // console.log("Hiiiii");
    },
    handleRedirect() {
      let page = localStorage.getItem("come");
      this.$router.go(-1);
    },
    async fetchPaymentDetails() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/payments/commitment/fee");
        // console.log(data.payload);
        this.paymentData = data.payload;
        loader.hide();
      } catch (error) {
        if (error?.response?.status == 409) {
          return this.$router.push({ name: "LearningDashboardIndex" });
        }
        loader.hide();
        console.error(error);
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    localStorage.setItem("come", from.fullPath);
    next();
  },
  mounted() {
    this.fetchPaymentDetails();
    if (
      this.user?.can_access == 1
    ) {
      return this.$router.push({ name: "LearningDashboardIndex" });
    };
    if (
      this.user?.pending_commitment_fee && !window.history.state.back == "/dashboard/learn/pay/countdown"
    ) {

      return this.$router.push({ name: "CommitmentFeeCountdown" });
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  fieldset {
    .radio-check-group {
      label {
        @apply font-body font-medium text-[10px] lg:text-xs text-[#3D403D] border border-[#C9C9C9] rounded-[4px] flex items-center px-4 py-5 lg:py-3 w-full text-left cursor-pointer h-full;
      }
    }
  }
}
</style>
