<template>
    <button class="text-xs">
        Can't make payment now? <span @click="auditCourse()"  class="text-primary-green underline font-medium">Explore this course for free</span>
    </button>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from 'vuex'
import swal from 'sweetalert';
export default {    
    methods: {
      ...mapActions({
        attempt: "auth/attempt"
      }),
      async auditCourse(){
        let loader = this.$loading.show();
        try {
          const { data } = await axios.post('v2/auth/audit')
          await this.attempt({}); // re-fetch user auth data 
          loader.hide()
          this.$router.push({ name: "LearningDashboardIndex"});
          return;
        } catch (error) {
          swal({
            title: "Message",
            text: error.response.data.message,
          })
          console.error(error);
          loader.hide()
          return this.$router.push({name: 'SelectBatchPage'});
        }
      }
    }

}
</script>

<style>

</style>