<template>
  <div>
    <table class="table-auto w-full border-separate border-spacing-y-3 font-body">
        <thead>
            <tr>
                <td class="pl-3 bg-primary-blue text-white text-base">
                    {{ "S/N" }}
                </td>
                <td class="pl-3 bg-primary-blue text-white text-base" v-for="(column, _index) in columns" :key="_index">
                    {{ column.title }}
                </td>
            </tr>
        </thead>
        <tbody v-if="data?.length > 0">
            <template v-for="(item, index) in data" :key="index">
                <tr 
                    class="cursor-pointer border  hover:shadow-lg transition ease-linear" 
                    v-if="index >= ((pageNumber - 1) * countPerPage) && index < (pageNumber * countPerPage)"
                >
                    <td class="pl-3">
                        {{ index + 1 }}.
                    </td>
                    <td class="pl-3" v-for="(column, _index) in columns" :key="_index">
                        {{ item[column.key] }}
                    </td>
                </tr>
            </template>
        </tbody>
        <td :colspan="(columns?.length + 1)" v-else class="font-semibold text-sm text-slate-900 text-center">
            No Data Available
        </td>
    </table>
    <div v-if="pages > 0" class="flex items-center gap-x-4 float-right mt-6">
        <button 
            class="flex justify-center gap-x-3 items-center rounded-md py-2 px-4 shadow-lg hover:bg-primary-blue hover:text-white text-sm bg-primary-blue text-white font-semibold disabled:bg-gray-200 border disabled:border-gray-600 disabled:text-gray-600 disabled:cursor-not-allowed "
            :disabled="pageNumber<=pages" 
            @click="pageNumber--"
        >
            <span
            class="iconify inline-block"
            data-icon="teenyicons:arrow-left-solid"
            ></span>
            Back
        </button>
        <button 
            class="flex justify-center gap-x-3 items-center rounded-md py-2 px-4 shadow-lg hover:bg-primary-blue hover:text-white text-sm bg-primary-blue text-white font-semibold disabled:bg-gray-200 border disabled:border-gray-600 disabled:text-gray-600 disabled:cursor-not-allowed"
            :disabled="pageNumber >= pages" 
            @click="pageNumber++"
        >
            Next
            <span
            class="iconify inline-block"
            data-icon="teenyicons:arrow-right-solid"
            ></span>
            
        </button>
       
    </div>
  </div>
</template>

<script>
export default {
    props: ["data", "columns"],
    data(){
        return {
            pageNumber: 1,
            countPerPage: 10,
        }
    },
    computed: {
        pages(){
            if (this.data?.length > 0) {
                return Math.ceil(this.data.length / this.countPerPage);                
            } else {
                return  0;
            }
        }
    }
}
</script>

<style scoped>
table tr td {
  @apply py-2 font-body text-sm font-semibold mb-4 border-y align-top;
}

table tr td:last-child {
  @apply py-2 font-body text-sm font-semibold mb-4 border-r rounded-r;
}
table tr td:first-child{
  @apply py-2 font-body text-sm font-semibold mb-4 border-l rounded-l;
}
table tr {
  @apply border;
}
</style>