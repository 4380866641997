<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center p-4 z-50"
    id="gender"
  >
    <div
      class="flex flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 py-12 space-y-6 md:w-3/5 text-left"
      ref="applicationSuccessModal"
    >
      <h2 class="font-header-1 text-primary-green font-bold text-4xl">Hey!</h2>
      <p class="font-body text-sm font-semibold text-[#616161] dark:text-white">
        You haven’t updated your Gender!
      </p>
      <form @submit.prevent="submitDetails()">
        <fieldset class="grid gap-4 my-8 w-full">
          <select
            class="w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
            name="gender"
            v-model="gender"
          >
            <option selected disabled value="">Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </fieldset>
        <div class="flex items-center justify-end w-full text-sm pt-8">
          <button type="submit" :disabled="isLoading || gender == ''">
            Update Now
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  props: {
    email: String,
  },
  data() {
    return {
      gender: "",
      buttonText: "Update Now",
      isLoading: false,
    };
  },
  methods: {
    closeModal() {
      const box = this.$refs.applicationSuccessModal;
      if (!box?.contains(event.target)) {
        return this.$emit("closemodal");
      }
      return;
    },
    async submitDetails() {
      let loader = this.$loading.show();
      const data = {
        gender: this.gender,
      };
      try {
        const res = await axios.post("auth/update/gender", data);
        if (res.status) {
          document.getElementById("gender").classList.add("active");
          loader.hide();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#gender.active {
  display: none;
}
button {
  @apply text-base lg:text-lg font-semibold text-white bg-primary-green rounded-md  py-4 px-12 w-full;
}

button:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF];
}
</style>
