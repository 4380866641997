<template>
  <div
    class="fixed left-0 top-0 bottom-0 min-h-screen hidden md:block border-r pt-32 w-20 border-[#D7D8D7] bg-white dark:border-[#282828] dark:bg-[#1A1A1A] z-[999]"
    v-if="$route.name != 'LearningDashboardIndex' && $route.name != 'StartWaitPage'"
  >
    <nav class="py-4 side__nav">
      <ul
        class="dark:text-[#4D4D4D] text-[#CBCDCB] text-center font-semibold font-body text-3xl"
      >
        <li
          class="relative"
          :class="[
            $route.name == 'LearningDashboardIndex'
              ? 'border-r-2 border-[#52A350] text-[#52A350]'
              : '',
          ]"
        >
          <router-link class="peer" :to="{ name: 'LearningDashboardIndex' }">
            <span
              class="iconify inline"
              data-icon="ant-design:home-filled"
            ></span>
          </router-link>
          <span
            class="hidden z-[99999] peer-hover:block absolute top-1/2 -translate-y-1/2 font-body whitespace-nowrap translate-x-[4rem] text-xs font-semibold text-center text-[#3F473F] bg-white dark:bg-[#282828] dark:text-[#E5E5E5] rounded px-4 py-3 shadow-lg"
            >Dashboard</span
          >
        </li>
        <li
          class="relative"
          :class="[
            lessonPagesArray.includes($route.name)
              ? 'border-r-2 border-[#52A350] text-[#52A350] bg-[#52a35010]'
              : '',
          ]"
        >
          <router-link class="peer" :to="{ name: 'LessonPage' }">
            <span
              class="iconify inline"
              data-icon="material-symbols:play-lesson-rounded"
            ></span>
          </router-link>
          <span
            class="hidden z-[99999] peer-hover:block absolute top-1/2 -translate-y-1/2 font-body whitespace-nowrap translate-x-[4rem] text-xs font-semibold text-center text-[#3F473F] bg-white dark:bg-[#282828] dark:text-[#E5E5E5] rounded px-4 py-3 shadow-lg"
            >Lessons</span
          >
        </li>
        <li
          class="relative"
          :class="[
            $route.name == 'AssessmentHistory'
              ? 'border-r-2 border-[#8B46FF] text-[#8B46FF] bg-[#8a46ff10]'
              : '',
          ]"
        >
          <router-link class="peer" :to="{ name: 'AssessmentHistory' }">
            <span class="iconify inline" data-icon="ic:round-assessment"></span>
          </router-link>
          <span
            class="hidden z-[99999] peer-hover:block absolute top-1/2 -translate-y-1/2 font-body whitespace-nowrap translate-x-[4rem] text-xs font-semibold text-center text-[#3F473F] bg-white dark:bg-[#282828] dark:text-[#E5E5E5] rounded px-4 py-3 shadow-lg"
            >Assessment History</span
          >
        </li>
        <li
          class="relative"
          :class="[
            $route.name == 'LiveClassesPage'
              ? 'border-r-2 border-[#20C933] text-[#20C933] bg-[#20C93310]'
              : '',
          ]"
          v-if="user?.cohort >= 8"
        >
          <router-link class="peer" :to="{ name: 'LiveClassesPage' }">
            <span class="iconify inline" data-icon="subway:video"></span>
          </router-link>
          <span
            class="hidden z-[99999] peer-hover:block absolute top-1/2 -translate-y-1/2 font-body whitespace-nowrap translate-x-[4rem] text-xs font-semibold text-center text-[#3F473F] bg-white dark:bg-[#282828] dark:text-[#E5E5E5] rounded px-4 py-3 shadow-lg"
            >Live Class Recordings</span
          >
        </li>
        <li
          class="relative"
          :class="[
            $route.name == 'ChoosePayment' ||
            $route.name == 'SubPayment' ||
            $route.name == 'Checkout'
              ? 'border-r-2 border-[#FB8036] text-[#FB8036] bg-[#FB803610]'
              : '',
          ]"
          v-if="user?.batch?.batch_id < settings?.current_batch || 
          ( user?.batch?.batch_id >= settings?.current_batch 
            && user?.batch?.learning_type == 'instructor' 
            && user?.access_weeks < 6 ) "
        >
          <router-link class="peer" :to="{ name: user?.batch?.batch_id < settings?.current_batch ?  'ChoosePayment' : 'ApplicationPayment', params: { slug: 'hands-on' } }">
            <span
              class="iconify inline"
              data-icon="fluent:payment-32-filled"
            ></span>
          </router-link>
          <span
            class="hidden z-[99999] peer-hover:block absolute top-1/2 -translate-y-1/2 font-body whitespace-nowrap translate-x-[4rem] text-xs font-semibold text-center text-[#3F473F] bg-white dark:bg-[#282828] dark:text-[#E5E5E5] rounded px-4 py-3 shadow-lg"
            >Payment</span
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

export default {
  computed: {
    ...mapGetters({
      settings: "auth/settings",
      user: "auth/user",
    }),
    batchStarted() {
      if (this.user?.batch?.start_on) {
        const user = this.user;
        const batch = user.batch;
        const batchStartDate = new Date(batch.start_on);
        const CURRENT_TIME = new Date();
        if (CURRENT_TIME >= batchStartDate) return true;
        else return false;
      } else return false;
    },
  },
  data() {
    return {
      lessonPagesArray: [
        "LessonsContainer",
        "LessonPage",
        "TakeQuizPage",
        "QuizInstructionPage",
        "QuizPreviewPage",
      ],
    };
  },
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
  },
};
</script>

<style scoped>
.side__nav li {
  @apply p-4 cursor-pointer;
}
.active__nav {
  @apply border-r-2 border-[#52A350] text-[#52A350];
}
</style>
