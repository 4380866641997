<template>
  <footer class="bg-[#1A1F1A] font-body-new text-white">
    <Subscribe />
    <main class="pb-8 grid md:grid-cols-5 gap-12 container">
      <section class="font-body md:col-span-3 text-left">
        <div class="flex mb-5 items-center">
          <img
            class="h-16 w-16 mr-3"
            src="@/assets/img/logo/terra.svg"
            alt="Terra Logo"
          />
          <p class="font-bold">Building Africa’s workforce</p>
        </div>
        <p class="mb-16 text-white/[0.58] text-sm lg:w-1/2">
          To achieve the future that we dream of, we must equip ourselves with
          the required skills to put us on the radar of employers all over the
          world
        </p>

        <p class="flex items-center w-fit text-[#52A350]">
          <a
            href="mailto:hello@sidehustle.ng"
            class="border-b border-b-[#52A350] border-b-1 font-bold mr-1 text-xl"
          >
            CONTACT US
          </a>
          <span class="iconify text-2xl" data-icon="bi:arrow-up-right"></span>
        </p>

        <!-- <img
                    src="@/assets/img/logo/side-hustle-logo.svg"
                    alt="Side Hustle Internship"
                /> -->
        <ul class="my-4 leading-8 text-xs">
                    <li>Get in touch</li>
                    <li>
                        <a href="tel:+234-907-957-7366">+234-907-957-7366</a>
                    </li>
                    <li>
                        <a href="mailto:hello@sidehustle.ng"
                            >hello@sidehustle.ng</a
                        >
                    </li>
                    <li>
                      <address>Olorunda abaa, Akobo, Ibadan, NG</address>
                    </li>
                </ul>
                 <div class="col-span-2 hidden md:block lg:hidden">
                    <h3 class="uppercase font-semibold mb-4">
                        CONNECT WITH US
                    </h3>
                    <ul class="list-style-none flex space-x-6">
                        <a
                            href="https://www.facebook.com/TerraLearningHQ"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-[2.8rem] text-primary-green"
                                data-icon="akar-icons:facebook-fill"
                            ></span>
                        </a>
                        <a
                            href="https://www.linkedin.com/company/terra-learning"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-5xl text-primary-green"
                                data-icon="entypo-social:linkedin-with-circle"
                            ></span>
                        </a>
                        <a
                            href="https://twitter.com/terralearning"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-5xl text-primary-green"
                                data-icon="ant-design:twitter-circle-filled"
                            ></span>
                        </a>
                        <a
                            href="https://www.instagram.com/terralearning/"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-5xl text-primary-green"
                                data-icon="entypo-social:instagram-with-circle"
                            ></span>
                        </a>
                    </ul>
                </div>
      </section>
      <section class="md:col-span-2 pr-10 grid grid-cols-4 gap-8 text-left">
        <div class="col-span-2 md:justify-self-center">
          <h3 class="uppercase font-semibold mb-4">Quick Links</h3>
          <ul class="font-body leading-8 text-xs">
            <li class="hidden">Courses</li>
            <router-link :to="{ name: 'FrequentlyQuestions' }">
              <li class="">FAQ</li>
            </router-link>
            <router-link :to="{ name: 'BlogPage' }">
              <li class="">Blog</li>
            </router-link>
            <li v-if="false">
              <a
                href="https://toptalent.africa/"
                target="_blank"
                rel="noopener noreferrer"
                >Hire a Talent</a
              >
            </li>
          </ul>
        </div>
        <div class="col-span-2 justify-self-center">
          <h3 class="uppercase font-semibold mb-4">Company</h3>
          <ul class="leading-8 font-body text-xs">
            <li class="hidden">About Us</li>
            <li class="hidden">Careers</li>
            <li>
              <router-link :to="{ name: 'Privacy' }">
                Privacy Policy
              </router-link>
            </li>
            <li class="">
              <router-link :to="{ name: 'TermsAndConditions' }">
                Terms of Use
              </router-link>
            </li>
          </ul>
        </div>
        <!-- <div
                    class="col-span-2 block md:hidden lg:block place-self-start lg:justify-self-end"
                >
                    <h3 class="uppercase font-semibold mb-4">
                        CONNECT WITH US
                    </h3>
                    <ul class="list-style-none grid grid-cols-2 gap-y-5">
                        <a
                            href="https://web.facebook.com/SideHustleNigeria"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-[2.8rem] text-primary-green"
                                data-icon="akar-icons:facebook-fill"
                            ></span>
                        </a>
                        <a
                            href="https://www.linkedin.com/company/sidehustleng/"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-5xl text-primary-green"
                                data-icon="entypo-social:linkedin-with-circle"
                            ></span>
                        </a>
                        <a
                            href="https://twitter.com/NgSidehustle"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-5xl text-primary-green"
                                data-icon="ant-design:twitter-circle-filled"
                            ></span>
                        </a>
                        <a
                            href="https://www.instagram.com/sidehustleinternship"
                            target="_blank"
                        >
                            <span
                                class="iconify mx-0 text-5xl text-primary-green"
                                data-icon="entypo-social:instagram-with-circle"
                            ></span>
                        </a>
                    </ul>
                </div> -->
      </section>
    </main>
    <aside class="grid gap-8 grid-flow-row md:grid-flow-col py-8 container">
      <p class="font-body text-xs text-[#6F6F90]">
        © Copyright. All Rights Reserved Terra Holding Limited
      </p>
    </aside>
  </footer>
</template>

<script>
import Subscribe from "@/components/landing/SubscribeSection.vue";
export default {
  components: {
    Subscribe,
  },
};
</script>
