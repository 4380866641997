<template>
  <div
    class="bg-white text-[#404040] font-body-new -mb-8 pb-8 min-h-screen h-full"
  >
    <main class="container flex text-[#1A1F1A] w-full md:w-5/6 lg:w-3/4 mx-auto justify-center">
      <div class="space-y-6">
        <div class="flex items-start">
          <h2 class="font-bold text-lg mr-5">Pay commitment fee  now</h2>
          <div class="text-primary-green h-fit rounded-full px-3 py-0.5 text-[9px] font-semibold bg-[#F8FCF8] border border-[#B5DAB4] whitespace-nowrap">Balance up later</div>
        </div>
        <p class="text-xs font-[400] text-justify">While our program is Tuition Free, there is a weekly commitment fee of {{ user?.country_id == 156 ? "N2,000" : "$10" }} for 6 weeks. This fee helps to cover the costs of administering the program and providing a certificate of completion.</p>

        <p class="text-xs font-[400] text-justify">Paying the commitment fee shows your commitment to the program  and that you are willing to invest in the outcome. You will also get a FREE certificate of completion from our program can demonstrate your commitment to learning and can be a valuable asset in your job search.</p>
        
        <p class="text-xs font-[400] text-justify">In addition to the commitment fee being affordable, you can decide to make a deposit of only {{ user?.country_id == 156 ? "N2,000" : "$10" }} for access to the program immediately and balance up later.</p>
        <div class="flex items-center">
          <p class="text-[11px] font-semibold">Note: Commitment fee of {{ user?.country_id == 156 ? "N2,000" : "$10" }}  is to be paid weekly for 6 weeks</p>
          <div @click="showFeeModal = true" v-if="false" class="h-fit shrink-0 rounded-full px-3 py-0.5 text-[9px] cursor-pointer font-semibold bg-[#F2F2F2] border border-[#F2F2F2] flex items-center ">See more about certificates  <span class="iconify ml-1" data-icon="material-symbols:add-circle-outline-rounded"></span> </div>
        </div>
        <div class="p-7 bg-[#F8FCF8] border border-[#B5DAB4] rounded flex flex-col space-y-4 md:space-y-0 md:flex-row md:items-center justify-between">
          <div>
            <p class="text-xs font-bold">Commitment fee (Week 1)</p>
            <h1 class="text-2xl font-body-new font-bold">{{ user?.country_id == 156 ? "N2,000" : "$10" }}</h1>
          </div>
          <div class="flex flex-col space-y-4 items-end">
            <div class="flex space-x-4 items-center justify-start md:justify-end">
              <button @click="proceedToPayment('part')" class="bg-primary-green cursor-pointer text-xs rounded text-[#FFFFFF] py-3 px-5 transition-colors ease-linear duration-200">Pay commitment fee</button>
              <button @click="proceedToPayment('full')" class="bg-[#ECF2FE] outline outline-1 outline-primary-green cursor-pointer text-xs rounded text-primary-green py-3 px-5 transition-colors ease-linear duration-200">Make full payment</button>
            </div>
            <Auditing />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Auditing from "./AuditCourse.vue";

export default {
  components: {
    Auditing
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods:{
    proceedToPayment(type){
      this.$router.push({ 
        name: 'ApplicationPayment', 
        params: { slug: this.$route.params.slug },
        query: {
          type
        }
      })
    }
  }

}
</script>

<style>

</style>