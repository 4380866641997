
import BootcampLayout from "@/views/bootcamp/BootcampLayout.vue";
import SelectType from "@/views/bootcamp/SelectType.vue";
import WaitPage from "@/views/bootcamp/WaitPage.vue";
import SelectBootcampProject from "@/views/bootcamp/dashboard/SelectProject.vue";
import ViewBootcampProject from "@/views/bootcamp/dashboard/ProjectDetails.vue";
import BootcampWelcome from "@/views/bootcamp/dashboard/Welcome.vue";

const bootcampRoutes = [
  {
    path: "/bootcamp",
    meta: {
      requireAuth: true,
      hasBootcampGuard: true,
      title: "Home | Terra Bootcamp",
    },
    name: "BootcampLayout",
    component: BootcampLayout,
    children: [
        {
            path: "welcome",
            name: 'BootcampWelcome',
            component: BootcampWelcome,
            meta: {
                title: "Welcome | Terra Bootcamp",
            },
        },
        {
            path: "select",
            name: 'SelectBootcampType',
            component: SelectType,
            meta: {
                title: "Select Bootcamp Type | Terra Bootcamp",
            },
        },
        {
            path: "wait",
            name: 'BootcampWaitPage',
            component: WaitPage,
            meta: {
                title: "Welcome | Terra Bootcamp",
            },
        },
        {
            path: "team/join",
            name: 'SelectBootcampProject',
            component: SelectBootcampProject,
            meta: {
              title: "Select Startup Project | Terra Bootcamp",
            },
        },
        {
            path: "team/join/:id",
            name: 'ViewBootcampProject',
            component: ViewBootcampProject,
            meta: {
              title: "Select Startup Project | Terra Bootcamp",
            },
        }
    ]
    
  },
];

export default bootcampRoutes;
