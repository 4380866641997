<template>
  <div
    class="dark:bg-[#333333] bg-white border-b-2 border-[#D6D6D6] w-full md:hidden fixed top-0 left-0 right-0"
  >
    <div
      class="container px-4 py-3 md:py-4 transition-all ease-linear duration-1000"
    >
      <nav class="grid grid-cols-2 gap-4 justify-between font-body-new">
        <h1
          v-if="!openNav"
          class="font-header-1 font-semibold text-xl text-body-text dark:text-[#e6e6e6] whitespace-nowrap flex items-center"
        >
          {{ $route?.meta?.sub_title ?? "Dashboard" }}
        </h1>
        <div v-else class="flex flex-row items-center justify-start">
          <img
            src="@/assets/img/logo/side-hustle-logo.svg"
            alt="Side Hustle Logo"
            class="max-h-8"
          />
        </div>
        <div class="flex justify-end items-center">
          <Notification />
          <i
            @click="openNav = !openNav"
            :class="[openNav ? 'fa-close' : 'fa-bars']"
            class="dark:text-primary-green fa-solid text-[#0C0C0C] text-lg ml-12 md:ml-8"
          ></i>
        </div>
      </nav>
      <div
        v-if="openNav"
        class="container p-0 font-body-new space-y-6 pt-8 h-screen overflow-y-scroll"
      >
        <div class="flex items-center space-x-2">
          <button
            class="w-10 h-10 rounded-full bg-[#99C7FA] font-medium overflow-clip"
          >
            <Image :src="user?.avatar_new" />
          </button>
          <div
            class="font-body-new text-left text-body-text dark:text-[#e6e6e6]"
          >
            <h3 class="font-bold text-[14px]">
              {{ `${user?.first_name} ${user?.last_name}` }}
            </h3>
            <p class="text-[11px] font-medium">{{ user?.user_id ?? " " }}</p>
          </div>
        </div>
        <h3 class="text-sm py-2 text-body-text dark:text-[#e6e6e6]">
          {{ `${user?.email}` }}
        </h3>
        <template v-if="user?.batch?.can_access == 1">
          <nav
            class="border-b border-[#D9D9D9] py-4 pb-12 dash_main_nav_items"
            v-if="$route.meta.hasLearningGuard == true"
          >
            <ul
              class="text-[#646D64] dark:text-[#e6e6e6] space-y-8 font-semibold font-body-new text-lg"
            >
              <li v-if="$route.name != 'LearningDashboardIndex'">
                <router-link :to="{ name: 'LearningDashboardIndex' }"
                  ><span
                    class="iconify inline mr-4"
                    data-icon="ic:round-dashboard"
                  ></span
                  >Dashboard</router-link
                >
              </li>
              <li v-if="$route.name != 'StartWaitPage'">
                <router-link :to="{ name: 'LessonPage' }"
                  ><span
                    class="iconify inline mr-4"
                    data-icon="material-symbols:play-lesson-rounded"
                  ></span
                  >Lessons</router-link
                >
              </li>
              <li v-if="$route.name != 'StartWaitPage'">
                <router-link :to="{ name: 'AssessmentHistory' }"
                  ><span
                    class="iconify inline mr-4"
                    data-icon="ic:round-assessment"
                  ></span
                  >Assessment History</router-link
                >
              </li>
            </ul>
          </nav>
        </template>
        <div class="pt-4">
          <ul
            class="font-medium text-base space-y-6 text-[#646D64] dark:text-[#e6e6e6]"
          >
            <template v-if="showFullNav">
              <li @click="navigate('ProfilePage')" class="cursor-pointer">
                Profile
              </li>
              <li
                @click="navigate('AllCertificatesPage')"
                class="cursor-pointer"
              >
                Certificates
              </li>
              <li
                @click="navigate('PeigesIndex')"
                class="cursor-pointer"
              >
                Portfolio
              </li>
              <li
                @click="$router.push({ name: 'SelectBatchPage' })"
                class="cursor-pointer flex justify-between items-center relative"
              >
                Course
                <span
                  class="iconify text-[#999999] z-0 inline text-lg relative"
                  data-icon="ant-design:caret-right-outlined"
                ></span>
              </li>
            </template>
            <li
              @click="logoutUser"
              class="cursor-pointer font-medium text-[#F91717] font-body-new-new"
            >
              Log Out
            </li>
          </ul>
          <div class="relative">
            <a
              href="https://wa.me/2347042164117"
              target="_blank"
              class="text-sm flex items-center text-[#9E9E9E] font-medium mt-16 ml-auto w-fit relative"
            >
              <span
                class="iconify text-[#999999] inline text-2xl"
                data-icon="heroicons:question-mark-circle-20-solid"
              ></span>
              HELP
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Notification from "@/components/lms/NotificationComponent.vue";
import Image from "./Image.vue";
import ProfileDropDown from "@/components/lms/ProfileDropDownComponent.vue";
import theme from "@/helpers/themeWatcher";
import {
  Switch,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import axios from "axios";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      userCourse: "auth/userCourse",
      baseline: "auth/baseline",
      prevBatches: "auth/prevBatches",
    }),
    showFullNav() {
      return true;
    },
  },
  components: {
    Notification,
    ProfileDropDown,
    Image,
    Switch,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    VueCountdown,
  },
  data() {
    return {
      openNav: false,
      darkThemeEnabled: false,
      previousBatches: null,
      switchNewBatch: null,
      feeCountdownEnd: true,
    };
  },
  watch: {
    $route() {
      this.openNav = false;
    },
    darkThemeEnabled() {
      if (this.darkThemeEnabled == true) {
        this.changeTheme("dark");
      } else {
        this.changeTheme("light");
      }
    },
    switchNewBatch() {
      this.switchBatch();
    },
  },
  methods: {
    ...mapActions({
      setTheme: "auth/switchTheme",
      logout: "auth/signOut",
      attempt: "auth/attempt",
    }),
    /**
     * Logout
     */
    logoutUser() {
      swal({
        title: "Logout",
        text: "Are you sure you want to logout?",
        buttons: true,
      }).then((result) => {
        if (result) {
          this.logout();
          this.$router.push({ name: "LoginPage" });
        }
      });
    },
    navigate(value) {
      this.openNav = false;
      this.$router.push({ name: value });
    },
    /**
     * Switch User Dashoard Theme
     * @param { theme } Boolean
     */
    changeTheme(mode) {
      theme(mode);
    },
    async switchBatch() {
      const loader = this.$loading.show();
      try {
        await axios.post("auth/batch/switch", {
          batch_id: this.switchNewBatch,
        });
        await this.attempt();
        this.$router.push({ name: "LearningDashboardIndex" });
        localStorage.removeItem("watched");
        location.reload();
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    countDown(time) {
      const now = new Date();
      var date = new Date(time);
      // add a day
      if (date.getDay() == 6) {
        date.setDate(date.getDate() + 3);
      } else if (date.getDay() == 7) {
        date.setDate(date.getDate() + 2);
      } else {
        date.setDate(date.getDate() + 1);
      }
      const update_at = new Date(date);
      return update_at - now;
    },
  },
  mounted() {
    const theme = localStorage.getItem("theme");
    if (theme == "dark") {
      this.darkThemeEnabled = true;
    }
  },
};
</script>

<style scoped>
* {
  @apply transition-colors ease-linear;
}

.iconify {
  transform:  translate(-4px,1.4px);
}

@media (display-mode: standalone) {
  .dash_main_nav_items {
    @apply !hidden;
  }
}
</style>
