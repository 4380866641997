<template>
  <main>
    <div class="font-body-new">
      <p
        class="text-[#404040] text-sm font-medium"
        v-if="data?.transaction_charge_amount > 0"
      >
        Kindly note that a service fee of 1.4% + 7.5% VAT will be charged on your payment
        when you’re redirected to complete your payment.
      </p>
      <div
        class="grid grid-flow-row gap-4 px-6 mt-7 shadow-[0px_16px_50px_rgba(37,77,36,0.05)] bg-[#F8FCF8] border border-[#B5DAB4] rounded-md pt-10 pb-4"
        v-if="false"
      >
        <p class="flex items-end justify-between">
          <span>Subtotal</span>
          <span class="font-bold"
            ><span class="font-sans">{{
              currency == "dollar" ? "&dollar;" : "&#8358;"
            }}</span
            >{{ integerFormatter(data?.amount) ?? "00.00" }}</span
          >
        </p>
        <p class="flex items-end justify-between">
          <span>Service Charge</span>
          <span class="font-bold"
            ><span class="font-sans">{{
              currency == "dollar" ? "&dollar;" : "&#8358;"
            }}</span
            >{{
              integerFormatter(data?.transaction_charge_amount) ?? "00.00"
            }}</span
          >
        </p>
        <p
          class="flex items-end justify-between border-t-[#BBDDBB] pt-2 border-t-[0.3px] font-bold"
        >
          Total
          <span class="text-primary-green"
            ><span class="font-sans">{{
              currency == "dollar" ? "&dollar;" : "&#8358;"
            }}</span
            >{{ integerFormatter(data?.total_amount) ?? "00.00" }}</span
          >
        </p>
      </div>
      <button
        @click="proceedToCheckout()"
        class="bg-primary-green text-[#FFFFFF] rounded-md disabled:text-[#707070] disabled:bg-[#E9E9E9] w-full py-4 px-4 mt-7 font-bold"
      >
        Proceed to Pay
        <span class="font-sans">{{
          currency == "dollar" ? "&dollar;" : "&#8358;"
        }}</span>
        {{ integerFormatter(data?.total_amount) ?? "00.00" }}
      </button>
    </div>
  </main>
</template>

<script setup>
import { integerFormatter } from "@/helpers/customFormatter.js";
</script>

<script>
export default {
  props: ["data", "currency"],
  methods: {
    proceedToCheckout() {
      this.$loading.show();
      localStorage.removeItem("paymentHash");
      return window.open(this.data?.payment_link, "_self");
    },
  },
};
</script>

<style></style>
