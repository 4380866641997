<template>
  <main>
    <h2
      class="font-body-new font-bold text-body-text text-lg leading-6 text-center mb-6"
    >
      <span class="text-[#9B9B9B]">What is the</span> <br />name of your school?
    </h2>
    <form @submit.prevent="submit()" class="mx-auto">
      <fieldset class="grid grid-cols-1 w-full">
        <div class="relative w-full" v-if="true">
          <input
            type="text"
            autocomplete="off"
            name="institution"
            id="institution"
            v-model="school"
            placeholder="Name of your school"
            @keyup="showSearchList = true"
            @keyup.enter="showSearchList = false"
            @blur="closeSearchList"
            class="w-full"
          />
          <div
            class="border absolute bg-white rounded-b w-full py-2 max-h-52 overflow-y-auto transition-all ease-linear"
            v-if="filterSchool && filterSchool.length > 0 && showSearchList"
          >
            <ul v-for="(sch, index) in filterSchool" :key="index">
              <template v-if="index < 25">
                <li
                  class="border-b p-2 cursor-pointer font-medium hover:bg-blue-400 transition-all ease-linear"
                  @click="selectSchool(sch)"
                >
                  {{ sch }}
                </li>
              </template>
            </ul>
          </div>
        </div>
      </fieldset>
      <button type="submit" :disabled="!school">Next</button>
    </form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Institutions from "./institutions.json";
import SimpleTypeahead from "vue3-simple-typeahead";
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css";
export default {
  data() {
    return {
      school: null,
      isLoading: false,
      showSearchList: false,
      Institutions,
    };
  },
  components: { "vue3-simple-typeahead": SimpleTypeahead },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    filterSchool() {
      if (this.Institutions.length > 0) {
        if (this.school?.length > 1) {
          return this.Institutions.filter((institution) => {
            return institution
              .toLowerCase()
              .includes(this.school.toLowerCase());
          });
        } else {
          return;
        }
      } else {
        return;
      }
    },
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    submit() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      user.school = this.school;
      localStorage.setItem("tempUser", JSON.stringify(user));
      this.$emit("next");
    },
    selectSchool(school) {
      this.school = school;
    },
    /**
     * Get list of institutions and store in localStorage
     */
    /**
     * Hide Suggestion list on blur of search input
     */
    closeSearchList() {
      setTimeout(() => {
        this.showSearchList = false;
      }, 700);
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      this.school = JSON.parse(localStorage.getItem("tempUser")).school;
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  @apply flex flex-col items-center;
  input[type="text"] {
    @apply border border-[#D2D2D2] rounded-lg py-4 px-6 outline-0;
  }
  input:active,
  input:focus,
  input:focus-within {
    outline: none;
  }
  button[type="submit"] {
    @apply py-3 px-8 text-white bg-primary-green font-body-new rounded-md ml-auto mt-6 lg:mt-8;
  }
}
</style>
