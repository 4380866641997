<template>
  <main class="p-12">
    <div
      v-if="new Date() < new Date(user?.bootcamp?.batch?.start_on)" 
      class="font-medium font-body text-xs bg-blue-800 text-white rounded py-2 text-center">
      Welcome {{ user?.first_name }}! Bootcamp starts in 
      <Vue-countdown
          class="font-semibold ml-1"
          :time="convertTime(user?.bootcamp?.batch?.start_on)"
          v-slot="{ days }"
          >{{days}} day{{days > 1 ? 's' : ''}}
      </Vue-countdown>
    </div>
    <div class="my-8 grid grid-cols-1 md:grid-cols-2 gap-12 font-body">
      <div class="space-y-12">
        <div
          v-if="new Date() < new Date(user?.bootcamp?.batch?.start_on)"  
          class="bg-[#FCFDFC] border-dashed border-4 border-primary-green rounded text-center py-8 font-semibold text-xl">
          Bootcamp Begins: {{ user?.bootcamp?.batch?.start_on }}
        </div>
        <div class="font-body bg-white shadow-lg border p-8 space-y-4 rounded-lg h-fit">
          <h1 class="font-semibold text-lg">Terra Learning Bootcamp</h1>
          <p class="text-sm text-[#666666]">{{ user?.bootcamp?.team?.bootcamp?.description }}</p>
          <div class="border flex flex-col rounded-md p-4 w-fit gap-y-4">
            <p class="font-semibold text-sm">
              <span class="iconify inline-block text-2xl" data-icon="devicon:slack"></span>
              Bootcamp Slack Workspace
            </p>
            <a :href="settings?.bootcamp_workspace_link"  class="uppercase bg-primary-green text-white py-2 px-4 rounded-md text-xs font-medium text-center cursor-pointer">
              join slack workspace
            </a>
          </div>
        </div>
      </div>
      <div class="font-body bg-white shadow-lg p-8 space-y-6 rounded-lg border" v-if="user?.bootcamp?.team">
        <h2 class="font-semibold text-lg">Your Team - {{user?.bootcamp?.team?.bootcamp?.title}}</h2>
        <template v-if="user?.bootcamp?.type == 'portfolio' && false">
          <p class="text-xs font-semibold text-primary-green">
            Invite your friend's to join your team, and build their portfolio together with you!
          </p>
          <div class="text-xs cursor-pointer w-full flex" @click="copyContent(teamURL);">
            <p class="truncate">  {{ teamURL }} </p> 
            <i class="fa-solid fa-copy text-primary-green"></i>
          </div>
        </template>
        <h3 class="text-[#878787] font-medium text-xs">TEAM MEMBERS</h3>
        <div class="gap-y-4 flex flex-col">
          <div v-for="(member, index) in user.bootcamp?.team?.bootcamp?.members" :key="index" class="flex items-center gap-x-4">
            <img class="rounded-full h-10 w-10" :src="member?.user?.avatar" :alt="member?.user?.first_name">
            <div>
              <p class="font-medium text-sm text-[#222222]">{{`${member?.user?.first_name} ${member?.user?.last_name}`}}</p>
              <p class="text-xs text-[#666666]">{{ `${member?.user?.bootcamp.course.title}` }}</p>
            </div>
            <button 
              v-if="user?.bootcamp?.team?.bootcamp.team_lead == member.user.id" 
              class="px-2 py-1 text-[10px] font-medium bg-primary-green text-white rounded-full"
              >
              Team Lead
            </button>
          </div>
          <button 
            v-if="!user?.bootcamp?.team?.bootcamp.team_lead" 
            @click="showApplicationForm = true"
            class="bg-primary-green text-white py-2 px-4 w-fit rounded text-xs font-medium mt-auto justify-self-end">
            Apply for role of Team Lead
          </button>
          <TeamLeadApplication :team="user?.bootcamp?.team?.bootcamp_id" v-if="showApplicationForm == true" @closemodal="showApplicationForm = false" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { encrypt } from "@/helpers/encrypt.js"
import { mapGetters } from 'vuex'
import VueCountdown from '@chenfengyuan/vue-countdown';
import TeamLeadApplication from "./TeamLeadApplication.vue";

export default {
  data() {
    return {
      showApplicationForm: false,
    };
  },
  components: {
    VueCountdown,
    TeamLeadApplication,
  },
  methods: {
    convertTime(time) {
        const now = new Date().getTime();
        var date = new Date(time);
        date = new Date(date.getTime() + 60*60*1000)
        return date - now;
    },
    async copyContent(text) {
      try {
        await navigator.clipboard.writeText(text);
        console.log('Content copied to clipboard');
        /* Resolved - text copied to clipboard successfully */
      } catch (err) {
        console.error('Failed to copy: ', err);
        /* Rejected - text failed to copy to the clipboard */
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings"
    }),
    teamURL(){
      return `${window.location.origin}?bootcampTeam=${encrypt(this.user?.bootcamp?.team?.bootcamp_id)}`
    }
  },

}
</script>

<style scoped>

</style>