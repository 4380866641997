<template>
  <div class="px-0 font-body bg-[#F8FCF9]">
    <div
      class="flex items-center justify-center w-full h-[100vh]"
      v-if="questions"
    >
      <div
        v-show="showGameWaiting == true"
        class="flex flex-col lg:w-[30%] w-full h-[80vh] px-5 relative"
      >
        <div class="flex place-content-center mb-7">
          <div v-if="passed">
            <span
              class="iconify text-center text-[64px] text-[#52A350]"
              data-icon="material-symbols:check-circle-rounded"
            ></span>
            <p class="text-[#52A350] text-center text-lg mt-3 font-bold">
              Correct
            </p>
          </div>
          <div v-else>
            <span
              class="iconify text-center text-[64px] text-[#EB2405]"
              data-icon="ph:x-circle-fill"
            ></span>
            <p class="text-[#EB2405] text-center text-lg mt-3 font-bold">
              Incorrect
            </p>
          </div>
        </div>
        <!-- <img src="@/assets/img/tom-and-jerry-tom.gif" alt="tom and jerry" /> -->

        <p class="font-body text-base text-center">
          You gained <strong>{{ currentRoundPoints }} points</strong> points in
          this round
        </p>
        <!-- <div
          class="w-4/5 mx-auto h-4/5 flex items-center justify-center relative"
        > -->
        <!-- <TrexPage :key="level" /> -->
        <!-- </div> -->
        <div class="mt-16 space-y-5">
          <div
            class="bg-white p-3 flex flex-col gap-y-3 rounded-md shadow-[0px_8px_50px_rgba(0,0,0,0.08)]"
          >
            <p class="text-xs mb-3 text-primary-green">CORRECT ANSWER</p>
            <p>{{ correct_answer }}</p>
          </div>
          <div
            class="bg-white p-3 flex flex-col gap-y-3 rounded-md shadow-[0px_8px_50px_rgba(0,0,0,0.08)]"
          >
            <p class="text-xs mb-3 text-[#7B7B7B]">OPTION SELECTED</p>
            <p>{{ selectedOption }}</p>
          </div>
        </div>
        <div
          class="flex w-fit mx-auto mt-auto items-center rounded-full p-3 border border-[#78BC76] bg-[#EEF7EE] text-primary-green uppercase font-body font-medium text-sm"
        >
          <span class="iconify text-lg mr-2" data-icon="mdi:alarm-clock"></span>
          <p>Next Round Begins In</p>
          <Vue-countdown
            v-if="showGameWaiting == true"
            class="font-semibold ml-1"
            @end="nextGame ? filterGame('WAIT') : redirect()"
            :time="convertTime(nextGame?.start_time ?? currentGame?.end_time)"
            v-slot="{ minutes, seconds }"
            >{{ minutes }}m {{ seconds }}s
          </Vue-countdown>
          <button
            v-else
            @click="load"
            class="text-center bg-green-600 text-white px-10 py-2 cursor-pointer rounded-md mt-10"
            :disabled="!timout"
            :key="timout"
          >
            <!-- <img src="" /> -->
            {{ "Next" }}
          </button>
        </div>
        <!-- <button
            @click="load"
            class="text-center bg-green-600 text-white px-10 py-2 cursor-pointer rounded-md mt-10"
            :disabled="!timout"
            :key="timout"
          >
          <img src="" />
            {{ "Next" }}
          </button> -->
      </div>
      <div
        v-if="currentGame && currentGame?.options && showGameWaiting == false"
        id="question-grp"
        class="flex flex-col md:w-[60%] w-full h-[60%] px-5 font-body"
        :key="trigger"
      >
        <div class="flex gap-5 items-center -mt-20" id="prog">
          <div>
            <Vue-countdown
              class="text-[#000000] font-extrabold"
              ref="cd"
              @end="filterGame()"
              :time="convertTime(currentGame?.end_time)"
              v-slot="{ seconds }"
              >{{ seconds }}s</Vue-countdown
            >
          </div>
          <ProgressBar :duration="convertTime(currentGame?.end_time)" />
        </div>
        <div
          class="bg-[#F19534] text-white rounded-2xl w-fit text-center py-2 text-xs px-4 mb-3"
        >
          Round {{ level }}
        </div>
        <p class="text-[#0C180C] font-semibold text-[24px] mt-3 mb-3">
          {{ currentGame?.question }}
        </p>
        <div
          @click="
            handleClick(currentGame?.id, option), (selectedOption = option)
          "
          :id="option"
          v-for="(option, index) in JSON.parse(currentGame?.options)"
          :key="index"
          class="bg-[#fff] mb-3 px-5 py-4 cursor-pointer option rounded shadow-[0px_8px_50px_rgba(0,0,0,0.08)] font-semibold"
          :class="
            answer == option ? 'border border-[#93C992] bg-[#F1F8F1]' : ''
          "
        >
          {{ option }}
        </div>
        <div class="flex items-center justify-between mt-4">
          <div class="text-green-500 text-[14px] font-semibold"></div>
          <button
            @click="handleNext"
            class="text-center bg-green-600 text-white px-10 py-2 cursor-pointer rounded-md"
            :disabled="answer?.length < 1 || !answer"
          >
            {{ level < rounds ? "Next" : "Finish" }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex items-center justify-center w-full h-[100vh] bg-[#fff] font-body"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";
import LearningNavBar from "@/components/lms/LearningNavBar.vue";
import LearningNavBarMobile from "@/components/lms/LearningNavBarMobile.vue";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import Countdown from "./Countdown.vue";
import ProgressBar from "./ProgressBar.vue";
import { ref } from "vue";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Countdown,
    LearningNavBar,
    LearningNavBarMobile,
    ProgressBar,
    VueCountdown,
    Loading,
  },

  data() {
    return {
      countd: null,
      cont: null,
      timout: false,
      duration: 30,
      selectedOption: "",
      correct_answer: "",
      sound: null,
      passed: true,
      disabled: true,
      countdown: 10,
      levelStart: 0,
      answer: "",
      start: 0,
      point: 10,
      score: 0,
      firstLoad: true,
      questions: null,
      end: false,
      timeCont: null,
      winners: null,
      show: null,
      level: 1,
      current_game_id: null,
      rounds: null,
      errorMessage: null,
      currentGame: null,
      nextGame: null,
      showGameWaiting: false,
      currentRoundPoints: 0,
      trigger: Math.random(),
    };
  },
  mounted() {
    this.fetchGame();
    this.cont = this.$refs.cd;
    this.sound = new Audio('https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/games/game.mp3');
    this.sound.loop = true;
    this.sound.volume = 0.2;
    this.sound.play();
  },
  beforeRouteLeave() {
    this.sound?.pause();
  },

  computed: {
    // soundPlay() {
    //   new Audio(sound);
    // },
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
    }),
  },
  watch: {},

  methods: {
    load() {
      this.timout = false;
      return this.$router.push({ name: "GameStart" });
    },
    filterGame(val = "DEFAULT") {
      const picked = JSON.parse(localStorage.getItem("answer"));
      if (this.questions?.length > 0) {
        // this.timout = false;
        let CURRENT_TIME = new Date();
        let curr_game = this.questions?.find((item) => {
          return (
            CURRENT_TIME >= new Date(item?.start_time) &&
            CURRENT_TIME <= new Date(item?.end_time)
          );
        });

        if (curr_game) {
          let i = this.questions.findIndex((item) => {
            return item?.id == curr_game?.id;
          });
          this.level = i + 1;
          this.nextGame = this.questions?.[this.level] ?? false;
          if (val == "DEFAULT") {
            this.duration = 30;
            // check if user has picked answer for question
            if (curr_game.id == picked?.gameId && picked.answer != "") {
              this.showGameWaiting = true;
              this.currentGame = false;
            } else {
              this.showGameWaiting = false;
              return (this.currentGame =
                curr_game ?? this.questions?.[this.level]);
            }
          } else {
            this.showGameWaiting = false;
            this.timout = !this.timout;
            return (this.currentGame =
              curr_game ?? this.questions?.[this.level]);
          }
        } else {
          this.timout = true;
          this.showGameWaiting = true;
          return (this.currentGame = false);
        }
      } else {
        this.timout = true;
        this.currentGame = false;
        return;
      }
    },
    convertTime(time) {
      const now = new Date();
      var date = new Date(time);
      date.setDate(date.getDate());
      const update_at = new Date(date);
      return (this.countd = update_at - now);
    },
    async fetchGame() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/games");
        this.questions = data?.payload?.game;
        this.rounds = data?.payload?.rounds;
        this.filterGame("DEFAULT");
        loader.hide();
      } catch (error) {
        this.$router.push({ name: "GameScore" });
        this.errorMessage = error?.response?.data?.payload ?? "Error!";
        loader.hide();
      }
    },

    removeClass() {
      //select the option group
      const options = document.querySelectorAll(".option");
      //remove active class
      Array.from(options).forEach((option) =>
        option.classList.remove("active")
      );
    },
    redirect() {
      return this.$router.push({ name: "GameEnd" });
    },

    handleClick(game_id, option) {
      this.current_game_id = game_id;
      this.answer = option;
    },

    stopPlay() {
      this.handleNext(this.currentGame?.id, "");
    },
    async handleNext() {
      let loader = this.$loading.show();
      localStorage.setItem(
        "answer",
        JSON.stringify({ gameId: this.current_game_id, answer: this.answer })
      );
      try {
        const { data } = await axios.post("auth/games/save", {
          id: this.currentGame?.id,
          answer: this.answer ?? " ",
        });
        this.currentRoundPoints = data?.payload?.score;
        this.correct_answer = data?.payload?.correct_answer;
        this.passed = this.correct_answer == this.selectedOption;
        this.answer = null;
        this.current_game_id = null;
        this.showGameWaiting = true;
        if (this.level < this.rounds) this.level++;
        loader.hide();
      } catch (error) {
        loader.hide();
        this.answer = null;
        this.current_game_id = null;
        this.showGameWaiting = true;
        if (this.level < this.rounds) this.level++;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

#cont {
  background: linear-gradient(107.93deg, #ecf2fe 0%, #f1f8f1 104.14%);
}

button:disabled {
  background-color: #c3c8c3;
}

#a.active,
#b.active,
#c.active,
#d.active {
  border: 2px solid blue;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

tbody tr {
  margin-bottom: 40px;
  /* background-color: red; */
  /* border: 3px solid #ededed !important; */
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

#prog {
  margin-bottom: 60px;
}

tr > td {
  border: none;
  margin-bottom: 20px;
  padding: 15px 0px;
  margin-bottom: 40px;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: 150ms ease;
}

.bounce-enter-from,
.bounce-leave-to {
  transform: scale(0);
}
</style>
