<template>
  <div
    :class="[$route.name == 'StartWaitPage' || $route.name == 'BootcampWaitPage' ? 'grid-cols-4' : 'grid-cols-3']"
    class="p-4 border border-[#52A350] rounded-xl bg-[#F8FCF8] grid gap-4 w-fit"
  >
    <div
      v-if="($route.name == 'StartWaitPage' || $route.name == 'BootcampWaitPage')"
      class="flex flex-col justify-between items-center md:items-start bg-[linear-gradient(180deg,#E4F2E4_53.05%,#D6EBD6_82.32%);] shadow-[4px_6px_15px_rgba(70,114,69,0.08)] rounded-lg  pl-2 pr-6 lg:px-4 py-2 cursor-pointer"
    >
      <span class="title_string">Days</span>
      <span class="int_value">{{ days >= 0 ? days : "0" }}</span>
    </div>
    <div
      class="flex flex-col justify-between items-center md:items-start rounded-lg bg-[linear-gradient(180deg,#E4F2E4_53.05%,#D6EBD6_82.32%);] shadow-[4px_6px_15px_rgba(70,114,69,0.08)] pl-2 pr-6 lg:px-4 py-2 cursor-pointer"
    >
      <span class="title_string">Hours</span>
      <span class="int_value">{{ hours >= 0 ? hours : "00" }}</span>
    </div>
    <div
      class="flex flex-col justify-between items-center md:items-start rounded-lg bg-[linear-gradient(180deg,#E4F2E4_53.05%,#D6EBD6_82.32%);] shadow-[4px_6px_15px_rgba(70,114,69,0.08)] pl-2 pr-6 lg:px-4 py-2 cursor-pointer"
    >
      <span class="title_string">Minutes</span>
      <span class="int_value">{{ minutes >= 0 ? minutes : "00" }}</span>
    </div>
    <div
      class="flex flex-col justify-between items-center md:items-start rounded-lg bg-[linear-gradient(180deg,#E4F2E4_53.05%,#D6EBD6_82.32%);] shadow-[4px_6px_15px_rgba(70,114,69,0.08)] pl-2 pr-6 lg:px-4 py-2 cursor-pointer"
    >
      <span class="title_string">Seconds</span>
      <span class="int_value">{{ seconds >= 0 ? seconds : "00" }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      now: Number(Math.trunc(new Date().getTime() / 1000)),
    };
  },
  props: {
    date: {
      type: [String, Number],
      default: Number(Math.trunc(new Date().getTime() / 1000)),
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),

    startDate() {
      return new Date(this.date).toUTCString();
    },
    dateInMilliseconds() {
      return Number(Math.trunc(Date.parse(this.startDate) / 1000));
    },
    seconds() {
      return Number((this.dateInMilliseconds - this.now) % 60);
    },
    minutes() {
      return Number(Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60);
    },
    hours() {
      if(this.$route.name == 'StartWaitPage' || this.$route.name == 'BootcampWaitPage'){
        return Number(
        Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24
      );
      }
      return Number(
        Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60)
      );
    },
    days() {
      return Number(
        Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24)
      );
    },
  },
  methods: {
    convertTime(date) {
      const d = new Date(date).toDateString();
      return d;
    },
  },
  mounted() {
    window.setInterval(() => {
      this.now = Number(Math.trunc(new Date().getTime() / 1000));
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.title_string {
  @apply text-[#0E1B0D] text-[6px] font-[nunito] font-semibold tracking-wide;
}
.int_value {
  @apply text-[#418240] font-[GD_Boing] font-bold text-2xl lg:text-3xl tracking-tight;
}
</style>
