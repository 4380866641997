<template>
  <div class="container p-0">
    <div
      v-if="dPost"
      class="pt-4 md:pt-8 rounded border font-body relative mb-8 dark:bg-[#333333] dark:border-[#333333] bg-[#FFFFFF] border-[#EEEEEE]"
    >
      <div class="flex items-center justify-start px-4 md:px-8 w-11/12">
        <img
          v-if="dPost.user.avatar"
          :src="dPost.user.avatar"
          :alt="dPost.user.first_name"
          class="rounded-full h-8 w-8 md:h-10 md:w-10 mr-2"
        />
        <img
          v-else
          src="@/assets/img/img/missing_avatar.png"
          :alt="dPost.user.first_name"
          class="rounded-full h-10 w-10 mr-2"
        />
        <div>
          <p class="text-xs text-[#606060] dark:text-[#F1F1F1]">
            {{ dPost.user.first_name }} {{ dPost.user.last_name }}
            <span
              v-if="
                dPost?.user?.role == 'admin' || dPost?.user?.role == 'mentor'
              "
              class="uppercase font-semibold text-[10px]"
            >
              {{ `( ${dPost.user.role} )` }}
            </span>
          </p>
          <p class="text-[10px] dark:text-[#E0E0E0] text-[#A7A5A5]">
            {{ user.batch?.course?.title }}
            <span class="iconify inline" data-icon="ci:dot-03-m"></span>
            {{ timeFormat(dPost.created_at) }}
          </p>
        </div>
      </div>
      <!-- Post content  -->
      <div class="mb-4 mt-4 md:mt-8 px-4 md:px-8">
        <h2 class="dark:text-[#F0F0F0] text-[#4D4D4D] break-all">
          {{ dPost.post }}
        </h2>
        <!-- Post Comment -->
        <form
          @submit.prevent="makeComment()"
          class="w-full flex items-end justify-between rounded-md p-2 border my-4 dark:border-[#656565] dark:bg-[#262626] dark:text-[#DBDBDB] border-[#777977] text-[#777977] bg-[#FFFFFF]"
        >
          <span
            class="bg-transparent text-sm min-h-[1rem] max-w-[95%] grow border-none focus:ring-0 focus:outline-none focus:border-none break-all"
            role="textbox"
            @input="onInput"
            contenteditable
            ref="commentInput"
          >
          </span>
          <button type="submit">
            <span class="iconify" data-icon="carbon:send-filled"></span>
          </button>
        </form>
        <!-- Post Comment -->
        <!-- Comments -->
        <template v-if="dPost.comment.length > 0">
          <template v-for="(comment, index) in dPost.comment" :key="comment.id">
            <Comments v-if="index < commentsToShow" :comment="comment" />
          </template>
        </template>
        <!-- Comments -->
      </div>
      <!-- Post content  -->
      <!-- See more comments -->
      <button
        v-if="dPost.comment.length > commentsToShow"
        class="dark:text-[#747272] dark:bg-[#191919] dark:border-[#0000001A] w-full border-t rounded-b text-xs py-2 text-[#747272] bg-[#FAFAFA] border-[#0000001A]"
        @click="commentsToShow += 5"
      >
        See more comments
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Comments from "./CommentComponent.vue";
import axios from "axios";
import swal from "sweetalert";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      comment: "",
      dPost: null,
      commentsToShow: 2,
    };
  },
  components: {
    Comments,
  },
  methods: {
    timeFormat(date) {
      return moment(date).format("M/D/YYYY h:mma");
    },
    onInput(e) {
      this.comment = e.target.innerText;
    },
    async makeComment() {
      if (this.comment == "") {
        swal({
          title: "Oops!",
          text: "Comment cannot be empty",
        });
        return;
      }
      try {
        let loader = this.$loading.show();
        const data = {
          comment: this.comment,
          post_id: this.post.id,
        };
        axios
          .post("auth/forum/comment", data)
          .then((res) => {
            loader.hide();
            this.dPost = res.data.payload;
            this.comment = "";
            this.$refs.commentInput.innerText = "";
          })
          .catch(() => {
            loader.hide();
          });
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    },
    // closePost() {
    //     this.$emit("closepost");
    // },
  },
  mounted() {
    this.dPost = this.post;
  },
  props: ["post"],
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
<style></style>
