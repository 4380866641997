<template>
  <div class="">
    <div>
      <LearningNavBar class="z-50" />
      <div class="relative">
        <LearningNavBarMobile class="z-50" />
      </div>
    </div>
    <div
      class="bg-gradient-to-l from-[#ECF2FE] to-[#F1F8F1] w-full font-light pt-20 font-body-new lg:px-28 md:pt-28 lg:pt-24 min-h-screen"
    >
      <router-link :to="{ name: 'ChoosePayment' }">
        <p
          class="w-full flex items-center font-bold text-left mb-12 text-sm text-[#52A350] uppercase my-4 md:my-8 px-4 md:px-0"
        >
          <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span> back
        </p></router-link
      >
      <div class="flex flex-col lg:flex-row">
        <div
          class="lg:basis-1/3 lg:text-left text-center mb-4"
          :class="pack ? '' : 'invisible'"
        >
          <h1 class="text-2xl capitalize text-[#0C0C0C]">
            {{
              `${pack?.level} ${
                pack?.type == "certificate" ? "Certificate" : ""
              }` ?? ""
            }}
          </h1>
          <p class="font-body-new text-2xl font-normal mb-8">
            <span class="font-sans">{{
              pack?.currency == "naira" ? "&#8358;" : "$"
            }}</span>
            {{ pack?.price ?? 0.0 }}
          </p>
          <h2 class="mb-4">You’d get</h2>
          <ul
            v-if="pack?.description"
            class="text-sm space-y-2 lg:space-y-6 text-left w-fit mx-auto md:mx-0"
          >
            <li class="" v-for="(item, index) in pack.description" :key="index">
              <span
                class="iconify text-[#52A350] inline font-extrabold"
                data-icon="akar-icons:check"
              ></span>
              {{ item }}
            </li>
          </ul>
        </div>
        <div
          class="lg:basis-1/3 shadow-[0px_16px_50px_rgba(37,77,36,0.05)] pb-16 lg:rounded-md border font-normal bg-[#FFFFFF]/[0.92] px-8 lg:m-0 font-body-new pt-12"
        >
          <router-link :to="{ name: 'ChoosePayment' }" v-if="false">
            <p
              class="w-full flex items-center invisible lg:visible font-bold text-left mb-12 text-sm text-[#52A350] uppercase mt-4 mr-[-10px]"
            >
              <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span>
              back
            </p></router-link
          >
          <form class="" @submit.prevent="submit()">
            <h2 class="text-[#404040] font-medium mb-5 text-lg text-center">
              Select Payment Plan
            </h2>
            <!-- full or partial payment inputs -->
            <fieldset class="grid grid-cols-2 items-stretch mb-10 gap-6">
              <div class="radio-check-group">
                <label
                  for="fullPayment"
                  :class="[
                    paymentType == 'fullPayment'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                  class=""
                >
                  <input
                    type="radio"
                    name="paymentType"
                    id="fullPayment"
                    value="fullPayment"
                    v-model="paymentType"
                    class="text-green-600 focus:ring-primary-green peer focus:ring-2 mr-3"
                    checked
                  />
                  <span>Full Payment</span>
                </label>
              </div>

              <div class="radio-check-group" v-if="settings?.promo_sales == 0 && false">
                <label
                  for="partPayment"
                  :class="[
                    paymentType == 'partPayment'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                  class=""
                >
                  <input
                    type="radio"
                    name="paymentType"
                    id="partPayment"
                    value="partPayment"
                    v-model="paymentType"
                    class="text-green-600 focus:ring-primary-green peer focus:ring-2 mr-3"
                  />
                  Installment Payment
                </label>
              </div>
            </fieldset>
            <div v-if="paymentType == 'partPayment'">
              <h3 class="font-medium">Select amount</h3>
              <fieldset
                class="grid mt-3 grid-cols-3 mb-10 gap-4"
                v-if="user?.country_id == 156"
              >
                <div class="radio-check-group">
                  <label
                    for="25"
                    class=""
                    :class="[
                      installmentplan == '25'
                        ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                        : `border-[#D2D2D2] bg-[#F7F7F7]`,
                    ]"
                  >
                    <input
                      type="radio"
                      name="installmentPlan"
                      id="25"
                      value="25"
                      v-model="installmentPlan"
                      class="text-[#D9D9D9] checked:text-[#52A350] checked:bg-opacity-0 checked:outline-offset-0 mr-3 focus:outline-hidden"
                    />
                    25%
                  </label>
                </div>
                <div class="radio-check-group">
                  <label
                    for="50"
                    class=""
                    :class="[
                      installmentplan == '50'
                        ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                        : `border-[#D2D2D2] bg-[#F7F7F7]`,
                    ]"
                  >
                    <input
                      type="radio"
                      name="installmentPlan"
                      id="50"
                      value="50"
                      v-model="installmentPlan"
                      class="text-[#D9D9D9] checked:text-[#52A350] checked:bg-opacity-0 checked:outline-offset-0 mr-3 focus:outline-hidden"
                    />
                    50%
                  </label>
                </div>
                <div class="radio-check-group">
                  <label
                    for="75"
                    :class="[
                      installmentplan == '75'
                        ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                        : `border-[#D2D2D2] bg-[#F7F7F7]`,
                    ]"
                    class=""
                  >
                    <input
                      type="radio"
                      name="installmentPlan"
                      id="75"
                      value="75"
                      v-model="installmentPlan"
                      class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                    />
                    75%
                  </label>
                </div>
              </fieldset>
              <fieldset
                class="grid mt-3 grid-cols-3 mb-10 gap-4"
                v-if="user?.country_id != 156 && pack?.currency == 'naira'"
              >
                <template v-if="pack.type == 'certificate'">
                  <template
                    v-for="(part, index) in certificatePartPaymentNaira"
                    :key="index"
                  >
                    <div class="radio-check-group" v-if="part < pack?.price">
                      <label
                        :for="part"
                        class=""
                        :class="[
                          installmentAmount == part
                            ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                            : `border-[#D2D2D2] bg-[#F7F7F7]`,
                        ]"
                      >
                        <input
                          type="radio"
                          name="installmentAmount"
                          :id="part"
                          :value="part"
                          v-model="installmentAmount"
                          class="text-[#D9D9D9] checked:text-[#52A350] checked:bg-opacity-0 checked:outline-offset-0 mr-3 focus:outline-hidden"
                        />
                        <span class="font-sans">&#8358;</span>{{ part }}
                      </label>
                    </div>
                  </template>
                </template>
                <template v-if="pack.type == 'bootcamp'">
                  <template
                    v-for="(part, index) in bootcampPartPaymentNaira"
                    :key="index"
                  >
                    <div class="radio-check-group" v-if="part < pack?.price">
                      <label
                        :for="part"
                        class=""
                        :class="[
                          installmentAmount == part
                            ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                            : `border-[#D2D2D2] bg-[#F7F7F7]`,
                        ]"
                      >
                        <input
                          type="radio"
                          name="installmentAmount"
                          :id="part"
                          :value="part"
                          v-model="installmentAmount"
                          class="text-[#D9D9D9] checked:text-[#52A350] checked:bg-opacity-0 checked:outline-offset-0 mr-3 focus:outline-hidden"
                        />
                        <span class="font-sans">&#8358;</span>{{ part }}
                      </label>
                    </div>
                  </template>
                </template>
              </fieldset>
              <fieldset
                class="grid mt-3 grid-cols-3 mb-10 gap-4"
                v-if="user?.country_id != 156 && pack?.currency == 'dollar'"
              >
                <template v-if="pack.type == 'certificate'">
                  <template
                    v-for="(part, index) in certificatePartPaymentDollar"
                    :key="index"
                  >
                    <div class="radio-check-group" v-if="part < pack?.price">
                      <label
                        :for="part"
                        class=""
                        :class="[
                          installmentAmount == part
                            ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                            : `border-[#D2D2D2] bg-[#F7F7F7]`,
                        ]"
                      >
                        <input
                          type="radio"
                          name="installmentAmount"
                          :id="part"
                          :value="part"
                          v-model="installmentAmount"
                          class="text-[#D9D9D9] checked:text-[#52A350] checked:bg-opacity-0 checked:outline-offset-0 mr-3 focus:outline-hidden"
                        />
                        ${{ part }}
                      </label>
                    </div>
                  </template>
                </template>
                <template v-if="pack.type == 'bootcamp'">
                  <template
                    v-for="(part, index) in bootcampPartPaymentDollar"
                    :key="index"
                  >
                    <div class="radio-check-group" v-if="part < pack?.price">
                      <label
                        :for="part"
                        class=""
                        :class="[
                          installmentAmount == part
                            ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                            : `border-[#D2D2D2] bg-[#F7F7F7]`,
                        ]"
                      >
                        <input
                          type="radio"
                          name="installmentAmount"
                          :id="part"
                          :value="part"
                          v-model="installmentAmount"
                          class="text-[#D9D9D9] checked:text-[#52A350] checked:bg-opacity-0 checked:outline-offset-0 mr-3 focus:outline-hidden"
                        />
                        ${{ part }}
                      </label>
                    </div>
                  </template>
                </template>
              </fieldset>
            </div>
            <!-- payment methods -->
            <h3 class="font-medium text-lg">Select payment method</h3>
            <fieldset
              class="grid mt-3 grid-cols-2 mb-7 gap-6 w-full"
              :disabled="amountToPay <= 0"
              v-if="user.country_id == 156"
            >
              <div
                class="radio-check-group"
                v-if="settings.flutter_card_payment_active == 1"
              >
                <label
                  for="Flutterwave"
                  class=" "
                  :class="[
                    paymentMethod == 'Flutterwave'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="Flutterwave"
                    value="Flutterwave"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Card
                </label>
              </div>

              <div
                class="radio-check-group"
                v-if="settings.raven_payment_active == 1"
              >
                <label
                  for="raven"
                  class=""
                  :class="[
                    paymentMethod == 'raven'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="raven"
                    value="raven"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  <img
                    class="w-[99.45px] h-[20.5px]"
                    src="@/assets/img/icons/Raven.png"
                    alt="Raven logo"
                  />
                </label>
              </div>
              <div
                class="radio-check-group"
                v-if="settings?.flutter_bank_payment_active == 1"
              >
                <label
                  for="virtual_account"
                  class=""
                  :class="[
                    paymentMethod == 'virtual_account'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="virtual_account"
                    value="virtual_account"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Bank Transfer
                </label>
              </div>
              <div
                class="radio-check-group"
                v-if="settings?.paystack_active == 1"
              >
                <label
                  for="monnify"
                  class=""
                  :class="[
                    paymentMethod == 'monnify'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="monnify"
                    value="monnify"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Bank Transfer
                </label>
              </div>
              <div
                class="radio-check-group"
                v-if="settings?.raven_virtual_payment == 1"
              >
                <label
                  for="raven_virtual_account"
                  class=""
                  :class="[
                    paymentMethod == 'raven_virtual_account'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="raven_virtual_account"
                    value="raven_virtual_account"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Bank Transfer
                </label>
              </div>
              <div
                class="radio-check-group"
                v-if="settings?.wema_payment_active == 1"
              >
                <label
                  for="wema_transfer"
                  class=""
                  :class="[
                    paymentMethod == 'wema_transfer'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="wema_transfer"
                    value="wema_transfer"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Bank Transfer
                </label>
              </div>
              <div
                class="radio-check-group"
                v-if="settings?.mint_payment == 1"
              >
                <label
                  for="mint_payment"
                  class=""
                  :class="[
                    paymentMethod == 'mint_payment'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="mint_payment"
                    value="mint_payment"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  Bank Transfer
                </label>
              </div>
            </fieldset>
            <fieldset
              class="grid mt-3 grid-flow-col mb-7 gap-6 w-full"
              :disabled="amountToPay <= 0"
              v-if="user?.country_id != 156 && pack?.currency == 'naira'"
            >
              <div class="radio-check-group">
                <label
                  for="Sellar"
                  class=" "
                  :class="[
                    paymentMethod == 'Sellar'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="Sellar"
                    value="Sellar"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  <img
                    class="h-8"
                    src="@/assets/img/img/selar.png"
                    alt="Sellar logo"
                  />
                </label>
              </div>
            </fieldset>
            <div
              @click="sellarGateway()"
              v-if="
                user?.country_id != 156 &&
                pack?.currency == 'naira' &&
                paymentMethod == 'Sellar'
              "
              class="bg-primary-green text-[#FFFFFF] rounded-md disabled:text-[#707070] disabled:bg-[#E9E9E9] w-full py-4 px-4 mt-7 font-bold text-center cursor-pointer"
            >
              Pay Now: <span class="font-sans">&#8358;</span
              >{{ amountToPay ?? "00.00" }}
            </div>
            <fieldset
              class="grid mt-3 grid-flow-col mb-7 gap-6 w-full"
              :disabled="amountToPay <= 0"
              v-if="user?.country_id != 156 && pack?.currency == 'dollar'"
            >
              <div class="radio-check-group">
                <label
                  for="PayPal"
                  class=""
                  :class="[
                    paymentMethod == 'PayPal'
                      ? 'border-[#D2D2D2] bg-[#FFFFFF]'
                      : `border-[#D2D2D2] bg-[#F7F7F7]`,
                  ]"
                >
                  <input
                    type="radio"
                    name="paymentMethod"
                    id="PayPal"
                    value="PayPal"
                    v-model="paymentMethod"
                    class="text-green-600 focus:ring-primary-green focus:ring-2 mr-3"
                  />
                  <img
                    class="h-8"
                    src="@/assets/img/img/paypal.png"
                    alt="PayPal logo"
                  />
                  <span class="font-bold text-[#3b7bbf] font-body-new"
                    >PayPal</span
                  >
                </label>
              </div>
            </fieldset>
            <div
              @click="payPalGateway()"
              v-if="
                user?.country_id != 156 &&
                pack?.currency == 'dollar' &&
                paymentMethod == 'PayPal'
              "
              class="bg-primary-green text-[#FFFFFF] rounded-md disabled:text-[#707070] disabled:bg-[#E9E9E9] w-full py-4 px-4 mt-7 font-bold text-center cursor-pointer"
            >
              Pay Now: $ {{ amountToPay ?? "00.00" }}
            </div>
            <FlutterCheckout
              v-if="paymentMethod == 'Flutterwave' && flutterData"
              :data="flutterData"
            />
            <VirtualCheckout
              v-if="
                (paymentMethod == 'virtual_account' && virtualAccount) ||
                (paymentMethod == 'raven_virtual_account' && virtualAccount)
              "
              :data="virtualAccount"
            />
            <RavenCheckout
              v-if="paymentMethod == 'raven' && ravenData"
              :data="ravenData"
            />
            <WemaCheckout
              v-if="paymentMethod == 'wema_transfer' && wemaData"
              :data="wemaData"
            />
            <MintCheckout
              v-if="paymentMethod == 'mint_payment' && mintData"
              :data="mintData"
            />
            <VirtualCheckout
              v-if="paymentMethod == 'monnify' && monnifyData"
              :data="monnifyData"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearningNavBar from "@/components/lms/LearningNavBar.vue";
import LearningNavBarMobile from "@/components/lms/LearningNavBarMobile.vue";
import { decrypt, encrypt } from "@/helpers/encrypt";
import axios from "axios";

import FlutterCheckout from "./checkout-components/FlutterwaveCheckout.vue";
import RavenCheckout from "./checkout-components/RavenCheckout.vue";
import VirtualCheckout from "./checkout-components/VirtualCheckout.vue";
import WemaCheckout from "./checkout-components/WemaCheckout.vue";
import MonnifyCheckout from "./checkout-components/MonnifyCheckout.vue";
import MintCheckout from "./checkout-components/MintCheckout.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      installmentAmount: null,
      paymentType: "fullPayment",
      paymentMethod: "",
      installmentPlan: "",
      paymentHash: "",
      pack: null,
      id: "",
      flutterData: null,
      ravenData: null,
      wemaData: null,
      monnifyData: null,
      mintData: null,
      virtualAccount: null,
      listOfGateway: {
        naira: ["Flutterwave", "Bank Transfer"],
        nairaForeign: ["Sellar"],
        dollar: ["Paypal"],
      },
      bootcampSellarLinks: [
        { amount: 1000, link: "https://selar.co/q4g3" },
        { amount: 2000, link: "https://selar.co/4b67" },
        { amount: 3000, link: "https://selar.co/p757" },
        { amount: 4000, link: "https://selar.co/jjcc" },
        { amount: 5000, link: "https://selar.co/ve2z" },
        { amount: 6000, link: "https://selar.co/vtnm" },
        { amount: 7000, link: "https://selar.co/zg8w" },
        { amount: 8000, link: "https://selar.co/dd9d" },
        { amount: 9000, link: "https://selar.co/9000" },
        { amount: 10000, link: "https://selar.co/4cpx" },
      ],
      certificateSellarLinks: [
        { amount: 500, link: "https://selar.co/1fq7" },
        { amount: 1000, link: "https://selar.co/ojiw" },
        { amount: 1500, link: "https://selar.co/en2u" },
        { amount: 2000, link: "https://selar.co/ws19" },
        { amount: 2500, link: "https://selar.co/ftqz" },
        { amount: 3000, link: "https://selar.co/klnl" },
        { amount: 3500, link: "https://selar.co/8vcc" },
        { amount: 4000, link: "https://selar.co/ckdt" },
        { amount: 4500, link: "https://selar.co/23ha" },
        { amount: 5000, link: "https://selar.co/o1hq" },
      ],
      bootcampPartPaymentNaira: [
        1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000,
      ],
      certificatePartPaymentNaira: [
        500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
      ],
      bootcampPartPaymentDollar: [10, 15, 20, 25, 50, 75],
      certificatePartPaymentDollar: [5, 10, 15, 20],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
    }),
    amountToPay() {
      if (this.user?.country_id == 156) {

        if (this.paymentType == "fullPayment") {
          return this.pack?.price;
        } else if (this.paymentType == "partPayment") {
          let amount = Math.ceil(
            (this.installmentPlan * this.pack?.price) / 100
          );
          return amount;
        } else {
          return 0;
        }
      } else {
        if (this.paymentType == "fullPayment") {
          return this.pack?.price;
        } else if (this.paymentType == "partPayment") {
          let amount = this.installmentAmount;
          return amount;
        } else {
          return 0;
        }
      }
    },
    africaGateway() {
      if (this.user?.country_id == 156) {
        return this.listOfGateway.naira;
      }
      return this.listOfGateway.nairaForeign;
    },
  },
  watch: {
    amountToPay() {
      // console.log(this.amountToPay);
      if (this.amountToPay <= 0) {
        return (this.paymentMethod = null);
      }
    },
    paymentMethod() {
      this.ravenData = null;
      this.flutterData = null;
      if (this.paymentMethod == "Flutterwave") {
        return this.initFlutterwave();
      } else if (this.paymentMethod == "raven") {
        return this.initRaven();
      } else if (this.paymentMethod == "virtual_account") {
        return this.initVirtualAccounts();
      } else if (this.paymentMethod == "wema_transfer") {
        return this.initWema();
      } else if (this.paymentMethod == "raven_virtual_account") {
        return this.initRavenVirtualAccounts();
      } else if (this.paymentMethod == "mint_payment") {
        return this.initMint();
      } else if (this.paymentMethod == "monnify") {
        return this.initMonnify();
      } else {
        return;
      }
    },
    paymentType() {
      this.ravenData = null;
      this.flutterData = null;
      this.virtualAccount = null;
      this.mintData = null;
      this.monnifyData = null;
      this.paymentMethod == "";
    },
    installmentPlan() {
      this.ravenData = null;
      this.flutterData = null;
      this.virtualAccount = null;
      this.mintData = null;
      this.monnifyData = null;
      if (this.paymentMethod == "Flutterwave") {
        return this.initFlutterwave();
      } else if (this.paymentMethod == "raven") {
        return this.initRaven();
      } else if (this.paymentMethod == "virtual_account") {
        return this.initVirtualAccounts();
      } else if (this.paymentMethod == "mint_payment") {
        return this.initMint();
      } else if (this.paymentMethod == "monnify") {
        return this.initMonnify();
      } else {
        return;
      }
    },
  },
  components: {
    LearningNavBar,
    LearningNavBarMobile,
    FlutterCheckout,
    RavenCheckout,
    VirtualCheckout,
    WemaCheckout,
    MintCheckout,
    MonnifyCheckout
  },
  methods: {
    sellarGateway() {
      let link = "";
      if (this.pack.type == "certificate") {
        link = this.certificateSellarLinks.find(
          (item) => item.amount == this.amountToPay
        );
      }
      if (this.pack.type == "bootcamp") {
        link = this.bootcampSellarLinks.find(
          (item) => item.amount == this.amountToPay
        );
      }
      if (link) {
        window.open(link.link, "_blank");
        localStorage.removeItem("paymentHash");
        router.push({ name: "ChoosePayment" });
      }
      // console.log(link);
    },
    async payPalGateway() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post("auth/payments/paypal", payload);
        loader.hide();
        localStorage.removeItem("paymentHash");
        window.open(data.payload, "_blank");
        return;
      } catch (error) {
        loader.hide();
        swal({
          title: "Oops",
          text: "We couldn't generate a payment link for you, please try again later.",
        });
      }
    },
    async fetchPaymentById() {
      try {
        this.paymentHash = decrypt(
          localStorage.paymentHash ? localStorage.paymentHash : ""
        );
        this.paymentType = this.paymentHash.paymentType
          ? this.paymentHash.paymentType
          : "fullPayment";
        this.id = this.paymentHash.id
          ? this.paymentHash.id
          : decrypt(this.$route.params.id);
      } catch (error) {
        console.error(error);
        this.$router.push({ name: "ChoosePayment" });
        return;
      }
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/payments/" + this.id);
        this.pack = data.payload;
        loader.hide();
      } catch (error) {
        loader.hide();
      }
    },
    async initFlutterwave() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post("auth/payments/flutterwave", payload);
        loader.hide();
        this.flutterData = data.payload;
        return;
      } catch (error) {
        loader.hide();
        swal({
          title: "Oops",
          text: "We couldn't generate a payment link for you, please try again later.",
        });
      }
    },
    async initVirtualAccounts() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post("auth/payments/virtual", payload);
        loader.hide();
        this.virtualAccount = data.payload;
        return;
      } catch (error) {
        loader.hide();
        swal({
          title: "Oops",
          text: "We couldn't generate an account number for you. Please try again or use another payment method",
        });
      }
    },
    async initRavenVirtualAccounts() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post(
          "auth/payments/raven/virtual",
          payload
        );
        loader.hide();
        this.virtualAccount = data.payload;
        return;
      } catch (error) {
        loader.hide();
        swal({
          title: "Oops",
          text: "We couldn't generate an account number for you. Please try again or use another payment method",
        });
      }
    },
    async initRaven() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack?.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post("auth/payments/raven", payload);
        loader.hide();
        this.ravenData = data.payload.data;
        return;
      } catch (error) {
        console.error(error);
        swal({
          title: "Oops",
          text: "We couldn't generate an account number for you. Please try again or use another payment method.",
        });
        loader.hide();
        return;
      }
    },
    async initWema() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack?.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post("auth/payments/wema", payload);
        loader.hide();
        this.wemaData = data.payload.data;
        return;
      } catch (error) {
        console.error(error);
        swal({
          title: "Oops",
          text: "We couldn't generate an account number for you. Please try again or use another payment method.",
        });
        loader.hide();
        return;
      }
    },
    async initMint() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack?.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post("auth/payments/mint", payload);
        loader.hide();
        this.mintData = data.payload.data;
        return;
      } catch (error) {
        console.error(error);
        swal({
          title: "Oops",
          text: "We couldn't generate an account number for you. Please try again or use another payment method.",
        });
        loader.hide();
        return;
      }
    },
    async initMonnify() {
      let payload = {
        amount: this.amountToPay,
        id: this.pack?.id,
        type: this.paymentType,
      };
      const loader = this.$loading.show();
      try {
        const { data } = await axios.post("auth/payments/monnify", payload);
        loader.hide();
        this.monnifyData = data.payload.data;
        return;
      } catch (error) {
        console.error(error);
        swal({
          title: "Oops",
          text: "We couldn't generate an account number for you. Please try again or use another payment method.",
        });
        loader.hide();
        return;
      }
    },
  },

  mounted() {
    this.fetchPaymentById();
  },
};
</script>

<style lang="scss" scoped>
form {
  fieldset {
    .radio-check-group {
      label {
        @apply font-body font-medium text-[10px] lg:text-xs text-[#3D403D] border border-[#C9C9C9] rounded-[4px] flex items-center px-4 py-5 lg:py-3 w-full text-left cursor-pointer h-full;
      }
    }
  }
}
</style>
