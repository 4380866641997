<template>
  <div
    v-if="announcement && announcement.description"
    class="bg-[#EBF5EA] py-2 text-center my-8"
  >
    <p class="font-body text-xs md:text-sm text-[#020F27] px-4">
      {{ announcement.description }}
    </p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      announcement: null,
    };
  },
  methods: {
    async fetchAnnouncement() {
      try {
        axios
          .get("auth/announcement")
          .then((res) => {
            this.announcement = res.data.payload;
          })
          .catch((err) => {
            console.log(err);
            this.announcement = null;
          });
      } catch (error) {
        this.announcement = null;
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchAnnouncement();
  },
};
</script>

<style></style>
