<template>
  <div class="container p-0">
    <div
      class="grid lg:grid-flow-col gap-8 lg:w-3/5 place-content-start items-start pt-8 px-4 md:px-0"
    >
      <div class="">
        <h3
          v-if="checkTests.baseline"
          class="font-header-1 text-[#0C0C0C] dark:text-[#E5E5E5] font-bold text-3xl mb-4"
        >
          Jobberman Endline Assessment
        </h3>
        <h3
          v-else
          class="font-header-1 text-[#0C0C0C] dark:text-[#E5E5E5] font-bold text-3xl mb-4"
        >
          Jobberman Baseline Assessment
        </h3>
        <div
          class="mb-12 bg-[#FCFCFC] dark:bg-[#0C0C0C] border dark:border-none border-dashed text-[#0C0C0C] dark:text-[#E5E5E5] border-[#CCCCCC] rounded-2xl p-4 font-body text-sm"
        >
          You are required to take
          {{
            !checkTests.baseline
              ? "a baseline test at the start"
              : "an endline test at the end"
          }}
          of the Jobberman Soft Skills training course
        </div>
        <p class="font-header-1 font-bold text-[#0C0C0C] dark:text-[#E5E5E5]">
          INSTRUCTIONS
        </p>
        <div class="font-body text-[#606060] text-sm leading-10">
          <p>
            <span
              class="inline-flex items-center justify-center p-1 w-6 h-6 text-xs rounded-full text-[#606060] bg-[#EDEBEB] mr-4"
              >1</span
            >
            All questions are multiples choice questions
          </p>
          <p>
            <span
              class="inline-flex items-center justify-center p-1 w-6 h-6 text-xs rounded-full text-[#606060] bg-[#EDEBEB] mr-4"
              >2</span
            >You have only one shot at each question
          </p>
          <p>
            <span
              class="inline-flex items-center justify-center p-1 w-6 h-6 text-xs rounded-full text-[#606060] bg-[#EDEBEB] mr-4"
              >3</span
            >Go conquer the world champ!👍🏽
          </p>
        </div>
        <router-link :to="{ name: 'LearningJobbermanQuiz' }">
          <button
            class="font-body font-semibold text-white bg-primary-green rounded-md py-6 md:py-3 px-12 hover:text-primary-green hover:bg-white hover:outline hover:outline-primary-green hover:outline-1 transition-all ease-linear max-h-fit w-full lg:max-w-fit my-8 text-sm"
          >
            Start Assessment
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      checkTests: "jobberman/checkTests",
      lessons: "jobberman/lessons",
      watchedLessons: "jobberman/watchedLessons",
    }),
    completed() {
      const totalLessons = this.lessons.length;
      const totalWatched = this.watchedLessons.length;
      if (totalWatched < totalLessons) {
        return false;
      }
      if (totalWatched >= totalLessons) {
        return true;
      }
    },
  },
  mounted() {
    if (this.user?.canTakeJobberman != 2) {
      this.$router.push({ name: "LearningJobbermanEnroll" });
      return;
    }
    if (this.lessons?.length == 0 && this.checkTests.baseline == true) {
      this.$router.push({ name: "LessonPage" });
      return;
    }
    if (this.checkTests.baseline == true && this.completed == false) {
      swal({
        title: "Alert!",
        text: "You must watch all the Jobberman Soft Skill lessons before you can take the Soft Skills Endline Assessment",
      });
      this.$router.push({ name: "LessonPage" });
      return;
    }
  },
};
</script>

<style></style>
