<template>
  <button
    class="bg-[#DBFEDA] text-[#606060] rounded-full px-4 py-[2px] text-[0.65rem] mr-4"
    v-if="props.score >= 90 && props.score <= 100"
  >
    LEGEND <i class="fa fa-star"></i>
  </button>
  <button
    class="bg-[#ECFFC4] text-[#606060] rounded-full px-4 py-[2px] text-[0.65rem] mr-4"
    v-if="user?.batch_id < 7 ? (props.score > 79 && props.score <= 89) : (props.score > 69 && props.score <= 89)"
  >
    GREAT
  </button>
  <button
    class="bg-[#ECFFC4] text-[#606060] rounded-full px-4 py-[2px] text-[0.65rem] mr-4"
    v-if="user?.batch_id < 7 &&  (props.score > 49 && props.score <= 79)"
  >
    GOOD
  </button>
  <button
    class="bg-[#FFEEEE] text-[#606060] rounded-full px-4 py-[2px] text-[0.65rem] mr-4"
    v-if="user?.batch_id < 7 ? props.score <= 49 :  props.score <= 69"
  >
    FAILED
  </button>
</template>

<script setup>
import { computed } from '@vue/reactivity';
import { useStore } from 'vuex';
const store = useStore();

const props = defineProps(["score"]);
const user = computed(()=>{
  return store.state.auth.user;
})
</script>
