<template>
  <section class="py-16 lg:py-16 container lg:mt-0" v-if="testimonies?.length > 0">
    <h2
      class="font-header-1 font-extrabold text-3xl md:text-5xl text-[#2F2F2F] text-center"
    >
      What others have <span class="text-primary-green">gained</span>
    </h2>
    <p
      class="text-sm text-body-text text-center my-4 font-body lg:w-2/5 mx-auto mb-16"
    >
      Read all about how the Terra Learning internship has impacted other youths
      and helped them achieve their goals.
    </p>
    <section class="w-screen -ml-4 md:-ml-32 testimony_container flex flex-row">
      <template v-for="testimony in testimonies" :key="testimony.id">
        <div
          class="w-[380px] h-[240px] p-6 md:w-[440px] md:h-[280px] border border-[#D9DDD8] rounded-2xl flex flex-col lg:p-10 font-body mx-2 item cursor-pointer bg-testimony flex-none"
        >
          <p class="text-[#6A6A6A] text-xs md:text-sm mb-4">
            "{{ testimony?.testimony }}"
          </p>
          <div class="flex items-center justify-self-end mt-auto">
            <img
              :src="testimony?.img"
              :alt="testimony?.name"
              loading="lazy"
              class="h-10 w-10 rounded-full mr-4"
            />
            <div class="flex flex-col">
              <span class="font-semibold text-sm md:text-base">{{
                testimony.name
              }}</span>
              <span class="text-xs font-light text-[#6A6A6A]"
                >Terra Learning {{ testimony?.cohort }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <template v-for="testimony in testimonies" :key="testimony.id">
        <div
          class="w-[380px] h-[240px] p-6 md:w-[440px] md:h-[280px] border border-[#D9DDD8] rounded-2xl flex flex-col lg:p-10 font-body mx-2 item cursor-pointer bg-testimony flex-none"
        >
          <p class="text-[#6A6A6A] text-xs md:text-sm mb-4">
            "{{ testimony.testimony }}"
          </p>
          <div class="flex items-center justify-self-end mt-auto">
            <img
              :src="testimony.img"
              :alt="testimony.name"
              loading="lazy"
              class="h-10 w-10 rounded-full mr-4"
            />
            <div class="flex flex-col">
              <span class="font-semibold text-sm md:text-base">{{
                testimony.name
              }}</span>
              <span class="text-xs font-light text-[#6A6A6A]"
                >Terra Learning {{ testimony.cohort }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <template v-for="testimony in testimonies" :key="testimony.id">
        <div
          class="w-[380px] h-[240px] p-6 md:w-[440px] md:h-[280px] border border-[#D9DDD8] rounded-2xl flex flex-col lg:p-10 font-body mx-2 item cursor-pointer bg-testimony flex-none"
        >
          <p class="text-[#6A6A6A] text-xs md:text-sm mb-4">
            "{{ testimony.testimony }}"
          </p>
          <div class="flex items-center justify-self-end mt-auto">
            <img
              :src="testimony.img"
              :alt="testimony.name"
              loading="lazy"
              class="h-10 w-10 rounded-full mr-4"
            />
            <div class="flex flex-col">
              <span class="font-semibold text-sm md:text-base">{{
                testimony.name
              }}</span>
              <span class="text-xs font-light text-[#6A6A6A]"
                >Terra Learning {{ testimony.cohort }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <template v-for="testimony in testimonies" :key="testimony.id">
        <div
          class="w-[380px] h-[240px] p-6 md:w-[440px] md:h-[280px] border border-[#D9DDD8] rounded-2xl flex flex-col lg:p-10 font-body mx-2 item cursor-pointer bg-testimony flex-none"
        >
          <p class="text-[#6A6A6A] text-xs md:text-sm mb-4 font-body">
            "{{ testimony.testimony }}"
          </p>
          <div class="flex items-center justify-self-end mt-auto">
            <img
              :src="testimony.img"
              :alt="testimony.name"
              loading="lazy"
              class="h-10 w-10 rounded-full mr-4"
            />
            <div class="flex flex-col font-body">
              <span class="font-semibold text-sm md:text-base">{{
                testimony.name
              }}</span>
              <span class="text-xs font-light text-[#6A6A6A]"
                >Terra Learning {{ testimony.cohort }}</span
              >
            </div>
          </div>
        </div>
      </template>
    </section>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      testimonies: [],
      gridListWidth: "",
    };
  },
  methods: {
    async fetchTestimonies() {
      const { data } = await axios.get("v2/testimonies")
      this.testimonies = data.data;
      this.loopTestimonies();
    },
    loopTestimonies() {
      const gridList = document.querySelector(".testimony_container");
      this.gridListWidth = 0;
      const gridListInterval = setInterval(() => {
        if (gridList?.children?.length > 0) {
          for (let i of gridList?.children) {
            this.gridListWidth += 334;
          }

          const cssAnimation = document.createElement("style");
          cssAnimation.type = "text/css";
          const rules = document.createTextNode(
            `@keyframes marquee {
              0% { transform: translateX(0%); }
              100% { transform: translateX(${-Math.abs(
                this.gridListWidth
              )}px); }
            }`
          );
          cssAnimation.appendChild(rules);
          document.getElementsByTagName("head")[0].appendChild(cssAnimation);
          clearInterval(gridListInterval);
        }
      }, 1);
    },
  },
  mounted() {
    this.fetchTestimonies();
  },
};
</script>

<style scoped>
.testimony_container {
  animation: marquee 420s infinite linear;
}
.testimony_container:hover {
  animation-play-state: paused;
}
</style>
