<template>
  <button class="lg:text-md">
    <slot> </slot>
  </button>
</template>

<script>
export default {};
</script>

<style scoped>
button {
  @apply text-sm font-body text-white bg-primary-green rounded-[5px]  py-3.5 px-10 hover:text-primary-green hover:bg-[#BCDEBA] max-h-fit transition-all ease-linear;
}
</style>
