<template>
  <main
    class="fixed bottom-0 right-0 flex items-center justify-center transition duration-300 ease-linear z-[9999] shadow-md"
    :class="
      openState == true
        ? 'h-screen w-screen bottom-0 right-0 bg-[#00000090]'
        : 'h-28 w-28 md:h-52 md:w-52 bottom-4 right-4 bg-none cursor-pointer hover:scale-105 drop-shadow-lg'
    "
  >
    <div
      class="relative flex items-center justify-center"
      :class="openState == true ? 'h-fit md:h-3/4 p-3' : 'h-full'"
    >
      <button
        class="absolute text-white font-body font-bold text-[10px] md:text-sm lg:text-base top-5 right-5 md:top-6 md:right-6 bg-body-text rounded-full px-3 py-1"
        v-if="openState"
        @click="openState = false"
      >
        CLOSE
      </button>
      <img
        src="@/assets/img/promo/new-year.webp"
        alt="Side Hustle Black Friday Promo"
        class="transition-all duration-100 ease-linear h-auto md:h-full w-full"
        @click="openState = true"
      />
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      openState: true,
    };
  },
};
</script>

<style></style>
