<template>
  <nav
    class="md:hidden w-full md:pb-32 absolute font-body overflow-y-scroll min-h-fit z-30 bg-[#FAFAFA] dark:bg-[#121212]"
  >
    <nav class="py-4 overflow-y-scroll">
      <div
        @click="openLearningPath = !openLearningPath"
        class="px-4 font-body font-semibold text-primary-green cursor-pointer flex items-center"
      >
        <span class="iconify" data-icon="codicon:kebab-vertical"></span>
        View Learning path
      </div>
      <div v-if="openLearningPath" class="text-gray-500 h-screen">
        <div class="pt-4">
          <!-- Baseline -->
          <button
            :disabled="checkTests.baseline"
            class="cursor-pointer w-full py-3 text-xs text-left md:text-sm px-4 lg:px-8 flex justify-start items-start dark:text-[#C1C6C1] text-[#3F473F]"
          >
            <span
              class="iconify text-2xl min-w-fit mr-3"
              data-icon="healthicons:i-exam-multiple-choice-outline"
            ></span>
            <span class="mr-2">Baseline Assessment</span>
            <i
              class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
            ></i>
          </button>
          <!-- Baseline -->
          <!-- Lessons -->
          <template v-for="lesson in lessons" :key="lesson.id">
            <li
              @click="selectLesson(lesson.id)"
              :class="
                currentLesson.id == lesson.id
                  ? 'bg-[#51a35021] text-primary-green font-semibold'
                  : 'dark:text-[#C1C6C1] text-[#3F473F]'
              "
              class="cursor-pointer py-3 text-xs text-left md:text-sm px-4 lg:px-8 flex justify-start items-start"
            >
              <span
                class="iconify text-2xl min-w-fit mr-3"
                data-icon="ei:play"
              ></span>
              <span class="mr-2">{{ lesson.title }}</span>
              <i
                v-if="watchedLessons.includes(lesson.id)"
                class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
              ></i>
            </li>
          </template>
          <!-- Lessons -->
          <!-- Endline -->
          <button
            @click="takeEndline"
            :disabled="checkTests.endline"
            class="cursor-pointer w-full py-3 text-xs text-left md:text-sm px-4 lg:px-8 flex justify-start items-start dark:text-[#C1C6C1] text-[#3F473F]"
          >
            <span
              class="iconify text-2xl min-w-fit mr-3"
              data-icon="healthicons:i-exam-multiple-choice-outline"
            ></span>
            <span class="mr-2">Endline Assessment</span>
            <i
              v-if="checkTests.endline"
              class="fa-solid fa-circle-check justify-self-end ml-auto mr-2 text-base text-primary-green"
            ></i>
          </button>
          <!-- Endline -->
          <!-- Certificate -->
          <div
            class="border-[#D2D7D2] px-4 lg:px-8 border-t mt-2 py-4 text-sm md:text-base justify-between items-center w-full cursor-pointer flex"
            @click="checkCertificate"
          >
            <span>Download Certificate </span>
            <span class="iconify text-sm ml-2" data-icon="bxs:lock-alt"></span>
            <i class="fa-solid fa-chevron-right text-xs ml-auto"></i>
          </div>
          <!-- Certificate -->
        </div>
      </div>
    </nav>
  </nav>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, watch } from "vue";
import { computed, ref } from "@vue/reactivity";
import { inject } from "vue";
// import swal from "sweetalert";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";

// const axios = inject("axios");
const $loading = inject("$loading");
const store = useStore();
const router = useRouter();
const route = useRoute();

const openLearningPath = ref(false);

const completedLessons = computed(() => {
  const totalLessons = lessons.value.length;
  const totalWatched = watchedLessons.value.length;
  if (totalWatched < totalLessons) {
    return false;
  }
  if (totalWatched >= totalLessons) {
    return true;
  }
});

const user = computed(() => {
  return store.state.auth.user;
});

const siteSettings = computed(() => {
  return store.state.auth.settings;
});

const lessons = computed(() => {
  return store.state.jobberman.lessons;
});

const checkTests = computed(() => {
  return store.state.jobberman.checkTests;
});

const currentLesson = computed(() => {
  return store.state.jobberman.currentLesson;
});

const watchedLessons = computed(() => {
  return store.state.jobberman.watchedLessons;
});

const selectLesson = (id) => store.dispatch("jobberman/setLesson", id);

const takeEndline = () => {
  if (completedLessons.value == false) {
    swal({
      title: "Oops!",
      text: "You have to watch all videos before proceeding to take your endline test",
    });
    return;
  }
  router.push({ name: "LearningJobbermanBaseline" });
};
/**
 * check certificate
 */
const checkCertificate = () => {
  if (user.value.batch.id >= siteSettings.value.active_batch) {
    swal({
      title: "Not Yet Available",
      text: "You will be able to download your certificate at the end of the 4th week of the internship",
    });
    return;
  }
  router.push({ name: "AllCertificatesPage" });
};
</script>

<style scoped>
* {
  @apply transition ease-linear;
}
.iconify {
  @apply mr-2;
}
/* Hide scrollbar for Chrome, Safari and Opera */
nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
nav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
