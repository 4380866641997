<template>
  <div
    @click="closeModal()"
    class="fixed bg-[#636363]/[0.27] backdrop-blur-[10px] custom_fade top-0 right-0 left-0 bottom-0 w-screen h-screen flex items-center justify-center z-[10000]"
  >
    <div class="container  p-0 flex items-center justify-center custom_bounce">
      <div
      ref="modal"
        class="max-w-[90vw] bg-holder w-11/12 md:w-4/6 text-[#3F3E3E] lg:w-6/12 lg:p-8 p-5 bg-[#FCFDFC] shadow-[0px_8px_50px_rgba(0,0,0,0.08)] rounded-2xl flex flex-col justify-between items-center relative overflow-clip font-body"
      >
        <div
          @click="$emit('closeFeeModal')"
          class="flex cursor-pointer  text-primary-green mr-auto"
        >
          <span
            class="iconify text-primary-green bg-[#E4F2E4] rounded-full mr-2"
            data-icon="ion:close"
          ></span>
          <p class="font-body text-sm">CLOSE</p>
        </div>
        <div class="mt-10 mb-12 w-full">
          <h1 class="font-bold text-lg text-left text-[#3B433B] mb-5">
            Certificate Packages
          </h1>
          <div class="flex justify-between mr-9">
            <div>
              <small class="text-[8px] font-semibold text-[#4C4C4C]">BASIC</small>
              <h2 class="text-xl font-bold text-[#333333] mb-3">₦2,500</h2>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">Image certificate</p>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">One time download</p>
            </div>
            <div>
              <small class="text-[8px] font-semibold text-[#4C4C4C]">STANDARD</small>
              <h2 class="text-xl font-bold text-[#333333] mb-3">₦3,500</h2>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">PDF certificate</p>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">Multiple times download</p>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">Verification link</p>
            </div>
            <div>
              <small class="text-[8px] font-semibold text-[#4C4C4C]">PREMIUM</small>
              <h2 class="text-xl font-bold text-[#333333] mb-3">₦5,000</h2>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">PDF certificate</p>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">Multiple times download</p>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">Verification link</p>
              <p class="text-[9px] mb-1 text-[#4A4B4A]">QR code</p>
            </div>
          </div>
        </div>
        <div class="text-xs text-center">
          <p class="mb-2">Have more questions?</p>
          <a href="https://wa.me/message/HX6JWNMRVERVO1" target="_blank">
            <p class="text-primary-green cursor-pointer">
              Reach our support team
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  components: {},
  methods:{
    closeModal(){
      let box = this.$refs.modal;
      if(!box.contains(event.target)){
        this.$emit('closeFeeModal');
        return;
      }
      return;
    }
  }
};
</script>

<style scoped>
.bg-holder{
  background-image: url("../../assets/img/CertifiateBG.png");
  background-position: right bottom; /*Positioning*/
  background-repeat: no-repeat;
  background-size: 180px auto;
}
</style>
