<template>
  <div>
    <div class="container md:p-0">
      <BackButton class="my-4 md:my-12" />
        <div class="">
          <h1
            class="font-header-1 font-bold text-3xl md:text-4xl dark:text-[#E5E5E5] text-black"
          >
            Select your preferred team
          </h1>
        </div>
        <div
          class="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-3 my-8"
          v-if="teams?.length > 0"
        >
          <template v-for="team in teams" :key="team.id">
            <div
              class="p-4 md:p-6 flex flex-col bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body"
            >
              <h3
                class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl"
              >
                {{ team.title }}
              </h3>
              <p
                class="font-normal text-[#666666] dark:text-[#f4f4f4] text-sm my-3"
              >
                {{ team.description }}
              </p>
              <router-link
                :to="{ name: 'SelectTeamDetails', params: { id: team.id } }"
                class="font-semibold text-primary-green text-sm mt-auto"
              >
                READ MORE
                <span
                  class="iconify inline-block"
                  data-icon="bi:arrow-up-right"
                ></span>
              </router-link>
            </div>
          </template>
        </div>
        <div class="my-8" v-if="teams?.length == 0">
          <h3 class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl">
            There are currently no teams available for selection
          </h3>
        </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { useStore } from "vuex";
import { computed, inject, onBeforeMount, onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
const $loading = inject("$loading");

const store = useStore();
const router = useRouter();

const user = computed(() => {
  return store.state.auth.user;
});

const teams = computed(() => {
  return store.state.teams.teams
});

const settings = computed(() => {
  return store.state.auth.settings;
});


onBeforeMount( async () => {
  if (user?.value?.cohort >= 10) {
    return router.push({ name: "LearningDashboardIndex" });
  }
  if (settings?.value?.current_week < 4) {
    swal({
      title: "Oops!!!",
      text: "Team selection will begin from level 4",
    }); 
    return router.push({ name: "LearningDashboardIndex" });
  }
  if (teams.value == null) {
    const loader = $loading.show();
    await store.dispatch("teams/fetchTeams");
    loader.hide()
  };
});
</script>

<style scoped></style>
