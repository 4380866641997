<template>
  <main class="bg-[linear-gradient(107.93deg,#ECF2FE_0%,#F1F8F1_104.14%)]  font-body flex text-[#404040]  place-content-center  min-h-screen">
      <div class="w-[60vw] h-fit bg-white md:1/3  rounded-md p-5 md:p-10   text-center flex flex-col mt-20 mb-16">
      
          <h1 class="text-center text-xl  md:text-2xl font-semibold">
              We’re waiting to receive your transfer. <br/> This can take a while
          </h1>
          <div class="flex gap-x-7 mt-8 mb-10 w-[80%] mx-auto justify-between items-center">
              <div class="w-fit flex flex-col items-center">
                  <span class="iconify text-primary-green text-3xl" data-icon="material-symbols:check-circle-rounded"></span>
                  <p class="text-xs mt-2">Transfer <br /> sent</p>
              </div>
              <!-- <div class=""></div> -->
              <ProgressBar :duration="convertTime(user?.pending_commitment_fee)"/>
              <div class=" w-fit flex flex-col items-center">
                  <div class=" bg-white rounded-full border-[1.9px] border-primary-green border-dashed w-6 h-6"></div>
                  <p class="text-xs mt-2">Transfer <br /> recieved</p>
              </div>
          </div>
          <!-- <h1 >Time Left: </h1> -->

          <div
              class="flex w-fit mx-auto items-center rounded-full px-3 py-1.5 border border-[#9ACD98] bg-[#F5FAF5] text-primary-green uppercase font-body font-medium text-sm"
              >
              <span class="iconify text-lg mr-2" data-icon="mdi:alarm-clock"></span>
              <Vue-countdown
                  class="font-semibold text-black ml-1"
                  @end="
                  $router.push({ name: 'CommitmentPayment' })
                  "
                  :time="convertTime(user?.pending_commitment_fee)"
                  v-slot="{ minutes, seconds }"
                  >{{ minutes }}:{{ seconds }}
              </Vue-countdown>
          </div>
          <div v-if="showDetails" >
              <div class="bg-[#F6F7F6] text-xs p-5 mt-5 w-[80%] text-left mx-auto" >
                  <p>Account Name: <span class="font-semibold ">{{ slug.account_name }}</span></p>
                  <p>Account Number :  <span class="font-semibold leading-relaxed mb-3">{{ slug.account_number }}</span></p>
                  <p>Bank Name : <span class="font-semibold leading-relaxed mb-3">{{ slug.bank_name }}</span></p>
                  <p>Narration : <span class="font-semibold leading-relaxed">{{ slug.sh_ref }}</span></p>
              </div>
              <p @click="showDetails = false"  class="text-sm cursor-pointer mt-3 mb-5">Hide account details</p>
          </div>
          <p v-else @click="showDetails = true" class="text-sm cursor-pointer mt-7 mb-8">Show account details</p>
          <p class="text-sm font-[400] mt-auto w-[90%] mx-auto"><span class="font-bold">NOTE: </span>For support or complaints regarding payment, kindly reach out to us via accounts@sidehustle.ng</p>
      </div>
  </main>
  <!-- <template>
  <main
    class="container font-body flex text-[#1A1F1A] h-screen justify-center pt-32 lg:pt-0"
  >
    <div class="w-full lg:w-fit lg:my-auto text-center">
      <p class="font-semibold mb-7">Payment Confirmation</p>
      <VueCountdown
        class="lg:text-3xl text-4xl font-semibold"
        :time="convertTime(user?.pending_commitment_fee)"
        v-slot="{ hours, minutes, seconds }"
        @end="$router.push({ name: 'CommitmentPayment' })"
      >
        Time Left: {{ hours }}h : {{ minutes }}m : {{ seconds }}s
      </VueCountdown>
    </div>
  </main>
</template> -->
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import { mapActions, mapGetters } from 'vuex';
import ProgressBar from '../../lms/game/ProgressBar.vue';

export default{
  data(){
      return{
          showDetails: false,
          slug: {},
      }
  },
  components: {
      VueCountdown,
      ProgressBar
  },
  computed:{
      ...mapGetters({
          user:  "auth/user",
          baseline: "auth/baseline",
          settings: "auth/settings", 
      })
  },
  watch:{
      user(){
          if (this.user?.can_access == 1) {
              return this.$router.push({name: "LearningDashboardIndex"});
          }
          // if (!this.user?.pending_payment?.updated_at) {
          //     return this.$router.push({name: "PaymentIncomplete"});
          // }
      }
  },
  methods:{
      ...mapActions({
          attempt: "auth/attempt"
      }),
      convertTime(time) {
          const now = new Date().getTime();
          var date = new Date(time);
          date = new Date(date.getTime() + 60*60*1000)
          console.log(date - now);    
          return date - now;
          // date.setMinutes(date  + 30)
          // const update_at = new Date(date).getTime();
          // return update_at - now;
      }
  },
  mounted(){
      if (this.user?.can_access == 1) {
          return this.$router.push({name: "LearningDashboardIndex"});
      }
      // if (!this.user?.pending_payment?.updated_at) {
      //     return this.$router.push({name: "PaymentIncomplete"});
      // }
      this.slug = JSON.parse(localStorage.getItem('slug'))
      console.log(this.slug);
      setInterval(() => {
          this.attempt();            
      }, 300000); // run this check every 5 minutes
      // }, 60000); // run this check every 1 minutes
  }
}
</script>
