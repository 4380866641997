<template>
  <main>
    <div class="font-body-new">
      <p class="text-[#404040] text-sm font-medium">
        Kindly note that a service fee of 1.4% will
        be charged on your payment.
      </p>
      <div
        class="grid grid-flow-row gap-4 px-6 mt-7 shadow-[0px_16px_50px_rgba(37,77,36,0.05)] bg-[#F8FCF8] border border-[#B5DAB4] rounded-md pt-6 pb-4"
      >
        <p class="text-xs font-medium text-red-500">
          <span class="iconify inline-flex mr-2" data-icon="material-symbols:info-outline"></span>
          Notice: Make sure you transfer the exact amount of 
          <span> &#8358; {{ integerFormatter(data?.total_amount) ?? "00.00" }} to avoid failed payments.</span
          >
        </p>
        <p>
          Account Name :
          <span class="text-primary-green font-semibold">{{
            data?.data?.account_name ?? "Side Hustle Nigeria Limited FLW"
          }}</span>
        </p>
        <p>
          Account Number :
          <span class="text-primary-green font-semibold">{{
            data?.data?.account_number ?? " "
          }}</span>
          <span
            @click="copy(data?.data?.account_number ?? '')"
            class="ml-2 cursor-pointer text-xs bg-primary-green rounded-md p-3 font-bold text-[#fff]"
            >COPY</span
          >
        </p>
        <p>
          Bank Name :
          <span class="text-primary-green font-semibold">{{
            data?.data?.bank_name ?? ""
          }}</span>
        </p>
        <template v-if="false">
          <p class="flex items-end justify-between">
            <span>Subtotal</span>
            <span class="font-bold"
              ><span class="font-sans">&#8358;</span
              >{{ integerFormatter(data?.initial_amount) ?? "00.00" }}</span
            >
          </p>
          <p class="flex items-end justify-between">
            <span>Service Charge</span>
            <span class="font-bold"
              ><span class="font-sans">&#8358;</span
              >{{
                integerFormatter(data?.transaction_charge_amount) ?? "00.00"
              }}</span
            >
          </p>
        </template>
        <p
          class="flex items-end justify-between border-t-[#BBDDBB] pt-2 border-t-[0.3px] font-bold"
        >
          Total Amount Payable
          <span class="text-primary-green"
            ><span class="font-sans">&#8358;</span
            >{{ integerFormatter(data?.total_amount) ?? "00.00" }}</span
          >
        </p>
      </div>
      <div
        @click="confirmPayment(data?.data?.sh_ref)"
        class="cursor-pointer text-center bg-primary-green text-[#FFFFFF] font-semibold text-[18px] rounded-md w-full py-4 px-4 my-6"
      >
        I have paid <span class="font-sans">&#8358;</span
        >{{ integerFormatter(data?.total_amount) ?? "00.00" }}
      </div>
    </div>
  </main>
</template>

<script setup>
import { integerFormatter } from "@/helpers/customFormatter.js";
</script>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  props: ["data"],
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    async confirmPayment(id) {
      let loader = this.$loading.show();
      try {
        await axios.post("v2/auth/payment/confirm", { reference_id: id });
        loader.hide();
        console.log('paid')
        this.$router.push({name: "PaymentConfirmationCountDown"})        
      } catch (error) {
        console.error(error);
        loader.hide();
      }
    },
    copy(Text) {
      const copyText = document.createElement("textarea");
      copyText.value = Text;
      document.body.appendChild(copyText);
      copyText.select();
      document.execCommand("copy");
      document.body.removeChild(copyText);
      if (document.execCommand("copy")) {
        swal({
          title: "Success",
          text: "Copied",
        });
      }
    },
  },
};
</script>

<style></style>
