// const CryptoJS = require("crypto-js");
import AES from "crypto-js/aes";
import enc from "crypto-js/enc-utf8";
const KEY = "Side Hustle";
export const encrypt = (data) => {
  const dataString = JSON.stringify(data);
  // Encrypt
  const result = AES.encrypt(dataString, KEY).toString();
  return result;
};

export const decrypt = (data) => {
  // Decrypt
  var bytes = AES.decrypt(data, KEY);
  var originalText = bytes.toString(enc);
  const result = JSON.parse(originalText);
  return result;
};
