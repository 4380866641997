<template>
  <div class="p-0 font-body">
    <div
      class="lg:w-3/4 p-4 rounded-sm border border-[#FCEBB6] dark:bg-[#575654] dark:border-[#C8AF5F] bg-[#FFFDF5]"
    >
      <span
        class="iconify text-lg dark:text-[#E9B307] text-green-600"
        data-icon="mingcute:bulb-fill"
      ></span>
      <p class="pt-3 dark:text-[#E9B307] text-[#211D12]">
        <strong>Important:</strong> The Terra Learning certificate is required in order to gain access to the learning materials on the dashboard. The certificate is credible and well-known, and it also shows potential employers that the individual has a measurable knowledge of their field of work.
      </p>
    </div>
    <form
      @submit.prevent="nextStep"
      class="lg:w-3/4 py-8 grid grid-flow-row gap-8"
    >
      <template v-for="pack in props.list" :key="pack.id">
        <label
          :for="pack.level"
          class="min-w-full p-6 rounded-md border pb-8 flex items-start"
          :class="[
            paymentPackage == pack.id
              ? 'border border-primary-green bg-transparent'
              : `border-[#E0E1E1] dark:bg-[#262626] dark:border-[#262626] bg-[#F9FAFA]`,
            !pack.hasPaid && 'border-primary-green bg-transparent',
          ]"
        >
          <input
            type="radio"
            name="certificate"
            :value="pack.id"
            v-model="paymentPackage"
            :id="pack.level"
            :disabled="pack.hasOwnProperty('hasPaid') ? !pack.hasPaid : false"
            :checked="pack.hasOwnProperty('hasPaid') && pack.price == 0"
            v-if="!pack.hasOwnProperty('hasPaid') || pack.hasPaid == true"
            class="text-green-600 bg-gray-100 border-gray-300 focus:ring-primary-green focus:ring-2 disabled:bg-black"
          />
          <div
            class="inline-grid grid-flow-col place-content-between grow ml-4"
          >
            <div class="flex flex-col">
              <h5
                class="font-bold text-[#5F5F5F] dark:text-[#E5E5E5] text-sm md:text-base mb-4 capitalize"
              >
                {{ pack.level }} Certificate

                <template v-if="pack.hasOwnProperty('hasPaid')">
                  <i class="fa-solid fa-lock" v-if="!pack.hasPaid"></i>

                  <i
                    class="fa-solid fa-circle-check text-primary-green"
                    v-if="pack.price <= 0"
                  ></i>
                </template>
              </h5>
              <ul
                class="text-xs md:text-sm text-[#8E8E8E] dark:text-[#E5E5E5] flex flex-col space-y-2 list-disc list-outside"
              >
                <li
                  v-for="(item, index) in pack.description"
                  :key="'item' + index"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="flex flex-col items-end">
              <h5
                class="font-bold text-[#5F5F5F] dark:text-[#E5E5E5] text-sm md:text-base mb-4 text-right"
              >
                {{
                  pack.price > 0
                    ? pack.currency == "naira"
                      ? "&#8358;"
                      : "&dollar;"
                    : ""
                }}
                <span>{{ pack.price <= 0 ? "" : pack.price }}</span>
              </h5>
              <h5
                class="font-bold mt-3 text-[#6EA66C] text-xs md:text-sm flex flex-col items-end space-y-2"
              >
                <span
                  class="block text-right -mt-6 line-through font-normal text-[#5F5F5F] dark:text-[#E5E5E5]"
                  v-if="pack.hasDiscount && pack.price != 0"
                  >{{
                    pack.price > 0
                      ? pack.currency == "naira"
                        ? "&#8358;"
                        : "&dollar;"
                      : ""
                  }}
                  {{ pack?.initial_price ?? pack?.price }}</span
                >
                <span
                  v-if="pack?.hasPaid && pack?.price > 0 && pack?.last_payment"
                  class="text-neutral-900 dark:text-[#fafafa] text-right block"
                >
                  <span
                    class="iconify inline font-bold mr-1"
                    data-icon="entypo:time-slot"
                  >
                  </span>
                  <Vue-countdown
                    :time="convertTime(pack.last_payment)"
                    v-slot="{ totalHours, minutes, seconds }"
                    ><span class="text-primary-green"
                      >Payment confirmation in: {{ totalHours }}:{{
                        minutes
                      }}:{{ seconds }} hrs</span
                    ></Vue-countdown
                  >
                </span>
                <span
                  class="text-[#F5C600] bg-[#FFFCF0] border-[#FFEB99] px-2 py-2 rounded-[20px] block w-fit"
                  v-if="
                    pack?.hasPaid &&
                    pack?.price > 0 &&
                    pack?.initial_price > pack?.price
                  "
                >
                  Paid ₦ {{ pack?.initial_price - pack?.price ?? "00.00" }}
                </span>
                <span
                  class="text-[#52A350] bg-[#EEF7EE] border-[#EEF7EE] px-2 py-2 rounded-[20px] block w-fit"
                  v-if="pack?.hasPaid && pack?.price <= 0"
                >
                  Paid
                </span>
              </h5>
              <span
                v-if="pack?.suggested == true"
                class="my-4 rounded-full py-1 px-2 font-semibold text-xs text-[#F49A07] bg-[#FEF3E1]"
              >
                <i class="fa-solid fa-star mr-1"></i> Recommended
              </span>
            </div>
          </div>
        </label>
      </template>
      <button
        :disabled="paymentPackage == ''"
        class="disabled:dark:bg-[#262626] bg-primary-green text-[#FFFFFF] rounded-md disabled:text-[#707070] disabled:bg-[#E9E9E9] w-full py-4 px-4"
        v-if="!completedPayment"
      >
        Make Payment
      </button>
    </form>
  </div>
</template>

<script setup>
import swal from "sweetalert";
import { encrypt, decrypt } from "@/helpers/encrypt";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { computed } from "@vue/reactivity";
import VueCountdown from "@chenfengyuan/vue-countdown";
const props = defineProps(["list"]);
let paymentPackage = ref("");
const router = useRouter();

const convertTime = (time) => {
  const now = new Date();
  var date = new Date(time);
  // add a day
  if (date.getDay() == 6) {
    date.setDate(date.getDate() + 3);
  } else if (date.getDay() == 7) {
    date.setDate(date.getDate() + 2);
  } else {
    date.setDate(date.getDate() + 1);
  }
  const update_at = new Date(date);
  return update_at - now;
};
const completedPayment = computed(() => {
  if (props.list) {
    let completed = props.list.find(
      (item) => item.price <= 0 && item.price <= 0
    );
    if (completed) {
      return true;
    }
  }
  return false;
});
const nextStep = () => {
  if (paymentPackage) {
    router.push({
      name: "Checkout",
      params: { id: encrypt(paymentPackage.value) },
    });
    localStorage.setItem("paymentHash", encrypt(paymentPackage.value));
    return;
  }
  swal({
    title: "Choose a Package",
    text: "Kindly choose a package you want to pay for",
  });
};
onMounted(() => {
  try {
    const list = props.list;
    const paidFor = list.find((item) => {
      return item.hasPaid == true;
    });
    if (paidFor) {
      return (paymentPackage.value = paidFor.id);
    }
    if (localStorage.paymentHash) {
      let data = decrypt(localStorage.paymentHash ?? "");
      const item = list.find((item) => {
        return item.id == data.id;
      });
      if (item) {
        return (paymentPackage.value = item.id);
      }
      data = props.list[0].id;
      paymentPackage.value = data;
    } else {
      const data = props.list.find((item) => {
        return item.suggested == true;
      });
      paymentPackage.value = data.id;
    }
  } catch (error) {}
});
</script>

<style scoped></style>
