<template>
  <time :style="gradient"></time>
</template>

<script>
import { DateTime } from "luxon";
export default {
  name: "Countdown",
  props: {
    time: Number,
  },
  data() {
    return {
      start: DateTime.local(),
      now: DateTime.local(),
      end: DateTime.local().plus({ seconds: 10 }),
      tick: null,
    };
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    total() {
      return this.end.diff(this.start).toObject();
    },
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    elapsed() {
      return this.now.diff(this.start).toObject();
    },
    percent() {
      return (this.elapsed.milliseconds / this.total.milliseconds) * 100;
    },
    display() {
      return luxon.Duration.fromObject(this.remaining).toFormat("hh:mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
    gradient() {
      return {
        background: `radial-gradient(white 60%, transparent 61%), conic-gradient(#52A350 0% ${this.percent}%, transparent ${this.percent}% 100%)`,
      };
    },
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.local();
    }, 10);
  },
};
</script>

<style scoped>
body {
  background: white;
  color: #52a350;
  font: 7vh "Orbitron", sans-serif;
}

main {
  align-items: center;
  display: flex;
  justify-content: center;
}

time {
  margin-top: 20px;
  border-radius: 50%;
  height: 10vh;
  width: 10vh;
  /* background: #52A350 !important; */
  /* border-color: #52A350 !important; */
}
</style>
