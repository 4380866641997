<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center p-4 z-50"
    @click="closeModal()"
  >
    <div
      class="flex items-center flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 pt-24 pb-12 space-y-4 md:w-3/5 text-center"
      ref="bootcampApplicationModal"
    >  
        <form @submit.prevent="submitApplication">
            <h1
                class="font-header-1 font-bold text-xl md:text-2xl dark:text-[#E5E5E5] text-black"
            >
                Select a course
            </h1>

            <fieldset class="grid gap-4 my-8 w-full" v-if="courses?.length > 0">
                <select
                class="w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-black placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
                name="course_id"
                required
                v-model="course_id"
                >
                <option selected disabled value="">
                    Select a course
                </option>
                <option
                    v-for="course in courses?.filter((c)=>c.bootcamp_available == 1)"
                    :key="course.id"
                    :value="course.id"
                >
                    {{ course?.title ?? "" }}
                </option>
                </select>
            </fieldset>
            <button
            type="submit"
            class="font-medium text-white rounded-md py-3 px-4 bg-primary-green"
            >
            Continue
            </button>
        </form>  
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert';
export default {
    data(){
        return {
            course_id: ""
        }
    },
    computed: {
        ...mapGetters({
            courses: "auth/courses",
        }),
    },
    methods: {
        ...mapActions({
            attempt: "auth/attempt",
        }),
        closeModal() {
        const box = this.$refs.bootcampApplicationModal;
        if (!box?.contains(event.target)) {
            return this.$emit("closemodal");
        }
        return;
        },
        async submitApplication() {
            let loader = this.$loading.show()
            try {
                await axios.post("v2/auth/bootcamp/apply", { course_id: this.course_id})
                await this.attempt({});
                this.$router.push({name: 'BootcampWaitPage'});
                loader.hide()
            } catch (error) {
                loader.hide()
                this.$emit("closemodal")
                swal({
                    title: "Error!",
                    text: error?.response?.data?.message ?? "Try again shortly" 
                })
                console.error(error);
            }
        }
    },
};
</script>

<style></style>
