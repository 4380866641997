<template>
  <div>
    <div class="container md:p-0">
      <button
        @click="paymentRouter()"
        class="my-4 md:my-12 font-body text-primary-green flex items-center"
      >
        <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span>
        <span class="font-semibold uppercase text-xs">Back</span>
      </button>
      <div class="lg:w-5/6 my-8 font-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { decrypt } from "@/helpers/encrypt";

const store = useStore();
const router = useRouter();
const route = useRoute();
const settings = computed(() => {
  return store.state.auth.settings;
});

const paymentRouter = () => {
  if (route.name == "ChoosePayment") {
    router.push({
      name: "LearningDashboardIndex",
      params: { id: route?.params?.id },
    });
    return;
  }
  if (route.name == "SubPayment") {
    // TODO -- OPTIMIZE
    const openSlot = decrypt(route.params.id);
    router.push({
      name: "ChoosePayment",
      params: {
        id: route?.params?.id,
        openSlot: openSlot > 6 || openSlot?.id > 6 ? 2 : 1,
      },
    });
    return;
  }
  if (route.name == "Amount") {
    router.push({ name: "SubPayment", params: { id: route?.params?.id } });
    return;
  }
};

onMounted(() => {
  // if (settings?.value?.payment_active != 1) {
  //   router.push({ name: "LearningDashboardIndex" });
  // }
  store.dispatch("auth/attempt");
  store.dispatch("auth/siteSetting");
});
</script>

<style scoped></style>
