<template>
  <div
    class="w-full hidden md:block fixed top-0 left-0 right-0 dark:bg-[#333333] bg-white border-b-2 border-[#D6D6D6] dark:border-[#333333] z-[9999]"
    ref="nav"
  >
    <nav
      class="grid grid-flow-col items-center px-4 py-4 lg:px-8 md:py-4 font-body-new container"
    >
      <h1
        class="font-header-1 font-semibold text-2xl text-body-text dark:text-[#e6e6e6]"
      >
        {{ $route?.meta?.sub_title ?? "Dashboard" }}
      </h1>
      <nav
        class="flex items-center font-body-new justify-end space-x-12 relative"
      >
        <a
          href="https://wa.me/2347042164117"
          target="_blank"
          class="text-xs flex items-center text-[#9E9E9E] font-medium"
        >
          <span
            class="iconify text-[#999999] inline text-2xl mr-1"
            data-icon="heroicons:question-mark-circle-20-solid"
          ></span>
          HELP
        </a>
        <Notification />
      </nav>
      <!-- Help Section  -->
      <div
        class="flex flex-row items-center justify-end font-body-new w-fit place-self-end"
      >
        <div
          class="font-body-new text-right space-y-1 dark:text-[#E6E6E6] text-[#0C180C]"
        >
          <h3 class="font-bold text-sm">
            {{ `${user?.first_name} ${user?.last_name}` }}
          </h3>
          <p class="text-[10px] font-medium">{{ user?.user_id ?? " " }}</p>
        </div>
        <Menu as="div" class="relative" ref="profileDropDown" v-slot="{ open }">
          <MenuButton>
            <div
              class="flex items-center cursor-pointer"
              @click="showDropDown = true"
            >
              <button
                class="ml-4 w-10 h-10 rounded-full bg-transparent font-medium overflow-clip"
              >
                <!-- <img
                  v-if="user?.avatar"
                  :src="user.avatar"
                  :alt="user?.first_name"
                /> -->
                <Image :src="user?.avatar_new" />
              </button>
              <span
                class="iconify text-[#999999]"
                data-icon="ant-design:caret-down-outlined"
              ></span>
            </div>
          </MenuButton>
          <div v-show="showDropDown == true && open">
            <MenuItems class="absolute right-5 top-8 w-56" static>
              <ProfileDropDown />
            </MenuItems>
          </div>
        </Menu>
      </div>
    </nav>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItems,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { mapActions, mapGetters } from "vuex";
import Image from "./Image.vue";
import Notification from "@/components/lms/NotificationComponent.vue";
import ProfileDropDown from "@/components/lms/ProfileDropDownComponent.vue";
import StreakBar from "@/components/lms/StreakBarComponent.vue";
import QuizRating from "@/views/lms/lessons/quiz/QuizRating.vue";
import StarRating from "vue-star-rating";
import axios from "axios";
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      userStreak: "lms/userStreak",
      userCourse: "auth/userCourse",
      prevBatches: "auth/prevBatches",
    }),
    /**
     * Rank data
     * @return { Object }
     */
    userRank() {
      // 0 star- Rookie -0-10
      // 1 star- Captain -10-30
      // 2 stars- Colonel -30-50
      // 3 stars- General -50-70
      // 4 stars- Field Marshal -70-100
      const userStreak = this.userStreak.streak;
      if (userStreak >= 0 && userStreak <= 10) {
        return {
          star: 0,
          image:
            "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
          title: "Rookie",
        };
      }
      if (userStreak >= 11 && userStreak <= 30) {
        return {
          star: 1,
          image:
            "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Captain.png",
          title: "Captain",
        };
      }
      if (userStreak >= 30 && userStreak <= 50) {
        return {
          star: 2,
          image:
            "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Colonel.png",
          title: "Colonel",
        };
      }
      if (userStreak >= 50 && userStreak <= 70) {
        return {
          star: 3,
          image:
            "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/General.png",
          title: "General",
        };
      }
      if (userStreak >= 70) {
        return {
          star: 4,
          image:
            "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Marshal.png",
          title: "Field Marshal",
        };
      }
      return {
        star: 0,
        image:
          "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/ranks/Rookie.png",
        title: "Rookie",
      };
    },
  },
  components: {
    Notification,
    ProfileDropDown,
    Menu,
    Image,
    MenuButton,
    MenuItems,
    StreakBar,
    QuizRating,
    StarRating,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    VueCountdown,
  },
  data() {
    return {
      showNotification: false,
      showDropDown: false,
      close: false,
      lessonPagesArray: [
        "LessonsContainer",
        "LessonPage",
        "TakeQuizPage",
        "QuizInstructionPage",
        "QuizPreviewPage",
      ],
      averageScore: 0,
      totalScore: 0,
      previousBatches: null,
      switchNewBatch: null,
    };
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    convertTime(date) {
      const d = new Date(date).toDateString();
      return d;
    },
    closeDropDown() {
      if (this.showDropDown == false) {
        return;
      } else if (this.showDropDown == true && this.close) {
        this.close = false;
        return (this.showDropDown = false);
      }
      return;
    },
    countDown(time) {
      const now = new Date();
      var date = new Date(time);
      // add a day
      if (date.getDay() == 6) {
        date.setDate(date.getDate() + 3);
      } else if (date.getDay() == 7) {
        date.setDate(date.getDate() + 2);
      } else {
        date.setDate(date.getDate() + 1);
      }
      const update_at = new Date(date);
      return update_at - now;
    },
    async switchBatch() {
      const loader = this.$loading.show();
      try {
        await axios.post("auth/batch/switch", {
          batch_id: this.switchNewBatch,
        });
        await this.attempt();
        this.$router.push({ name: "LearningDashboardIndex" });
        localStorage.removeItem("watched");
        location.reload();
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
  },
  watch: {
    $route() {
      // this.showNotification = false;
      this.showDropDown = false;
    },
    switchNewBatch() {
      this.switchBatch();
    },
  },
  mounted() {},
  created() {
    // document.addEventListener('click', this.closeDropDown)
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}
</style>
