<template>
  <main>
    <div>
      <p class="my-3 text-sm">
        For further enquiries kindly send a mail to accounts@sidehustle.ng
      </p>

      <p class="my-7">
        <strong
          >NOTE: Payment will be updated within
          {{ paymentUpdateTime ?? "24" }} hours</strong
        >
      </p>

      <div
        class="border-[#B5DAB4] border mt-12 mb-5 py-6 px-6 bg-[#F8FCF8] rounded-md"
      >
        <p
          class="border-b-[0.3px] font-bold pb-2 mb-3 text-sm border-b-[#BBDDBB]"
        >
          You’ll pay
          <span class="float-right text-[#52A350] text-[14px]"
            ><span class="font-sans">&#8358;</span>
            {{ integerFormatter(data?.amount) ?? "00.00" }}</span
          >
        </p>
        <ol class="list-decimal list-inside text-sm space-y-2">
          <li class="">Open Raven app on your phone</li>
          <li class="">Click on send money</li>
          <li class="">Input your login details</li>
          <li class="">Click on Raven User</li>
          <li class="">
            Enter <span class="font-bold">“sidehustle”</span> as the username
            <span
              class="ml-2 text-sm font-bold cursor-pointer text-[#52A350]"
              @click="copy('sidehustle')"
              >COPY</span
            >
          </li>
          <li class="">
            Enter
            <strong>
              <span class="font-sans">&#8358;</span>
              {{ integerFormatter(data?.amount) ?? "00.00" }}</strong
            >
            as the amount
          </li>
          <li class="">Click on find user</li>
          <li class="">
            Kindly confirm and agree that Adefunke Adeniyi is the user you
            intend to transfer to
          </li>
          <li class="">
            Enter "<strong>{{ data?.sh_ref ?? "" }}</strong
            >" as your narration
            <span
              @click="copy(data?.sh_ref ?? '')"
              class="ml-2 cursor-pointer text-sm font-bold text-[#52A350]"
              >COPY</span
            >
          </li>
          <li class="">Click on complete payment</li>
        </ol>
      </div>

      <button
        @click="confirmPayment(data.sh_ref)"
        class="bg-primary-green text-[#FFFFFF] font-semibold text-[18px] rounded-md w-full py-4 px-"
      >
        I have paid <span class="font-sans">&#8358;</span>
        {{ integerFormatter(data?.amount) ?? "00.00" }}
      </button>
    </div>
  </main>
</template>

<script setup>
import { integerFormatter } from "@/helpers/customFormatter.js";
</script>

<script>
import axios from "axios";
export default {
  props: ["data"],
  computed: {
    paymentUpdateTime() {
      var date = new Date();
      if (date.getDay() == 5) {
        return 72;
      } else if (date.getDay() == 6) {
        return 48;
      } else {
        return 24;
      }
    },
  },
  methods: {
    async confirmPayment(id) {
      try {
        await axios.post("auth/payments/user-confirm", { reference_id: id });
        return this.$router.push({ name: "ChoosePayment" });
      } catch (error) {
        console.error(error);
        return this.$router.push({ name: "ChoosePayment" });
      }
    },
    copy(Text) {
      const copyText = document.createElement("textarea");
      copyText.value = Text;
      document.body.appendChild(copyText);
      copyText.select();
      document.execCommand("copy");
      document.body.removeChild(copyText);
      if (document.execCommand("copy")) {
        swal({
          title: "Success",
          text: "Copied",
        });
      }
    },
  },
};
</script>

<style></style>
