<template>
  <div>
    <div class="container md:p-0">
      <button
        @click="$router.push({ name: 'LearningDashboardIndex' })" class="font-body-new text-primary-green flex items-center my-4 md:my-12"
      >
        <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span>
        <span class="font-semibold uppercase text-xs">Back</span>
      </button>
      <div class="md:w-5/6">
        <div
          class="p-4 md:p-6 bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body grid grid-flow-row md:grid-flow-col justify-between items-start gap-12 md:gap-24"
        >
          <div>
            <h3
              class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl"
            >
              {{ team?.title ?? " " }}
            </h3>
            <p
              class="font-normal text-[#666666] dark:text-[#F4F4F4] text-sm mt-3 mb-12"
            >
              {{ team?.description ?? " " }}
            </p>
            <div v-if="team && !team?.team_lead && !team.submission">
              <a class="text-primary-green font-medium" :href="team_lead_application_form" target="_blank" rel="noopener noreferrer">
              Submit your application for team lead here
              </a>
            </div>
          </div>
          <div
            class="rounded bg-[#F7F7F7] dark:bg-[#0000004D] p-3 md:p-4 font-body space-y-3 w-fit"
          >
            <h4 class="font-semibold text-[#222222] dark:text-[#E5E5E5]">
              This team has:
            </h4>
            <p class="text-[#666666] dark:text-[#E5E5E5] text-xs uppercase">
              <span
                class="font-semibold text-[#222222] dark:text-[#F4F4F4] text-3xl mr-3"
                >{{ team?.count ?? "0" }}</span
              >
              MEMBERS
            </p>
          </div>
        </div>
        <!-- Task Submission Form -->
        <form v-if="(team?.team_lead == user?.id) && !team?.submission" @submit.prevent="submitTask()" class="mt-10 mb-4 w-full">
          <div class="flex flex-col space-y-4">
            <h2 class="font-header-1 font-bold text-xl text-[#0C0C0C]'] dark:text-[#E5E5E5]">
              Submit the link to Task
            </h2>
            <input required type="url" v-model="submission_url" name="submission_url" id="submission_url" placeholder="Paste link to task here"
              class="w-full px-3 py-2 mb-2 inline-block valid:border-green-500 invalid:border-red-500" />
          </div>
          <button type="submit"
            class="mt-3 bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-4 py-2 text-white w-fit">Submit
            task</button>
        </form>
        <!-- Task Submission Form -->
        <!-- Task Submission Result -->
        <div
          v-if="team?.submission"
          class="p-4 md:p-6 bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body grid grid-flow-row md:grid-flow-col justify-between items-start gap-12 md:gap-24 mt-12"
        >
          <div class="space-y-8">
            <p
              class="font-normal text-[#666666] dark:text-[#F4F4F4]"
            >
              The task for your team has been submitted.
            </p>
            <a v-if="team?.submission?.submission_url" class="text-primary-green text-sm font-medium inline-block" :href="team?.submission?.submission_url" target="_blank" rel="noopener noreferrer">
              Task submission URL
            </a>
          </div>
          <div
            class="rounded bg-[#F7F7F7] dark:bg-[#0000004D] p-4 md:p-6 font-body space-y-3 w-fit flex items-center flex-col"
            v-if="team?.submission?.score"
          >
            <h4 class="font-semibold text-[#222222] dark:text-[#E5E5E5]">
              Score:
            </h4>
            <span class="font-semibold text-[#222222] text-3xl">
              {{team?.submission?.score ?? "0" }}%
            </span>
          </div>
          <div
            class="rounded bg-[#F7F7F7] dark:bg-[#0000004D] p-4 md:p-6 font-body space-y-3 w-fit flex items-center flex-col"
            v-else
          >
            <h4 class="font-semibold text-[#222222] dark:text-[#E5E5E5]">
              Task has yet to be graded.
            </h4>
          </div>
        </div>
        <!-- Task Submission Result -->
        
        <!-- Team Members -->
        <div class="relative my-8" v-if="team?.user_teams?.length > 0">
            <div
              class="flex flex-col items-stretch md:flex-row space-y-8 md:space-x-8 md:space-y-0 h-64 md:h-fit flex-nowrap py-6 overflow-x-auto customized-scrollbar md:px-8"
            >
                <div
                  class="p-4 md:p-6 bg-[#FFFFFF] rounded-lg shadow-lg font-body w-full md:min-w-[35%] md:w-[35%] flex flex-col"
                  v-for="user in team.user_teams" :key="user.id"                  
                >
                  <span
                    v-if="team.team_lead == user.user_id" 
                    class="py-1 px-3 text-[10px] font-semibold bg-orange-400 w-fit rounded-full">
                    Team Lead
                  </span>
                  <h3
                    class="font-semibold text-[#222222] text-lg mb-6 mt-4"
                  >
                    {{ `${user.user.first_name} ${user.user.last_name}` }}
                  </h3>
                  <p
                    class="font-normal text-[#666666] text-xs mt-auto mb-3"
                  >
                    {{ `${user.user.user_id}` }}
                  </p>
                </div>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const $loading = inject("$loading");
const store = useStore();
const router = useRouter();

const user = computed(() => {
  return store.state.auth.user;
});
const settings = computed(() => {
  return store.state.auth.settings;
});

const team_lead_application_form = ref('https://forms.gle/uuV9q8vm4LwVg8mX9');
const team = ref(null);

const submission_url = ref('');

const fetchUserTeam = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("v2/auth/teams/team");
    team.value = data?.data;
    loader.hide();
  } catch (error) {
    loader.hide();
    if (error.response.status == 401) {
      router.push({ name: "LearningDashboardIndex" });
      return;
    }
    console.error(error);
  }
};

const submitTask = async () => {
  const payload = {
    team_id: team.value.id,
    submission_url: submission_url.value
  }
  const loader = $loading.show();
  try {
    const { data } = await axios.post("v2/auth/teams/tasks/submission", payload);
    team.value = data?.data;
    loader.hide();
  } catch (error) {
    loader.hide();
    if (error.response.status == 401) {
      router.push({ name: "LearningDashboardIndex" });
      return;
    }
    console.error(error);
  }};

onMounted(() => {
  if (user?.value?.cohort >= 10) {
    return router.push({ name: "LearningDashboardIndex" });
  }
  fetchUserTeam();
});
</script>

<style scoped></style>
