<template>
  <main
    class="bg-gradient-to-r pt-5 md:pt-12 lg:pt-0 font-body-new from-[#ECF2FE] to-[#F1F8F1] pb-12"
  >
    <section class="text-left mb-9 bg-transparent lg:mb-6 space-y-2 container">
      <button
        class="text-primary-green font-body-new text-xs font-semibold uppercase mb-8"
        @click="$router.push({name: 'SelectBatchPage'})"
      >
        <span class="iconify inline" data-icon="eva:arrow-ios-back-fill"></span>
        BACK
      </button>
      <h1 class="font-bold text-[#0C180C] text-4xl">
        Choose a course to learn
      </h1>
      <p class="">
        Available courses in {{ `${categoryTitle}` ?? "this" }} category
      </p>

      <div class="pb-7 pt-12 lg:py-0">
        <Listbox as="div" class="lg:hidden relative rounded-lg font-body">
          <div class="flex items-center">
            <span class="text-[#0C180C] font-semibold mr-3">Sort by:</span>
            <ListboxButton
              @click="toggleList"
              class="rounded-full text-xs font-body px-3 py-1 flex items-center text-[#52A350] border border-[#52A350] capitalize bg-[#EEF7EE]"
              >{{ categoryTitle }}
              <span
                class="iconify text-[#52A350]"
                data-icon="material-symbols:keyboard-arrow-down-rounded"
              ></span>
            </ListboxButton>
          </div>
          <transition
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-300 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
          >
            <ListboxOptions
              static
              v-show="open"
              class="shadow-[4px_18px_15px_-3px_rgba(0,0,0,0.5)] text-xs rounded-md flex flex-col items-start text-left absolute z-50 w-fit"
            >
              <button
                v-if="selectedCategory != 'all'"
                @click="
                  selectedCategory = 'all';
                  closeList();
                "
                class="text-left w-full rounded-t-md py-2 px-3 bg-[#fff] hover:text-[#fff] hover:bg-[#52A350] transition-colors ease-linear duration-100"
              >
                {{ "All Categories" }}
              </button>
              <template v-for="(category, index) in categories" :key="index">
                <button
                  @click="
                    selectedCategory = category.searchParam;
                    closeList();
                  "
                  v-if="category.searchParam != selectedCategory"
                  class="py-2 px-3 bg-[#fff] hover:text-[#fff] hover:bg-[#52A350] transition-colors ease-linear duration-100 w-full last-of-type:rounded-b-md text-left"
                >
                  {{ category.title }}
                </button>
              </template>
              <button
                v-if="selectedCategory != 'without laptop'"
                @click="
                  selectedCategory = 'without laptop';
                  closeList();
                "
                class="text-left w-full rounded-t-md py-2 px-3 bg-[#fff] hover:text-[#fff] hover:bg-[#52A350] transition-colors ease-linear duration-100"
              >
                {{ "Without Laptop" }}
              </button>
              <button
                v-if="selectedCategory != 'requires laptop'"
                @click="
                  selectedCategory = 'requires laptop';
                  closeList();
                "
                class="text-left w-full rounded-t-md py-2 px-3 bg-[#fff] hover:text-[#fff] hover:bg-[#52A350] transition-colors ease-linear duration-100"
              >
                {{ "Requires Laptop" }}
              </button>
            </ListboxOptions>
          </transition>
        </Listbox>
      </div>

      <!-- for desktop -->
      <div class="lg:w-fit pt-12 lg:py-9 hidden lg:block">
        <div class="grid grid-flow-col gap-4 mt-4">
          <button
            @click="selectedCategory = 'all'"
            :class="
              selectedCategory == 'all'
                ? 'bg-[#EEF7EE] text-[#52A350] border-[#52A350]'
                : 'bg-white'
            "
            class="rounded-full px-3 py-2 hover:text-[#52A350] border border-white hover:border hover:bg-[#EEF7EE] text-[11px]"
          >
            {{ "All Categories" }}
          </button>
          <template v-for="(category, index) in categories" :key="index">
            <button
              @click="selectedCategory = category.searchParam"
              :class="
                category.searchParam == selectedCategory
                  ? 'bg-[#EEF7EE] text-[#52A350] border-[#52A350]'
                  : 'bg-white'
              "
              class="rounded-full px-3 py-2 hover:text-[#52A350] border border-white hover:border hover:bg-[#EEF7EE] text-[11px]"
            >
              {{ category.title }}
            </button>
          </template>
          <button
            @click="selectedCategory = 'requires laptop'"
            :class="
              selectedCategory == 'requires laptop'
                ? 'bg-[#EEF7EE] text-[#52A350] border-[#52A350]'
                : 'bg-white'
            "
            class="rounded-full px-3 py-2 hover:text-[#52A350] border border-white hover:border hover:bg-[#EEF7EE] text-[11px]"
          >
            Requires Laptop
          </button>
          <button
            @click="selectedCategory = 'without laptop'"
            :class="
              selectedCategory == 'without laptop'
                ? 'bg-[#EEF7EE] text-[#52A350] border-[#52A350]'
                : 'bg-white'
            "
            class="rounded-full px-3 py-2 hover:text-[#52A350] border border-white hover:border hover:bg-[#EEF7EE] text-[11px]"
          >
            Without Laptop
          </button>
        </div>
      </div>
    </section>
    <section class="bg-transparent font-body-new w-full">
      <div class="container grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7">
        <div
          class="bg-white rounded-xl lg:shadow=[0px_16px_30px_rgba(0,0,0,0.05)] shadow-[0_11.2px_21.15px_rgba(0,0,0,0.05)] border p-6 flex flex-col"
          v-for="course in courses"
          :key="course.id"
        >
          <div class="flex space-x-4 items-start">
            <button
              class="rounded-full px-3 cursor-default bg-[#FB8036] text-white text-[10px] w-fit"
              :class="course?.id == 16 || course?.id == 17 ? 'invisible' : ' '"
            >
              Requires Laptop
            </button>
          </div>
          <div class="mt-3 mb-12">
            <h3 class="mb-3 text-2xl font-bold capitalize text-[#0C180C]">
              {{ course.title }}
            </h3>
            <p class="font-body text-sm text-[#4A4B4A]">
              {{ course.description }}
            </p>
          </div>
          <button
            @click="
              $router.push({
                name: 'CoursePage',
                params: { id: course.id },
              })
            "
            class="!mt-auto w-fit px-6 py-2 text-primary-green bg-white transition-all ease-linear duration-200 hover:bg-primary-green hover:text-white font-semibold rounded-md text-sm border border-[#52A350]"
          >
            Select course
            <span
              class="iconify inline"
              data-icon="eva:arrow-ios-forward-fill"
            ></span>
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
  },
  data() {
    return {
      open: false,
      category: "",
      course_id: "",
      selectedCategory: "all",
      categories: [
        {
          searchParam: "marketing",
          icon: "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/icons/speaker.png",
          title: "Marketing",
          description:
            "learn how to promote a business and it’s product&services using online channels",
        },
        {
          searchParam: "frontend",
          icon: "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/icons/html5.png",
          title: "Frontend engineering",
          description:
            "learn how to plan, build, design, and implement website and software program user interface systems.",
        },
        {
          searchParam: "backend",
          icon: "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/icons/html5.png",
          title: "Backend engineering",
          description:
            "learn how to design, build, and maintain web application structures.",
        },

        {
          searchParam: "design",
          icon: "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/icons/pencil.png",
          title: "Design",
          description:
            "learn how to use the various design tools to create and execute your ideas.",
        },
        {
          searchParam: "product",
          icon: "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/icons/cloud.png",
          title: "Product",
          description:
            "learn how to oversee product development from conception to completion",
        },
        {
          searchParam: "data",
          icon: "https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/icons/code.png",
          title: "Data Science",
          description:
            "learn how to build and develop new cloud-based systems to meet specific business requirements.",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      fetchedCourses: "auth/courses",
    }),
    courses() {
      if (this.fetchedCourses) {
        if (this.selectedCategory == "all") {
          return this.fetchedCourses;
        } else if (this.selectedCategory == "without laptop") {
          return this.withoutLaptop;
        } else if (this.selectedCategory == "requires laptop") {
          return this.requiresLaptop;
        } else {
          return this.fetchedCourses.filter((item) => {
            return item.category.includes(this.selectedCategory);
          });
        }
      }
      return [];
    },
    categoryTitle() {
      if (
        this.selectedCategory == "requires laptop" ||
        this.selectedCategory == "without laptop"
      ) {
        return this.selectedCategory;
      } else if (this.categories && this.selectedCategory != "all") {
        return this.categories.find((item) => {
          return item.searchParam == this.selectedCategory;
        }).title;
      } else {
        return "all";
      }
    },
    withoutLaptop() {
      return this.fetchedCourses.filter((course) => {
        return course.id == 16 || course.id == 17;
      });
    },
    requiresLaptop() {
      return this.fetchedCourses.filter((course) => {
        return course.id != 16 && course.id != 17;
      });
    },
  },
  watch: {},

  methods: {
    ...mapActions({
      fetchAllCourses: "auth/fetchCourses",
    }),
    async fetchCourses() {
      let loader = this.$loading.show();
      await this.fetchAllCourses();
      loader.hide();
    },
    toggleList() {
      this.open = !this.open;
    },
    closeList() {
      this.open = false;
    },
  },
  mounted() {
    if (!this.fetchedCourses) {
      this.fetchCourses();
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  .radio-check-group {
    @apply grid grid-cols-1 md:col-span-2 lg:col-span-2 w-full font-normal;
  }
  @apply grid justify-center md:grid-cols-3 lg:grid-cols-5 gap-8;
  fieldset {
    @apply flex flex-col justify-end items-start col-span-2;
    select {
      @apply border border-[#DEDEDE] text-[#505050] font-body font-light text-sm rounded-lg p-4 bg-white w-full;
    }
    button[type="submit"] {
      @apply py-4 px-16 text-white bg-primary-green font-body rounded-md mt-8 lg:mt-12 w-full lg:w-min min-w-fit;
    }
  }
}
</style>
