<template>
  <div
    class="bg-white pt-10 md:pt-16 -mt-4 lg:-mt-12 text-[#404040] font-body-new pb-8 min-h-screen h-full"
  >
    <div
      @click="handleRedirect()"
      class="w-full container mb-8 text-sm flex items-center"
    >
      <span class="iconify inline" data-icon="eva:arrow-ios-back-fill"></span>
      <p
        class="text-left font-bold text-[#52A350] uppercase cursor-pointer"
      >
        back
      </p>
    </div>
    <div>
    <SelfPacedDeposit v-if="$route?.params?.slug == 'self_paced'" />
    <InstructorDeposit v-if="$route?.params?.slug == 'hands-on'" />
    
    </div>
  </div>
</template>
<script>
import SelfPacedDeposit from "./deposits/SelfPaced.vue"
import InstructorDeposit from "./deposits/Instructor.vue"
import { mapGetters } from "vuex";

export default {
  components:{
    SelfPacedDeposit,
    InstructorDeposit,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods:{
    handleRedirect() {
      let course_id = localStorage.getItem('select-course');
      if (course_id) {
        return this.$router.push({ name: 'CoursePage', params: { id: course_id }, })
      } else {
        return this.$router.push({name: 'SelectCorse'});
      }
    },
  },
  mounted() {
    if(this.user?.cohort < 8) {
      return this.$router.push({ name: 'LearningDashboardIndex' })
    }
  }
}
</script>

<style>

</style>