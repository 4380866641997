import swal from "sweetalert";

export const  getOS = async () => {
  var userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform ||
      window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;
  if (macosPlatforms.indexOf(platform) != -1) {
    return "Mac OS";
  } else if (iosPlatforms.indexOf(platform) != -1) {
    return "iOS";
  } else if (windowsPlatforms.indexOf(platform) != -1) {
    return "Windows";
  } else if (/Android/.test(userAgent)) {
    return "Android";
  } else if (/Linux/.test(platform)) {
    return "Linux";
  }
};

export const share = async (file, file_name, text, url) => {
  const response = await fetch(file);
  const blob = await response.blob();
  const filesArray = [
    new File([blob], `${file_name}.jpg`, {
      type: "image/jpeg",
      lastModified: new Date().getTime(),
    }),
  ];
  const shareData = {
    files: filesArray,
    url,
    text,
  };
  if (! navigator.userActivation.isActive) {
    swal({
      title: "Proceed to share",
      button: "Share",
    }).then(() => {
      navigatorSharer(shareData)        
    })
    return;
  }
  navigatorSharer(shareData)        
  return;
};


const navigatorSharer = (shareData) => {
  navigator.share(shareData).then(()=>{
    // 
  }).catch((err)=>{
    console.error(err);
    downloadFile(file, file_name)
  });
}

const downloadFile = (file, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  } else {
    // Others
    var a = document.createElement("a");
    a.href = file;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(file);
    }, 0);
  }
}