<template>
  <section class="my-16 lg:my-24">
    <h3
      class="font-semibold font-body text-[#1A1F1A] text-xl text-center"
      @click="handleFootPrintScroll"
    >
      We’ve got our footprint
    </h3>
    <section
      class="flex flex-nowrap overflow-hidden items-center justify-between w-full mt-16"
    >
      <div id="box1" class="flex flex-nowrap items-center justify-around">
        <img src="@/assets/img/logo/entry-level.svg" alt="Entry Level" />
        <img src="@/assets/img/logo/keytrust.svg" alt="KeyTrust Bank" />
        <img src="@/assets/img/logo/reina.svg" alt="Reina Lake" />
        <img src="@/assets/img/logo/trinipicks.svg" alt="Trinipicks" />
        <img src="@/assets/img/logo/spurt.svg" alt="Spurt" />
      </div>
      <div id="box2" class="flex flex-nowrap items-center justify-around">
        <img
          src="@/assets/img/logo/entry-level.svg"
          class="ml-8 lg:ml-4"
          alt="Entry Level"
        />
        <img src="@/assets/img/logo/keytrust.svg" alt="KeyTrust Bank" />
        <img src="@/assets/img/logo/reina.svg" alt="Reina" />
        <img src="@/assets/img/logo/trinipicks.svg" alt="Trinipicks" />
        <img src="@/assets/img/logo/spurt.svg" alt="Spurt" />
      </div>
    </section>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
section img {
  @apply mx-8 lg:mx-12 hover:scale-110 cursor-pointer transition ease-linear h-8 w-fit lg:h-auto;
}

section img:hover #box1 {
  border: red solid 1rem;
}

#box1 {
  animation: marquee 42s linear infinite;
}
#box1,
#box2 {
  @apply min-w-[182%] sm:min-w-[155%] md:min-w-[75%] lg:min-w-[145%] px-0;
}
#box2 {
  animation: marquee2 42s linear infinite;
  animation-delay: 21s;
}

@keyframes marquee {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes marquee2 {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0%);
  }
}
</style>
