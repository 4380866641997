<template>
  <main class="container px-4 md:px-0">
    <div >
      <template v-if="!currentWeekScore">
        <h1
          class="font-header-1 font-extrabold text-2xl md:text-3xl dark:text-white text-[#0C0C0C]"
        >
          {{`${weekModule()} ${week} Quiz`}}
        </h1>
        <div class="grid-flow-row gap-12 py-4 lg:w-4/6">
          <p class="font-header-1 font-semibold mb-3 dark:text-[#FFFFFF] text-[#242424]">
            INSTRUCTIONS
          </p>
          <div
            class="font-body text-sm leading-12 dark:text-[#FFFFFF] text-[#606060]"
          >
            <p>
              <span
                class="inline-flex items-center justify-center p-1 w-6 h-6 text-xs rounded-full text-[#606060] bg-[#EDEBEB] mr-4"
                >1</span
              >
              All questions are multiples choice questions
            </p>
            <p >
              <span
                class="inline-flex items-center justify-center p-1 w-6 h-6 text-xs rounded-full text-[#606060] bg-[#EDEBEB] mr-4"
                >2</span
              >You have only one shot at each question
            </p>
            <p>
              <span
                class="inline-flex items-center justify-center p-1 w-6 h-6 text-xs rounded-full text-[#606060] bg-[#EDEBEB] mr-4"
                >3</span
              >Go conquer the world champ!👍🏽
            </p>
          </div>
            <router-link
              :to="{ name: 'TakeQuizPage', params: { week: encrypt(week) } }"
            >
              <button
                class="font-body font-semibold text-white bg-primary-green rounded-md py-6 md:py-3 px-12 hover:text-primary-green hover:bg-white hover:outline hover:outline-primary-green hover:outline-1 transition-all ease-linear max-h-fit w-full lg:max-w-fit my-8 text-sm"
              >
                Take Quiz
              </button>
            </router-link>
        </div>
      </template>
      <template v-if="currentWeekScore">
        <div
          class="border-b flex items-center justify-between pb-16 md:py-4 lg:w-4/6"
        >
          <div
            class="grid gap-4 grid-flow-col place-items-center dark:text-white text-[#242424]"
          >
            <h5 class="font-header-1 font-bold text-xl">Quiz Score:</h5>
            <div class="flex items-center justify-between">
              <QuizRating :score="currentWeekScore.score" />
              <span class="font-body font-semibold text-3xl md:text-4xl">{{
                currentWeekScore.score
              }}</span>
            </div>
          </div>
          <!-- Only  User who score above 60 should see Review button  -->
          <button
            v-if="currentWeekScore.score >= 70"
            @click="
              $router.push({
                name: 'QuizPreviewPage',
                params: { id: encrypt(currentWeekScore.id) },
              })
            "
            class="p-2 md:py-3 md:px-6 border border-primary-green text-sm md:text-base text-primary-green font-body font-semibold rounded-md"
          >
            Review Quiz
          </button>
          <!-- User who score below 60 should see retake button instead -->
          <button
            v-if="currentWeekScore.score < 70"
            @click="
              $router.push({
                name: 'ReTakeQuizPage',
                params: {
                  week: encrypt(currentWeekScore.week),
                  retake: true, // pass a retake @ param -- Boolean
                },
              })
            "
            class="p-2 md:py-3 md:px-6 border border-primary-green text-sm md:text-base text-primary-green font-body font-semibold rounded-md"
          >
            Retake Quiz
          </button>
        </div>
      </template>
    </div>
  </main>
</template>

<script setup>
import {  weekModule } from "@/helpers/learningController";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { useStore } from "vuex";
import { onMounted, ref, reactive } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
import { encrypt, decrypt } from "@/helpers/encrypt";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
import QuizRating from "./QuizRating.vue";
const axios = inject("axios");
const route = useRoute();
const $loading = inject("$loading");
const store = useStore();
let submittedQuizzes = ref([]);
let questions = ref([]);
const selected = computed(() => {
  return questions.value.filter((item) => item.option).length;
});
const week = computed(() => {
  return route.params.week ? decrypt(route.params.week) : null;
});
const user = computed(() => {
  return store.state.auth.user;
});
const siteSettings = computed(() => {
  return store.state.auth.settings;
});
onMounted(async () => {
  // setTimeout(()=>{
  //   console.log(course?.course?.resources_link[0]);
  //   console.log(user);

  // }, 5000)
  const loader = $loading.show();
  try {
    const { data } = await axios.get("auth/quizzes/submitted");
    submittedQuizzes.value = data.payload;
    loader.hide();
  } catch (error) {
    console.log(error);
    loader.hide();
  }
});

const test = (x)=>{
  console.log(x);
}

const currentWeekScore = computed(() => {
  /**
   * Returns only the score for the week
   */
  if (submittedQuizzes.value.length > 0) {
    const currWeekQuiz = submittedQuizzes.value.filter(
      (item) => item.week == week.value
    );
    return currWeekQuiz[0];
  } else {
    return;
  }
});
</script>

<style scoped></style>