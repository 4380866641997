<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center p-4 z-50"
    @click="closeModal()"
  >
    <div
      class="flex flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 py-12 space-y-6 md:w-3/5 text-left"
      ref="applicationSuccessModal"
    >
      <h2
        class="text-[#0C0C0C]'] dark:text-[#E5E5E5] font-header-1 font-extrabold md:text-3xl text-xl px-4 md:px-0 md:mb-5 -mt-6"
      >
        Pay for certificate to enjoy unlimited access
      </h2>
      <div class="flex w-full relative">
        <div class="w-full ml-4">
          <ul class="-mt-5 mb-8">
            <li
              class="text-sm md:mb-4 mb-2 w-full items-center flex gap-5 text-left text-[#0C0C0C]'] dark:text-[#E5E5E5]"
            >
              <i class="fa-solid fa-check text-green-500"></i>
              <span> Access to modules 3 & 4 </span>
            </li>
            <li
              class="text-sm md:mb-4 mb-2 w-full items-center flex gap-5 text-left text-[#0C0C0C]'] dark:text-[#E5E5E5]"
            >
              <i class="fa-solid fa-check text-green-500"></i>
              <span> Certificate of completion </span>
            </li>
            <li
              class="text-sm md:mb-4 mb-2 w-full items-center flex gap-5 text-left text-[#0C0C0C]'] dark:text-[#E5E5E5]"
            >
              <i class="fa-solid fa-check text-green-500"></i>
              <span>Full life-time access to contents for this course</span>
            </li>
          </ul>
          <div class="flex items-center absolute md:bottom-0 -bottom-10 flex-row-reverse mb-2">
            <button
              v-if="paymentDeadline > 0"
              @click="this.$emit('closemodal')"
              class="text-[#6E736E] bg-[#EFF2EF] whitespace-nowrap dark:text-[#B3B3B3] rounded-xl font-semibold font-body w-fit py-3 text-center px-6 text-xs md:text-md"
            >
              Pay Later
            </button>
            <button
              @click="
                this.$emit('closemodal'),
                  $router.push({ name: 'ChoosePayment' })
              "
              class="bg-primary-green whitespace-nowrap rounded-md text-white font-semibold font-body w-fit p-3 mr-8 text-xs md:text-md"
            >
              Pay Now
            </button>
          </div>
        </div>
        <div
          class="flex items-start self-start justify-end w-full text-sm pt-8 md:-translate-y-20 -translate-y-12"
        >
          <img src="@/assets/img/badge.png" alt="badge" width="200" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["paymentDeadline"],
  methods: {
    closeModal() {
      if (this.paymentDeadline <= 0) return;
      const box = this.$refs.applicationSuccessModal;
      if (!box?.contains(event.target)) {
        return this.$emit("closemodal");
      }
      return;
    },
  },
};
</script>

<style></style>
