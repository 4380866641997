<template>
  <div>
    <div class="container md:p-0">
      <BackButton class="my-4 md:my-12" />
      <template v-if="user?.batch_id == 6 ? (user?.learning_type == 'instructor'? true:false): true">
        <div
          class="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-3 my-8"
          v-if="liveClasses?.length > 0"
        >
          <template v-for="video in liveClasses" :key="video.id">
            <div
              class="p-4 md:p-6 bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body"
            >
              <h3
                class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl"
              >
                {{ video?.title ?? "Week 1 Live class" }}
              </h3>
              <p
                class="font-normal text-[#666666] dark:text-[#f4f4f4] text-sm my-3"
              >
                {{ video?.description ?? "Topic of the live class goes here" }}
              </p>
              <a
                :href="video?.url"
                target="_blank"
                class="font-semibold text-primary-green text-sm mt-auto"
              >
                VIEW
                <span
                  class="iconify inline-block"
                  data-icon="bi:arrow-up-right"
                ></span>
              </a>
            </div>
          </template>
        </div>
        <div
          class="my-8 font-body font-medium dark:text-[#FAFAFA]"
          v-if="liveClasses?.length == 0"
        >
          There are no live class recordings available for
          {{ user?.course?.title ?? "your track" }}.
        </div>
      </template>
      <template v-else>
        <div
          class="flex flex-col space-y-4 font-body-new text-sm text-body-text dark:text-[#e6e6e6] items-center justify-center text-center pt-8"
        >
          <img src="@/assets/img/icons/empty-box.png" alt="" />
          <p>Live class not available</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
const $loading = inject("$loading");

const store = useStore();

const user = computed(() => {
  return store.state.auth.user;
});

const liveClasses = ref(null);

const fetchLiveClasses = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("/auth/classes/videos");
    liveClasses.value = data.payload;
    loader.hide();
  } catch (error) {
    loader.hide();
    liveClasses.value = [];
    console.log(error);
  }
};
onMounted(() => {
    fetchLiveClasses();
    // console.log(this.user);

});
</script>

<style scoped></style>
