<template>
  <main class="lg:-mt-8 lg:px-8 md:pt-16 px-4 pt-8">
    <div
      @click="$router.push({ name: 'LearningDashboardIndex' })"
      class="font-body text-primary-green flex items-center cursor-pointer my-4"
    >
      <span
        class="iconify mr-2 text-2xl font-extralight"
        data-icon="entypo:chevron-with-circle-left"
      ></span>
      <span class="font-semibold">Go to Dashboard</span>
    </div>
    <h2
      class="font-header-1 font-bold dark:text-[#E5E5E5] text-black text-3xl text-left mb-4"
    >
      Open Raven bank account
    </h2>

    <p class="dark:text-[#E5E5E5] text-black mt-4 mb-4 font-body text-sm">
      Create a Raven Bank account to qualify to win amazing prizes
    </p>
    <div
      class="lg:w-5/12 text-left text-sm flex flex-col dark:text-[#E5E5E5] text-black"
    >
      <p class="font-body dark:text-[#E5E5E5] text-black">
        Stand a chance to win amazing prizes at the Side Hustle Internship 7.0
        when you register on the Raven bank app with the Side Hustle referral
        link or referral code <b>“sidehustle”</b>
      </p>
      <h4 class="font-bold font-body mt-8 mb-4">What’s in it for you</h4>
      <ul class="list-disc ml-6">
        <li class="mb-4">
          A chance to win a Cash prize of 1 million naira courtesy of Raven bank
        </li>
        <li class="mb-4">
          A chance to win a sponsorship deal for a special advisory package with
          Kentaur Venture Capital
        </li>
      </ul>
      <p class="font-medium">
        <em>
          Disclaimer- Only Interns who register with the Side Hustle referral
          link or referral code are eligible.
        </em>
      </p>
      <button @click="downloadRavenBankApp()" class="lg:hidden">
        Download Raven Bank App
      </button>
      <span
        @click="downloadRavenBankApp()"
        class="hidden cursor-pointer lg:block border-[#52A350] mt-8 font-semibold text-primary-green border-2 text-center rounded bg-[#F1F8F1] border-dashed p-4"
      >
        Download Raven Bank App on App Store or Play Store
      </span>

      <p
        @click="$router.push({ name: 'LearningRavenVerify' })"
        class="bg-transparent text-[#747874] dark:text-[#E5E5E5] font-body font-semibold text-center mt-8 lg:mt-4 mb-8 cursor-pointer"
      >
        I have a Raven Bank account already
      </p>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    downloadRavenBankApp() {
      window.open("https://ravenbank.page.link/Q4Xrtx8yonaeDUiy7", "_blank");
    },
  },
  mounted() {
    if (this.user.account) {
      this.$router.push({ name: "WelcomeDashboard" });
    }
  },
};
</script>

<style lang="scss" scoped>
button {
  @apply py-4 px-16 text-white bg-primary-green font-body rounded-md mx-auto mt-8 lg:mt-12 w-full lg:w-min min-w-fit;
}
</style>
