<template>
    <main>
      <div class="font-body-new">
        <p class="my-3 text-sm">
          For further enquiries kindly send a mail to accounts@sidehustle.ng
        </p>
  
        <p class="my-7">
          <strong
            >NOTE: Payment will be updated within
            {{ paymentUpdateTime ?? "24" }} hours</strong
          >
        </p>
        <div
          class="grid grid-flow-row gap-4 px-6 mt-7 shadow-[0px_16px_50px_rgba(37,77,36,0.05)] bg-[#F8FCF8] border border-[#B5DAB4] rounded-md pt-10 pb-4"
        >
          <p>
            Account Name :
            <span class="text-primary-green font-semibold">{{
              "SIDE HUSTLE NIGERIA LIMITED "
            }}</span>
          </p>
          <p>
            Account Number :
            <span class="text-primary-green font-semibold">{{
              "1101742023"
            }}</span>
            <span
              @click="copy(data?.account_number ?? '1101742023')"
              class="ml-2 cursor-pointer text-xs bg-primary-green rounded-md p-3 font-bold text-[#fff]"
              >COPY</span
            >
          </p>
          <p>
            Bank Name :
            <span class="text-primary-green font-semibold">{{
              "MINT-FINEX MFB"
            }}</span>
          </p>
          <p>
            Narration :
            <span class="text-primary-green font-bold">{{
              data?.sh_ref ?? " "
            }}</span>
            <span
              @click="copy(data?.sh_ref ?? '')"
              class="ml-2 cursor-pointer text-xs bg-primary-green rounded-md p-3 font-bold text-[#fff]"
              >COPY</span
            >
          </p>
          <p class="flex items-end justify-between">
            <span>Subtotal</span>
            <span class="font-bold"
              ><span class="font-sans">&#8358; </span
              >{{ integerFormatter(data?.amount) ?? "00.00" }}</span
            >
          </p>
          <p class="flex items-end justify-between">
            <span>Service Charge</span>
            <span class="font-bold"
              ><span class="font-sans">&#8358; </span
              >{{
                integerFormatter(data?.transaction_charge_amount) ?? "00"
              }}</span
            >
          </p>
          <p
            class="flex items-end justify-between border-t-[#BBDDBB] pt-2 border-t-[0.3px] font-bold"
          >
            Total
            <span class="text-primary-green"
              ><span class="font-sans">&#8358; </span
              >{{ integerFormatter(data?.amount) ?? "00.00" }}</span
            >
          </p>
        </div>
        <button
          @click="confirmPayment(data?.sh_ref)"
          class="bg-primary-green text-[#FFFFFF] font-semibold text-[18px] rounded-md w-full py-4 px-4 my-6"
        >
          I have paid <span class="font-sans">&#8358;</span
          >{{ integerFormatter(data?.amount) ?? "00.00" }}
        </button>
      </div>
    </main>
  </template>
  
  <script setup>
  import { integerFormatter } from "@/helpers/customFormatter.js";
  </script>
  
  <script>
  import axios from "axios";
  export default {
    props: ["data"],
    computed: {
      paymentUpdateTime() {
        var date = new Date();
        if (date.getDay() == 5) {
          return 72;
        } else if (date.getDay() == 6) {
          return 48;
        } else {
          return 24;
        }
      },
    },
    methods: {
      async confirmPayment(id) {
        try {
          await axios.post("auth/payments/user-confirm", { reference_id: id });
          return this.$router.push({ name: "ChoosePayment" });
        } catch (error) {
          console.error(error);
          return this.$router.push({ name: "ChoosePayment" });
        }
      },
      copy(Text) {
        const copyText = document.createElement("textarea");
        copyText.value = Text;
        document.body.appendChild(copyText);
        copyText.select();
        document.execCommand("copy");
        document.body.removeChild(copyText);
        if (document.execCommand("copy")) {
          swal({
            title: "Success",
            text: "Copied",
          });
        }
      },
    },
  };
  </script>
  
  <style></style>
  