<template>
  <main>
    <!-- <h2
      class="font-body-new font-bold text-body-text text-lg leading-6 text-center mb-6"
    >
      <span class="text-[#9B9B9B]">What is your</span> <br />
      highest level of qualification?
    </h2> -->
    <p class="font-body font-[800] text-[#242424]">
      What is your highest level of qualification?
    </p>
    <!-- <div class="flex flex-col font-light my-7 space-y-3">
      <label
        :for="`option_${index + 1}_${sub_index + 1}`"
        v-for="(item, sub_index) in question.options"
        :key="sub_index"
      >
        <input
          type="radio"
          :name="`question_${index + 1}`"
          :id="`option_${index + 1}_${sub_index + 1}`"
          @click="selectOption(question.id, item)"
          class="checked:text-primary-green focus:ring-0 border-2 border-[#D9D9D9] mr-2"
          
        />
        {{ item }}
      </label>
    </div> -->
    <form @submit.prevent="submit()" class="w-full">
      <!-- <fieldset class="grid grid-flow-row gap-4 w-full"> -->
      <fieldset class="flex flex-col font-light my-4 space-y-3">
        <div class="">
          <label for="undergraduate" class="">
            <input
              type="radio"
              v-model="level_of_education"
              name="level_of_education"
              id="undergraduate"
              value="undergraduate"
              class="w-5 h-5 my-auto mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Undergraduate
          </label>
        </div>
        <div class="">
          <label for="graduate" class="">
            <input
              type="radio"
              v-model="level_of_education"
              name="level_of_education"
              id="graduate"
              value="graduate"
              class="w-5 h-5 mr-2 text-green-600 bg-gray-100 border-gray-300 focus:ring-green-500 dark:focus:ring-green-600"
            />
            Graduate
          </label>
        </div>
      </fieldset>
      <button type="submit" :disabled="!level_of_education">Next</button>
    </form>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      level_of_education: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    submit() {
      let user = JSON.parse(localStorage.getItem("tempUser"));
      user.level_of_education = this.level_of_education;
      localStorage.setItem("tempUser", JSON.stringify(user));
      this.$emit("next");
    },
  },
  mounted() {
    if (localStorage.getItem("tempUser")) {
      this.level_of_education = JSON.parse(
        localStorage.getItem("tempUser")
      ).level_of_education;
    }
  },
};
</script>

<style lang="scss" scoped>
form {
  @apply flex flex-col;
  fieldset {
    .radio-check-group {
      @apply col-span-1 grid grid-cols-1;
      label {
        @apply font-body-new font-semibold text-sm text-[#3D403D] border border-[#D2D2D2] rounded-lg px-6 py-4 w-full cursor-pointer;
      }
    }
  }
  button[type="submit"] {
    @apply py-3 px-8 text-white bg-primary-green font-body-new rounded-md ml-auto mt-6 lg:mt-8;
  }
}
</style>
