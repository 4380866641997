<template>
  <main class="container px-4 md:px-0">
    <template v-if="certificate.file">
      <h1
        class="font-header-1 font-extrabold text-4xl text-center dark:text-white text-[#0C0C0C]"
      >
        Congratulations!!!
      </h1>
      <div
        class="grid place-items-start lg:grid-flow-col gap-12 py-4 w-full mt-16"
      >
        <img
          :src="certificate.file"
          :alt="user.first_name"
          id="downloadUserCertificate"
          class="rounded"
        />
        <div
          class="grid grid-cols-1 md:grid-cols-2 gap-8 font-body pt-12 w-full"
        >
          <button
            @click="downloadCert()"
            class="rounded-md bg-primary-green text-white font-semibold py-4 whitespace-nowrap px-6 min-w-fit"
          >
            Download Certificate
          </button>
          <button
            class="text-primary-green font-semibold min-w-fit whitespace-nowrap"
            @click="$router.push({ name: 'ProfilePage', params: { slot: 2 } })"
          >
            Explore more courses
            <span
              class="iconify inline"
              data-icon="akar-icons:arrow-right"
            ></span>
          </button>
          <details class="md:col-span-2" v-if="certificate.link">
            <summary
              class="text-[#8D998C] font-medium text-center md:text-left mb-2 -ml-2 cursor-pointer"
            >
              <span class="iconify inline" data-icon="bx:share"></span>
              Share
            </summary>
            <div class="grid grid-cols-2 gap-4">
              <a
                class="twitter-share-button rounded-md px-3 py-2 bg-[#1DA1F2] text-white min-w-fit text-sm"
                target="_blank"
                rel="noopener noreferrer"
                data-size="large"
                :href="`https://twitter.com/intent/tweet?text=Check out my Terra Learning Certificate ${certificate.link}`"
              >
                <i class="fa-brands fa-twitter"></i> Tweet
              </a>
              <a
                class="rounded-md px-3 py-2 bg-[#0077B5] text-white min-w-fit text-sm"
                :href="`https://www.linkedin.com/sharing/share-offsite/?url=${certificate.link}`"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-linkedin-in"></i> LinkedIn
              </a>
              <a
                class="rounded-md px-3 py-2 bg-[#4267B2] text-white min-w-fit text-sm"
                :href="`https://www.facebook.com/sharer/sharer.php?u=${certificate.link}`"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-facebook-f"></i> Facebook
              </a>
              <a
                class="rounded-md px-3 py-2 bg-[#25D366] text-white min-w-fit text-sm"
                :href="
                  'whatsapp://send?text=Check out my Terra Learning Certificate ' +
                  certificate.link
                "
                data-action="share/whatsapp/share"
              >
                <i class="fa-brands fa-whatsapp"></i> Whatsapp</a
              >
            </div>
          </details>
        </div>
      </div>
    </template>
    <template v-if="!certificate.file && showErrorMessage">
      <h1
        class="font-header-1 font-extrabold text-4xl text-center dark:text-white text-[#0C0C0C]"
      >
        Oops!!!
      </h1>
      <div
        class="text-center py-4 w-full mt-16 text-sm text-body-text dark:text-[#E5E5E5]"
      >
        {{ showErrorMessage }}
      </div>
    </template>
  </main>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { onMounted, ref, reactive, inject } from "vue";
import { useStore } from "vuex";
import { encrypt, decrypt } from "@/helpers/encrypt";
import { useRoute, useRouter } from "vue-router";
import { jsPDF } from "jspdf";

const axios = inject("axios");
const $loading = inject("$loading");

const store = useStore();
const route = useRoute();
const router = useRouter();

const certificate = ref({
  file: null,
  type: "",
  link: null,
});

const showErrorMessage = ref(null);

const course_id = computed(() => {
  return route.params.course ? decrypt(route.params.course) : null;
});

const user = computed(() => {
  return store.state.auth.user;
});

// jsPDF init
const pdf = new jsPDF({
  orientation: "landscape",
  unit: "in",
  format: [17.1875, 12.1458], // size of image
});

const downloadCert = () => {
  const file_name =
    user.value.first_name +
    "_" +
    user.value.last_name +
    "_Side_Hustle_Certificate";
  const img = document.getElementById("downloadUserCertificate");

  // PDF download
  const pdfArray = [2, 3, 5, 6, 14, 15];
  if (pdfArray.includes(certificate.value.type*1)) {
    const loader = $loading.show();
    pdf.addImage(img.src, 0, 0);
    const newBlob = new Blob([pdf.output("blob")], {
      type: "application/octet-stream",
    });
    let url = URL.createObjectURL(newBlob);
    var a = document.createElement("a");
    a.href = url;
    a.download = file_name + ".pdf";
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
    loader.hide();
    return;
  }

  // png download
  const basicArray = [1, 4, 13];
  if (basicArray.includes(certificate.value.type*1)) {
    const loader = $loading.show();
    let a = document.createElement("a");
    a.href = img.src;
    a.download = file_name;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(img.src);
    }, 0);
    loader.hide();
    return;
  }
  return;
};

/**
 * Check if user can download certificate
 */
const checkCertificate = async () => {
  const loader = $loading.show();
  axios
    .get("auth/certificate")
    .then((res) => {
      loader.hide();
      getCertificateFile(res.data.payload);
    })
    .catch((err) => {
      showErrorMessage.value = err.response.data.payload;
      loader.hide();
      swal({
        title: "Oops!",
        text: err?.response?.data?.payload ?? "Please try again shortly.",
      });
      if (localStorage.cert) {
        localStorage.removeItem("cert");
      }
      console.log(err);
    });
};

/**
 * Get the certificate file from localstorage or server
 */
const getCertificateFile = async (package_id) => {
  // if (localStorage.cert) {
  //     let cert = JSON.parse(localStorage.getItem('cert'))
  //     if (cert.email && cert.id) {
  //         if (user.value.email != decrypt(cert.email) || user.value.user_id != decrypt(cert.id) || package_id != decrypt(cert.type)) {
  //             localStorage.removeItem('cert')
  //         } else {
  //             certificate.value.file = 'data:image/png;base64,' + cert.file;
  //             certificate.value.type = decrypt(cert.type);
  //             if (cert.link) {
  //                certificate.value.link =  decrypt(cert.link);
  //             }
  //             return;
  //         }
  //     } else {
  //         localStorage.removeItem('cert')
  //     }
  // }
  const loader = $loading.show();
  axios
    .post("auth/certificate/download", { course_id: user?.value?.course_id })
    .then((res) => {
      loader.hide();
      certificate.value.file = "data:image/png;base64," + res.data.payload.file;
      certificate.value.type = res.data.payload.type;
      certificate.value.link = res.data.payload.link;
      // const storage = {
      //     id: encrypt(user.value.user_id),
      //     email: encrypt(user.value.email),
      //     type: encrypt(res.data.payload.type),
      //     link: encrypt(res.data.payload.link),
      //     file: res.data.payload.file,
      // };
      // localStorage.setItem('cert', JSON.stringify(storage))
    })
    .catch((error) => {
      loader.hide();
      router.push({ name: "AllCertificatesPage" });
      swal({
        title: "Oops!",
        text: error?.response?.data?.payload ?? "Please try again later",
      });
      console.error(error);
    });
};

onMounted(() => {
  checkCertificate();
});
</script>

<style></style>
