<template>
  <!-- <button @click="$router.push({name: 'ViewAssessmentTask'})" class="font-body-new text-primary-green flex items-center my-4 md:my-12">
      <span class="iconify" data-icon="eva:arrow-ios-back-fill"></span>
      <span class="font-semibold uppercase text-xs">Back</span>
  </button> -->
  <main class="mt-4 md:mt-20">
    <div
      class="font-body bg-white shadow-[0px_3.64384px_22.774px_rgba(0,0,0,0.08)] rounded-lg p-4 md:p-8 mb-8 grid md:grid-cols-2 justify-between gap-8 overflow-clip"
      v-if="
        baseline?.baseline_task?.graded == 0 && baseline?.baseline_task?.passed == null
      "
    >
      <div class="flex flex-col justify-between h-full space-y-4">
        <h1
          class="font-header-1 font-bold text-2xl leading-6 md:text-4xl md:leading-8"
        >
          Assessment submitted!
        </h1>
        <p class="text-sm">
          Congratulations on submitting your baseline assessment!
        </p>
        <p class="text-sm">
          You’ll receive an email regarding the status of your application
          within the next 1 week
        </p>
        <VueCountdown
          class="font-semibold"
          :time="countDown(baseline?.baseline_task?.created_at)"
          v-slot="{ days, hours, minutes, seconds }"
        >
          Time Left: {{ days }}days: {{ hours }}hrs : {{ minutes }}mins :
          {{ seconds }}secs
        </VueCountdown>
        <button
          @click="$router.push({ name: 'ViewAssessmentTask' })"
          class="bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-6 py-4 text-white text-sm mr-auto w-fit font-bold"
        >
          View task
        </button>
      </div>
      <img
        src="@/assets/img/img/office_img.png"
        alt=""
        class="ml-auto -mr-12 hidden md:block"
      />
    </div>
    <div
      class="font-body bg-white shadow-[0px_3.64384px_22.774px_rgba(0,0,0,0.08)] rounded-lg p-4 md:p-8 mb-8 grid md:grid-cols-2 justify-between gap-8 overflow-clip"
      v-if="baseline?.baseline_task?.passed == 1"
    >
      <div class="flex flex-col justify-between h-full space-y-4">
        <h1
          class="font-header-1 font-bold text-2xl leading-6 md:text-4xl py-2 md:leading-8 bg-[linear-gradient(90.5deg,#52A350_-1.26%,#0D68CE_12.41%,#52A350_28.83%,#FB8036_32.05%,#0951A1_35.57%)] text-transparent bg-clip-text"
        >
          Congratulations!
        </h1>
        <p class="text-sm">
          You passed the baseline assessment, we are pleased to inform you that
          you’ve gained admission into the internship for the 2023 cohort. Your
          learning path will begin on
          <strong
            >{{ userCourse?.batch?.start_on ?? " " }} ({{
              userCourse?.batch?.title ?? " "
            }}).</strong
          >
        </p>
        <div class="font-bold">
          <button
            @click="acceptAdmission()"
            class="bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-6 py-4 text-white text-sm mr-auto w-fit"
          >
            Accept Admission
          </button>
          <button
            v-if="false"
            @click="$router.push({ name: 'LearningDashboardIndex' })"
            class="bg-transparent rounded px-4 py-6 text-primary-green text-sm mr-auto w-fit"
          >
            Defer Admission
          </button>
        </div>
      </div>
      <img
        src="@/assets/img/img/fireworks.png"
        alt=""
        class="ml-auto hidden md:block"
      />
    </div>
    <div
      class="font-body bg-white shadow-[0px_3.64384px_22.774px_rgba(0,0,0,0.08)] rounded-lg p-4 md:p-8 mb-8 grid md:grid-cols-2 justify-between gap-8 overflow-clip"
      v-if="baseline?.baseline_task?.passed == 0"
    >
      <div class="flex flex-col justify-between h-full space-y-4">
        <h1
          class="font-header-1 font-bold text-2xl leading-6 md:text-4xl md:leading-8"
        >
          Oh No!
        </h1>
        <p class="text-sm" v-if="baseline?.baseline_task?.can_retake == true">
          Unfortunately, you didn’t pass the baseline assessment. However, you
          have been granted another chance to retake your baseline task.
        </p>
        <p class="text-sm" v-else>
          Unfortunately, you didn’t pass the baseline assessment. However, you can
          join the waitlist for the next batch that will kickoff on Monday,
          March 8 2023 (Bravo Clan).
        </p>
        <button
          v-if="baseline?.baseline_task?.can_retake == true"
          class="bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-6 py-4 text-white text-sm mr-auto w-fit font-bold"
          @click="retakeBaseline()"
        >
         Retake Baseline Assessment
        </button>
        <a  v-else target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSc1nVzLH86ZWZz1O0rBnzc1POXA6Bu6ozpPtIuvLxdJS437Ug/viewform">
          <button
            class="bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-6 py-4 text-white text-sm mr-auto w-fit font-bold"
          >
           Join waitlist
          </button>
        </a>
      </div>
      <img
        src="@/assets/img/img/failed_baseline.png"
        alt=""
        class="ml-auto hidden md:block"
      />
    </div>
    <div class="md:flex md:gap-10">
      <a
        class="w-fit inline-block"
        href="https://t.me/+a3vHaV90vI9jOGY8"
        target="_blank"
      >
        <div
          class="rounded-lg font-body bg-white hover:shadow-[0px_6.64384px_25.774px_rgba(0,0,0,0.20)] transition ease-linear duration-150 shadow-[0px_8px_50px_rgba(0,0,0,0.08)] px-6 py-7 md:w-fit flex items-center"
        >
          <!-- <img src="@/assetes" alt="Telegram"> -->
          <span class="iconify text-4xl mr-3" data-icon="logos:telegram"></span>
          <p class="text-[#3B433B] font-bold">
            Join the learning community on Telegram
          </p>
        </div>
      </a>
      <div
        class="bg-[#F1F7FE] py-5 font-body md:w-1/2 pl-4 mt-10 md:mt-0 pr-10 border border-[#60AAFD] rounded-md"
      >
        <div class="">
          <h2 class="font-bold mb-3 text-[#384138]">Refer your friends</h2>
          <p class="text-sm text-[#4B524B]">
            Share your referral link with your friends
          </p>
        </div>
        <div class="mt-6">
          <h2 class="font-bold mb-3 text-[#384138]">Your Referral link</h2>
          <div class="flex flex-col gap-y-5 md:flex-row md:gap-x-3">
            <input
              class="grow bg-white border rounded border-primary-green"
              type="text"
              disabled
              :value="
                'https://lms.terrahq.co/register?ref=' + user?.referral_code ??
                ''
              "
            />
            <button
              @click="
                copy(
                  'https://lms.terrahq.co/register?ref=' +
                    user?.referral_code ?? ''
                )
              "
              class="bg-primary-green hover:bg-hover-green transition-colors duration-200 ease-linear rounded px-8 py-3 text-white text-sm ml-auto w-fit font-bold"
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueCountdown from "@chenfengyuan/vue-countdown";
import axios from "axios";
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      baseline: "auth/baseline",
      userCourse: "auth/userCourse"

    }),
  },
  components: {
    VueCountdown,
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    copy(Text) {
      const copyText = document.createElement("textarea");
      copyText.value = Text;
      document.body.appendChild(copyText);
      copyText.select();
      document.execCommand("copy");
      document.body.removeChild(copyText);
      if (document.execCommand("copy")) {
        swal({
          title: "Success",
          text: "Copied",
        });
      }
    },
    countDown(time) {
      const now = new Date();
      var date = new Date(time);
      // add a week
      date.setDate(date.getDate() + 7);
      const update_at = new Date(date);
      return update_at - now;
    },
    async acceptAdmission() {
      let loader = this.$loading.show();
      try {
        await axios.get("auth/learning/accept");
        await this.attempt();
        this.$router.push({ name: "SelectPath" });
        loader.hide();
      } catch (error) {
        console.error(error);
        loader.hide();
        this.$router.push({ name: "SelectPath" });
      }
    },
    async retakeBaseline(){
      let loader = this.$loading.show()
      try {
        await axios.get("auth/baselines/retake")
        await this.attempt()
        this.$router.push({name: 'ViewAssessmentTask'})   
        loader.hide()     
      } catch (error) {
        console.error(error);
        loader.hide()     
        this.$router.push({name: 'ViewAssessmentTask'})
      }
    }
  },
  mounted() {
    // if (!this.baseline) {
    //   return this.$router.push({ name: "ViewAssessmentTask" });
    // }
    if (this.user?.accepted == 1) {
      this.$router.push({ name: "SelectPath" });
    }
  },
};
</script>

<style></style>
