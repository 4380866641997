<template>
  <div>
    <div class="container md:p-0 font-body-new">
      <BackButton class="my-4 md:my-12" />
      <template
        v-if="submittedQuizzes"
      >
        <div class="md:w-4/5 lg:w-3/6">
          <div
            class="rounded border p-4 flex items-center space-x-4 dark:bg-[#333333] dark:border-none dark:text-[#e6e6e6]"
          >
            <img src="@/assets/img/icons/goal.png" alt="" />
            <div class="text-sm">
              Your average {{user?.batch?.batch_id == 6 ? "Assessment" : "Quiz"}} score is
              <strong class="text-primary-green"> {{ averageScore }}%</strong>
            </div>
          </div>
          <div class="w-full my-8">
            <table
              class="w-full py-4 border-collapse table-auto dark:text-[#e6e6e6]"
            >
              <thead class="text-center py-3 font-bold text-sm">
                <td></td>
                <td class="text-left">{{`${weekModule()}`}}</td>
                <td class="py-4">Status</td>
                <td>Score</td>
              </thead>
              <tbody
                class="border dark:border-none dark:bg-[#333333] rounded-2xl text-center text-sm text-[#6F706F] font-medium"
              >
                <tr class="overflow-clip">
                  <td class="pl-3 md:pl-6 text-center pr-0 w-fit rounded-tl-lg">
                    <span
                      v-if="user?.batch?.batch_id < 7 ? filterQuizzes(1)?.score >= 50 : filterQuizzes(1)?.score >= 70"
                      class="iconify text-[#037700] text-xl"
                      data-icon="bi:check-circle-fill"
                    ></span>
                    <span
                      v-else
                      class="iconify text-xl dark:text-[#948E78]"
                      data-icon="ant-design:clock-circle-twotone"
                    ></span>
                  </td>
                  <td
                    class="flex flex-col text-left items-start h-full my-6 font-semibold text-[#6F706F] dark:text-[#e6e6e6]"
                  >
                    <p class="leading-10">{{`${weekModule()}`}} 1 {{user?.batch?.batch_id == 6 ? "Task" : "Quiz"}}</p>
                    <button
                      class="text-xs font-bold text-[#747874] dark:text-[#e6e6e6]"
                      v-if="user?.batch?.batch_id < 7 ? filterQuizzes(1)?.score < 50 : filterQuizzes(1)?.score < 70"
                      @click="
                        $router.push({
                          name: 'QuizInstructionPage',
                          params: { week: encrypt(1), retake: true },
                        })
                      "
                    >
                      Retake
                    </button>
                  </td>
                  <td>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#E2FEE1]"
                      v-if="user?.batch?.batch_id < 7 ? filterQuizzes(1)?.score >= 50 : filterQuizzes(1)?.score >= 70"
                    >
                      Pass
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#FFEEEE]"
                      v-else-if="user?.batch?.batch_id < 7 ? filterQuizzes(1)?.score < 50 : filterQuizzes(1)?.score < 70"
                    >
                      Fail
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#F2F2F2]"
                      v-else
                    >
                      Pending
                    </button>
                  </td>
                  <td
                    class="font-bold text-[#3B443B] dark:text-[#e6e6e6] rounded-tr-lg"
                    v-if="filterQuizzes(1)?.score"
                  >
                    {{ filterQuizzes(1)?.score ?? "0" }}%
                  </td>
                  <td
                    class="font-medium text-[#3B443B] dark:text-[#e6e6e6] rounded-tr-lg"
                    v-else
                  >
                    {{ "N/A" }}
                  </td>
                </tr>
                <tr class="first:rounded-xl overflow-clip">
                  <td class="pl-3 md:pl-6 text-center pr-0 w-fit">
                    <span
                      v-if="user?.batch?.batch_id < 7 ? filterQuizzes(2)?.score >= 50 : filterQuizzes(2)?.score >= 70"
                      class="iconify text-[#037700] text-xl"
                      data-icon="bi:check-circle-fill"
                    ></span>
                    <span
                      v-else
                      class="iconify text-xl dark:text-[#948E78]"
                      data-icon="ant-design:clock-circle-twotone"
                    ></span>
                  </td>
                  <td
                    class="flex flex-col text-left items-start h-full my-6 font-semibold text-[#6F706F] dark:text-[#e6e6e6]"
                  >
                    <p class="leading-10">{{`${weekModule()}`}} 2 {{user?.batch?.batch_id == 6 ? "Task" : "Quiz"}}</p>
                    <!-- <button
                      class="text-xs font-bold text-[#747874] dark:text-[#e6e6e6]"
                      v-if="filterQuizzes(2)?.score >= 50"
                      @click="
                        $router.push({
                          name: 'QuizPreviewPage',
                          params: { id: encrypt(2) },
                        })
                      "
                    >
                      Review
                    </button> -->
                    <button
                      class="text-xs font-bold text-[#747874] dark:text-[#e6e6e6]"
                      v-if="user?.batch_id < 7 ? filterQuizzes(2)?.score < 50 : filterQuizzes(2)?.score < 70"
                      @click="
                        $router.push({
                          name: 'QuizInstructionPage',
                          params: { week: encrypt(2), retake: true },
                        })
                      "
                    >
                      Retake
                    </button>
                  </td>
                  <td>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#E2FEE1]"
                      v-if="user?.batch_id < 7 ? filterQuizzes(2)?.score >= 50 : filterQuizzes(2)?.score >= 70"
                    >
                      Pass
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#FFEEEE]"
                      v-else-if="user?.batch_id < 7 ? filterQuizzes(2)?.score < 50 : filterQuizzes(2)?.score < 70"
                    >
                      Fail
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#F2F2F2]"
                      v-else
                    >
                      Pending
                    </button>
                  </td>
                  <td
                    class="font-bold text-[#3B443B] dark:text-[#e6e6e6]"
                    v-if="filterQuizzes(2)?.score"
                  >
                    {{ filterQuizzes(2)?.score ?? "0" }}%
                  </td>
                  <td
                    class="font-medium text-[#3B443B] dark:text-[#e6e6e6]"
                    v-else
                  >
                    {{ "N/A" }}
                  </td>
                </tr>
                <tr class="first:rounded-xl overflow-clip">
                  <td class="pl-3 md:pl-6 text-center pr-0 w-fit">
                    <span
                      v-if="user?.batch_id < 7 ? filterQuizzes(3)?.score >= 50 : filterQuizzes(3)?.score >= 70"
                      class="iconify text-[#037700] text-xl"
                      data-icon="bi:check-circle-fill"
                    ></span>
                    <span
                      v-else
                      class="iconify text-xl dark:text-[#948E78]"
                      data-icon="ant-design:clock-circle-twotone"
                    ></span>
                  </td>
                  <td
                    class="flex flex-col text-left items-start h-full my-6 font-semibold text-[#6F706F] dark:text-[#e6e6e6]"
                  >
                    <p class="leading-10">{{`${weekModule()}`}} 3 {{user?.batch_id == 6 ? "Task" : "Quiz"}}</p>
                    <!-- <button
                      class="text-xs font-bold text-[#747874] dark:text-[#e6e6e6]"
                      v-if="filterQuizzes(3)?.score >= 50"
                      @click="
                        $router.push({
                          name: 'QuizPreviewPage',
                          params: { id: encrypt(3) },
                        })
                      "
                    >
                      Review
                    </button> -->
                    <button
                      class="text-xs font-bold text-[#747874] dark:text-[#e6e6e6]"
                      v-if="user?.batch_id < 7 ? filterQuizzes(3)?.score < 50 : filterQuizzes(3)?.score < 70"
                      @click="
                        $router.push({
                          name: 'QuizInstructionPage',
                          params: { week: encrypt(3), retake: true },
                        })
                      "
                    >
                      Retake
                    </button>
                  </td>
                  <td>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#E2FEE1]"
                      v-if="user?.batch_id < 7 ? filterQuizzes(3)?.score >= 50 : filterQuizzes(3)?.score >= 70"
                    >
                      Pass
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#FFEEEE]"
                      v-else-if="user?.batch_id < 7 ? filterQuizzes(3)?.score < 50 : filterQuizzes(3)?.score < 70"
                    >
                      Fail
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#F2F2F2]"
                      v-else
                    >
                      Pending
                    </button>
                  </td>
                  <td
                    class="font-bold text-[#3B443B] dark:text-[#e6e6e6]"
                    v-if="filterQuizzes(3)?.score"
                  >
                    {{ filterQuizzes(3)?.score ?? "0" }}%
                  </td>
                  <td
                    class="font-medium text-[#3B443B] dark:text-[#e6e6e6]"
                    v-else
                  >
                    {{ "N/A" }}
                  </td>
                </tr>
                <tr class="overflow-clip">
                  <td class="pl-3 md:pl-6 text-center pr-0 w-fit rounded-bl-lg">
                    <span
                      v-if="user?.batch_id < 7 ? filterQuizzes(4)?.score >= 50 : filterQuizzes(4)?.score >= 70"
                      class="iconify text-[#037700] text-xl"
                      data-icon="bi:check-circle-fill"
                    ></span>
                    <span
                      v-else
                      class="iconify text-xl dark:text-[#948E78]"
                      data-icon="ant-design:clock-circle-twotone"
                    ></span>
                  </td>
                  <td
                    class="flex flex-col text-left items-start h-full my-6 font-semibold text-[#6F706F] dark:text-[#e6e6e6]"
                  >
                    <p class="leading-10">{{`${weekModule()}`}} 4 {{user?.batch_id == 6 ? "Task" : "Quiz"}}</p>
                    <!-- <button
                      class="text-xs font-bold text-[#747874] dark:text-[#e6e6e6]"
                      v-if="filterQuizzes(4)?.score >= 50"
                      @click="
                        $router.push({
                          name: 'QuizPreviewPage',
                          params: { id: encrypt(4) },
                        })
                      "
                    >
                      Review
                    </button> -->
                    <button
                      class="text-xs font-bold text-[#747874] dark:text-[#e6e6e6]"
                      v-if="user?.batch_id < 7 ? filterQuizzes(4)?.score < 50 : filterQuizzes(4)?.score < 70"
                      @click="
                        $router.push({
                          name: 'QuizInstructionPage',
                          params: { week: encrypt(4), retake: true },
                        })
                      "
                    >
                      Retake
                    </button>
                  </td>
                  <td>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#E2FEE1]"
                      v-if="user?.batch_id < 7 ? filterQuizzes(4)?.score >= 50 : filterQuizzes(4)?.score >= 70"
                    >
                      Pass
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#FFEEEE]"
                      v-else-if="user?.batch_id < 7 ? filterQuizzes(4)?.score < 50 : filterQuizzes(4)?.score < 70"
                    >
                      Fail
                    </button>
                    <button
                      class="rounded-full px-3 py-1 text-xs font-medium bg-[#F2F2F2]"
                      v-else
                    >
                      Pending
                    </button>
                  </td>
                  <td
                    class="font-bold text-[#3B443B] dark:text-[#e6e6e6] rounded-br-lg"
                    v-if="filterQuizzes(4)?.score"
                  >
                    {{ filterQuizzes(4)?.score ?? "0" }}%
                  </td>
                  <td
                    class="font-medium text-[#3B443B] dark:text-[#e6e6e6] rounded-br-lg"
                    v-else
                  >
                    {{ "N/A" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="flex flex-col space-y-4 font-body-new text-sm text-body-text dark:text-[#e6e6e6] items-center justify-center text-center pt-8"
        >
          <img src="@/assets/img/icons/empty-box.png" alt="" />
          <p>No Assessment History</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import {  weekModule } from "@/helpers/learningController";

import { encrypt } from "@/helpers/encrypt";
</script>
<script>
import { mapGetters } from "vuex";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import QuizRating from "@/views/lms/lessons/quiz/QuizRating.vue";
import { encrypt } from "@/helpers/encrypt";

import axios from "axios";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    averageScore() {
      if (this.submittedQuizzes?.length > 0) {
        const sum = this.submittedQuizzes.reduce((accumulator, object) => {
          return accumulator + Number(object.score);
        }, 0);
        let average = sum / this.submittedQuizzes.length;
        average = average.toFixed(2);
        return average;
      } else {
        return 0;
      }
    },
  },
  components: {
    BackButton,
  },
  data() {
    return {
      submittedQuizzes: null,
    };
  },
  mounted() {
    // if (new Date() > new Date(this.user?.batch.start_on)) {
      this.fetchSubmittedQuizzes();
    // }
  },
  methods: {
    filterQuizzes(week) {
      if (this.submittedQuizzes?.length > 0) {
        const q = this.submittedQuizzes.find((item) => {
          return item.week == week;
        });
        return q;
      } else return [];
    },
    async fetchSubmittedQuizzes() {
      let loader = this.$loading.show();
      try {
        const { data } = await axios.get("auth/quizzes/submitted");
        this.submittedQuizzes = data.payload;
        loader.hide();
      } catch (error) {
        console.log(error);
        loader.hide();
      }
    },
  },
};
</script>

<style scoped></style>
