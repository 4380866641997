<template>
  <main>
    <NavBar />
    <div class="mb-7 py-12 lg:py-16">
      <div class="mb-12 blog_header_banner bg-cover bg-no-repeat bg-center">
        <div class="container pt-36 pb-16">
          <h2 class="font-header-1 font-bold text-5xl py-4">Our Blog</h2>
          <p class="font-body w-full md:w-5/6 lg:w-3/5">
            Discover resources to help you become a professional
          </p>
        </div>
      </div>
      <div class="container">
        <nav class="py-0 border-b">
          <button class="font-bold font-body py-2 border-b-2 border-black pr-2">
            Latest Post
          </button>
        </nav>
        <section class="">
          <div class="w-full lg:w-4/6">
            <a
              :href="post.link"
              target="_blank"
              rel="noreferrer"
              class="py-6 border-b border-body-text flex md:flex-row flex-col items-start justify-between space-y-12 md:space-x-12"
              v-for="(post, index) in posts"
              :key="index"
            >
              <div class="space-y-2 md:w-3/5">
                <p class="font-body text-xs">
                  {{ post.author }}
                  <span
                    class="iconify text-lg inline"
                    data-icon="ci:dot-01-xs"
                  ></span>
                  {{ new Date(post.pubDate).toDateString() }}
                </p>
                <h3 class="font-header-1 text-2xl font-bold capitalize">
                  {{ post.title }}
                </h3>
                <!-- <p class="font-body text-xs leading-5 space-y-4 truncate" v-html="post.description"></p> -->
                <div class="flex items-center space-x-4 text-xs">
                  <button class="rounded-full px-2 py-1 bg-gray-200 capitalize">
                    {{ removeHyphen(post?.categories?.[1]) ?? "Side Hustle" }}
                  </button>
                  <button class="font-medium">
                    {{ wordCount(post.content) }} min read
                  </button>
                </div>
              </div>
              <img
                :src="post.thumbnail"
                :alt="post.title"
                class="md:w-48 w-full"
              />
            </a>
          </div>
          <aside class="hidden lg:block"></aside>
        </section>
      </div>
    </div>
    <Footer class="mb-0" />
  </main>
</template>

<script>
import NavBar from "@/components/inc/PrimaryNavBar.vue";
import Footer from "@/components/inc/FooterComponent.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    async fetchPosts() {
      let loader = this.$loading.show();
      try {
        const response = await fetch(
          "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@Terra-Learning"
        );
        const data = await response.json();
        this.posts = data.items;
        // console.log(data);
        loader.hide();
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    removeHyphen(str) {
      if (str) {
        return str.replaceAll("-", " ");
      } else {
        return;
      }
    },
    wordCount(str) {
      if (str) {
        const count = str.split(" ").length;
        const speed = 180;
        const time = Math.ceil(count / speed);
        return time;
      } else {
        return 1;
      }
    },
  },
  beforeMount() {
    this.fetchPosts();
  },
};
</script>

<style scoped>
.blog_header_banner {
  background-image: url('https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/blog-header-bg.png');
}
</style>
