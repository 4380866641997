import store from "./../store";
import router from "./../router";
import swal from "sweetalert";
import { encrypt, decrypt } from "./encrypt.js";


const SELF_PACED_ACCESS_WEEKS = 2;

export const streakProgress = ()=>{
  const lessons =  store.state.lms.lessons;
  const watchedLessons =  store.state.lms.watchedLessons;
  const takenQuiz =  store.state.lms.takenQuiz;
  let quiz_progress = takenQuiz?.length * 12.5;
  let lesson_progress = ( watchedLessons?.length / lessons?.length ) * 50;
  return Math.ceil(quiz_progress + lesson_progress);
}

export const eachWeekLessons = (week) => {
  const lessons =  store.state.lms.lessons;
  return lessons?.filter((item) => item.week == week);
}

export const eachWeekBadges = (week) => {
  const badges =  store.state.lms.badges;
  return badges.filter((item) => item.week === week);
}

/**
 * @returns string  {Level || Module}
 */
export const weekModule = () => {
  const user =  store.state.auth.user;
  if (user?.batch?.learning_type == 'instructor') {
      return "Level";        
  } else {
    return 'Module';
  }
};


/**
 * 
 * @returns String {Quiz | Task | Individual Challenge} 
 */
export const quizTask = () => {
  const user =  store.state.auth.user;
  let settings =  store.state.auth.settings;
  let active_batch = settings?.current_batch;
  if (user?.batch?.learning_type == 'self_paced') {
      return "Quiz";        
  } else if (user?.batch?.learning_type == 'instructor' && user?.cohort < 8) {
      return 'Task';         
  } else if (user?.batch?.learning_type == 'instructor' && user?.cohort >= 8) {
      return 'Individual Challenge';         
  } else return 'Quiz'
};

export const isWatched = (id) => {
    let check = store.state.lms.watchedLessons?.includes(id.toString()) || store.state.lms.watchedLessons?.includes(id);
    return check;
};

/**
 * Check if a user should see button for Tasks
 * @returns Boolean
 */
export const canSeeTasks = () => {
    const user =  store.state.auth.user;
    if (user?.batch?.batch_id == 6) return true;
    if (user?.batch?.learning_type == 'instructor' && user?.cohort >= 8) return true
    else return false;
}

/**
 * Check if user should see button for quiz
 * @returns Boolean
 */
export const canSeeQuiz = () => {
  const user =  store.state.auth.user;
  if (user?.batch?.batch_id == 6) return false;
  else return true;
}

/**
 * Check if user should see button for extra resources 
 * @returns Boolean
 */
export const canSeeResource = () => {
    return true;
}

/**
 * Set a current lesson -- includes the guards for access to weeks/resources/etc.
 * @param {*} lesson 
 * @param {*} learningType 
 * @returns void 
 */
export const setLesson = (lesson, learningType) => {
  const user = store.state.auth.user;
  const settings = store.state.auth.settings;
  const takenQuiz = store.state.lms.takenQuiz;
  const allQuizScore =  store.state.lms.allQuizScore;
  if(user?.batch?.batch_id > 5 && user?.cohort < 8  ){
    if (lesson?.week > 2 && user?.certificate_status?.status == false) {
      swal({
        title: "Oops!",
        text: `You have to make payment for your certificate to access your module ${lesson.week} learnings`,
      });
      let week_3 = eachWeekLessons(2);
      setCurrentLesson(week_3[0], learningType);
      return;
    }
  }
  if (lesson?.week > user.access_weeks 
  && user?.batch?.learning_type == 'instructor' 
  && user?.batch?.can_access == 1 
  && user?.cohort >= 8) {
    swal({
      title: "Access denied!",
      text: `You are yet to make payment for week ${lesson?.week}. Proceed to the home screen to make payment.`,
    });
    return;
  }  
  /**
   * Lesson Guard for Instructor Led Mode  
   */
  if (lesson.week > settings.current_week 
  && user?.batch?.learning_type == 'instructor' 
  && user?.cohort >= settings.cohort) {
    swal({
      title: "Access denied!",
      text: `You cannot access  ${weekModule()} ${lesson?.week} yet.`,
    });
    return;
  }
  router.push({ name: "LessonPage" });
  /**
   * Block user from going to next weeks lesson if user has not taken quiz for previous week
   */
  if (user?.batch?.batch_id != 6 && lesson.week > 1 && (!takenQuiz?.includes((lesson.week -1).toString()) && !takenQuiz?.includes((lesson.week - 1)))) {
    swal({
      title: "Oops!",
      text: `You are yet to take your ${ user?.batch?.batch_id == 6 ? 'task' : 'quiz' } for  ${weekModule()}  ${lesson.week - 1}`,
    });
    return;
  }
  /**
   * block user from taken lesson if they have not pass quiz for previous week
   */
  if (user?.batch?.batch_id != 6 && lesson.week > 1 && allQuizScore.length > 0) {
    const passQuiz = allQuizScore.filter((item) => {
      return item.week < lesson.week && item.score < 70;
    });
    if (passQuiz.length > 0) {
      swal({
        title: "Oops!",
        text: `You are yet to pass your ${ user?.batch?.batch_id == 6 ? 'task' : 'quiz' } for ${weekModule()} ${lesson.week - 1}`,
      });
      return;
    }
  } 
  setCurrentLesson(lesson, learningType);
};

/**
 * Send the Vuex dispatch to set the current lesson
 * @param {*} lesson 
 * @param {*} learningType 
 */
const setCurrentLesson = (lesson, learningType) => {
  const user = store.state.auth.user;
  const settings = store.state.auth.settings;
  if (lesson?.week > SELF_PACED_ACCESS_WEEKS 
  && user?.batch?.learning_type == 'self_paced' 
  && user.access_weeks < 2 && user?.cohort >= 8) {
    swal({
      title: "Access denied!",
      text: `Complete payment for this course to enjoy unrestricted access.`,
    });
    return;
  }  
  if (user?.batch?.learning_type == 'instructor' && user?.cohort >= 8 && // this rule only applies to instructor-led in cohort 8 and above
      ((user?.access_weeks < lesson?.week) || // ensure user has paid upto the week of the video user is trying to access
      (user.cohort >= settings.cohort && settings?.current_week < lesson?.week) // ensure that the cohort has gotten to the week for the video
    )) {
    return false;
  }
  store.dispatch("lms/setCurrentLesson", lesson);
  store.dispatch("lms/setLearningType", learningType);
};

/**
 * Route to quiz page
 * @param {*} week 
 */
export const goToQuiz = (week) => {
  const user = store.state.auth.user;
  // setup guard for users auditing the course
  if(user?.batch?.can_access == 0 && user.batch.accepted == 1) {
    return swal({
      title: "Make Payment",
      text: `You can only access a few lessons while auditing this course, make payment for full access.`,
      button: `Pay Now`
    }).then((res) => {
      if (res) return router.push({name: 'ApplicationPayment', params: { slug: user.batch?.learning_type == 'instructor' ? 'hands-on' : 'self_paced' }});
    });
  }
  const settings = store.state.auth.settings;
  const takenQuiz = store.state.lms.takenQuiz;
  const allQuizScore =  store.state.lms.allQuizScore;
  if (week > 2 && user?.certificate_status?.status == false) {
    swal({
      title: "Oops!",
      text: "You have to make payment for your certificate to access your module 4 learnings",
    });
    router.push({
      name: "QuizInstructionPage",
      params: { week: encrypt(2) },
    });
    return;
  }
  if (week > SELF_PACED_ACCESS_WEEKS 
    && user?.batch?.learning_type == 'self_paced' 
    && user.access_weeks < 2 && user?.cohort >= 8) {
      swal({
        title: "Access denied!",
        text: `Complete payment for this course to enjoy unrestricted access.`,
      });
      return;
    }
  /**
   * Quiz Guard for Instructor Led Mode  
   */
  if (week > user.access_weeks 
    && user?.batch?.learning_type == 'instructor' 
    && user?.batch?.can_access == 1 
    && user?.cohort >= 8) {
      swal({
        title: "Access denied!",
        text: `You are yet to make payment for ${week}. Proceed to the home screen to make payment.`,
      });
      return;
    }
  /**
   * Quiz Guard for Instructor Led Mode  
   */
  if (week > settings.current_week 
    && user?.batch?.learning_type == 'instructor' 
    && user?.cohort >= settings.cohort) {
      swal({
        title: "Access denied!",
        text: `You cannot access  ${weekModule()} ${week} yet.`,
      });
      return;
    }
  
  /**
   * Block user from going to next weeks quiz if user has not taken quiz for previous week
   */
  if (week > 1 && (!takenQuiz?.includes((week -1).toString()) && !takenQuiz?.includes((week -1)))) {
    swal({
      title: "Oops!",
      text: `You are yet to take your quiz for  ${weekModule()}  ${week - 1}`,
    });
    return;
  }
  /**
   * block user from taken lesson if they have not pass quiz for previous week
   */
  if (week > 1 && allQuizScore.length > 0) {
    const passQuiz = allQuizScore.filter((item) => {
      return item.week < week && item.score < 70;
    });
    if (passQuiz.length > 0) {
      swal({
        title: "Oops!",
        text: `You are yet to pass your quiz for ${weekModule()} ${week - 1}`,
      });
      return;
    }
  } 
  router.push({
      name: "QuizInstructionPage",
      params: { week: encrypt(week) },
  });
};

/**
 * Route to task page 
 * @param {} week 
 */
export const goToTask = (week) => {
  const user = store.state.auth.user;
  const settings = store.state.auth.settings;
  const takenQuiz = store.state.lms.takenQuiz;
  const allQuizScore =  store.state.lms.allQuizScore;
  // setup guard for users auditing the course
  if(user?.batch?.can_access == 0 && user.batch.accepted == 1) {
    return swal({
      title: "Make Payment",
      text: `You can only access a few lessons while auditing this course, make payment for full access.`,
      button: `Pay Now`
    }).then((res) => {
      if (res) return router.push({name: 'ApplicationPayment', params: { slug: user.batch?.learning_type == 'instructor' ? 'hands-on' : 'self_paced' }});
    });
  }

  if ((user?.batch?.learning_type == "instructor" && user?.batch?.can_access == 1 ) 
      || user?.batch?.batch_id == 6 ) 
    {
    if (week > user.access_weeks 
      && user?.cohort >= 8) {
        swal({
          title: "Access denied!",
          text: `You are yet to make payment for ${week}. Proceed to the home screen to make payment.`,
        });
        return;
      }
    /**
     * Quiz Guard for Instructor Led Mode  
    */
    if (week > settings.current_week && user?.cohort >= settings.cohort) {
        swal({
          title: "Access denied!",
          text: `You cannot access  ${weekModule()} ${week} yet.`,
        });
        return;
      }
    router.push({
      name: "TaskInstructionPage",
      params: { week: encrypt(week) },
    });
  } else {
    swal({
      title: "Upgrade",
      text: `This feature is available to only Instructor-led users. Upgrade to gain access (Note: The next instructor-led cohort will commence by ${settings.internship_start_date})`,
      buttons: [true, "Upgrade"],
    }).then((result) => {
      if (result) {
        return store.dispatch("auth/upgradeLearning");
      } else {
        return next({ name: 'LearningDashboardIndex' });
      }
    });
  }
};
/**
 * Route to resources page
 * @param {*} week 
 * @returns 
 */
export const goToResource = (week) => {
  const user = store.state.auth.user;
  const takenQuiz = store.state.lms.takenQuiz;
  // setup guard for users auditing the course
  if(user?.batch?.can_access == 0 && user.batch.accepted == 1) {
    return swal({
      title: "Make Payment",
      text: `You can only access a few lessons while auditing this course, make payment for full access.`,
      button: `Pay Now`
    }).then((res) => {
      if (res) return router.push({name: 'ApplicationPayment', params: { slug: user.batch?.learning_type == 'instructor' ? 'hands-on' : 'self_paced' }});
    });
  }
  const allQuizScore =  store.state.lms.allQuizScore;
  if (week > 2 && user?.certificate_status?.status == false) {
    swal({
      title: "Oops!",
      text: "You have to make payment for your certificate to access your module 4 resources",
    });
    router.push({
      name: "ResourceInstructionPage",
      params: { week: encrypt(2) },
    });
    return;
  }
  if (week > user.access_weeks 
    && user?.batch?.learning_type == 'instructor' 
    && user?.batch?.can_access == 1 
    && user?.batch?.batch_id >= settings?.current_batch) {
      swal({
        title: "Access denied!",
        text: `You are yet to make payment for ${week}. Proceed to the home screen to make payment.`,
      });
      return;
    }
    if (week > 1 && (!takenQuiz.includes((week - 1).toString()) && !takenQuiz.includes((week - 1)))) {
      swal({
        title: "Oops!",
        text: `You are yet to take your ${ user?.batch?.batch_id == 6 ? 'task' : 'quiz' } for module  ${week - 1}`,
      });
      return;
    }
    if (week > 1 && allQuizScore.length > 0) {
      const passQuiz = allQuizScore.filter((item) => {
        return item.week < week && item.score < 70;
      });
      if (passQuiz.length > 0) {
        swal({
          title: "Oops!",
          text: `You are yet to pass your ${ user?.batch?.batch_id == 6 ? 'task' : 'quiz' } for module  ${week - 1}`,
        });
        return;
      }
    }
  router.push({
    name: "ResourceInstructionPage",
    params: { week: encrypt(week) },
  });
};