<template>
  <div>
    <HeroSection />
    <BenefitsSection />
    <LearningExperience />
    <TestimoniesSection />
    <FootPrintSection />
    <ProductsSection />
    <NextCohortCard />
    <!-- <SubscribeSection /> -->
  </div>
</template>

<script>
import HeroSection from "./../../components/landing/HeroSection.vue";
import BenefitsSection from "./../../components/landing/BenefitsSection.vue";
import TestimoniesSection from "./../../components/landing/TestimoniesSection.vue";
import FootPrintSection from "./../../components/landing/FootPrintSection.vue";
import NextCohortCard from "./../../components/landing/NextCohortCard.vue";
import ProductsSection from "./../../components/landing/ProductsSection.vue";
import SubscribeSection from "./../../components/landing/SubscribeSection.vue";
import LearningExperience from "./../../components/landing/LearningExperienceSection.vue";
export default {
  // data(){

  // },
  props: ["showCourseModal"],
  components: {
    HeroSection,
    BenefitsSection,
    TestimoniesSection,
    FootPrintSection,
    NextCohortCard,
    ProductsSection,
    SubscribeSection,
    LearningExperience,
  },
  beforeMount() {
    window.addEventListener("appinstalled", () => {
      this.$router.push({ name: "LearningDashboardIndex" });
    });
  },
};
</script>
