<template>
  <main class="lg:px-8 px-4 pt-8">
    <section class="lg:w-full">
      <div
        @click="$router.push({ name: 'LearningDashboardIndex' })"
        class="font-body text-primary-green flex items-center cursor-pointer my-4"
      >
        <span
          class="iconify mr-2 text-2xl font-extralight"
          data-icon="entypo:chevron-with-circle-left"
        ></span>
        <span class="font-semibold">Go to Dashboard</span>
      </div>
      <p class="text-[#343434] dark:text-[#E5E5E5] mt-4 mb-5 font-body text-sm">
        Here you will find your class schedules 
      </p>
      <div class="grid grid-flow-row md:grid-cols-3 my-8 gap-4">
        <div
          class="schedule_cards"
          v-for="(list, index) in course?.timetable"
          :key="index"
        >
          <h5>{{ list.day }} <span class="uppercase text-base tracking-widest">({{list.week}})</span></h5>

          <p>{{ "Live Sessions" }}</p>
          <time>{{ list.time }}</time>
          <a v-if="course?.meet_link" :href="course?.meet_link" class="text-primary-green text-sm mt-5" target="_blank">Click here to join the class</a>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      live_class: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      userCourse: "auth/userCourse",
      courses: "auth/courses"
    }),
    course(){
      return this.courses?.find((item)=> {
        return item.id == this.userCourse?.course?.id;
      })
    }
  },
  methods: {
    
  }, 

  mounted(){
    if (this?.user?.batch?.learning_type != 'instructor') {
      return this.$router.push({name: "LearningDashboardIndex"})      
    }
  }
};

</script>

<style lang="scss" scoped>
.schedule_cards {
  h5 {
    @apply text-black dark:text-[#E5E5E5] font-header-1 text-2xl font-bold;
  }
  p {
    @apply text-[#242424] dark:text-[#E5E5E5] my-2;
  }
  time {
    @apply text-primary-green font-body font-bold text-sm justify-self-end;
  }
  @apply bg-[#f4f5f7] dark:bg-body-text dark:border-none border border-[#C9C9C9] rounded-2xl px-4 py-4 flex flex-col justify-start items-start cursor-pointer;
}
</style>
