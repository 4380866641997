<template>
  <div
    class="fixed bg-[#63636345] backdrop-blur-[10px] top-0 right-0 left-0 bottom-0 w-screen h-screen flex items-center justify-center z-50"
  >
    <div class="container p-0 flex items-center justify-center custom_scale_in">
      <div
        class="max-w-[90vw] w-11/12 md:w-4/6 lg:w-5/12 goodies_modal p-8 pt-16 bg-white rounded-lg flex flex-col justify-between items-center text-center space-y-12 relative overflow-clip"
        ref="modalContainer"
      >
        <header
          class="font-header-1 font-bold text-[#0C180C] text-2xl md:text-3xl bg-[#F7F8F7] w-full absolute top-0 left-0 right-0 py-4"
        >
          {{ headingText }}
          <button
            class="absolute top-2 right-2 md:top-4 md:right-4 w-fit"
            @click="closeModalFunction()"
          >
            <span
              class="iconify text-[#AAAAAA] font-bold"
              data-icon="eva:close-square-outline"
            ></span>
          </button>
        </header>
        <!-- ant-design:check-circle-outlined -->
        <!-- <span class="iconify text-primary-green font-bold text-8xl" data-icon="eva:close-square-outline"></span> -->
        <div
          class="font-body-new font-medium text-[#413B3A] text-base md:w-5/6 mx-auto my-4"
        >
          {{ bodyText }}
        </div>
        <div
          class="grid grid-cols-3 md:grid-flow-col gap-12 md:gap-6 font-body-new text-xs md:text-sm font-medium pb-8"
          v-if="!feedbackSubmitted"
        >
          <button
            @click="submitFeedback(rate.id)"
            v-for="rate in ratings"
            :key="rate.id"
            class="flex flex-col items-center space-y-4"
          >
            <span
              class="iconify text-3xl md:text-4xl"
              :style="`color: ${rate.color}`"
              :data-icon="rate.icon"
            ></span>
            <p>{{ rate.title }}</p>
          </button>
        </div>
        <img
          src="@/assets/img/icons/excited-emoji.png"
          alt="Excited Emoji"
          class="!my-0 !-mb-8 animate-[bounce_1s_3]"
          v-if="feedbackSubmitted"
        />
        <button
          @click="closeModalFunction()"
          v-if="feedbackSubmitted"
          class="bg-primary-green rounded text-white text-sm py-3 px-6 font-body-new font-bold"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      ratings: [
        {
          id: 1,
          title: "Frustrating",
          icon: "fa-solid:angry",
          color: "#F34747",
        },
        {
          id: 2,
          title: "Bad",
          icon: "bi:emoji-frown-fill",
          color: "#FA8F27",
        },
        {
          id: 3,
          title: "Average",
          icon: "bi:emoji-expressionless-fill",
          color: "#FECF0A",
        },
        {
          id: 4,
          title: "Good",
          icon: "bi:emoji-smile-fill",
          color: "#87D33D",
        },
        {
          id: 5,
          title: "Awesome",
          icon: "bi:emoji-heart-eyes-fill",
          color: "#52A350",
        },
      ],
      headingText: "Rate your experience",
      bodyText:
        "Select an emoji that describes how you feel about your learning experience this week",
      feedbackSubmitted: false,
    };
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    closeModalFunction() {
      this.attempt();
      return this.$emit("closeModal");
    },
    async submitFeedback(id) {
      try {
        this.bodyText =
          "We’re looking forward to making your experience even better in the future!";
        this.headingText = "Thank You";
        this.feedbackSubmitted = true;
        const payload = {
          rating: id,
        };
        localStorage.setItem('review', payload);
        await axios.post("auth/feedback", payload);
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.goodies_modal {
  background-image: url('https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/goodies-bg.png');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 0%;
  background-size: contain;
}
</style>
