import axios from "axios";
export default {
  namespaced: true,
  state: {
    enrollment_status: false,
    lessons: [],
    currentLesson: {},
    checkTests: {
      baseline: false,
      endline: false,
    },
    watchedLessons: [],
  },

  getters: {
    enrollment_status(state) {
      return state.enrollment_status;
    },
    lessons(state) {
      return state.lessons;
    },
    currentLesson(state) {
      return state.currentLesson;
    },
    checkTests(state) {
      return state.checkTests;
    },
    watchedLessons(state) {
      return state.watchedLessons;
    },
  },

  mutations: {
    SET_ENROLLMENT(state, enrollment_status) {
      state.enrollment_status = enrollment_status;
    },
    SET_LESSONS(state, lessons) {
      state.lessons = lessons;
    },
    SET_CURRENT_LESSON(state, currentLesson) {
      state.currentLesson = currentLesson;
    },
    SET_TEST_STATUS(state, checkTests) {
      state.checkTests = checkTests;
    },
    SET_WATCHED_LESSONS(state, watchedLessons) {
      state.watchedLessons = watchedLessons;
    },
  },

  actions: {
    /**
     * Check Jobberman Enrollment Status
     * @param {commit}
     */
    async checkStatus({ commit, state }) {
      try {
        let response = await axios.get("auth/jobberman/status");
        commit("SET_ENROLLMENT", response.data.payload);
      } catch (e) {
        commit("SET_ENROLLMENT", false);
      }
    },

    /**
     * get all jobberman lessons
     * @param {Object} {commit}
     */
    fetchJobbermanLessons({ commit }, lessons) {
      commit("SET_LESSONS", lessons);
      commit("SET_CURRENT_LESSON", lessons?.[0] ?? null);
      return;
    },
    /**
     * get all jobberman lessons
     * @param {Object} {commit}
     */
    async checkTests({ commit }) {
      try {
        let response = await axios.get("auth/jobberman/tests/check");
        const tests = {
          baseline: response.data.payload.taken_baseline,
          endline: response.data.payload.taken_endline,
        };
        commit("SET_TEST_STATUS", tests);
      } catch (e) {
        const tests = {
          baseline: false,
          endline: false,
        };
        commit("SET_TEST_STATUS", tests);
      }
      return;
    },
    /**
     * set current jobberman lesson
     * @param {Object} {commit}
     */
    async setLesson({ commit, state }, id) {
      if (state.lessons.length > 0 && id) {
        const lesson = state.lessons.find((item) => item.id == id);
        if (lesson) {
          commit("SET_CURRENT_LESSON", lesson);
        }
      }
      return;
    },

    async savedLessons({ commit }, lessons) {
      if (!lessons) {
        try {
          let response = await axios.get("auth/jobberman/lessons/progress");
          const lessons = response.data.payload;
          commit("SET_WATCHED_LESSONS", lessons);
        } catch (e) {
          commit("SET_WATCHED_LESSONS", []);
        }
      }
      if (lessons) {
        commit("SET_WATCHED_LESSONS", lessons);
      }
      return;
    },
  },
};
