<template>
  <main class="font-body">
    <div class="w-full">
        <div class="w-full p-0 my-4">
            <h2 class="font-header-1 text-primary-green font-bold mb-4 text-lg">Ask a question</h2>
            <div class="">
                <form @submit.prevent="addQuestion(lesson?.id)" class="relative border border-gray-400 rounded-md w-full px-4 py-2 text-xs">
                        <input
                            class="w-full"
                            v-model="question"
                            placeholder="Ask your question here"
                        />
                        <button type="submit" class="absolute right-2 text-primary-green font-semibold text-lg">
                            <span
                                class="iconify"
                                data-icon="carbon:send-filled"
                            ></span>
                        </button>
                </form>
            </div>
            <div class="my-6 space-y-4 w-full" v-if="lesson?.questions?.length > 0" >
                <template v-for="(question, index) in lesson?.questions" :key="question.id">
                    <div v-if="index < questionLimit" class="w-full flex items-start space-x-4">
                        <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/png.png" alt="Alt text" class="hidden md:block">
                        <div class="grow">
                            <!-- Question -->
                            <div class="font-body text-[#606060] rounded-md p-4 border border-[#0000001A] bg-[#FAFAFA66] text-xs">
                                <p class="text-[#606060] break-all">{{ question?.question }}</p>
                                <p class="text-[8px] mt-4">{{`${question?.user?.first_name} ${question?.user?.last_name} `}} <span class="text-[#08386D]">{{postDate(question?.created_at)}}</span></p>
                            </div>
                            <!-- Question -->
                            <!-- Reply -->
                            <details>
                                <summary class="text-[10px] my-2 inline text-primary-green font-semibold cursor-pointer">See Replies 
                                    <span
                                    class="iconify inline ml-2"
                                    data-icon="mdi:comments"
                                    ></span> {{question?.answers?.length}}</summary>
                                <div class="my-4 space-y-4" v-if="question?.answers?.length > 0">
                                    <div v-for="answer in question.answers" :key="answer.id">
                                        <div class="w-full flex items-start space-x-4">
                                            <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/png.png" alt="Alt text" class="hidden md:block">
                                            <div class="grow">
                                                <div class="font-body text-[#606060] rounded-md p-4 border border-[#0000001A] bg-[#FAFAFA66] text-xs">
                                                    <p class="text-[#606060] break-all">{{ answer?.answer }}</p>
                                                    <p class="text-[8px] mt-4">{{`${answer?.user?.first_name} ${answer?.user?.last_name} `}} <span class="text-[#08386D]">{{postDate(answer?.created_at)}}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div v-else class="text-xs">
                                    No replies yet...
                                </div>                              
                            </details>
                            <div>

                            </div>
                            <form @submit.prevent="addReply(question?.id, question?.reply), question.reply = ''" class="relative my-2 text-[10px] placeholder:text-[10px] border border-gray-400 rounded-md w-full px-3 py-2">
                                <input
                                    class="w-full"
                                    @keyup.enter="addReply(question?.id, question?.reply), question.reply = ''"
                                    v-model="question.reply"
                                    placeholder="Reply this question"
                                />
                                <button type="submit" class="absolute right-2 text-primary-green font-semibold text-lg">
                                    <span
                                        class="iconify"
                                        data-icon="carbon:send-filled"
                                    ></span>
                                </button>
                            </form>
                            <!-- Reply -->
                        </div>
                    </div>
                </template>
                <button @click="questionLimit = (questionLimit+5)" class="bg-[#F3F3F3] text-[#777977] rounded text-center w-full font-medium py-4 text-sm" v-if="lesson?.questions?.length > questionLimit">See More</button>
            </div>
        </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from "axios";
import moment from "moment";

export default {
    computed: {
        ...mapGetters({
            user: "auth/user"
        })
    },
    data(){
        return {
            question: "",
            questionLimit: 5,
        }
    },
    components:['Image'],
    props:['lesson'],
    methods: {
        postDate(postD) {
            return moment(postD).format("h:mma, MMMM Do YYYY");
        },
        async addQuestion(id){
            const payload = {
                lesson_id: id,
                question: this.question
            }
            let loader = this.$loading.show()
            try {
                const { data } = await axios.post("auth/lessons/questions/ask", payload)
                this.question = "";
                const newQuestion = {
                    id: data?.payload?.id,
                    question: payload.question,
                    user_id: this.user?.id,
                    lesson_id: this.lesson?.id,
                    created_at: new Date(),
                    user: {
                        user_id: this.user?.id,
                        first_name: this.user?.first_name,
                        last_name: this.user?.last_name,
                        avatar: this.user?.avatar,
                    },
                    answers: []
                }
                if(this?.lesson?.questions?.length > 0) this.lesson.questions.push(newQuestion)
                else this.lesson.questions = [newQuestion]
                loader.hide();
            } catch (error) {
                console.log(error)
                loader.hide()
            }
        },
        async addReply(id, text){
            const payload = {
                question_id: id,
                answer: text
            }
            let loader = this.$loading.show()
            try {
                await axios.post("auth/lessons/questions/reply", payload)
                const newReply = {
                    answer: text,
                    user_id: this.user?.id,
                    question_id:id,
                    created_at: new Date(),
                    user: {
                        user_id: this.user?.id,
                        first_name: this.user?.first_name,
                        last_name: this.user?.last_name,
                        avatar: this.user?.avatar,
                    },
                }
                let quest = this.lesson.questions.find((item) => {
                    return item.id == id
                })
                quest.answers.push(newReply)
                loader.hide();
            } catch (error) {
                console.log(error)
                loader.hide()
            }
        },
    },

}
</script>

<style>

</style>

