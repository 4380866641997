<template>
  <div>
    <div class="container md:p-0 font-body-new">
      <BackButton class="my-4 md:my-12" />
      <div>
        <div class="grid md:grid-cols-3 gap-12">
          <div class="col-span-2">
            <h2 class="font-bold  text-4xl">{{ user?.batch?.course?.title ?? "Terra Learning"}} Instructor-led Internship</h2>
            <p class="text-sm my-3"> {{ user?.batch?.course?.description ?? " "}} </p>
            <button @click="proceedToPayment()" class="w-full md:w-fit uppercase bg-primary-green px-4 py-3 rounded-md text-white hover:bg-primary-green hover:text-white font-semibold my-4 text-sm">
              Get Started
            </button>
          </div>
        </div>
        <div class="my-6">
          <h3 class="text-3xl text-center font-bold mb-12">Why the <span class="text-primary-green">Instructor-led</span> program?</h3>
          <div class="grid grid-cols-1 md:grid-cols-3 gap-12 my-8">
            <div class="space-y-3">
              <span class="text-primary-green iconify inline text-4xl" data-icon="hugeicons:teacher"></span>
              <h4 class="font-bold text-xl">World Class Instructors</h4>
              <p class="text-justify text-sm text-slate-900">Learn from the best in the industry. Our instructors are experts with years of experience and a passion for teaching. They’ll guide you through every step of your learning journey, ensuring you gain the skills and knowledge needed to succeed.</p>
            </div>
            <div class="space-y-3">
              <span class="text-primary-green iconify inline text-4xl" data-icon="hugeicons:mentoring"></span>
              <h4 class="font-bold text-xl">Project and Tasks</h4>
              <p class="text-justify text-sm text-slate-900">Get hands-on experience with real-world projects and tasks. You'll work on practical assignments that mirror actual industry challenges, helping you to build a strong portfolio and gain confidence in your abilities.</p>
            </div>
            <div class="space-y-3">
              <span class="text-primary-green iconify inline text-4xl" data-icon="hugeicons:computer-video-call"></span>
              <h4 class="font-bold text-xl">Weekly Live Classes</h4>
              <p class="text-justify text-sm text-slate-900">Stay engaged and motivated with our weekly live classes. Interact with instructors and fellow interns in real-time, ask questions, and get instant feedback. These sessions are designed to deepen your understanding and keep you on track with your learning goals.</p>
            </div>
          </div>
          <div class="flex items-center justify-center w-full">
            <button @click="proceedToPayment()" class="uppercase bg-primary-green px-4 py-3 rounded-md text-white font-semibold text-sm my-4 text-center w-full md:w-fit mx-auto">
              Get Started
            </button>
          </div>
        </div>
        <div class="my-6 grid grid-cols-2 md:grid-cols-4 gap-6 items-start border-t-4 border-t-primary-green py-8 px-6 shadow-md rounded-b-md">
          <div>
            <h4 class="font-semibold text-sm">Duration</h4>
            <p class="font-bold text-lg">{{ internship_duration }}</p>
          </div>
          <div>
            <h4 class="font-semibold text-sm">Start Date</h4>
            <p class="font-bold text-lg">{{ internship_start }}</p>
          </div>
          <div>
            <h4 class="font-semibold text-sm">Slots</h4>
            <p class="font-bold text-lg">{{ internship_slots }}</p>
          </div>
          <div>
            <h4 class="font-semibold text-sm">Time</h4>
            <p class="font-bold text-lg">{{ internship_time }}</p>
          </div>
        </div>
        <template v-if="user?.batch?.course?.mentors?.length > 0">
          <h3 class="text-3xl text-center font-bold mb-4 mt-8">Meet Your <span class="text-primary-green">Mentor</span></h3>
        <div v-for="(mentor, index) in user?.batch?.course?.mentors" :key="index" class="border border-gray-100 shadow-md rounded-md flex flex-col items-center md:items-start md:flex-row gap-8 py-6 px-8">
          <!-- <div class=""> -->
            <img :src="mentor.image" :alt="mentor.name" class="h-36 w-36 overflow-clip rounded-full border-2 border-primary-green p-1 grow">
          <!-- </div> -->
            <div class="flex flex-col gap-y-4">
              <p class="text-center md:text-left text-base font-semibold">{{ mentor?.name }}</p>
              <p class="text-xs font-medium leading-5" v-html="mentor?.summary"></p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { createToast } from 'mosha-vue-toastify';
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings"
    }),
    internship_duration(){
      return this.settings?.internship_duration;
    },
    internship_time(){
      return `${this.user?.batch?.course?.timetable[0].day}: ${this.user?.batch?.course?.timetable[0].time}`
    },
    internship_start(){
      return this.settings?.internship_start_date
    },
    internship_slots(){
      return `${this.user?.batch?.course?.slots} slot(s) left`
    }
  },
  components: {
    BackButton,
  },
  data() {
    return {

    };
  },
  mounted() {
    if (this.user?.batch?.learning_type != 'self_paced' || this.user?.cohort < 8) {
      return this.$router.push({ name: 'LearningDashboardIndex'});
    }
    if (this.user?.access_weeks < 2) {
      createToast(`Complete your payment for this course to unlock the upgrade feature.`, { type: "info" });
      return this.$router.push({ name: 'LearningDashboardIndex'});
    }
    if (this.user?.batch?.course?.is_available_instructor != 1) {
      createToast(`The instructor-led option is not available for this course yet.`, { type: "info" });
      return this.$router.push({ name: 'LearningDashboardIndex'});
    }

  },
  methods: {
    proceedToPayment(){
      this.$router.push({ 
        name: 'ApplicationPayment', 
        params: { slug: 'instructor' },
        query: {
          type: 'full'
        }
      })
    }
    
  },
};
</script>

<style scoped></style>
