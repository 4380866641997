<template>
  <div
    class="fixed h-screen w-screen top-0 left-0 right-0 bottom-0 bg-[#0000004D] flex items-center justify-center p-4 z-50"
    @click="closeModal()"
  >
    <div
      class="flex items-center flex-col bg-white dark:bg-[#333333] rounded-2xl px-6 md:px-12 pt-24 pb-12 space-y-4 md:w-3/5 text-center"
      ref="bootcampModal"
    >
      <span
        class="iconify text-primary-green text-6xl"
        data-icon="icon-park-outline:success"
      ></span>
      <h2 class="font-header-1 text-primary-green font-bold text-4xl">
        Yaaaay!
      </h2>
      <p class="font-body text-sm font-semibold text-[#616161] dark:text-white">
        Welcome to {{ title }}
      </p>
      <button
        @click="$emit('closemodal')"
        class="font-medium text-white rounded-md py-3 px-4 bg-primary-green"
      >
        Continue
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    closeModal() {
      const box = this.$refs.bootcampModal;
      if (!box?.contains(event.target)) {
        return this.$emit("closemodal");
      }
      return;
    },
  },
};
</script>

<style></style>
