<template>
  <div class="container p-0">
    <div
      class="p-4 md:p-8 border-[0.6px] rounded dark:bg-[#191919] dark:border-[#191919] bg-[#FAFAFA] border-[#CCCCCC]"
    >
      <div class="flex items-start justify-between container p-0">
        <img
          v-if="user.avatar"
          :src="user.avatar"
          :alt="user.first_name"
          class="rounded-full h-8 w-8 mr-2 md:h-12 md:w-12 md:mr-4"
        />
        <img
          v-else
          src="@/assets/img/img/missing_avatar.png"
          :alt="user.first_name"
          class="rounded-full h-8 w-8 mr-2 md:h-12 md:w-12 md:mr-4"
        />
        <form
          @submit.prevent="makePost()"
          class="w-[calc(100%-4rem)] flex items-end justify-between rounded-md p-2 border border-[#777977] text-[#777977] bg-white dark:border-[#656565] dark:bg-[#262626] dark:text-[#DBDBDB] container"
        >
          <span
            class="bg-transparent text-sm min-h-[1rem] max-w-[95%] grow border-none focus:ring-0 focus:outline-none focus:border-none break-all"
            role="textbox"
            @input="onInput"
            contenteditable
            ref="postInput"
          >
          </span>
          <button type="submit">
            <span class="iconify" data-icon="carbon:send-filled"></span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { inject } from "vue";
import swal from "sweetalert";

const axios = inject("axios");
const $loading = inject("$loading");
const emit = defineEmits(["refreshPosts"]);
const payload = reactive({
  post: "",
});
const postInput = ref(null);
const onInput = (e) => {
  payload.post = e.target.innerText;
};

const store = useStore();
const user = computed(() => {
  return store.state.auth.user;
});
const darkTheme = computed(() => {
  return store.state.auth.darkTheme;
});
const makePost = async () => {
  if (payload.post == "") {
    swal({
      title: "Oops!",
      text: "Post cannot be empty",
    });
    return;
  }
  const loader = $loading.show();
  try {
    axios
      .post("auth/forum/post", payload)
      .then(() => {
        loader.hide();
        payload.post = "";
        postInput.value.innerText = "";
        emit("refreshPosts");
      })
      .catch((error) => {
        loader.hide();
        swal({
          title: "Oops!",
          text:
            error?.response?.data?.payload ??
            "Unable to save your post. Please try again shortly",
        });
      });
  } catch (error) {
    loader.hide();
    swal({
      title: "Oops!",
      text:
        error?.response?.data?.payload ??
        "Unable to save your post. Please try again shortly",
    });
  }
};
</script>

<style></style>
