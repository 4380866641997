<template>
    <main class="container px-0">
        <h1
            class="text-[#0C0C0C]'] dark:text-[#E5E5E5] font-header-1 font-extrabold text-2xl md:text-3xl px-4 md:px-0"
        >
            {{ currentLesson ? currentLesson?.title : "" }}
        </h1>
        <div
            class="flex md:flex-row flex-col py-4 gap-8"
            v-if="currentResource"
        >
            <!-- Main Section -->
            <div class="md:w-3/4 w-[90%] mx-auto md:mx-0">
                <VideoPlayer
                :key="currentResource.id"
                :videoSrc="currentResource.url"
                @ended="nextLesson(currentLesson.id, currentLesson.week)"
                @play="saveLesson(currentLesson.id, currentLesson.week)"
                v-if="currentResource?.source =='DO' && resourceType == 'video'"
                />
                <div  v-if="currentResource?.source =='youtube' && resourceType == 'video'" class="relative h-[320px] overflow-hidden">
                    <div @click="showPlay = true" class="absolute top-0 w-full h-[85%]"></div>
                    <div v-if="showPlay" class="rounded-md pl-3 absolute bottom-1 left-16 z-50 bg-white border border-[#3B2081] flex items-center gap-x-3">
                        <span
                        class="iconify text-primary-green text-lg"
                        data-icon="fa-solid:hand-point-left"></span>
                        Click here to play or pause your videos
                        <button class="bg-primary-green text-white py-2 px-2" @click="showPlay = false">Got it</button>
                    </div>
                    <iframe class="-mt-[50px]" width="100%" height="370px" 
                        oncontextmenu="return false"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                        :src="`${currentResource.youtube_url}?autoplay=1&;modestbranding=1&;rel=0`"  >
                    </iframe>
                </div>
                <!-- Lesson Video Element -->
                <!-- Video Navigation Buttons -->
                <div class="flex items-start justify-between font-body text-sm">
                    <button
                    @click="
                        prevLesson(currentLesson.id, currentLesson.week)
                    "
                        v-if="showPreviousBtn"
                        class="py-4 px-4 md:px-8 bg-[#e5e5e5] text-[#777977] mr-auto dark:bg-transparent dark:border-2 dark:border-t-0 dark:border-[#1F3E1E] dark:text-[#e1e1e1] font-semibold"
                    >
                    Previous Lesson
                </button>
                <button
                @click="
                    nextLesson(currentLesson.id, currentLesson.week)
                "
                        class="py-4 px-4 md:px-8 bg-[#e5e5e5] text-[#777977] ml-auto dark:bg-transparent dark:border-2 dark:border-t-0 dark:border-[#1F3E1E] dark:text-[#e1e1e1] font-semibold"
                    >
                        {{ showNextBtn }}
                    </button>
                </div>
                
                <!-- Question & Answer Section -->
                <ChatBox :lesson="currentLesson" />
                <!-- Question & Answer Section -->
            </div>
            <!-- Main Section -->
            
            <!-- Aside Buttons -- volume, text, & slides -->
            <div
                class="hidden md:grid grid-flow-row gap-4 h-fit w-fit text-[#999999] dark:text-[#999999]"
                v-if="
                    currentLesson?.modules && currentLesson?.modules.length > 1 && false
                "
            >
                <button
                    v-for="resource in currentLesson.modules"
                    :key="resource.id"
                    @click="resourceType = resource.format"
                    class="flex items-center justify-center border rounded-full p-4 dark:border-[#999999] border-[#999999]"
                >
                    <span
                        v-if="resource.format == 'audio'"
                        class="iconify"
                        data-icon="fluent:speaker-2-24-filled"
                    ></span>
                    <span
                        v-if="resource.format == 'video'"
                        class="iconify"
                        data-icon="bi:play-fill"
                    ></span>
                    <span
                        v-if="resource.format == 'doc'"
                        class="iconify"
                        data-icon="ri:slideshow-4-fill"
                    ></span>
                </button>
            </div>
            <!-- Aside Buttons -- volume, text, & slides -->
            <!-- <skeleton-loader >
            </skeleton-loader > -->

        </div>
        
        
    </main>
</template>

<script>
import { saveAs } from "file-saver";
import ChatBox from "./ChatBox.vue";
import VideoPlayer from "@/components/lms/VideoPlayer.vue";


export default {
    data() {
    return {
      iframeSrc: '',
      show: false
    }
  },
  components:{
    ChatBox,
    VideoPlayer,
  },
  methods: {
    openIframe(link) {
      // set the iframe source URL
      this.show = true;
      this.iframeSrc = `https://docs.google.com/document/url=${link}?embedded=true`
    },
    async downloadExcel(url) {
        fetch(url)
        .then(response => response.arrayBuffer())
        .then(data => {
            const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
            saveAs(blob, 'myfile.xls');
        });
    }
  }
}
</script>

<script setup>
// import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
// import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'





import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { encrypt } from "@/helpers/encrypt";
// import SkeletonLoader from "@/components/lms/SkeletonLoader";

import { useStore } from "vuex";
import { onMounted, ref, watch, watchEffect } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
// import swal from "sweetalert";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
const axios = inject("axios");
const store = useStore();
const router = useRouter();
const route = useRoute();
let id = ref(null);
let question = ref("");
let resourceType = ref("video");
let showPlay = ref(true);



/**
 * Site Settings
 */
const siteSettings = computed(() => {
    return store.state.auth.settings;
});

const user = computed(() => {
    return store.state.auth.user;
});

/**
 * Current Lesson
 */
const currentLesson = computed(() => {
    return store.state.lms.currentLesson;
});

const currentDocs = computed(() => {
    return store.state.lms.lessonsDocs;
})


/**
 * Learning Type -- orientation || learning || jobberman
 */
const learningType = computed(() => {
    return store.state.lms.learningType;
});

/**
 * Learning Lessons
 */
const lessons = computed(() => {
    return store.state.lms.lessons;
});

/**
 * Jobberman Lessons
 */
const jobberman_lessons = computed(() => {
    return store.state.jobberman.lessons;
});
/**
 * Watched Learning Lessons
 */
const watchedLessons = computed(() => {
    return store.state.lms.watchedLessons;
});

/**
 * Jobberman wathced lessons
 */
const jobbermanWatchedLessons = computed(() => {
    return store.state.jobberman.watchedLessons;
});

/**
 * 
 */
const jobbermanTests = computed(() => {
    return store.state.jobberman.checkTests;
});

const filterResources = computed(() => {
    if (currentLesson?.value?.modules) {
        return currentLesson.value.modules.filter((item) => {
            return item.format == resourceType.value ?? "video";
        });
    } else if(currentLesson?.value) {
        return currentLesson.value;
    } else return null;
});

/**
 * Select the current resource to display to user
 * @param [Array] this.filterResources
 * @return object
 */
const currentResource = computed(() => {
    if (filterResources.value) {
        return filterResources.value[0] ?? filterResources.value;
    } else {
        return null;
    }
});

/**
 * This return the lessons for the week pass to it.
 * @params  week
 */
const eachWeekLessons = computed(() => {
    return (week) => lessons?.value?.filter((item) => item.week == week);
});

/**
 * show next button
 */
const showNextBtn = computed(() => {
    const currentWeekLessons = eachWeekLessons.value(currentLesson.value.week);
    let currentLessonIndex = currentWeekLessons?.findIndex(
        (item) => item.id == currentLesson.value.id,
    );
    if ((currentLessonIndex + 1 == currentWeekLessons?.length) && learningType?.value == 'learning') {
        if(user.value?.batch?.batch_id == 6) {
            return "Take Task"
        }
        return "Take Quiz"
    }
    if (currentLesson?.value) {
        return "Next";
    }
});

/**
 * Show Previous Button
 */
const showPreviousBtn = computed(() => {
    if (currentLesson.value) {
        const currentWeekLessons = eachWeekLessons.value(currentLesson.value.week);
        let currentLessonIndex = currentWeekLessons?.findIndex(
            (item) => item.id == currentLesson.value.id,
        );
        if (currentLessonIndex < 1 ) {
            return false;
        } 
        return true
    } else return false;
});

/**
 * Set Current learning material
 */
const setCurrentLesson = (lesson) =>    store.dispatch("lms/setCurrentLesson", lesson);

/**
 * Store lessons 
 */
const storeLessons = (payload) => store.dispatch("lms/storeLessons", payload);

/**
 * Save a watched lesson
 */
const saveWatchedLessons = (payload) => store.dispatch("lms/saveWatchedLessons", payload);

const setLearningType = ((learningType) =>{
    store.dispatch("lms/setLearningType", learningType)
});

const goToQuiz = (week) => {
    router.push({
        name: "QuizInstructionPage",
        params: { week: encrypt(week) },
    });
};

const goToTask = (week) => {
    router.push({
        name: "TaskInstructionPage",
        params: { week: encrypt(week) },
    });
};

const prevLesson = (id, week) => {
    if (learningType.value == 'learning') {
        const currentWeekLessons = eachWeekLessons.value(week);
        let currentLessonIndex = currentWeekLessons.findIndex(
            (item) => item.id == id,
        );
    
        if (currentLessonIndex > 0) {
            setCurrentLesson(currentWeekLessons[currentLessonIndex - 1]);
        }
        
        if (currentLessonIndex < 1 &&  week > 0) {
            const prevWeekLessons = eachWeekLessons.value(week - 1);
            setCurrentLesson(prevWeekLessons[(prevWeekLessons?.length - 1)]);
            setLearningType('learning'); 
            return
        }
    }
};

const nextLesson = async (id, week) => {
    const currentWeekLessons = eachWeekLessons.value(week);
    let currentLessonIndex = currentWeekLessons.findIndex(
        (item) => item.id == id,
    );    
    if (week == 3 && user?.value?.certificate_status?.status == false) {
        swal({
            title: "Oops!",
            text: "You are have to make payment for your certificate to access your week  learnings",
        });
        let week_2 =  eachWeekLessons.value(2)
        setCurrentLesson(week_2[0], learningType);
        return
    }
    if (week == 4 && user?.value?.certificate_status?.status == false) {
        swal({
            title: "Oops!",
            text: "You are have to make payment for your certificate to access your week 4 learnings",
        });
        let week_3 =  eachWeekLessons.value(3)
        setCurrentLesson(week_3[0], learningType);
        return
    }


    if (currentLessonIndex >= 0 && learningType?.value == 'learning') {
        if (currentLessonIndex + 1 == currentWeekLessons.length) {
            const done = await saveLesson(
                currentLesson.value?.id,
                currentLesson.value?.week,
            );
            if(user.value?.batch?.batch_id == 6) {
                    goToTask(week);
                    return;
                } 
                goToQuiz(week);
                return;
        }
        saveLesson(currentLesson.value.id, currentLesson.value.week);
        setCurrentLesson(currentWeekLessons[currentLessonIndex + 1]);
    }
};

/**
 * Save watch lessons
 */
const saveLesson = async (id, week) => {
    try {
        const payload = {
            lesson_id: id,
            week: week,
        };
        const { data } = await axios.post("v2/auth/lessons/progress", payload);
        saveWatchedLessons(data.data);
        return true;
    } catch (error) {
        console.log(error);
    }
};



/**
 * Ask fetaured Question
 */
const askQuestion = async () => {
    try {
        const payload = {
            question: question.value,
        };
        const { data } = await axios.post(
            `auth/lessons/${currentLesson.value.id}/featured-questions`,
            payload,
        );
        question.value = "";
        fetchLessons(true);
        swal({
            title: "Posted",
            text: data.payload,
        });
    } catch (error) {
        console.log(error);
    }
};


watch(id, () => {
    if (id.value) {
        if (lessons.value) {
            let lesson = lessons.value.find((item) => item.id == id.value);
            if (lesson) {
                setCurrentLesson(lesson);
            }
        }
    }
});
watch(lessons, () => {
    if (! currentLesson.value && lessons?.value?.length > 0) {
        setCurrentLesson(lessons.value[0]);        
    }
});


onMounted(() => {
    if (! currentLesson.value && lessons?.value?.length > 0) {
        setCurrentLesson(lessons.value[0]);        
    }
});
</script>

<style scoped>
 #vid {
     height: 200px !important
    }
    
    .vjs-fluid {
        height: 0;
        object-fit: contain !important;
  padding-top: 56.25% !important; /* 16:9 aspect ratio */ 
}
</style>
