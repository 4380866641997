<template>
  <div>
    <div class="container md:p-0">
      <BackButton class="my-4 md:my-12" />
      <template v-if="authorized">
        <div class="">
          <h1
            class="font-header-1 font-bold text-3xl md:text-4xl dark:text-[#E5E5E5] text-black"
          >
            Select your preferred team
          </h1>
        </div>
        <div
          class="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-3 my-8"
          v-if="bootcampTeams?.length > 0"
        >
          <template v-for="team in bootcampTeams" :key="team.id">
            <div
              class="p-4 md:p-6 bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body"
            >
              <h3
                class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl"
              >
                {{ team.title }}
              </h3>
              <p
                class="font-normal text-[#666666] dark:text-[#f4f4f4] text-sm my-3"
              >
                {{ team.description }}
              </p>
              <router-link
                :to="{ name: 'TeamDetails', params: { id: team.id } }"
                class="font-semibold text-primary-green text-sm mt-auto"
              >
                READ MORE
                <span
                  class="iconify inline-block"
                  data-icon="bi:arrow-up-right"
                ></span>
              </router-link>
            </div>
          </template>
        </div>
        <div class="my-8" v-if="bootcampTeams?.length == 0">
          <h3 class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl">
            There are currently no teams available for selection
          </h3>
        </div>
      </template>
      <template v-else>
        <div class="">
          <h1
            class="font-header-1 font-bold text-3xl md:text-4xl dark:text-[#E5E5E5] text-black"
          >
            Select your preferred team
          </h1>
        </div>
        <div class="my-8" v-if="bootcampTeams?.length == 0">
          <h3 class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl">
            There are currently no teams available for selection
          </h3>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
const $loading = inject("$loading");

const store = useStore();
const router = useRouter();

const user = computed(() => {
  return store.state.auth.user;
});

const authorized = ref(false);

const bootcampTeams = ref(null);

const fetchAllTeams = async () => {
  const loader = $loading.show();
  try {
    const { data } = await axios.get("/auth/teams");
    bootcampTeams.value = data.payload;
    loader.hide();
    authorized.value = true;
  } catch (error) {
    loader.hide();
    if (error.response.status == 401) {
      authorized.value = false;
      // return router.push({ name: "LearningDashboardIndex"})
    }
    bootcampTeams.value = [];
    console.log(error);
  }
};
const checkBootCampTeam = async () => {
  try {
    const { data } = await axios.get("auth/teams/status/check");
    if (data.payload == true) {
      router.push({ name: "LearningDashboardIndex" });
    }
  } catch (error) {
    if (error.response.status == 401) {
      authorized.value = false;
      // router.push({ name: "LearningDashboardIndex"})
    }
  }
};

onMounted(() => {
  fetchAllTeams();
  checkBootCampTeam();
});
</script>

<style scoped></style>
