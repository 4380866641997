<template>
  <div class="container">
    <nav class="py-4">
      <img
        src="@/assets/img/logo/side-hustle-logo.svg"
        alt="Side Hustle Logo"
      />
    </nav>
    <div
      class="grid grid-flow-row gap-8 place-items-center items-center text-center pt-16 md:pt-8"
    >
      <img
        src="@/assets/img/404error.png"
        class="text-center max-w-[215px]"
        alt="Page Not Found"
      />
      <h2 class="mt-3 font-header-1 font-extrabold text-4xl text-primary-green">
        Error 404! Page Not Found
      </h2>
      <p class="font-body text-[#898989]">
        Oh dear! We couldn't find that page!
      </p>
      <button
        @click="$router.push('/')"
        class="py-2 p-4 bg-[E1F0E0] border border-[#52a35033] rounded-lg font-body text-[#52A350]"
      >
        <i class="fas fa-angle-left fa-1x"></i> Go Home
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style scoped></style>
