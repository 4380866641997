<template>
  <main class="bg-[#1A1F1A] min-w-full">
    <section
      id="hero"
      class="bg-transparent container font-body-new lg:grid lg:grid-cols-2 gap-x-4 pb-12 lg:pb-24 2xl:pb-10 pt-16 lg:pt-40 h-full xl:h-fit"
    >
      <div
        class="text-[#ffffff] text-center lg:text-left pt-12 lg:pt-24 font-body-new"
      >
        <h1
          class="text-white text-4xl lg:text-[52px] tracking-[0.01em] leading-10.8 lg:leading-12 font-bold mb-5"
        >
          Boost your skills <br />
          and become job-ready
        </h1>
        <p class="lg:text-xl lg:mr-40">
          Join an
          <span class="text-[#52A350] font-bold">instructor-led bootcamp</span>
          to practice and improve your skills. Build your portfolio and become
          better equiped to pursue that dream career
        </p>
      </div>
      <div class="mx-auto lg:ml-5 relative lg:w-3/4 w-11/12 my-12 lg:my-0">
        <div class="rounded-full absolute bg-[#52A350] top-8 h-36 w-36"></div>
        <div
          class="rounded-full absolute bg-[#0B52D1] bottom-10 right-0 h-36 w-36"
        ></div>
        <div
          class="p-6 lg:px-12 mx-2 rounded-[10px] backdrop-blur-[25px] mt-3 shadow-[0px_16px_50px_rgba(37,77,36,0.08)] bg-[#FFFFFF]"
        >
          <h1
            class="font-header-1 tracking-[0.01em] font-bold text-[#0C180C] text-3xl lg:text-4xl"
          >
            Join the Terra bootcamp
          </h1>
          <p class="text-[#0C180C] text-sm lg:text-sm font-body-new mb-2 mt-2">
            Enter your details to begin your application
          </p>
          <form @submit.prevent="submitDetails()">
            <fieldset
              class="grid grid-flow-row gap-8 md:grid-cols-2 md:gap-4 my-4"
            >
              <CustomInputField
                errorMessage="First name is required"
                label="First Name"
                inputType="text"
                :validState="!v$.user.first_name.$invalid"
                required
                v-model="user.first_name"
                @change="SpecialCharactersFirst"
              />
              <CustomInputField
                errorMessage="Last name is required"
                label="Last Name"
                :validState="!v$.user.last_name.$invalid"
                inputType="text"
                required
                v-model="user.last_name"
                @change="SpecialCharacterslast"
              />
            </fieldset>
            <fieldset class="grid grid-cols-6 gap-4 my-8 w-full" v-if="true">
              <select
                class="col-span-2 w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
                name="country"
                v-model="user.country_id"
                @change="checkPhoneValid"
              >
                <option selected disabled value="">Select Country</option>
                <template v-for="country in countries" :key="country.id">
                  <option :value="country.id">
                    {{ country.nicename }}
                  </option>
                </template>
              </select>
              <CustomInputField
                :errorMessage="phoneValidationMsg"
                :validState="phoneValid"
                label="Phone number"
                inputType="tel"
                required
                class="col-span-4"
                v-model="user.phone_number"
                @validatephone="checkPhoneValid"
              />
            </fieldset>
            <CustomInputField
              errorMessage="Email is required"
              :validState="!v$.user.email.$invalid"
              v-model="user.email"
              label="Email address"
              inputType="email"
              required
              class="my-4"
            />
            <CustomInputField
              errorMessage="Email doesn't match"
              :validState="!v$.user.confirmEmail.$invalid"
              v-model="user.confirmEmail"
              label="Confirm email address"
              inputType="email"
              required
              class="my-4"
            />
            <fieldset class="grid gap-4 my-8 w-full">
              <select
                class="w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-transparent placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
                name="gender"
                v-model="user.gender"
              >
                <option selected disabled value="">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </fieldset>
            <fieldset class="grid gap-4 my-8 w-full" v-if="courses?.length > 0">
              <select
                class="w-full h-14 focus:outline-none focus:border-primary-green focus:rounded-md p-4 focus:border-2 font-light text-sm border rounded border-[#8B908B] bg-[white] text-[#414141] focus:placeholder:text-black placeholder:text-base placeholder:font-light placeholder:text-[#858585]"
                name="course_id"
                v-model="user.course_id"
              >
                <option selected disabled value="">
                  Select a skill/career path
                </option>
                <option
                  v-for="course in courses?.filter((c)=>c.bootcamp_available == 1)"
                  :key="course.id"
                  :value="course.id"
                >
                  {{ course?.title ?? "" }}
                </option>
              </select>
            </fieldset>
            <CustomInputField
              errorMessage="Enter at least 6 characters"
              :validState="!v$.user.password.$invalid"
              label="Password"
              inputType="password"
              class="mt-4 mb-8"
              required
              v-model="user.password"
              autocomplete="new-password"
            />
            <CustomInputField
              errorMessage="Password doesn’t match"
              :validState="!v$.user.rePassword.$invalid"
              label="Confirm password"
              inputType="password"
              required
              class="mt-4 mb-8"
              v-model="user.rePassword"
              autocomplete="new-password"
            />
            <button
              class="text-white font-body-new"
              type="submit"
              :disabled="v$.$invalid || isLoading || !phoneValid"
            >
              Sign Up
            </button>
          </form>
          <p
            class="text-black hidden lg:block text-sm mt-5 w-4/5 mx-auto text-center"
          >
            By signing up, you agree to Terra’s
            <router-link
              class="text-[#52A350]"
              :to="{ name: 'TermsAndConditions' }"
              >Terms & Conditions</router-link
            >
            and
            <router-link class="text-[#52A350]" :to="{ name: 'Privacy' }"
              >Privacy Policy</router-link
            >
          </p>
          <div class="text-center border-t border-t-[#ACB1AC] pt-5 mt-5">
            <p class="text-xs md:text-sm mb-5">Already have a Terra account?</p>
            <SecondaryButton
              @click="$router.push({ name: 'LoginPage' })"
              class="w-full"
              >Login</SecondaryButton
            >
          </div>
        </div>
      </div>
      <div class="lg:w-4/6 w-11/12 lg:-mt-32 mx-auto">
        <img
          src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/bootcamp_image1.png"
          alt="Bootcamp picture"
        />
      </div>
      <div class="h-fit lg:ml-8 my-12 text-center lg:text-left lg:my-auto">
        <p class="text-[#52A350] mb-5">What’s in it for you</p>
        <ul class="list-disc leading-7 list-inside text-sm text-white">
          <li>Become a part of a fast-paced startup</li>
          <li>Business Development Opportunity</li>
          <li>Real-life work experience</li>
          <li>Recommendation Letter</li>
          <li>Access to Talent Pool</li>
        </ul>
      </div>
      <div
        class="col-span-2 p-6 lg:p-12 w-11/12 lg:w-4/5 my-20 mx-auto bg-white"
      >
        <p class="text-[2f2f2f] font-body-new font-bold mb-7 text-sm">
          Startups from previous bootcamps
        </p>
        <div class="grid grid-cols-3 lg:grid-cols-7 gap-x-7 lg:gap-y-4 gap-y-6">
          <img
            class="h-7 lg:h-[30px] self-center w-40"
            src="@/assets/img/img/employ.png"
            alt="employ"
          />
          <img
            class="h-7 lg:h-[30px] self-center w-32"
            src="@/assets/img/img/abacus.png"
            alt="abacus"
          />
          <img
            class="h-7 lg:h-[30px] self-center w-36"
            src="@/assets/img/img/japa.png"
            alt="japa"
          />
          <img
            class="h-11 lg:h-[41px] self-center w-20"
            src="@/assets/img/img/peersapp.png"
            alt="peersapp"
          />
          <img
            class="h-7 lg:h-[30px] self-center w-[136px]"
            src="@/assets/img/img/flatpay.png"
            alt="flatpay"
          />
          <img
            class="h-14 lg:h-[52px] self-center w-[62px]"
            src="@/assets/img/img/ivory.png"
            alt="ivory"
          />
          <img
            class="h-7 lg:h-[30px] self-center w-[74px]"
            src="@/assets/img/img/mk.png"
            alt="mk"
          />
          <img
            class="h-4 lg:h-[15px] self-center w-[146px]"
            src="@/assets/img/img/getfunds.png"
            alt="getfunds"
          />
          <img
            class="h-10 lg:h-[40px] self-center w-[103px]"
            src="@/assets/img/img/eschoolpedia.png"
            alt="eschoolpedia"
          />
          <img
            class="h-10 lg:h-[40px] self-center w-[119px]"
            src="@/assets/img/img/shopraks.png"
            alt="shopraks"
          />
          <img
            class="h-7 lg:h-[30px] self-center w-[126px]"
            src="@/assets/img/img/veepay.png"
            alt="veepay"
          />
          <img
            class="h-5 lg:h-[20px] self-center w-[69px]"
            src="@/assets/img/img/bizka.png"
            alt="bizka"
          />
          <img
            class="h-7 lg:h-[30px] self-center w-[71px]"
            src="@/assets/img/img/kappari.png"
            alt="kappari"
          />
          <img
            class="h-10 lg:h-[40px] self-center w-[112px]"
            src="@/assets/img/img/foodrevy.png"
            alt="foodrevy"
          />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CustomInputField from "@/components/inc/CustomInputField.vue";
import SecondaryButton from "@/components/inc/SecondaryButton.vue";

import useVuelidate from "@vuelidate/core";
import { required, email, sameAs, minLength } from "@vuelidate/validators";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

import phoneValidator from "@/helpers/phoneValidator";

export default {
  name: "IndexBootcamp",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    CustomInputField,
    SecondaryButton,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        confirmEmail: "",
        course_id: "",
        phone_number: "",
        country_id: "",
        gender: "",
        password: "",
        rePassword: "",
      },
      isLoading: false,
      phoneValid: false,
      phoneValidationMsg: "",
      countries: null
    };
  },
  computed: {
    SpecialCharactersFirst() {
      const newValue = this.user.first_name;
      if (this.checkForSpecialCharacters(newValue)) {
        this.user.first_name = this.removeSpecialCharacters(newValue);
      }
    },
    SpecialCharacterslast() {
      const newValue = this.user.last_name;
      if (this.checkForSpecialCharacters(newValue)) {
        this.user.last_name = this.removeSpecialCharacters(newValue);
      }
    },
    ...mapGetters({
      courses: "auth/courses",
    }),
  },
  watch: {
    "user.phone_number"() {
      this.checkPhoneValid();
    },
  },
  validations() {
    return {
      user: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        phone_number: { required },
        confirmEmail: { required, sameAs: sameAs(this.user.email) },
        gender: { required },
        password: { required, minLength: minLength(6) },
        rePassword: { required, sameAs: sameAs(this.user.password) },
      },
    };
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    countryChanged(country) {
      const selectedCountry = this.countries.find((item) => {
        return item.iso == country.iso2;
      });
      return (this.user.country_id = selectedCountry.id);
    },
    checkPhoneValid() {
      let selectedCountry = this.countries.find(
        (item) => item.id == this.user.country_id
      );
      if (!this.user.country_id) {
        this.phoneValidationMsg = "Please select your country";
        return;
      }
      if (this.user.phone_number.length < 8) {
        this.phoneValid = false;
        return;
      }
      let res = phoneValidator(this.user.phone_number, selectedCountry.iso);
      if (res.status) {
        this.user.phone_number = res.phoneNumber;
        this.phoneValidationMsg = "";
        this.phoneValid = true;
      } else {
        this.phoneValid = false;
        this.phoneValidationMsg = "Enter a valid phone number";
      }
    },
    async submitDetails() {
      this.v$.$validate();
      if (!this.v$.$error) {
        let loader = this.$loading.show();
        try {
          const { data } = await axios.post("v2/bootcamp/register", this.user);
          loader.hide();
          swal({
            title: "Success",
            text: "You have successfully applied for the Terra bootcamp. Proceed to make payment!",
          });
          await this.attempt({token: data.data})
          this.$router.push({ name: 'ApplicationPayment', params: { slug: 'bootcamp' }, });
        } catch (error) {
          console.log(error);
          loader.hide();
          swal({
            title: "Try Again",
            text:
              error?.response?.data?.message ??
              "Unable to submit your details. Please try again later.",
          });
        }
      }
    },
    checkForSpecialCharacters(charactersString) {
      const specialCharactersRegex = /[^\w\s]/;
      return specialCharactersRegex.test(charactersString);
    },
    removeSpecialCharacters(charactersString) {
      return charactersString.replace(/[^\w\s]/gi, "");
    },
    getCountries() {
      axios.get("countries").then((res) => {
        this.countries = res.data.payload;
        localStorage.setItem("countries", JSON.stringify(this.countries));
      });
    },
  },
  mounted(){
    const countries = localStorage.getItem("countries");
    if (Array.isArray(countries) && countries?.length > 1 ) {
      this.countries = JSON.parse(countries);
    } else {
      this.getCountries();
    }
  }
};
</script>

<style lang="scss" scoped>
button[type="submit"] {
  @apply text-base lg:text-lg font-semibold text-white bg-primary-green rounded-md  py-4 px-12 w-full;
}
button[type="submit"]:disabled {
  @apply cursor-not-allowed bg-[#BFC4BF];
}
</style>
