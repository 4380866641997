<template>
  <div class="px-0">
    <LearningNavBar class="z-50" />
    <LearningBottomNav />
    <OnboardingModal v-if="openOnboardModal == true" @closeModal='openOnboardModal = false' />
    <div class="relative">
      <LearningNavBarMobile class="z-50" />
    </div>
    <div
      class="dark:bg-[#1A1F1A] bg-[#FFFFFF] w-full pt-16 lg:pt-24 pb-8 min-h-screen"
    >
      <div class="">
        <PaymentPopUpModal
          :paymentDeadline="paymentDeadline"
          v-if="
            ((paymentDeadline == 0 &&
              openPaymentPopUp &&
              !paymentPages.includes($route.name)) ||
            (paymentDeadline > 0 &&
              openPaymentPopUp &&
              $route.name == 'LearningDashboardIndex')) && user?.cohort <= 7
          "
          @closemodal="openPaymentPopUp = false"
        />
        <SideNavigation />
        <!-- LMS Side Navigation -->
        <div
          :class="[
            $route.name != 'LearningDashboardIndex'
              ? 'grid md:grid-cols-[5rem_auto]'
              : '',
          ]"
          class=""
        >
          <div class="md:col-start-2 w-full">
            <div class="">
              <router-view class="md:px-0 " />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import checkUserDataCompletion from "@/helpers/checkUserDataCompletion.js"

import RavenPopUp from "./RavenPopModal.vue";
import PaymentPopUpModal from "./PaymentPopUpModal.vue";
import GenderPopupModal from "./GenderPopupModal.vue";
import Announcement from "./LearningAnnouncementComponent.vue";
import OnboardingModal from "@/views/kyc/OnboardingModal.vue";
import SideNavigation from "@/components/lms/LearningSideNav.vue";
import LearningNavBar from "@/components/lms/LearningNavBar.vue";
import LearningNavBarMobile from "@/components/lms/LearningNavBarMobile.vue";
import LearningBottomNav from "@/components/lms/LearningBottomNav.vue";
import BootcampPaymentModal from "@/components/lms/BootcampPaymentModal.vue";

import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  components: {
    SideNavigation,
    LearningNavBar,
    LearningNavBarMobile,
    Announcement,
    RavenPopUp,
    PaymentPopUpModal,
    GenderPopupModal,
    OnboardingModal,
    LearningBottomNav,
    BootcampPaymentModal
  },
  data() {
    return {
      openOnboardModal: false,
      openBootcampPopup: true,
      openRavenPopUp: false,
      openPaymentPopUp: true,
      paymentPages: ["ChoosePayment", "SubPayment", "Amount"],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      lessons: "lms/lessons",
      currentLesson: "lms/currentLesson",
      settings: "auth/settings",
      baseline: "auth/baseline",
      userCourse: "auth/userCourse"
    }),

    paymentDeadline() {
      const deadlineData = new Date(this.user?.certificate_status?.deadline);
      const CURRENT_TIME = new Date();
      const diff = deadlineData.getTime() - CURRENT_TIME.getTime();
      const dayDiff = diff / (1000 * 60 * 60 * 24);
      if (this.user?.certificate_status?.status == false) {
        if (Math.ceil(dayDiff) <= 0) {
          return 0;
        } else {
          return Math.ceil(dayDiff);
        }
      }
    },
  },
  watch: {
    $route() {
      if (
        this.paymentDeadline == 0 &&
        this.user?.certificate_status?.status == false
      ) {
        this.openPaymentPopUp = true;
      }
    },
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
      fetchUserData: "auth/fetchUserData",
      fetchUserStreak: "lms/fetchUserStreak",
      fetchProgress: "lms/fetchProhress",
      setCurrentLesson: "lms/setCurrentLesson",
      setLearningType: "lms/setLearningType",
      fetchLessons: "lms/fetchLessons",
      setTakenQuiz: "lms/setTakenQuiz",
      saveWatchedLessons: "lms/saveWatchedLessons",
    }),
    
  },
  async mounted() {
    if (this.user?.batches?.length < 1) {
      return this.$router.push({ name: "SelectCourse" });
    }
    if (this.user?.batch?.can_access != 1 && this.user?.batch?.accepted != 1) {
      return this.$router.push({ name: "SelectBatchPage" });
    }
    await this.fetchUserData()
    let dataCheck = checkUserDataCompletion()
    if (dataCheck == false) {
      this.openOnboardModal = true
    } else {
      this.openOnboardModal = false
    }
    
    if (this.user?.batch?.learning_type == 'instructor'  && this.user.batch?.can_access == 1 && new Date(this.user?.batch?.batch?.start_on)  > new Date() && this.settings?.can_access == 0) {
      return this.$router.push({ name: "StartWaitPage" })
    } 
    else {
      await this.fetchLessons();
      await this.setTakenQuiz();
      await this.saveWatchedLessons();
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}

.bounce-enter-active,
.bounce-leave-active {
  transition: 150ms ease;
}

.bounce-enter-from,
.bounce-leave-to {
  transform: scale(0);
}
</style>
