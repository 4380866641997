<template>
  <div>
    <div class="container md:p-0 font-body">
      <BackButton class="my-4 md:my-12" />
        <div class="text-center space-y-2">
          <h1
            class="font-header-1 font-bold text-3xl md:text-4xl dark:text-[#E5E5E5] text-black"
            v-if="user?.bootcamp.type =='portfolio'" 
          >
            Join a Team
          </h1>
          <h1
            class="font-header-1 font-bold text-3xl md:text-4xl dark:text-[#E5E5E5] text-black"
            v-else 
          >
            Select your Project
          </h1>
        </div>
        <div 
          v-if="user?.bootcamp.type =='portfolio'" 
          class="border py-4 px-6 flex items-center flex-col gap-y-4 my-6 shadow-md text-center">
          Would you like to create your team, and build your portfolio with other like minds?
          <button 
            @click="createTeam()"
            class="py-3 font-medium px-4 rounded-full border-2 border-dashed text-primary-green hover:opacity-95 transition ease-linear wit-fit"
          >
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <div
          class="grid gap-4 md:gap-8 grid-cols-1 md:grid-cols-3 my-8"
          v-if="teams?.length > 0"
        >
            <div
                v-for="(team, index) in teams" :key="index"
              class="p-4 md:p-6 flex flex-col bg-[#FFFFFF] dark:bg-[#333333] rounded-lg shadow-lg font-body"
            >
              <h3
                class="font-semibold text-[#222222] dark:text-[#E5E5E5] text-xl"
              >
                {{ team.title }}
              </h3>
              <p
                class="font-normal text-[#666666] dark:text-[#f4f4f4] text-sm my-3"
              >
                {{ team.description }}
              </p>
              <span
                class="font-semibold text-primary-green text-sm mt-auto border cursor-pointer border-primary-green rounded w-fit px-4 py-2 "
                @click="$router.push({name: 'ViewBootcampProject', params: {id:team.id} })"
              >
                READ MORE
                <span
                  class="iconify inline-block"
                  data-icon="iconamoon:arrow-right-2-duotone"
                ></span>
              </span>
            </div>
        </div>
        <div class="my-8" v-else>
          <h3 class="font-medium text-[#222222] dark:text-[#E5E5E5] text-center">
            Be the first to join a team in this track. <span class="underline text-primary-green font-semibold">Click here</span> to create a team.
          </h3>
        </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import BackButton from "@/components/lms/DashboardBackComponent.vue";
import { useStore } from "vuex";
import { computed, inject, onMounted, ref } from "@vue/runtime-core";
import { useRouter } from "vue-router";
const $loading = inject("$loading");

const store = useStore();
const router = useRouter();

const user = computed(() => {
  return store.state.auth.user;
});

const settings = computed(() => {
  return store.state.auth.settings;
});
const teamsStore = computed(() => {
  return store.state.bootcamp.teams;
});

const teams  = ref([]);

const fetchTeams = async () => {
    if (teamsStore.value) {
      return teams.value = teamsStore.value;
    }
    let loader = $loading.show();
    try {
        const { data } = await axios.get("v2/auth/bootcamp/teams");
        teams.value = data.data;
        store.dispatch("bootcamp/storeTeams", data.data)
        loader.hide()
    } catch (error) {
        loader.hide()
        console.error(error);
    }
}

const createTeam = async () => {
  let loader = $loading.show();
  try {
      await axios.get("v2/auth/bootcamp/create/team");
      await store.dispatch("auth/attempt", {});
      router.push({ name: "BootcampWelcome" });
      loader.hide();
      return;
  } catch (error) {
      await store.dispatch("auth/attempt", {});
      if (error.response.status == 401) {
        router.push({ name: "SelectBatchPage" });
        return;
      }
      loader.hide()
      console.error(error);
  }
}
onMounted( async () => {
    return router.push({name: "BootcampWelcome"})
});
</script>

<style scoped></style>
