<template>
  <div
    class="rounded-lg shadow-[0px_8px_50px_rgba(0,0,0,0.15)] p-8 absolute top-0 right-0 w-[24rem] font-body-new dark:bg-[#2E2E2E] dark:text-[#F4F4F4] bg-white text-[#646D64]"
  >
    <div class="border-b border-[#D9D9D9] pb-3">
      <h3 class="font-medium text-base">
        {{ user?.email ?? "" }}
      </h3>
    </div>
    <div class="pt-4 overflow-y-scroll scrollbar-hide">
      <ul
        class="font-medium text-base leading-10 dark:text-[#E6E6E6] text-[#646D64]"
      >
        <template v-if="showFullNav">
          <div
            v-if="user?.batch_id == 6"
            class="flex items-center justify-center font-body text-sm font-semibold border cursor-pointer dark:text-white rounded-md py-4 mb-3 text-center"
          >
            {{
              user?.learning_type == "instructor"
                ? "INSTRUCTOR LED"
                : "SELF PACED LEARNING"
            }}
          </div>


          <li @click="navigate('ProfilePage')" class="cursor-pointer">
            Profile
          </li>
          <li @click="navigate('AllCertificatesPage')" class="cursor-pointer">
            Certificates
          </li>
          <li @click="navigate('PeigesIndex')" class="cursor-pointer">
            Portfolio
          </li>
          <li
            v-if="true"
            @click="
              $router.push({ name: 'SelectBatchPage' }), closeDropDown()
            "
            class="cursor-pointer flex items-center justify-between"
          >
            Course
            <span
              class="iconify text-[#999999] inline text-lg"
              data-icon="ant-design:caret-right-outlined"
            ></span>
          </li>
        </template>
        <li
          @click="logoutUser"
          class="cursor-pointer font-medium text-[#F91717] font-body-new"
        >
          Log Out
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import theme from "@/helpers/themeWatcher";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { Switch } from "@headlessui/vue";
export default {
  components:{
    VueCountdown,
  },
  data() {
    return {
      darkThemeEnabled: false,
      feeCountdownEnd: true,
    };
  },
  components: {
    Switch,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      course: "auth/userCourse",
      prevBatches: "auth/prevBatches",
      baseline: "auth/baseline"
    }),
    showFullNav() {
      return true;
    },
  },
  watch: {
    darkThemeEnabled() {
      if (this.darkThemeEnabled == true) {
        this.changeTheme("dark");
      } else {
        this.changeTheme("light");
      }
    },
  },
  methods: {
    countDown(time) {
      const now = new Date();
      var date = new Date(time);
      // add a day
      if (date.getDay() == 6) {
        date.setDate(date.getDate() + 3);
      } else if (date.getDay() == 7) {
        date.setDate(date.getDate() + 2);
      } else {
        date.setDate(date.getDate() + 1);
      }
      const update_at = new Date(date);
      return update_at - now;
    },
    ...mapActions({
      setTheme: "auth/switchTheme",
      logout: "auth/signOut",
    }),
    closeDropDown() {
      this.$emit("close");
    },
    navigate(value) {
      this.closeDropDown();
      this.$router.push({ name: value });
    },
    /**
     * Switch User Dashoard Theme
     * @param { theme } Boolean
     */
    changeTheme(mode) {
      theme(mode);
    },
    /**
     * Logout
     */
    logoutUser() {
      this.closeDropDown();
      swal({
        title: "Logout",
        text: "Are you sure you want to logout?",
        buttons: true,
      }).then((result) => {
        if (result) {
          this.logout();
          this.$router.push({ name: "LoginPage" });
        }
      });
    },
  },
  mounted() {
    const theme = localStorage.getItem("theme");
    if (theme == "dark") {
      this.darkThemeEnabled = true;
    }
  },
};
</script>

<style></style>
