<template>
  <section class="pt-16 flex flex-col items-center container">
    <div class="grid grid-flow-row md:grid-cols-2 lg:grid-cols-3 gap-8">
      <div class="product_cards">
        <div class="rounded-full">
          <img 
            src="@/assets/img/img/product-internship.svg" 
            alt="Internship" 
            class="w-full h-auto"
            loading="lazy"
          />
        </div>
        <div class="p-8 md:p-4 lg:p-8">
          <h5>Internship</h5>
          <p>Learn soft & hard skills in 4 weeks</p>
        </div>
      </div>
      <div class="product_cards">
        <div class="rounded-full relative">
          <img
            src="@/assets/img/img/product-certification.svg"
            loading="lazy"
            class="w-full h-auto"
            alt="Certification"
          />
          <button
            class="text-[#50DD4C] bg-[#0C180C99] border border-[#50DD4C] text-xs rounded-full px-2 py-1 absolute top-4 right-4"
          >
            Compulsory
          </button>
        </div>
        <div class="p-8 md:p-4 lg:p-8">
          <h5>Certification</h5>
          <p>
            Get a certificate of completion from Side
            Hustle.
          </p>
        </div>
      </div>
      <div class="product_cards">
        <div class="rounded-full relative">
          <img 
            src="@/assets/img/img/product-bootcamp.svg" 
            loading="lazy" 
            class="w-full h-auto"
            alt="Bootcamp" />
          <button
            class="text-[#50DD4C] bg-[#0C180C99] border border-[#50DD4C] text-xs rounded-full px-2 py-1 absolute top-4 right-4"
          >
            Optional
          </button>
        </div>
        <div class="p-8 md:p-4 lg:p-8">
          <h5>Bootcamp</h5>
          <p>
            Join a team of brilliant minds to build a startup, a strong
            portfolio and world class products.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PrimaryButton from "./../inc/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton,
  },
};
</script>

<style lang="scss" scoped>
.product_cards {
  @apply max-w-fit relative rounded-full;
  h5 {
    @apply font-header-1 text-[#2F2F2F] font-extrabold text-2xl;
  }
  p {
    @apply text-[#898989] font-body mt-4;
  }
  @apply rounded-3xl overflow-clip border border-[#C2C1C1];
}
</style>
