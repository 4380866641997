import axios from "axios";
import { app } from "@/main.js"
import router from "@/router";
import swal from "sweetalert"

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    userCourse: "",
    baseline: "",
    prevBatches: "",
    course: {},
    settings: {},
    darkTheme: false,
    courses: null,
    tour_guide_stage: null,

  },

  getters: {
    darkTheme(state) {
      return state.darkTheme;
    },
    user(state) {
      return state.user;
    },
    userCourse(state) {
      return state.userCourse;
    },
    prevBatches(state) {
      return state.prevBatches;
    },
    baseline(state) {
      return state.baseline;
    },
    token(state) {
      return state.token;
    },
    course(state) {
      return state.course;
    },
    settings(state) {
      return state.settings;
    },
    courses(state) {
      return state.courses;
    },
    tour_guide_stage(state) {
      return state.tour_guide_stage;
    },
  },

  mutations: {
    SET_THEME(state, darkTheme) {
      state.darkTheme = darkTheme;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_COURSE(state, userCourse) {
      state.userCourse = userCourse;
    },
    SET_PREV_BATCHES(state, prevBatches) {
      state.prevBatches = prevBatches;
    },
    SET_BASELINE(state, baseline) {
      state.baseline = baseline;
    },
    SET_COURSE(state, course) {
      state.course = course;
    },
    SET_COURSES(state, courses) {
      state.courses = courses;
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
    SET_TOUR_GUIDE(state, tour_guide_stage) {
      state.tour_guide_stage = tour_guide_stage;
    },
  },

  actions: {
    /**
     * Get auth user's data
     * To set user and course state
     * @param {Object} {commit,state}
     * @param {object} payload
     */
    async attempt({ commit, state }, {token, user_payload}) {
      if (token) {
        commit("SET_TOKEN", token);
      }
      if (!state.token) {
        return;
      }
      if (user_payload) {
        commit("SET_USER", user_payload);
        return;
      }
      try {
        let response = await axios.get("v2/auth");
        if (response?.data?.data?.batch?.course_id == 3) {
          let settings = state.settings
          settings.current_week = settings.current_week - 2;
          commit("SET_SETTINGS", settings);
        }
        commit("SET_USER", response.data.data);
      } catch (e) {
        console.log(e)
        commit("SET_TOKEN", null);
        commit("SET_USER", "");
      }
    },

    /**
     * get settings for the website
     * To set setting state
     * @param {Object} {commit}
     */
    async siteSetting({ commit }) {
      try {
        let response = await axios.get("v2/settings");
        commit("SET_SETTINGS", response.data.data);
      } catch (e) {
        commit("SET_SETTINGS", {});
      }
    },

    async fetchUserData({ commit }) {
      try {
        let userCourse = await axios.get("v2/auth/user/course");       
        commit("SET_USER_COURSE", userCourse?.data?.data);        
      } catch (e) {
        commit("SET_USER_COURSE", null);
      }
    },

    /**
     * When site is being loaded, get site setting and auth user's data
     * Dispatch two action- siteSettings and attempt
     * @param {Object} dispatch
     * @param {string} token
     */
    async preloader({ dispatch }, token) {
      await dispatch("siteSetting").then(() => dispatch("attempt", {token}));
    },

    async signOut({ commit, dispatch }) {
      // await axios.post("auth/logout")
      localStorage.removeItem("token");
      localStorage.removeItem("watched");
      localStorage.removeItem("pushNotificationToken");
      // clear lessons from store
      dispatch("lms/storeLessons", null, { root: true });
      commit("SET_TOKEN", null);
      commit("SET_USER");
    },
    /**
     * Set the theme and store in user localStorage
     *
     */
    switchTheme({ commit }, theme) {
      localStorage.removeItem("userTheme");
      localStorage.setItem("userTheme", JSON.stringify(theme));
      commit("SET_THEME", theme);
    },

    async fetchCourses({ commit }) {
      const { data } = await axios.get("v2/courses");
      const courses = data.data;
      commit("SET_COURSES", courses);
    },
    setGuideStage({ commit }, stage) {
      commit("SET_TOUR_GUIDE", stage);
    },
    upgradeLearning () {
      router.push({ name: "UpgradeCourse" });
    }
  },
};
