import store from "./../store";
import router from "./../router";
import swal from "sweetalert"

let settings =  store.state.auth.settings;
let active_batch = settings?.current_batch

const routeGuards = () => {
  /**
   *  Authentication Guard
   */
  router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
      let authUser = store.getters["auth/user"];
      if (authUser) {
        next();
      } else {
        next({ name: "LoginPage", query: { redirect: to.fullPath }})
      }
    } else {
      next();
    }
  });
  
  router.beforeEach((to, from, next) => {
      if (to.meta.instructorGuard) {
        return next();
        let userCourse = store.getters["auth/userCourse"];
        let user = store.getters["auth/user"];
        let settings = store.getters["auth/settings"];
        if(user?.batch?.learning_type != 'instructor'){
          swal({
            title: "Upgrade",
            text: `This feature is available to only Instructor-led users. Upgrade to gain access (Note: The next instructor-led cohort will commence by ${settings.internship_start_date})`,
            buttons: [true, "Upgrade"],
          }).then((result) => {
            if (result) {
              return store.dispatch("auth/upgradeLearning");
            } else {
              return next({ name: 'LearningDashboardIndex' });
            }
          });
        } 
        else {
          return next();
        }
      } else {
          return next();
      }
  });
  
  router.beforeEach((to, from, next) => {
    if (to.meta.hasBatchGuard) {
      let user = store.getters["auth/user"];
      let settings = store.getters["auth/settings"];
      if (user?.batch?.learning_type == 'instructor' 
        && new Date(user?.batch?.batch?.start_on)  > new Date() && settings?.can_access == 0 && user?.batch?.can_access == 1) {
        return next({ name: "StartWaitPage" })
      } else {
        return next();
      }
    } else {
      return next();
    }
  });
  
  router.beforeEach((to, from, next) => {
    let user = store.getters["auth/user"];
    if (to.meta.oldCohort == true && user?.batch?.batch_id > 28) {
      return next({ name: "LearningDashboardIndex" })
    } else {
        return next();
    }
  });

  router.beforeEach((to, from, next) => {
    if (to.meta.hasLearningGuard) {
      let authUser = store.getters["auth/user"];
      let userCourse = store.getters["auth/userCourse"];
      if (! userCourse && ! authUser?.active_batch ) {
        return next({ name: "SelectBatchPage" });
      }
      if (userCourse?.can_access == 0) {
        // setup guard for users auditing the course
        if (userCourse.accepted == 1 && (to.name == "LessonPage" || to.name == "LearningDashboardIndex")) {
          return next();
        }
        if (userCourse.accepted == 1 && userCourse.can_access != 1 && (to.name != "LessonPage" && to.name != "LearningDashboardIndex")) {
          swal({
            title: "Make Payment",
            text: `You can only access a few lessons while auditing this course, make payment for full access.`,
            button: `Pay Now`
          }).then((res) => {
            if (res) return router.push({name: 'ApplicationPayment', params: { slug: userCourse?.learning_type == 'instructor' ? 'hands-on' : 'self_paced' }});
          });
          return next({ name: 'LearningDashboardIndex' });
        }
        if ( userCourse?.learning_type == 'instructor' ) {
          return next({ name: 'DepositPage', params: { slug: 'hands-on' } });
        }
          else if ( userCourse?.learning_type == 'self_paced' ) {
            return next({ name: 'DepositPage', params: { slug: 'self_paced' } });
        }
        else return next({ name: 'CoursePage', params: { id: userCourse?.course_id } });
      } else {
        next();
      }
    } else {
      next();
    }
  });
  router.beforeEach((to, from, next) => {
      if (to.meta.hasTeamGuard) {
          let user = store.getters["auth/user"];
          if (user?.batch?.learning_type == "instructor") {
            if(user.access_weeks < 6){
              swal({
                title: "Oops!",
                text: "You need to complete payment for the instructor-led mode to have access to join a team. Proceed to the home screen to make payment.",
              });  
              return next({ name: 'LearningDashboardIndex' });
            } else {
              return next();
            }
          } else {
            swal({
              title: "Upgrade",
              text: `This feature is available to only Instructor-led users. Upgrade to gain access (Note: The next instructor-led cohort will commence by ${settings.internship_start_date})`,
              buttons: [true, "Upgrade"],
            }).then((result) => {
              if (result) {
                return store.dispatch("auth/upgradeLearning");
              } else {
                return next({ name: 'LearningDashboardIndex' });
              }
            });
          }
      } else {
          next();
      }
  });

  router.beforeEach((to, from, next) => {
    if (to.meta.hasBootcampGuard) {
      let user = store.getters["auth/user"];
      if (user?.hasPaidBootcamp != 1) {
        return next({ name: 'ApplicationPayment', params: { slug: 'bootcamp' }, })
      } else {
        return next();
      }
    } else {
      return next();
    }
  });
};


export default routeGuards;
