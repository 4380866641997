<template>
  <main class="lg:mt-8 lg:px-8 pt-8 px-4">
    <p @click="$router.back()" class="text-primary-green text-sm font-semibold mb-5 flex items-center">
      <span class="iconify mr-2" data-icon="akar-icons:arrow-back-thick"></span>
      BACK</p>
    <template v-if="verification_type == 'account'">
      <h2
        class="font-header-1 font-bold text-body-text dark:text-[#E5E5E5] text-3xl text-left mb-8"
      >
        Input your Raven Bank account number
      </h2>
      <h5 class="font-bold dark:text-[#E5E5E5] text-black">
        How to get your Raven Bank account number
      </h5>
      <div class="flex dark:text-[#E5E5E5] text-black">
        <ul class="list-outside px-4 py-2 list-disc text-sm space-y-3">
          <li>Log in to your Raven Bank App on your device</li>
          <li>Click on Verify your BVN and NIN on the homepage</li>
          <li>Enter your BVN and Legal name on the next page</li>
          <li>Click on proceed</li>
          <li>Follow Steps 2 - 4 to verify your NIN</li>
          <li>Click on Add Money on the homepage</li>
          <li>On the pop up, click on Bank Transfer</li>
          <li>Copy your account number on the next page</li>
          <li>Paste your account number below</li>
        </ul>
      </div>
    </template>
    <template v-if="verification_type == 'phone'">
      <h2
        class="font-header-1 font-bold text-body-text dark:text-[#E5E5E5] text-3xl text-left mb-8"
      >
        Input the phone number registered on your Raven bank account
      </h2>
    </template>
    <form
      class="mr-auto w-fit text-left text-sm flex flex-col items-center"
      @submit.prevent="submitAccountNumber()"
    >
      <div
        class="flex items-start justify-between space-x-4 font-semibold font-body mt-12 mb-4"
      >
        <label for="with_phone" class="text-body-text dark:text-[#FAFAFA]">
          <input
            type="radio"
            name="verification_type"
            id="with_phone"
            value="phone"
            v-model="verification_type"
            class="text-green-600 focus:ring-primary-green focus:ring-2 mx-2"
          />
          Verify with Phone Number
        </label>
        <label
          for="with_account_number"
          class="text-body-text dark:text-[#FAFAFA]"
        >
          <input
            type="radio"
            name="verification_type"
            id="with_account_number"
            value="account"
            v-model="verification_type"
            class="text-green-600 focus:ring-primary-green focus:ring-2 mx-2"
          />
          Verify with Account Number
        </label>
      </div>
      <div class="flex items-center justify-center w-full my-4">
        <input
          type="tel"
          name="raven"
          id="raven"
          v-model="accountNumber"
          v-if="verification_type == 'account'"
          maxlength="10"
          placeholder="Enter your raven account number"
          class="text-center rounded-lg border border-[#C4C4C4] focus:border-primary-green focus:ring-primary-green font-body text-body-text text-sm w-full font-semibold tracking-[0.4em]"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        />
        <input
          type="tel"
          name="phone"
          id="phone"
          v-model="phone_number"
          v-if="verification_type == 'phone'"
          maxlength="13"
          placeholder="Input phone number"
          class="text-center rounded-lg border border-[#C4C4C4] focus:border-primary-green focus:ring-primary-green font-body text-body-text text-sm w-full font-semibold tracking-[0.1em]"
          oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        />
      </div>
      <button
        type="submit"
        :disabled="
          (accountNumber.length < 10 && verification_type == 'account') ||
          (phone_number.length < 11 && verification_type == 'phone')
        "
        class="py-4 md:px-16 text-white bg-primary-green font-body rounded-md mt-6 w-full min-w-fit"
      >
        {{
          verification_type == "phone"
            ? "Confirm Phone Number"
            : "Confirm Account Number"
        }}
      </button>
      <!-- <button
        @click="$router.push({ name: 'RavenBank' })"
        class="bg-transparent text-[#747874] dark:text-white font-body font-semibold text-center my-8 cursor-pointer"
      >
        Cancel
      </button> -->
    </form>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import swal from "sweetalert";
import OtpInput from "vue3-otp-input";
export default {
  components: {
    OtpInput,
  },
  data() {
    return {
      accountNumber: "",
      verification_type: "phone",
      phone_number: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    goBack() {
      this.$router.go(-1);
    },
    async submitAccountNumber() {
      let data = {
        type: this.verification_type,
      };
      if (this.verification_type == "phone") {
        data["phone_number"] = this.phone_number;
      }
      if (this.verification_type == "account") {
        data["account_number"] = this.accountNumber;
      }
      let loader = this.$loading.show();
      try {
        await axios.post("auth/update/raven", data);
        loader.hide();
        swal({
          title: "Success",
          text: "Raven account number saved successfully",
        }).then(() => {
          this.attempt().then(() => {
            this.$router.push({ name: "WelcomeDashboard" });
          });
        });
      } catch (error) {
        loader.hide();
        swal({
          title: "Oops!",
          text:
            error?.response?.data?.payload ??
            "Unable to save your account number at the moment. Please try again shortly.",
        });
      }
    },
    // handleOnChange(value) {
    //     this.accountNumber = value;
    // },
    // clearInput() {
    //     otpInput.value.clearInput();
    // },
  },
  mounted() {
    if (this.user.account) {
      this.$router.push({ name: "WelcomeDashboard" });
    }
  },
};
</script>

<style>
.account-input-inputs {
  @apply md:w-12 md:h-12 h-8 w-8 mx-[1px] md:mx-2 !rounded-lg border border-[#C4C4C4] focus:!border-primary-green focus:!ring-primary-green text-center font-body text-body-text !text-xs md:text-sm !px-1 font-semibold;
}
</style>
