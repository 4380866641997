<template>
  <div class="">
    <section class="grid gap-4 my-8 container p-0">
      <h2
        class="dark:text-[#E1E1E1] text-body-text font-bold text-2xl md:text-3xl font-header-3"
      >
        Recommended Lessons
      </h2>
      <div class="flex items-center justify-between">
        <div ref="recommendedVideos" class="recommendedVideosContainer">
          <div
            class="bg-white border text-black dark:bg-[#333333] dark:text-white flex flex-col rounded-2xl border-[#E0E1E0] dark:border-[#333333] overflow-clip cursor-pointer md:h-96 lg:h-80"
          >
            <div class="overflow-clip h-2/3">
              <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/video-thumbnail.svg" />
            </div>
            <div class="px-2 pt-2 pb-4 font-body">
              <h6 class="font-semibold">HTML Tags and Attributes</h6>
              <p
                class="text-sm md:text-base text-[#686868] mt-2 mb-4 font-normal font-body"
              >
                Frontend Development
              </p>
              <button
                class="text-xs text-[#434343] bg-[#E4E7E4] px-2 py-1 rounded-full"
              >
                ⭐ Recommended
              </button>
            </div>
          </div>
          <div
            class="bg-white border text-black dark:bg-[#333333] dark:text-white flex flex-col rounded-2xl border-[#E0E1E0] dark:border-[#333333] overflow-clip cursor-pointer md:h-96 lg:h-80"
          >
            <div class="overflow-clip h-2/3">
              <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/video-thumbnail.svg" />
            </div>
            <div class="px-2 pt-2 pb-4 font-body">
              <h6 class="font-semibold">HTML Tags and Attributes</h6>
              <p
                class="text-sm md:text-base text-[#686868] mt-2 mb-4 font-normal font-body"
              >
                Frontend Development
              </p>
              <button
                class="text-xs text-[#434343] bg-[#E4E7E4] px-2 py-1 rounded-full"
              >
                ⭐ Recommended
              </button>
            </div>
          </div>
          <div
            class="bg-white border text-black dark:bg-[#333333] dark:text-white flex flex-col rounded-2xl border-[#E0E1E0] dark:border-[#333333] overflow-clip cursor-pointer md:h-96 lg:h-80"
          >
            <div class="overflow-clip h-2/3">
              <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/video-thumbnail.svg" />
            </div>
            <div class="px-2 pt-2 pb-4 font-body">
              <h6 class="font-semibold">HTML Tags and Attributes</h6>
              <p
                class="text-sm md:text-base text-[#686868] mt-2 mb-4 font-normal font-body"
              >
                Frontend Development
              </p>
              <button
                class="text-xs text-[#434343] bg-[#E4E7E4] px-2 py-1 rounded-full"
              >
                ⭐ Recommended
              </button>
            </div>
          </div>
          <div
            class="bg-white border text-black dark:bg-[#333333] dark:text-white flex flex-col rounded-2xl border-[#E0E1E0] dark:border-[#333333] overflow-clip cursor-pointer md:h-96 lg:h-80"
          >
            <div class="overflow-clip h-2/3">
              <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/video-thumbnail.svg" />
            </div>
            <div class="px-2 pt-2 pb-4 font-body">
              <h6 class="font-semibold">HTML Tags and Attributes</h6>
              <p
                class="text-sm md:text-base text-[#686868] mt-2 mb-4 font-normal font-body"
              >
                Frontend Development
              </p>
              <button
                class="text-xs text-[#434343] bg-[#E4E7E4] px-2 py-1 rounded-full"
              >
                ⭐ Recommended
              </button>
            </div>
          </div>
          <div
            class="bg-white border text-black dark:bg-[#333333] dark:text-white flex flex-col rounded-2xl border-[#E0E1E0] dark:border-[#333333] overflow-clip cursor-pointer md:h-96 lg:h-80"
          >
            <div class="overflow-clip h-2/3">
              <img src="https://terrahq.nyc3.cdn.digitaloceanspaces.com/public/lms/video-thumbnail.svg" />
            </div>
            <div class="px-2 pt-2 pb-4 font-body">
              <h6 class="font-semibold">HTML Tags and Attributes</h6>
              <p
                class="text-sm md:text-base text-[#686868] mt-2 mb-4 font-normal font-body"
              >
                Frontend Development
              </p>
              <button
                class="text-xs text-[#434343] bg-[#E4E7E4] px-2 py-1 rounded-full"
              >
                ⭐ Recommended
              </button>
            </div>
          </div>
        </div>
        <button
          @click="scrollRecommendedVideos"
          class="hidden md:flex items-center justify-center ml-4 rounded-full bg-primary-green min-w-[2rem] min-h-[2rem] h-8 w-8 text-white mt-4"
        >
          <span class="iconify" data-icon="akar-icons:chevron-right"></span>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

//
export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    scrollRecommendedVideos() {
      const container = this.$refs.recommendedVideos;
      container.scrollLeft += 575;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}
img {
  @apply transition ease-linear scale-105 hover:scale-110 w-full;
}
.recommendedVideosContainer {
  @apply grid grid-flow-col grid-cols-[repeat(5,_calc(42%_-_16px))] md:grid-cols-[repeat(5,_calc(45%_-_24px))] lg:grid-cols-[repeat(5,_calc(25%_-_24px))] grid-rows-1 gap-4 md:gap-8 grow overflow-x-scroll scroll-smooth scroll-pr-16 md:pr-4 py-4 min-w-full;
}
</style>
