<template>
  <div>
    <NavBar />
    <div class="container mb-7 pt-16 lg:pt-20">
      <div class="space-y-5">
        <h1 class="font-header-1 text-5xl font-bold text-center">
          PARTICIPANT CODE OF CONDUCT
        </h1>
        <p>
          This internship code of conduct (“Code of Conduct”) outlines our
          expectations for behaviour from all participants of the Side Hustle
          Internship and applies to all interactions including networking,
          classes, webinars, interviews, meetings, panels, social media, and
          chat.
        </p>
        <p>It outlines the consequences for unacceptable behaviour.</p>
        <p>
          By participating in this Internship, all interns, speakers,
          participants, media, exhibitors, mentors, media representatives,
          service providers, and others (collectively “participants”, “you”, or
          “your”) agree to abide by this Code of Conduct.
        </p>
        <p>
          The Terra Learning Hosts (“hosts”) are dedicated to providing
          a harassment-free and inclusive event experience for everyone
          regardless of gender identity and expression, sexual orientation,
          disabilities, physical appearance, body size, ethnicity, nationality,
          race, age, religion, gender, or other protected category.
        </p>
        <p>
          Participants asked to stop any harassing behaviour are expected to
          comply immediately.
        </p>
        <div>
          <h5 class="font-header-1 text-2xl font-bold">EXPECTED BEHAVIOR</h5>
          <p>The hosts expect that participants will:</p>
          <ul>
            <li>
              Be considerate and respectful to all mentors, hosts, admins,
              volunteers and fellow participants
            </li>
            <li>
              Refrain from any demeaning, offensive, discriminatory, or
              harassing behaviour, materials, and speech.
            </li>
            <li>
              Speak up if they observe anything at an event that conflicts with
              this Code of Conduct. If you are being harassed or feel
              uncomfortable, notice that someone else is being harassed, or have
              any other concerns, please contact a member of the Side Hustle
              team immediately. All reports will be addressed immediately and
              remain confidential.
            </li>
          </ul>
        </div>
        <div>
          <h5 class="font-header-1 text-2xl font-bold">
            UNACCEPTABLE BEHAVIOR
          </h5>
          <p>
            Unacceptable behaviour from any community member will not be
            tolerated. Unacceptable behaviour includes, but is not limited to:
          </p>
          <ul>
            <li>
              Intimidating, harassing, abusive, offensive, discriminatory,
              derogatory, or demeaning speech, materials, or conduct by any
              Participants of this internship and related activities.
            </li>
            <li>
              Violence, threats of violence, or violent language directed
              against any admin, host, team member, volunteer or fellow intern.
            </li>
            <li>
              Sexist, racist, homophobic, transphobic, or otherwise
              discriminatory jokes and language.
            </li>
            <li>
              Personal insults, particularly those related to gender, sexual
              orientation, race, religion, or disability.
            </li>
            <li>
              Inappropriate photography, recording or appearances during video
              conference calls.
            </li>
            <li>
              Any boisterous, lewd, or offensive behaviour or language,
              including but not limited to using sexually explicit or offensive
              language, materials or conduct, or any language, behaviour, or
              content that contains profanity, obscene gestures, or racial,
              religious, or ethnic slurs.
            </li>
            <li>
              Failure to obey any rules or regulations of the Side Hustle
              Internship will lead to immediate disqualification from the Side
              Hustle Internship.
            </li>
          </ul>
        </div>
        <div>
          <h5 class="font-header-1 text-2xl font-bold">
            CONSEQUENCES OF UNACCEPTABLE BEHAVIOR
          </h5>
          <p>
            Unacceptable behaviour will not be tolerated. Anyone asked to stop
            unacceptable behaviour is expected to comply immediately.
          </p>
          <p>
            If a Participant engages in unacceptable behaviour, the hosts may
            take any action they deem appropriate, including warning and/or
            expelling the offender from the Internship.
          </p>
        </div>
      </div>
    </div>
    <Footer class="mb-0" />
  </div>
</template>

<script>
import NavBar from "./../components/inc/PrimaryNavBar.vue";
import Footer from "./../components/inc/FooterComponent.vue";

export default {
  name: "TermsAndConditions",
  components: {
    NavBar,
    Footer,
  },
};
</script>

<style></style>
