import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
const PNF = PhoneNumberFormat;
const PhoneNumber = PhoneNumberUtil.getInstance();

const customValidation = (number, iso) => {
  const validNumber = PhoneNumber.parseAndKeepRawInput(number, iso);
  const isValid = PhoneNumber.isValidNumberForRegion(validNumber, iso);
  const newNumber = PhoneNumber.format(validNumber, PNF.INTERNATIONAL).replace(
    /\s/g,
    ""
  );
  if (isValid) {
    return { status: isValid, phoneNumber: newNumber };
  } else {
    return { status: false };
  }
};

export default customValidation;
