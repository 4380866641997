<template>
  <CommitmentFeeModal
    @close-fee-modal="showFeeModal = false"
    v-if="showFeeModal"
  />
  <div
    class="bg-white pt-8 lg:-mt-32 -mt-16 text-[#404040]  md:pt-16 font-body-new -mb-8 pb-8 lg:pt-32 min-h-screen h-full"
  >
    <main class="container mt-28 lg:mt-16 flex text-[#1A1F1A] w-3/4 md:w-1/2 mx-auto justify-center">
      <div class="space-y-6">
        <div class="flex items-center">
          <h2 class="font-bold text-lg mr-5">Pay commitment fee</h2>
          <div v-if="false" class="text-primary-green h-fit rounded-full px-3 py-0.5 text-[9px] font-semibold bg-[#F8FCF8] border border-[#B5DAB4]">Refunded upon course completion</div>
        </div>
        <p class="text-xs font-[400] text-justify">The commitment fee is a mandatory and non-refundable fee required to be paid by participants of Terra Learning. This fee is a way to show commitment to the program and help ensure its successful completion.</p>
        <p class="text-xs font-[400] text-justify">By paying the commitment fee of {{ user?.country_id == 156 ? "₦ 1,000" : "$ 1" }}, participants can access additional resources for self-learning and a gamified learning experience. These resources and experiences enhance the overall learning experience and provide participants with the tools they need to succeed in their chosen field.</p>
        <!-- <p class="text-xs font-[400]">The commitment fee is an investment in the participant's future and a testament to their dedication to personal and professional growth.</p> -->
        <div class="flex items-center">
          <p class="text-[11px] font-semibold">Note: Payment for certificate is mandatory for course completion</p>
          <div @click="showFeeModal = true" class="h-fit shrink-0 rounded-full px-3 py-0.5 text-[9px] cursor-pointer font-semibold bg-[#F2F2F2] border border-[#F2F2F2] flex items-center ">See more about certificates  <span class="iconify ml-1" data-icon="material-symbols:add-circle-outline-rounded"></span> </div>
        </div>
        <div class="p-7 bg-[#F8FCF8] border border-[#B5DAB4] rounded flex items-center justify-between">
          <div>
            <p class="text-xs font-bold">Commitment Fee</p>
            <h1 class="text-2xl font-body-new font-bold">{{ user?.country_id == 156 ? "₦ 1,000" : "$ 1" }}</h1>
          </div>
          <button @click="$router.push({ name: 'CommitmentPayment' })" class="bg-primary-green cursor-pointer text-[9px] rounded text-[#FFFFFF] py-3 px-5 transition-colors ease-linear duration-200">Pay commitment fee</button>
        </div>
      </div>
    </main>

    <main v-if="false" class="container flex text-[#1A1F1A] font-body justify-center">
      <div
        class="w-full lg:w-11/12 mt-20 flex flex-col items-center lg:text-center"
      >
        <h1 class="md:text-3xl text-2xl text-[#3B433B] mb-3 font-bold">
          Select your learning path
        </h1>
        <div
          class="grid lg:grid-flow-col mt-6 lg:mt-12 lg:grid-cols-2 grid-flow-row pb-32 lg:gap-x-10 gap-y-7"
        >
          <div v-if="user?.batch_id < 7"
            class="bg-white border hover:bg-[#F8FCF8] hover:border-primary-green transition ease-linear duration-200 cursor-pointer border-[#C3C2C2] rounded-lg flex flex-col px-7 pt-5 pb-20"
          >
            <div class="flex items-center justify-between">
              <h2 class="md:text-2xl text-lg my-5 font-bold md:font-[500] text-left">
                Self-paced path
              </h2>
              <div class="flex flex-col items-center ml-3">
                <p class="text-[#4B524B] whitespace-nowrap font-semibold text-xs md:text-sm">
                  Commitment fee
                </p>
                <p class="whitespace-nowrap">
                  {{ user?.country_id == 156 ? "₦ 0" : "$ 0" }}
                </p>
              </div>
            </div>
            <div
              class="flex mb-7 md:items-center flex-col gap-y-5 md:flex-row md:gap-x-3"
            >
              <!-- <p class="text-[#4B524B] font-semibold">Commitment fee: ₦ 0</p> -->
              <div
                @click="showFeeModal = true"
                class="flex bg-[#F2F2F2] w-fit rounded-full py-1.5 px-5 cursor-pointer text-[10px] text-[#697269] items-center"
              >
                Why should I pay commitment fee?
                <span
                  class="iconify ml-2"
                  data-icon="material-symbols:add-circle-outline-rounded"
                ></span>
              </div>
            </div>
            <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Collaboration with team-mates
            </p>
            <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Weekly tasks
            </p>
            <SecondaryButton
              @click="selectSelfPaced('self_paced')"
              class="text-sm mt-auto w-full transition-colors ease-linear duration-200"
            >
              Continue learning path</SecondaryButton
            >
          </div>
          <div
            class="bg-white border border-[#C3C2C2] rounded-lg hover:border-primary-green transition ease-linear duration-200 cursor-pointer hover:bg-[#F8FCF8] flex flex-col px-7 pt-5 pb-12"
          >
            <div class="flex items-center justify-between">
              <h2 class="md:text-2xl text-lg whitespace-nowrap my-5 font-bold md:font-[500] text-left">
                {{ user?.batch_id > 6 ? "Self-paced path" : "Instructor-led path"}}
              </h2>
              <div class="flex flex-col items-center ml-3">
                <p class="text-[#4B524B] font-semibold text-xs md:text-sm">
                  Commitment fee
                </p>
                <div class="flex items-center justify-around">
                  <p
                    class="text-[#4B524B] font-semibold relative whitespace-nowrap w-fit mr-3"
                  >
                    {{ user?.country_id == 156 ? "₦ 1,000" : "$ 2" }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="flex mb-7 md:items-center flex-col gap-y-5 md:flex-row md:gap-x-3"
            >
              <div
                @click="showFeeModal = true"
                class="flex cursor-pointer bg-[#F2F2F2] w-fit rounded-full py-1.5 px-5 text-[10px] text-[#697269] items-center"
              >
                Why should I pay commitment fee?
                <span
                  class="iconify ml-2"
                  data-icon="material-symbols:add-circle-outline-rounded"
                ></span>
              </div>
            </div>
            <!-- <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Weekly live classes
            </p> -->
            <!-- <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Feedbacks on tasks
            </p> -->
            <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Collaboration with team-mates
            </p>
            <!-- <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Weekly review of task with instructors
            </p> -->
            <!-- <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Career prospect talks with lead instructors
            </p> -->
            <p class="flex items-center text-sm mb-5">
              <span
                class="iconify text-primary-green mr-3"
                data-icon="akar-icons:check"
              ></span>
              Weekly tasks
            </p>
            <SecondaryButton
              @click="$router.push({ name: 'CommitmentPayment' })"
              class="text-sm mt-auto w-full transition-colors ease-linear duration-200"
            >
              Proceed
            </SecondaryButton
            >
            <!-- <p class="text-xs font-semibold mt-3 text-left text-[#F48707]">
              **Get 50% off on the commitment fee if you make payment now
            </p> -->
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SecondaryButton from "@/components/inc/SecondaryButton.vue";
// import PrimaryButton from "@/components/inc/PrimaryButton.vue";
import CommitmentFeeModal from "../../../components/lms/CommitmentFeeModal.vue";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      showFeeModal: false,
    };
  },
  components: {
    SecondaryButton,
    CommitmentFeeModal,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
    }),
    async selectSelfPaced(mode) {
      let loader = this.$loading.show();
      try {
        await axios.post("auth/learning/mode", { learning_type: mode });
        await this.attempt();
        loader.hide();
        this.$router.push({ name: "LearningDashboardIndex" });
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
  },
  mounted() {
    if(this.user?.can_access == 1) {
      this.$router.push({name: 'LearningDashboardIndex'})
    }
    if (
      this.user?.pending_commitment_fee
    ) {
      return this.$router.push({ name: "CommitmentFeeCountdown" });
    }
  },
};
</script>

<style></style>
