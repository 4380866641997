<template>
  <div class="p-0 md:px-0 xl:px-0">
    <div class="p-0">
      <SideNav @toggle-task="fetchBaselineTask" />
      <div class="relative ">
        <SideNavMobile @toggle-task="fetchBaselineTask" />
      </div>
      <main
        class="pt-40 md:pt-0 md:grid md:grid-cols-[25%_auto] md:pl-16 px-0 md:px-4 w-full container"
      >
        <div class="md:col-start-2 py-4">
          <BreadCrumb />
          <router-view class="md:py-4" v-if="!taskShown" />
          <div v-if="taskShown">
            <div class="mb-4" v-if="!baselineTask?.submission">
              <h1 class="md:mt-5 mt-20 text-[25px] md:pl-1 pl-4 mb-5">
                Week {{ baselineTask?.week }} Task
              </h1>
              <div
                class="bg-[#F6F7F6] md:px-10 px-2 py-5 pb-10 md:w-3/4 w-[350px] md:mx-0 mx-auto rounded-md"
              >
                <h2 class="mb-[19px] font-semibold text-[24px]">
                  Instructions
                </h2>
                <ul type="" class="pl-5">
                  <li>{{ baselineTask?.instruction }}</li>
                </ul>
              </div>
              <div class="mt-8 px-3">
                <div class="flex flex-col mb-4">
                  <label for="link" class="mb-3 text-[20px] font-semibold"
                    >Submit link to Task</label
                  >
                  <input
                    type="text"
                    id="link"
                    ref="linkInput"
                    placeholder="Paste link to task here"
                    class="w-3/4 px-3 py-2"
                  />
                </div>
                <button
                  class="bg-[#52A350] text-white px-5 py-2 rounded-md"
                  @click="handleTask"
                >
                  Submit task
                </button>
              </div>
            </div>
            <div
              class="bg-[#F6F7F6] task md:w-[500px] mx-auto space-y-6 rounded-lg p-4 md:p-8 mt-5"
              v-if="baselineTask?.submission"
            >
              <div class="flex items-center space-y-4 space-x-4 my-4">
                <h2 class="text-body-text font-bold">Task</h2>
                <button
                  v-if="baselineTask?.submitted"
                  class="status text-[#0D68CE] bg-[#E7F2FD] rounded-full px-2 md:px-4 py-1 font-bold text-[10px] w-fit"
                >
                  Task Submitted
                </button>
              </div>
              <ul
                class="text-sm text-[#4B524B] list-inside list-disc"
                v-for="(task, index) in JSON.parse(baselineTask?.task)"
                :key="index"
              >
                <li>
                  {{ baselineTask?.instruction }}
                  <!-- <strong>Step {{ index + 1 }}:</strong>
                  <span v-html="task"></span> -->
                </li>
              </ul>
              <p class="text-sm italic font-semibold" v-if="baselineTask?.note">
                Note: {{ baselineTask?.note }}
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import SideNav from "./LessonSideNav.vue";
import BreadCrumb from "./BreadCrumbComponent.vue";
import SideNavMobile from "./LessonSideNavMobile.vue";
import { ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import { onMounted } from "vue";
import { computed } from "@vue/reactivity";
import { inject } from "vue";
// import swal from "sweetalert";
import { useRoute, useRouter } from "vue-router";
import swal from "sweetalert";
const axios = inject("axios");
const $loading = inject("$loading");
const store = useStore();
const taskShown = ref(false);
const baselineTask = ref(null);
const linkInput = ref(null);
// const router = useRouter();
// const route = useRoute();

const currentLesson = computed(() => {
  return store.state.lms.currentLesson;
});
const lessons = computed(() => {
  return store.state.lms.lessons;
});
const showUpgradeModal = computed(() => {
  return store.state.lms.showUpgradeModal;
});
const user = computed(() => {
  return store.state.auth.user;
});

const setCurrentLesson = (lesson) =>
  store.dispatch("lms/setCurrentLesson", lesson);

const storeLessons = (payload) => store.dispatch("lms/storeLessons", payload);
const setBages = (payload) => store.dispatch("lms/setBages", payload);

const fetchBaselineTask = async (week) => {
  if (Object.keys(week).length == 0) {
    taskShown.value = false;
    return;
  }
  taskShown.value = true;
  let loader = $loading.show();
  try {
    const { data } = await axios.get("auth/tasks/fetch/" + week.week);
    baselineTask.value = data.payload;
    // console.log(baselineTask);

    loader.hide();
  } catch (error) {
    loader.hide();
    console.error(error);
  }
};

const fetchLessons = async () => {
  if (!lessons.value) {
    const request = await axios.get("auth/lessons/latest");
    let latest = request?.data?.payload;
    // console.log(latest);
    try {
      axios
        .get("auth/lessons")
        .then((res) => {
          const payload = res.data.payload.lessons;
          setBages(res.data.payload.badges);
          // if(latest){
          setCurrentLesson(latest);
          // };
          // if (!currentLesson.value) {
          //   setCurrentLesson(payload[0]);
          // }
        })
        .catch((err) => {
          console.log(err);
        });
        storeLessons(payload);
        // setTimeout(() => {
          
        // }, 1000);
    } catch (error) {
      console.log(error);
    }
  } else {
    return;
  }
};

const handleTask = async () => {
  const payload = new FormData();
  payload.append("link", linkInput.value);
  payload.append("id", baselineTask?.value?.id);
  try {
    await axios.post("auth/tasks/submit2", payload);
    await store.dispatch("auth/attempt");
    fetchBaselineTask();
    loader.hide();
  } catch (error) {
    loader.hide();
    swal({
      title: "Oops",
      text:
        error?.response?.data?.payload ??
        "Unable to save your submission at the moment. Please try again shortly",
    });
    console.error(error);
  }
};

onMounted(() => {
  fetchLessons();
  if (showUpgradeModal.value == true && user.value.cohort >= 8 && user.value.batch?.learning_type != 'instructor' && false) {
    swal({
      title: "Upgrade",
      text: "Want to have an instructor-led experience with practice projects to work on? Upgrade now. (Note: The next instructor-led cohort will commence by 29 April, 2024)",
      buttons: [true, "Upgrade"],
    }).then((result) => {
      if (result) {
        // second confirmation
        swal({
          title: "Confirm!",
          text: "Are you sure you want to upgrade to the instructor-led mode. You cannot change back to the self-paced mode after upgrading",
          buttons: ["Cancel", "Upgrade"],
        }).then((result) => {
          if (result) {
            return store.dispatch("auth/upgradeLearning");
          } })
      }
      else {
        store.dispatch("lms/setUpgradeModal", false);
      }
    });
  }
});
</script>

<style scoped>
ul {
  list-style-type: disc !important;
}

.status {
  transform: translateY(-7px);
}

.task {
  margin-top: 50px;
}
</style>
