<template>
  <div
    class="flex justify-between items-center px-4 py-6 lg:py-8 md:px-12 sticky top-0 left-0 right-0 w-full max-w-full z-10"
    :class="[
      changeNavColor ? 'bg-white bg-opacity-95 border-b border-b-gray-200' : '',
    ]"
  >
    <router-link to="/">
      <img
        src="@/assets/img/logo/side-hustle-logo.svg"
        alt="Side Hustle Logo"
        class="lg:hidden max-h-6"
      />
    </router-link>
    <router-link
      :to="{ name: 'RegisterEmail' }"
      v-if="$route.name == 'LoginPage'"
      class="ml-auto"
    >
      <secondary-button> Register </secondary-button>
    </router-link>
    <router-link :to="{ name: 'LoginPage' }" class="ml-auto" v-else>
      <secondary-button> Login </secondary-button>
    </router-link>
  </div>
</template>

<script>
import SecondaryButton from "./SecondaryButton.vue";
export default {
  components: {
    SecondaryButton,
  },
  data() {
    return {
      changeNavColor: false,
      canBeLoggedIn: false,
      navMobile: false,
      openNav: false,
    };
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 50) {
        this.changeNavColor = true;
      } else {
        this.changeNavColor = false;
      }
    },
  },
};
</script>

<style></style>
