<template>
  <nav
    class="learning_btm_nav fixed bottom-0 left-0 right-0 bg-[#F5F6F5] dark:bg-[#333] py-4 grid-flow-col justify-around gap-x-4 text-[10px] font-body-new font-semibold px-4 z-[99] border-t border-[#CCD1CC]"
  >
    <router-link :to="{ name: 'LearningDashboardIndex' }">
      <button
        :class="
          $route.name == 'LearningDashboardIndex'
            ? 'text-primary-green'
            : 'text-[#8E8E8E]'
        "
      >
        <span class="iconify" data-icon="ant-design:home-filled"></span>
        Home
      </button>
    </router-link>
    <router-link :to="{ name: 'LessonPage' }">
      <button
        :class="
          $route.name == 'LessonPage' ? 'text-primary-green' : 'text-[#8E8E8E]'
        "
      >
        <span
          class="iconify"
          data-icon="material-symbols:play-lesson-rounded"
        ></span>
        Lessons
      </button>
    </router-link>
    <router-link :to="{ name: 'AssessmentHistory' }">
      <button
        :class="
          $route.name == 'AssessmentHistory'
            ? 'text-[#8B46FF]'
            : 'text-[#8E8E8E]'
        "
      >
        <span class="iconify" data-icon="ic:round-assessment"></span>
        Assessment
      </button>
    </router-link>
    <router-link :to="{ name: 'ChoosePayment' }">
      <button
        :class="
          $route.name == 'ChoosePayment' ||
          $route.name == 'SubPayment' ||
          $route.name == 'Checkout'
            ? 'text-[#FB8036]'
            : 'text-[#8E8E8E]'
        "
      >
        <span class="iconify" data-icon="fluent:payment-32-filled"></span>
        Payment
      </button>
    </router-link>
    <router-link :to="{ name: 'ProfilePage' }" v-if="true">
      <button
        :class="
          $route.name == 'ProfilePage' ? 'text-primary-green' : 'text-[#8E8E8E]'
        "
      >
        <span class="iconify" data-icon="majesticons:user"></span>
        Profile
      </button>
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
button {
  @apply flex flex-col items-center space-y-1;
}
.iconify {
  @apply text-xl;
}

.learning_btm_nav {
  @apply hidden;
}

@media (display-mode: standalone) {
  .learning_btm_nav {
    @apply grid md:hidden;
  }
}
</style>
