<template>
  <!-- Start Section 2 -- Continue Learning Path Card  -->
  <section class="my-8 mt-4">
    <template v-if="lessons?.length > 0">
      <h2
        v-if="currentLesson?.id > 0"
        class="font-bold text-2xl md:text-3xl font-header-3 dark:text-[#E1E1E1] text-body-text"
      >
        Continue on your learning path
      </h2>
      <h2
        v-else
        class="font-bold text-2xl md:text-3xl font-header-3 dark:text-[#E1E1E1] text-body-text"
      >
        Begin your learning path
      </h2>
    </template>
    <h2
        v-else-if="lessons && lessons?.length < 1"
        class="font-bold text-2xl md:text-3xl font-header-3 dark:text-[#E1E1E1] text-body-text"
      >
        Learning content unavailable
      </h2>
    <h2
        v-else-if="!lessons"
        class="font-bold text-2xl md:text-3xl font-header-3 dark:text-[#E1E1E1] text-body-text"
      >
        Fetching learning content
      </h2>
      <template v-if="lessons && lessons?.length > 0">
        <div
          @click="resumeLearning()"
          class="overflow-clip rounded-md my-4 grid grid-cols-1 md:grid-cols-4 justify-start text-body-text border bg-[#FFF9F5] dark:text-white cursor-pointer"
        >
            <div
              class="overflow-clip min-h-full h-full hidden md:grid items-center justify-center bg-[#FFE7D6]"
            >
              <img
                src="@/assets/img/bg/learning-path.png"
                alt="Video thumbnail"
                class="h-full w-auto min-h-full p-0 m-0 grow scale-105"
              />
            </div>
            <div
              class="py-4 md:col-span-3 px-4 md:px-8 flex flex-col font-body-new md:pt-12 md:pb-2max-h-full h-full text-body-text w-full bg-[url(/src/assets/img/bg/learning-path-mob.png)] bg-no-repeat bg-right-bottom md:bg-none"
            >
              <div class="flex md:flex-row md:items-center justify-between mb-6">
                <div class="">
                  <h5 class="text-sm md:text-xl font-semibold font-body-new">
                    {{ lessonToResume?.title ?? " " }}
                  </h5>
                </div>
                <button
                  class="bg-primary-green text-white text-sm font-body-new px-4 py-4 mt-8 md:mt-0 rounded self-end"
                >
                  {{ currentLesson?.id > 0 ? "Continue Learning" : "Begin Learning" }}
                </button>
              </div>
              <div class="mt-auto font-semibold text-primary-green font-header-1 text-xl flex items-center justify-between md:justify-start">
                {{ user?.batch?.course?.title }}
                <button class="rounded-full px-3 py-1 w-fit bg-[rgba(81,163,80,0.49)] text-xs font-body font-semibold text-[#000000] ml-4 whitespace-nowrap">
                  {{ user?.batch?.learning_type == 'instructor' ? "Instructor-Led Mode" : "Self Paced Mode"}}
                </button>
              </div>
            </div>
        </div>
      </template>
      <div v-else-if="lessons && lessons?.length < 1">
        <div
          class="overflow-clip rounded-md my-4 grid grid-cols-1 md:grid-cols-4 justify-start text-body-text border bg-[#FFF9F5] dark:text-white"
        >
            <div
              class="overflow-clip min-h-full h-full hidden md:grid items-center justify-center bg-[#FFE7D6]"
            >
              <img
                src="@/assets/img/bg/learning-path.png"
                alt="Video thumbnail"
                class="h-full w-auto min-h-full p-0 m-0 grow scale-105"
              />
            </div>
            <p class="p-4 text-sm font-body md:col-span-2">
              The learning content for this course are currently unavailable. You can enroll in another course <router-link class="font-bold text-primary-green underline cursor-pointer" :to="{name: 'SelectCourse'}">here</router-link>.
            </p>
        </div>
      </div>
      <div v-else-if="!lessons">
        <div
          class="overflow-clip rounded-md my-4 grid grid-cols-1 md:grid-cols-4 justify-start text-body-text border bg-[#FFF9F5] dark:text-white"
        >
            <div
              class="overflow-clip min-h-full h-full hidden md:grid items-center justify-center bg-[#FFE7D6]"
            >
              <img
                src="@/assets/img/bg/learning-path.png"
                alt="Video thumbnail"
                class="h-full w-auto min-h-full p-0 m-0 grow scale-105"
              />
            </div>
            <p class="p-4 text-sm font-body md:col-span-2">
              Your learning content for this course are being fetched... 
            </p>
        </div>
      </div>
    <video class="hidden" ref="videoTag" v-if="module">
      <source :src="module && module.url" type="video/mp4" />
    </video>
  </section>
  <!-- End Section 2 -- Continue Learning PAth Card  -->
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, ref, watchEffect } from "vue";
import { inject } from "vue";
import { computed } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";

const axios = inject("axios");
const store = useStore();
const router = useRouter();
let lastWatchedLesson = ref(null);
let module = ref(null);
let videoTag = ref(null);
let duration = ref(null);
const user = computed(() => {
  return store.state.auth.user;
});
const lessons = computed(() => {
  return store.state.lms.lessons;
});

const lessonToResume = computed(() => {
  if (currentLesson.value) {
    return currentLesson.value
  } else if (lastWatchedLesson.value) {
    return lastWatchedLesson.value
  } else {
    return lessons.value[0]
  }
})
const currentLesson = computed(() => {
  return store.state.lms.currentLesson;
});
/**
 * Set Current learning material
 */
const setCurrentLesson = async (lesson) =>   await store.dispatch("lms/setCurrentLesson", lesson);

const resumeLearning = async () => {
  await setCurrentLesson(currentLesson.value ? currentLesson.value : lastWatchedLesson.value);
  router.push({name: 'LessonPage'})
}

const userStreak = computed(() => {
  return store.state.lms.userStreak;
});
watchEffect(() => {
  if (videoTag.value) {
    videoTag.value.onloadedmetadata = function (video) {
      duration.value = Math.floor(video.target.duration / 60);
    };
  }
});
onMounted(async () => {
  try {
    const { data } = await axios.get("auth/lessons/latest");
    lastWatchedLesson.value = data.payload;
    module.value = data.payload.modules.find((item) => item.format == "video");
  } catch (error) {
    console.log(error);
  }
});
</script>

<style lang="scss" scoped>
* {
  @apply transition-colors ease-linear;
}
img {
  @apply hover:scale-110 transition ease-linear;
}
</style>
