<template>
  <div>
    <BootcampApplicationModal v-if="showBootcampApplication == true" @closemodal="showBootcampApplication = false" />
    <DiscountModal v-if="user.hasDiscount != false && showDiscountModal == true" @closemodal="showDiscountModal = false" />
    <div>
      <div class="container pt-12 space-y-6 pb-24">
        <div class="rounded-md p-4 text-sm font-body font-medium bg-primary-blue text-white border">
          <p>
            Earn up to 50,000 monthly by referring your friends to any of our learning program. Get your referral link and start earning! 
            <router-link class="underline font-semibold px-2 underline-offset-4" :to="{ name: 'ReferEarn' }">
              Learn More 
              <span
                class="iconify inline-block"
                data-icon="teenyicons:arrow-right-solid"
              ></span>
            </router-link>
          </p>
        </div>
        <div v-if="checkIfUserCanReapply == true">
          <h2 class="mb-3 text-2xl font-bold text-[#0C180C] font-header-1">Start a new course</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 lg:gap-8">
            <div class="bg-white rounded-xl lg:shadow=[0px_16px_30px_rgba(0,0,0,0.05)] shadow-[0_11.2px_21.15px_rgba(0,0,0,0.05)] border px-8 pt-12 pb-6 flex flex-col">
              <p class="font-body font-semibold text-2xl w-5/6 pb-6">
                Looking to learn something new?
              </p>
              <button class="md:w-fit w-full px-8 py-3 text-white bg-primary-green transition-all ease-linear duration-200 font-semibold rounded-md text-base font-body" @click="$router.push({name:'SelectCourse'})">
                Click Here
              </button>
            </div>
          </div>
        </div>
        <div v-if="user?.batches?.length > 0">
          <h2 class="mb-3 text-2xl font-bold text-[#0C180C] font-header-1">Terra Learning Courses</h2>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 lg:gap-8" >
            <div class="bg-white rounded-xl lg:shadow=[0px_16px_30px_rgba(0,0,0,0.05)] shadow-[0_11.2px_21.15px_rgba(0,0,0,0.05)] border px-8 pt-8 pb-6 flex flex-col" v-for="(batch, index) in user?.batches" :key="index">
              <div class="w-full flex items-center justify-between mb-4">
                <button class="rounded-full px-3 py-1 w-fit bg-[#F2F3F2] text-xs font-body uppercase font-semibold text-[#596059]">
                  {{ batch?.learning_type == 'instructor' ? "Instructor-Led" : "Self-Paced"}}
                </button>
                <button class="text-gray-500 text-sm">
                  <Tooltip v-if="batch?.can_access == 1">
                    <template #button>
                      <i class="fa-solid fa-lock-open"></i>
                    </template>
                    <template #message>
                      You now have access to this course
                    </template>
                  </Tooltip>
                  <Tooltip v-else>
                    <template #button>
                      <i class="fa-solid fa-lock"></i>
                    </template>
                    <template #message>
                      You do not have access to this course yet
                    </template>
                  </Tooltip>
                </button>
              </div>
              <p class="font-body font-semibold text-2xl pb-2 mb-8">
                {{batch?.course?.title ?? " "}}
              </p>
              <p class="text-[#808080] text-sm hidden">Week 1| Module 4    3mins</p>
              <button @click="switchBatch(batch?.batch_id)" class="md:w-fit w-full mt-auto px-8 py-3 text-white bg-primary-green transition-all ease-linear duration-200 font-semibold rounded-md text-base font-body">
                Continue Learning
              </button>
            </div>
          </div>
        </div>
        <div v-if="true">
          <h2 class="mb-3 text-2xl font-bold text-[#0C180C] font-header-1">Terra Bootcamp</h2>
          <p v-if="user?.bootcamps && checkIfUserCanReapplyBootcamp == true" class="font-medium text-sm font-body mb-4">
            Looking to gain more hands-on experience in your tech learnings? 
            <span @click="showBootcampApplication = true" class="cursor-pointer text-primary-green font-semibold underline">Click here to apply for the Terra Bootcamp</span></p>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 lg:gap-8" v-if="user?.bootcamps" >
            <template v-for="(bootcamp, index) in user?.bootcamps" :key="index">
              <div class="bg-white rounded-xl lg:shadow=[0px_16px_30px_rgba(0,0,0,0.05)] shadow-[0_11.2px_21.15px_rgba(0,0,0,0.05)] border px-8 pt-8 pb-6 flex flex-col">
                <div class="w-full flex items-center justify-between mb-4">
                  <button class="rounded-full px-3 py-1 w-fit bg-[#F2F3F2] uppercase text-xs font-body font-semibold text-[#596059]" v-if="bootcamp.type">
                    {{ `${bootcamp.type} Bootcamp` }}
                  </button>
                  <button class="text-gray-500 text-sm ml-auto">
                    <Tooltip v-if="bootcamp?.status == 1">
                      <template #button>
                        <i class="fa-solid fa-lock-open"></i>
                      </template>
                      <template #message>
                        You have completed payment for the bootcamp
                      </template>
                    </Tooltip>
                    <Tooltip v-else>
                      <template #button>
                        <i class="fa-solid fa-lock"></i>
                      </template>
                      <template #message>
                        You are yet to complete payment for the bootcamp
                      </template>
                    </Tooltip>
                  </button>
                </div>
                <p class="font-body font-semibold text-2xl pb-2 mb-8">
                  {{ `${bootcamp?.course?.title ?? "Terra"} Bootcamp` }}
                </p>
                <button @click="switchBootcamp(bootcamp.batch_id)" class="md:w-fit w-full mt-auto px-8 py-3 text-white bg-primary-green transition-all ease-linear duration-200 font-semibold rounded-md text-base font-body">
                  Proceed
                </button>
              </div>
            </template>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-4 lg:gap-8" v-else>
            <div class="bg-white rounded-xl lg:shadow=[0px_16px_30px_rgba(0,0,0,0.05)] shadow-[0_11.2px_21.15px_rgba(0,0,0,0.05)] border px-8 pt-12 pb-6 flex flex-col">
              <p class="font-body font-semibold text-2xl w-5/6 pb-6">
                Enroll for the Terra Bootcamp
              </p>
              <button class="md:w-fit w-full px-8 py-3 text-white bg-primary-green transition-all ease-linear duration-200 font-semibold rounded-md text-base font-body" @click="showBootcampApplication = true">
                Click Here
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex'
import Tooltip from "@/components/lms/TooltipComponent.vue"
import BootcampApplicationModal from "@/views/lms/bootcamp/ApplicationModal.vue"
import DiscountModal from "@/views/lms/DiscountModal.vue"
export default {
  data(){
    return {
      showBootcampApplication: false,
      showDiscountModal: true,
    }
  },
  components:{
    Tooltip,
    BootcampApplicationModal,
    DiscountModal
  },
  computed:{
    ...mapGetters({
      user: "auth/user",
      settings: "auth/settings",
    }),
    checkIfUserCanReapply(){
      return true;
    },
    checkIfUserCanReapplyBootcamp(){
      const bootcamps = this.user?.bootcamps ?? [];
      const ACTIVE_REG_BATCH_ID = this.settings?.current_batch;
      const truety = bootcamps.some(bootcamp => bootcamp.batch_id >= ACTIVE_REG_BATCH_ID)
      if (truety) return false;
      else return true;
    }
  },
  methods: {
    ...mapActions({
      attempt: "auth/attempt",
      fetchUserData: "auth/fetchUserData",
      fetchLessons: "lms/fetchLessons",
      setCurrentLesson: "lms/setCurrentLesson",
      fetchUserStreak: "lms/fetchUserStreak",
      clearTeams: "teams/clearTeams",
    }),
    async switchBatch(id) {
      const loader = this.$loading.show();
      try {
        // switch users active batch on the users table
        await axios.post("auth/batch/switch", {
          batch_id: id,
        });
        await this.attempt({}); // re-fetch user auth data 
        this.setCurrentLesson(null) 
        await this.fetchLessons(); // prefetch lesson resources before pushing to learning dashboard
        await this.fetchUserData()
        await this.fetchUserStreak()
        this.clearTeams()
        loader.hide(); // hide loader 
        return this.$router.push({ name: "LearningDashboardIndex" }); // push to learning dashboard 
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
    async switchBootcamp(id) {
      const loader = this.$loading.show();
      try {
        // switch users active batch on the users table
        await axios.post("v2/auth/bootcamp/switch", {
          batch_id: id,
        });
        await this.attempt({}); // re-fetch user auth data
        loader.hide(); // hide loader 
        return this.$router.push({ name: "BootcampWaitPage" }); // push to learning dashboard 
      } catch (error) {
        loader.hide();
        console.error(error);
      }
    },
  },
  async mounted(){
    if (this.user && this.user?.batches?.length < 1 && this.user?.bootcamps?.length < 1) {
      // on mounting of page, if user has no batch application, re-route user to course selection page.
      return this.$router.push({name:'SelectCourse'});
    }
  }
}
</script>

<style>

</style>