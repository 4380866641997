<template>
  <div>
    <NavBar />
    <div class="bg-[#222522]">
      <div class="container pt-16 lg:pt-20 bg-[#222522] pb-32">
        <section class="md:w-3/5 mb-12">
          <h1
            class="font-header-1 font-bold text-white text-4xl md:text-6xl text-left"
          >
            Frequently Asked <span class="text-primary-green">Questions</span>
          </h1>
          <p
            class="text-white py-4 md:w-3/4 text-sm md:text-base font-body mb-4 text-left"
          >
            We’ve answered some of the questions you might have about the
            internship here
          </p>
          <div>
            <template v-for="faq in faqs.general" :key="faq.id">
              <Disclosure v-slot="{ open }">
                <div
                  :class="open ? 'bg-[#434343]' : 'bg-[#333333]'"
                  class="each_faq_card"
                >
                  <DisclosureButton
                    class="px-2 md:px-4 my-0 w-full flex justify-between items-center font-header-1 font-bold text-lg md:text-2xl text-left"
                    :class="open ? ' text-white' : 'text-primary-green'"
                  >
                    {{ faq.question }}
                    <i
                      class="fa-solid transition-all ease-linear mr-2"
                      :class="open ? 'fa-angle-up' : 'fa-angle-down'"
                    ></i>
                  </DisclosureButton>
                  <DisclosurePanel
                    class="text-white px-2 md:px-4 my-4 w-4/5 font-body text-sm text-left"
                  >
                    <p v-html="faq.answer"></p>
                  </DisclosurePanel>
                </div>
              </Disclosure>
            </template>
          </div>
        </section>
        <section class="md:w-3/5 mb-12">
          <h1
            class="font-header-1 font-bold text-white text-4xl md:text-6xl text-left"
          >
            Certificate <span class="text-primary-green">Questions</span>
          </h1>
          <p
            class="text-white py-4 md:w-3/4 text-sm md:text-base font-body mb-4 text-left"
          >
            We’ve answered some of the questions you might have about the
            certificate here
          </p>
          <div>
            <template v-for="faq in faqs.certificate" :key="faq.id">
              <Disclosure v-slot="{ open }">
                <div
                  :class="open ? 'bg-[#434343]' : 'bg-[#333333]'"
                  class="each_faq_card"
                >
                  <DisclosureButton
                    class="px-2 md:px-4 my-0 w-full flex justify-between items-center font-header-1 font-bold text-lg md:text-2xl text-left"
                    :class="open ? ' text-white' : 'text-primary-green'"
                  >
                    {{ faq.question }}
                    <i
                      class="fa-solid transition-all ease-linear mr-2"
                      :class="open ? 'fa-angle-up' : 'fa-angle-down'"
                    ></i>
                  </DisclosureButton>
                  <DisclosurePanel
                    class="text-white px-2 md:px-4 my-4 w-4/5 font-body text-sm text-left"
                  >
                    <p v-html="faq.answer"></p>
                  </DisclosurePanel>
                </div>
              </Disclosure>
            </template>
          </div>
        </section>
        <section class="md:w-3/5 mb-12">
          <h1
            class="font-header-1 font-bold text-white text-4xl md:text-6xl text-left"
          >
            Raven <span class="text-primary-green">Questions</span>
          </h1>
          <p
            class="text-white py-4 md:w-3/4 font-body text-sm md:text-base mb-4 text-left"
          >
            We’ve answered some of the questions you might have about Raven Bank
            here
          </p>
          <div>
            <template v-for="faq in faqs.raven" :key="faq.id">
              <Disclosure v-slot="{ open }">
                <div
                  :class="open ? 'bg-[#434343]' : 'bg-[#333333]'"
                  class="each_faq_card"
                >
                  <DisclosureButton
                    class="px-2 md:px-4 my-0 w-full flex justify-between items-center font-header-1 font-bold text-lg md:text-2xl text-left"
                    :class="open ? ' text-white' : 'text-primary-green'"
                  >
                    {{ faq.question }}
                    <i
                      class="fa-solid transition-all ease-linear mr-2"
                      :class="open ? 'fa-angle-up' : 'fa-angle-down'"
                    ></i>
                  </DisclosureButton>
                  <DisclosurePanel
                    class="text-white px-2 md:px-4 my-4 w-4/5 font-body text-sm text-left"
                  >
                    <p v-html="faq.answer"></p>
                  </DisclosurePanel>
                </div>
              </Disclosure>
            </template>
          </div>
        </section>
        <section class="md:w-3/5 mb-12">
          <h1
            class="font-header-1 font-bold text-white text-4xl md:text-6xl text-left"
          >
            Bootcamp <span class="text-primary-green">Questions</span>
          </h1>
          <p
            class="text-white py-4 md:w-3/4 font-body text-sm md:text-base mb-4 text-left"
          >
            We’ve answered some of the questions you might have about the
            bootcamp here
          </p>
          <div>
            <template v-for="faq in faqs.bootcamp" :key="faq.id">
              <Disclosure v-slot="{ open }">
                <div
                  :class="open ? 'bg-[#434343]' : 'bg-[#333333]'"
                  class="each_faq_card"
                >
                  <DisclosureButton
                    class="px-2 md:px-4 my-0 w-full flex justify-between items-center font-header-1 font-bold text-lg md:text-2xl text-left"
                    :class="open ? ' text-white' : 'text-primary-green'"
                  >
                    {{ faq.question }}
                    <i
                      class="fa-solid transition-all ease-linear mr-2"
                      :class="open ? 'fa-angle-up' : 'fa-angle-down'"
                    ></i>
                  </DisclosureButton>
                  <DisclosurePanel
                    class="text-white px-2 md:px-4 my-4 w-4/5 font-body text-sm text-left"
                  >
                    <p v-html="faq.answer"></p>
                  </DisclosurePanel>
                </div>
              </Disclosure>
            </template>
          </div>
        </section>
      </div>
    </div>
    <Footer class="mb-0" />
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
</script>
<script>
import NavBar from "./../components/inc/PrimaryNavBar.vue";
import Footer from "./../components/inc/FooterComponent.vue";
export default {
  components: {
    NavBar,
    Footer,
  },
  data() {
    return {
      faqs: {
        general: [
          {
            id: 1,
            question: "What is the Terra Learning Internship about?",
            answer:
              "The Terra Learning Internship is an ed-tech platform that aims to provide African youths with learning opportunities in marketing, engineering, product, and design.",
          },
          {
            id: 2,
            question: "Is the internship remote?",
            answer: "Yes, the internship is 100% online",
          },
          {
            id: 3,
            question: "How long is the internship?",
            answer:
              "The internship lasts four weeks in total, with four weeks of learning and four weeks of hands-on bootcamp experience.",
          },
          {
            id: 4,
            question: "Can I enroll in two courses at the same time?",
            answer:
              "To ensure a smooth learning experience, we recommend that our interns only apply for one course at a time. Once you've completed the first course, you can sign up for another.",
          },
          {
            id: 6,
            question: "How do I register for the Internship?",
            answer:
              "To apply as an intern, visit <a target='_blank' href='https://lms.terrahq.co'>https://lms.terrahq.co</a> and click on the “Apply as an intern” button on the home page",
          },
          {
            id: 7,
            question: "Is the Terra Learning Internship free?",
            answer:
              "The Terra Learning Internship is tuition free. However, you are expected to pay a mandatory certificate fee.",
          },
          {
            id: 8,
            question: "Can I get jobs from Terra Learning after the Internship?",
            answer:
              "We have a talent pool where we inform graduates about job openings in their respective fields; however, the internship is not a permanent job opportunity.",
          },
          {
            id: 9,
            question: "Where will the internship take place?",
            answer:
              "The Terra Learning Internship takes place on the learning dashboard and the Slack workspace.",
          },
        ],
        certificate: [
          {
            id: 1,
            question: "Is the Terra Learning Internship certificate free?",
            answer:
              "No, the certificates of completion goes for a fee",
          },
          {
            id: 2,
            question: "Is the certificate compulsory?",
            answer:
              "Yes, you are required to pay for the certificate.",
          },
          {
            id: 3,
            question: "How much is the Terra Learning Internship certificate?",
            answer:
              "The Terra Learning certificate of completion costs 5000 NGN",
          },
          {
            id: 4,
            question: "Does the certificate expire?",
            answer: "No, the Internship Certificate does not expire",
          },
          {
            id: 5,
            question: "What are the benefits of the Internship Certificate?",
            answer:
              "<ul class='list-inside list-disc'><li>Validation of knowledge</li><li>Increased marketability</li><li>Increased earning power</li><li>Establish professional credibility</li><li>Boost efficiency</li><li>Realize a competitive advantage</li></ul>",
          },
        ],
        bootcamp: [
          {
            id: 1,
            question: "What is the bootcamp about?",
            answer:
              "The Terra Learning Bootcamp is a 4-week hands-on experience scheme where you get to build live projects to improve your skills. The bootcamp phase of the internship is mandatory for all finalists and has three packages. <br /> Some projects from the previous bootcamp include: waytojapa.com, easylearnafrica.com, ivorycloudify.com, studypadi.com, hustlevest.com, tradelexi.com",
          },
          {
            id: 2,
            question: "Is the bootcamp compulsory?",
            answer: "No, the bootcamp is optional",
          },
          {
            id: 3,
            question: "What are the benefits of the bootcamp?",
            answer:
              "<ul class='list-inside list-disc'><li>Opportunity to build your portfolio</li><li>Opportunity to work in a fast-paced start-up</li><li>Access to the Terra Talent Pool</li><li>Relevant professional experience on projects</li><li>Free CV Review and Recommendation Letter</li><li>Land a Job in 3 months</li></ul>",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.each_faq_card {
  background-image: url('@/assets/img/bg/faq-card-bg.png');
  @apply border-0 rounded transition-all ease-linear p-2 mb-4 shadow-md bg-no-repeat bg-cover;
}
</style>
