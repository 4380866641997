<template>
  <div>
    <!-- Show this section if user does not have any pending payment for this particular batch -- applies to both self-paced and instructor accounts  -->
    <template v-if="showPaymentNotice == false">
    <!-- Self Paced Telegram Link -->
    <a :href="site_settings?.self_paced_telegram_link"
        target="_blank"
        v-if="user?.cohort >= 8 && user?.batch?.learning_type == 'self_paced'"
        class="bg-[#EFEFEF] rounded py-3 text-left mt-8 px-4 md:px-6 cursor-pointer block"
      >
        <p
          class="font-body-new font-medium text-[12px] md:text-sm text-[#5B645B]"
        >
          To join the telegram channel
          <strong class="text-primary-green underline">Click Here</strong>
        </p>
      </a>
        <!-- Instructor Led Telegram Link -->
      <a :href="site_settings?.instructor_telegram_link" 
            target="_blank"
            v-if="user?.cohort >= 8 && user?.batch?.learning_type == 'instructor'"
      class="bg-[#EFEFEF] rounded py-3 text-left mt-8 px-4 md:px-6 cursor-pointer block"
      >
      <p
          class="font-body-new font-medium text-[12px] md:text-sm text-[#5B645B]"
      >
          To join the telegram channel
          <strong class="text-primary-green underline">Click Here</strong>
      </p>
      </a>
      <!-- Course Telegram Link -->
      <a :href="user?.batch?.course?.telegram_link"
      target="_blank"
      v-if="user?.cohort >= 8 && user?.batch?.learning_type == 'instructor'"
      class="bg-[#EFEFEF] rounded py-3 text-left mt-4 px-4 md:px-6 cursor-pointer block"
      >
      <p
          class="font-body-new font-medium text-[12px] md:text-sm text-[#5B645B]"
      >
          To join the {{ user?.batch?.course?.title ?? " "}} telegram channel
          <strong class="text-primary-green underline">Click Here</strong>
      </p>
      </a>
    </template>
    <template v-else>
      <!-- Show this notice to users who have made part payment for instructor led -->
      <router-link 
      v-if="( 
        user?.cohort >= 8 && 
        user?.batch?.learning_type == 'instructor' ) && 
        (user?.batch?.can_access == 1) &&
        (user?.access_weeks < 6) && 
        (site_settings?.current_week == user?.access_weeks ) "
      :to="{ name: 'ApplicationPayment', params: { slug: 'hands-on' },}"
      class="bg-[#f1b7b7] rounded py-3 text-left mt-4 px-4 md:px-6 cursor-pointer block"
      >
      <p
          class="font-body-new font-semibold text-[12px] md:text-sm text-[#232423]"
      >
          Level {{ (site_settings?.current_week + 1) }} commences in 
            <Vue-countdown
                class="font-semibold text-black ml-1"
                :time="convertTime(getNextMonday())"
                v-slot="{ days, hours, minutes, seconds }"
                >{{days}} day{{days > 1 ? 's' : ''}} , {{hours}} hour{{hours > 1 ? 's' : ''}} {{ minutes }} minute{{minutes > 1 ? 's' : ''}} {{seconds}} second{{seconds > 1 ? 's' : ''}}
            </Vue-countdown>
          . <span class="underline font-bold underline-offset-2">Click here</span> to make payment to continue to enjoy access 
      </p>
      </router-link>
      <!-- Show notice to users auditing the instructor led course -->
      <router-link 
      v-if="( 
        user?.cohort >= 8 && 
        user?.batch?.learning_type == 'instructor' ) && 
        (user?.batch?.can_access == 0 && user.batch?.accepted == 1) "
      :to="{ name: 'ApplicationPayment', params: { slug: 'hands-on' },}"
      class="bg-[#f1b7b7] rounded py-3 text-left mt-4 px-4 md:px-6 cursor-pointer block"
      >
      <p
          class="font-body-new font-semibold text-[12px] md:text-sm text-[#232423]"
      >
          The instructor-led learning path begins in 
            <Vue-countdown
                class="font-semibold text-black ml-1"
                :time="convertTime(user?.batch?.batch?.start_on)"
                v-slot="{ days, hours, minutes, seconds }"
                >{{days}} day{{days > 1 ? 's' : ''}} , {{hours}} hour{{hours > 1 ? 's' : ''}} {{ minutes }} minute{{minutes > 1 ? 's' : ''}} {{seconds}} second{{seconds > 1 ? 's' : ''}}
            </Vue-countdown>
          . <span class="underline font-bold underline-offset-2">Click here</span> to make payment now. 
      </p>
      </router-link>
      <!-- Sow notice to users who have used up their installment payment for instructor led mode -->
      <router-link 
      v-if="( 
        user?.cohort >= 8 && 
        user?.batch?.learning_type == 'instructor' ) && 
        (user?.batch?.can_access == 1) &&
        (user?.access_weeks < 6) && 
        (site_settings?.current_week > user?.access_weeks ) "
      :to="{ name: 'ApplicationPayment', params: { slug: 'hands-on' },}"
      class="bg-[#f1b7b7] rounded py-3 text-left mt-4 px-4 md:px-6 cursor-pointer block"
      >
      <p
          class="font-body-new font-semibold text-[12px] md:text-sm text-[#232423]"
      >
          You are already late for payment. <span class="underline font-bold underline-offset-2">Click here</span> to make payment now to continue to enjoy access 
      </p>
      </router-link>
      <!-- Show notice to users who made deposit for self-paced learning mode -->
      <router-link 
      v-if="( 
        user?.cohort >= 8 && 
        user?.batch?.learning_type == 'self_paced' ) && 
        (user?.access_weeks < 2)"
      :to="{ name: 'ApplicationPayment', params: { slug: 'self_paced' },}"
      class="bg-[#f1b7b7] rounded py-3 text-left mt-4 px-4 md:px-6 cursor-pointer block"
      >
      <p
          class="font-body-new font-semibold text-[12px] md:text-sm text-[#232423]"
      >
          <span class="underline font-bold underline-offset-2">Click here</span> to complete your payment and enjoy unrestricted access to this course. 
      </p>
      </router-link>
    </template>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

import { mapGetters } from 'vuex'
export default {
    components:{
        VueCountdown
    },
    computed: {
    ...mapGetters({
      user: "auth/user",
      lessons: "lms/lessons",
      userCourse: "auth/userCourse",
      baseline: "auth/baseline",
      site_settings: "auth/settings",
      takenQuiz: "lms/takenQuiz",
      showEnrollPrompt: "lms/showEnrollPrompt",
    }),
    showPaymentNotice(){
        let user = this.user;
        let site_settings = this.site_settings;
        if (user?.cohort >= 8 && user?.batch?.learning_type == 'self_paced' && user?.access_weeks < 2) {
            return true;
        }
        if ( (user?.cohort >= 8) && 
            (user?.batch?.learning_type == 'instructor' ) && 
            (user?.batch?.can_access == 0 && user?.batch?.accepted == 1 )
            ) {
            return true;            
        }
        if ( (user?.cohort >= 8) && 
            (user?.batch?.learning_type == 'instructor' ) && 
            (user?.access_weeks < 6) && 
            (site_settings?.current_week == user?.access_weeks )
            ) {
            return true;            
        }
        if (( 
        user?.cohort >= 8 && 
        user?.batch?.learning_type == 'instructor' ) && 
        (user?.access_weeks < 6) && 
        (site_settings?.current_week > user?.access_weeks )) {
            return true;
        }
        return false;
    }
  },
  methods: {
    getNextMonday(date = new Date()) {
        const dateCopy = new Date(date.toLocaleDateString());
        const nextMonday = new Date(
            dateCopy.setDate(
            dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
            ),
        );
        return nextMonday;
    },
    convertTime(time) {
        const now = new Date().getTime();
        var date = new Date(time);
        date = new Date(date.getTime() + 60*60*1000)
        return date - now;
    },
  }

}
</script>

<style>

</style>