export default {
  namespaced: true,
  state: {
    teams: null,
  },

  getters: {
    teams(state) {
        return state.teams;
    }, 
  },

  mutations: {
    SET_TEAMS(state, teams) {
        state.teams = teams;
    },    
  },

  actions: {
    async storeTeams({ commit }, data) {
        commit("SET_TEAMS", data);
    }, 
  },
};
