<template>
  <main>
    <section class="container font-body-new lg:grid lg:grid-cols-5 gap-12">
      <div class="lg:col-span-3 mb-16 lg:mb-0">
        <header class="float-left">
          <h2>Stay in the loop</h2>
          <p class="text-sm">
            Be the first to get the latest updates from our fast-growing
            community of African youths.
          </p>
        </header>
        <form @submit.prevent="submitSubscriberForm()">
          <!-- <input
                        type="text"
                        name="name"
                        id="name"
                        v-model="user.name"
                        placeholder="Your full name"
                    /> -->
          <input
            type="email"
            name="email"
            id="email"
            v-model="user.email"
            placeholder="Email address"
          />
          <span
            class="iconify text-[#6F6F90] text-xl bottom-5 absolute left-3"
            data-icon="ic:outline-email"
          ></span>
          <button type="submit">Subscribe</button>
        </form>
      </div>
      <div class="lg:col-span-2 mx-auto mt-auto text-white">
        <p class="text-3xl mb-10 font-bold">Follow us</p>
        <div class="flex gap-x-5">
          <button class="bg-[#52A350] rounded-[3px] w-fit py-2 px-4">
            <a
              href="https://www.linkedin.com/company/terra-learning/"
              target="_blank"
              class="flex items-center"
            >
              <span class="iconify mr-2" data-icon="ri:linkedin-fill"></span>
              LinkedIn</a
            >
          </button>
          <button class="bg-[#52A350] rounded-[3px] w-fit py-2 px-4">
            <a
              href="https://www.instagram.com/terralearning"
              target="_blank"
              class="flex items-center"
            >
              <span class="iconify mr-2" data-icon="ri:instagram-line"></span
              >Instagram</a
            >
          </button>
          <button class="bg-[#52A350] rounded-[3px] w-fit py-2 px-4">
            <a
              href="https://twitter.com/terralearning"
              target="_blank"
              class="flex items-center"
            >
              <span class="iconify mr-2" data-icon="ph:twitter-logo-bold"></span
              >Twitter</a
            >
          </button>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: {
        email: "",
        name: "",
      },
    };
  },
  methods: {
    submitSubscriberForm() {
      const data = this.user;
      axios
        .post("/newsletter", data)
        .then(() => {
          this.user.email = "";
          this.user.name = "";
          swal({
            title: "Success!",
            text: "You have successfully been subscribed",
          });
        })
        .catch((err) => {
          swal({
            title: "Oops!",
            text:
              err?.response?.data?.payload ??
              "Unable to subscribe you at the moment. Please try again later.",
          });
        });
      // console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  // @apply bg-cover bg-no-repeat;
  // background-image: url(./../../assets/img/bg/subscribe-bg.svg);
  header {
    @apply text-white mb-3;
    h2 {
      @apply font-extrabold text-3xl md:text-5xl;
    }
    p {
      @apply font-body-new lg:font-bold my-6;
    }
  }
  @apply bg-[#1A1F1A] pt-12 lg:mb-16 mb-5  lg:pt-24 pb-16;
}
form {
  @apply w-full lg:w-5/6 relative p-0 font-body-new lg:mt-8;
  input {
    @apply rounded-[2px] pl-10 w-full py-4 border border-[#7CA9F8] bg-[#F9FAF9] text-[#6F6F90] focus:ring-0 focus:border-primary-green;
  }
  button {
    @apply absolute text-sm bg-primary-green bottom-2.5 right-3 text-white font-body rounded-[2px] px-8 py-2 w-fit;
  }
}
</style>
