<script>
import NavBar from "./../components/inc/PrimaryNavBar.vue";
import Footer from "./../components/inc/FooterComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NavBar,
    Footer,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  mounted() {
    if (this.$route?.query?.ref) {
      localStorage.setItem("referral_code", this.$route?.query?.ref);
    }
    if (
      window?.matchMedia("(display-mode: standalone)")?.matches ||
      window?.navigator?.standalone == true
    ) {
      return this.user
        ? this.$router.push({ name: "LearningDashboardIndex" })
        : this.$router.push({ name: "LoginPage" });
    }
  },
};
</script>

<template>
  <main>
    <NavBar />
    <router-view />
    <Footer class="mb-16 lg:mb-0" />
  </main>
</template>
