<template>
  <div
    class="bg-white h-screen w-screen absolute top-0 bottom-0 left-0 right-0"
  >
    <div class="modal_parent_container" @click="handleCloseModal">
      <div class="modal_content_box font-body" ref="modal_content">
        <h2 class="text-body-text">Thank You!</h2>
        <p class="md:w-3/5 mx-auto">
          Alright, proceed to complete your baseline assessment to begin your
          learning path
        </p>
        <button
          @click="$router.push({ name: 'ViewAssessmentTask' })"
          class="bg-primary-green text-white"
        >
          Begin
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      staticModal: ["CompleteApplication"],
    };
  },
  methods: {
    handleCloseModal(outSideClose) {
      const box = this.$refs.modal_content;

      if (
        box &&
        !box.contains(event.target) &&
        !this.staticModal.includes(this.$router.currentRoute.value.name)
      ) {
        this.$emit("closemodal");
      }
    },
  },
  mounted() {
    if (true) {
      this.$router.push({ name: "ViewAssessmentTask" });
    }
  },
};
</script>

<style lang="scss" scoped>
.modal_parent_container {
  @apply bg-[#63636345] fixed top-0 bottom-0 left-0 right-0 z-[9999] px-4 backdrop-blur-0;
  .modal_content_box {
    background-image: url('@/assets/img/bg/modal-bg-pattern.png');
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none;
    }
    @apply text-center bg-white bg-no-repeat bg-cover rounded-3xl px-4 py-8 md:p-16 w-11/12 lg:w-3/5 mx-auto absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col;
    h2 {
      @apply text-primary-green font-header-1 font-extrabold text-xl lg:text-3xl text-center;
    }
    p {
      @apply text-[#242424] font-body text-base py-2 my-4;
    }
    button {
      @apply rounded-lg py-4 lg:px-16 w-full lg:w-fit font-body font-medium self-center;
    }
  }
}
</style>
