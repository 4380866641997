/**
 * Registration, Login & Password Routes
 */
import OnboardingContainer from "@/views/auth/OnboardingContainer.vue";
import RegisterEmail from "@/views/auth/RegisterEmail.vue";
import RequestPasswordReset from "@/views/auth/RequestPasswordReset.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import LoginPage from "@/views/auth/LoginPage.vue";
import EnterDetails from "@/views/auth/EnterDetails.vue";

import ApplicationContainer from "@/views/apply/ApplicationContainer.vue";
import SelectCourse from "@/views/apply/SelectCourse.vue";
import CoursePage from "@/views/apply/CoursePage.vue";
import DepositPage from "@/views/apply/DepositPage.vue";
import ApplicationPayment from "@/views/apply/ApplicationPayment.vue";
import PaymentConfirmationCountDown from "@/views/apply/PaymentConfirmationCountdown.vue";

/**
 * Refer and Earn
 */
import ReferEarn from "@/views/lms/referral/ReferSpin.vue";
import AllCertificatesPage from "@/views/lms/AllCertificatesPage.vue";
import PeigesIndex from "@/views/lms/peiges/IndexPage.vue";

const dashboardRoutes = [
  {
    path: "/onboarding/",
    name: "OnboardingContainer",
    component: OnboardingContainer,
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
        meta: {
          title: "Sign in to your dashboard",
        },
      },
      {
        path: "/password/reset",
        name: "RequestPasswordReset",
        component: RequestPasswordReset,
        meta: {
          title: "Reset Password",
        },
      },
      {
        path: "/password/new",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          title: "Enter new password",
        },
      },
      {
        path: "/register",
        name: "RegisterEmail",
        component: RegisterEmail,
        meta: {
          title: "Apply now",
        },
      },
      {
        path: "details",
        name: "EnterDetails",
        component: EnterDetails,
        props: true,
        meta: {
          title: "Setup your account",
        },
      },
    ],
  },
  {
    path: "/application",
    name: "ApplicationContainer",
    component: ApplicationContainer,
    meta: {
      requireAuth: true,
      hasApplicationGuard: true
    },
    children: [
      /**
       * Referral Program
       */
      {
        path: "/refer-earn",
        name: "ReferEarn",
        component: ReferEarn,
        meta: {
          title: "Refer & Earn",
          hasApplicationGuard: false,
        },
      },
      {
        path: "/dashboard/certificate",
        name: "AllCertificatesPage",
        component: AllCertificatesPage,
        meta: {
          breadcrumb: "Certificates",
          title: "Certificates",
          sub_title: "Certificates",
        },
      },
      /**
       * User select the track
       */
      {
        path: "courses",
        name: "SelectCourse",
        component: SelectCourse,
        meta: {
          title: "Select a course",
          courseSelectionGuard: true,
        },
      },
      /**
       * User see details about the course
       */
      {
        path: "course/:id",
        name: "CoursePage",
        component: CoursePage,
        meta: {
          title: "Course Details",
          courseSelectionGuard: true,
        },
      },
      {
        path: "pay/select/:slug",
        name: "DepositPage",
        component: DepositPage,
        meta: {
          title: "Pay For Learning Certificate",
        },
      },
      {
        path: "pay/:slug",
        name: "ApplicationPayment",
        component: ApplicationPayment,
        meta: {
          title: "Pay For Learning Certificate",
        },
      },
      {
        path: "pay/confirm",
        name: "PaymentConfirmationCountDown",
        component: PaymentConfirmationCountDown,
        meta: {
          title: "Pay For Learning Certificate",
        },
      },
      {
        path: "portfolio",
        name: "PeigesIndex",
        component: PeigesIndex,
        meta: {
          title: "Peiges Portfolio",
        },
      },
    ],
  },
];

export default dashboardRoutes;
